import { Component, OnInit, OnDestroy, ViewRef, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { AuthorizationsService } from '../../authorizations/authorizations.service';
import { PackagesService } from '../packages.service';

@Component({
  selector: 'app-owners',
  templateUrl: './owners.component.html',
  styleUrls: ['./owners.component.scss']
})
export class OwnersComponent implements OnInit, OnDestroy {

  //initialize nature of logs
  viewNatures = [
    { label: 'Owners view', value: 'Owners view' },
    { label: 'Workflows view', value: 'Workflows view' }
  ];
  selectedViewNature: string = 'Owners view';
  //variable to get all the owners (active and inactive)
  owners: any[] = [];
  filteredOwners: any[] = [];
  allShownOwners: boolean = true;
  //subscription to get all the owners when updated
  ownersSubscription = new Subscription();

  //form control to initialize the selected owner in the list
  owner: any[] = []
  //subscription to update the selected owner (when changing)
  ownerSubscription = new Subscription();

  //all the possible workflows
  workflows: any[] = [];
  filteredWorkflows: any[] = [];
  //subscription so that workflows are automatically updated
  workflowsSubscription = new Subscription();
  //form control to initialize the selected owner in the list
  workflow: any[] = []
  //subscription to update the selected owner (when changing)
  workflowSubscription = new Subscription();

  constructor(private authorizationsServices: AuthorizationsService,
    private packagesService: PackagesService,
    private router: Router,
    private cd: ChangeDetectorRef) { }

  ngOnInit() {
    //allows to get all the active and inactive owners, all the sources, webservices, workflows and users
    this.authorizationsServices.getAllWorkflows();
    this.packagesService.getOwnerData();

    //allows to update the owners when one is changed // created
    this.ownersSubscription = this.packagesService.ownersChange
      .subscribe((owners: any) => {
        this.owners = owners;
        this.filterActive('');
        this.cd.detectChanges();

      })


    this.workflowsSubscription = this.authorizationsServices.workflowsChange
      .subscribe((workflows: any) => {
        this.workflows = workflows;
        this.filterActiveWorkflows('');
        if (this.cd && !(this.cd as ViewRef).destroyed) {
          this.cd.detectChanges();
        }
      })

  }// ng_init


  changeOwner() {
    if (this.owner.length != 0) {
      if (this.owner[0].UserName) {
        this.router.navigate(["admin", "settings", "owners", "owner", this.owner[0].UserName])
      }
    }
    else {
      this.router.navigate(["admin", "settings", "owners"])
    }
  }

  changeWorkflow() {
    if (this.workflow.length != 0) {
      if (this.workflow[0].WFName) {

        this.router.navigate(["admin", "settings", "owners", "workflow", this.workflow[0].WFName])
      }
    }
    else {
      this.router.navigate(["admin", "settings", "owners"])
    }

  }

  //filter active or all owners
  filterActive(param) {
    this.owner = [];
    this.changeOwner()
    this.filteredOwners = JSON.parse(JSON.stringify(this.owners))
  }
  //filter active or all workflows
  filterActiveWorkflows(param) {
    this.workflow = [];
    this.changeWorkflow()
    this.filteredWorkflows = JSON.parse(JSON.stringify(this.workflows))
  }
  //allows to clear the form control when clicking on new

  viewNatureChange() {
    this.workflow = [];
    this.owner = [];
    this.changeWorkflow();
    this.changeOwner()
    this.router.navigate(['admin', 'settings', 'owners'])
  }
  //this is to destroy subscriptions when component is destroyed
  ngOnDestroy() {
    this.ownersSubscription.unsubscribe();
    this.ownerSubscription.unsubscribe();
    this.workflowsSubscription.unsubscribe();
    this.workflowSubscription.unsubscribe();
  }

}
