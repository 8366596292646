import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'cardabel-topics-dashboard-topic-card-item',
  templateUrl: './topics-dashboard-topic-card-item.component.html',
  styleUrls: ['./topics-dashboard-topic-card-item.component.css']
})
export class TopicsDashboardTopicCardItemComponent implements OnInit {

  @Input() itemTitle: string = "";
  @Input() itemNumber: number = 0;
  
  constructor() { }

  ngOnInit() {
  }

}
