import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Configuration } from "./configuration.model";

@Injectable({
  providedIn: "root"
})
export class ConfigurationLoader {
  private readonly CONFIGURATION_URL = "./assets/configurations/config.json";
  private _configuration: Configuration = {cardabelUrl:"", appNavigationUrl:"", appUrl:"", webUrl:"", scatterUrl:"", graphUrl:""};

  constructor(private _http: HttpClient) {}

  public loadConfiguration() {
    return this._http
      .get(this.CONFIGURATION_URL)
      .toPromise()
      .then((configuration: Configuration) => {
        this._configuration = configuration;
        return configuration;
      })
      .catch((error: any) => {
        console.error(error);
      });
  }

  public getConfiguration() {
    return this._configuration;
  }
}
