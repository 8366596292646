
import { Component, OnInit, NgModule, ViewEncapsulation, Input, SimpleChanges, ElementRef, ChangeDetectionStrategy, Output, ViewChild, ChangeDetectorRef, forwardRef, ViewRef } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule, FormsModule, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

import { TooltipPosition } from '@angular/material';
import { EventEmitter } from '@angular/core';

import { SharedGeneralFunctionsService } from '../shared-general-functions.service';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { CardabelListBoxModule } from '../cardabel-listbox/cardabel-listbox.component';
import { DropdownModule } from 'primeng/dropdown';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CardabelSelectionListModule } from '../cardabel-selection-list/cardabel-selection-list.component';


@Component({
  selector: 'cardabel-dropdown-listbox',
  templateUrl: './cardabel-dropdown-listbox.component.html',
  styleUrls: ['./cardabel-dropdown-listbox.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CardabelDropdownListboxComponent),
    multi: true
  }],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardabelDropdownListboxComponent implements OnInit {

  //init inputs
  @Input() title: string;
  @Input() options: any[];
  @Input() filterBy: string = "";
  @Input() multiple: boolean;
  @Input() placeholder: string;
  @Input() btnGroup: boolean = false;
  @Input() disabled: boolean = false;
  @Input() small: boolean = false;

  @Output() change: EventEmitter<any> = new EventEmitter();
  @Input() disabledOption: boolean = false;
  @Input() inputSelectedOptions: any[] = [];
  @Input() tooltipText: any;
  @Input() tooltipDisabled: boolean;
  @Input() tooltipPosition: string;
  @Input() dropdownAbove: boolean = false;
  @Input() dropdownAbove1: boolean = false;

  @ViewChild("myDrop") myDrop;

  value;
  onChange;


  myControl = new FormControl('');


  allOptionsSelected: boolean = false;

  //Tooltip
  positionOptions: TooltipPosition[] = ['after', 'before', 'above', 'below', 'left', 'right'];
  position = new FormControl(this.positionOptions[0]);

  //init window height
  windowHeight: number = 1200;
  windowMiddle: number = 600;

  listboxPosition: any = { top: 0, bottom: 0, left: 0, right: 0, width: 0 }
  name1: string = "";
  name2: string = "";
  @ViewChild("dropdownListbox") dropdownListbox;
  @ViewChild("dropdownButton") dropdownButton;

  constructor(private cd: ChangeDetectorRef,
    private sharedFunctionsService: SharedGeneralFunctionsService) { }
  ngOnInit() {
    console.log(this.options)

    //set window height
    this.windowHeight = window.innerHeight;
    this.windowMiddle = this.windowHeight / 2;

    if (this.filterBy.indexOf('.') > -1) {
      this.name1 = this.filterBy.substring(0, this.filterBy.indexOf('.'));
      this.name2 = this.filterBy.substring(this.filterBy.indexOf('.') + 1, this.filterBy.length);

      console.log(this.name1);
      console.log(this.name2);
    }

    this.changeSelectedOptions()



  }

  ngOnChanges(changes: SimpleChanges) {
    if ('disabled' in changes) {
      console.log(changes)
    }
  }

  changeSelectedOptions() {

    if (this.inputSelectedOptions) { // agnes 27/03/2024
     // if (this.inputSelectedOptions.length != 0) {agnes 26/04/2024
      // in case of multiple= true or mutliple = false, an array is generated
        this.change.emit({ value: this.inputSelectedOptions })
    //  }
    }
    if (this.cd && !(this.cd as ViewRef).destroyed) {
      this.cd.detectChanges();
    }
    //  this.onChange(value)

    // this.setFormControlValue();

  }

  //make sure list box is closed
  /*public close() {
    this.myDrop.close();
  }

  //control value accessor so that formcontrol works
  writeValue(value) {
    this.value = value;
  }

  registerOnChange(fn) {
    
    this.onChange = fn;
  }

  registerOnTouched(fn) {
  }
*/
  //return correct height class

  getDropdownClass(position) {

    if (position > this.windowMiddle) {
      if (position - 350 < 0) {
        return "dropdown-sm"
      }
      else {
        return "dropdown-md"
      }
    }
    else {
      if (position + 350 > window.innerHeight) {
        return "dropdown-sm"
      }
      else {
        return "dropdown-md"
      }
    }

  }

  //((cardabelDropdown.getBoundingClientRect().top +300 > windowHeight ) && (cardabelDropdown.getBoundingClientRect().top -300 <0))  ? 'dropdown-sm': 'dropdown-md'

}

@NgModule({
  imports: [
    CardabelListBoxModule, CommonModule, ScrollingModule, ReactiveFormsModule, FormsModule, DropdownModule, NgbModule, CardabelSelectionListModule
  ],
  exports: [RouterModule, ScrollingModule, CardabelDropdownListboxComponent, FormsModule, CardabelListBoxModule, DropdownModule, NgbModule],
  declarations: [CardabelDropdownListboxComponent],
  providers: [

  ]
})
export class CardabelDropdownListBoxModule {

}
