
export class AnalysisTab {
    public name: string;
    public workflow: string;
    public type: string;
    public table: string;
    public subtable: string;
    public segAttributeValue: string;
    public dynSegAttributeValue: string;
    public tabType: string;
    public subtableType: string;
    
    constructor(
        name: string,
        workflow: string,
        type: string,
        table: string,
        subtable: string,
        segAttributeValue: string,
        dynSegAttributeValue: string,
        tabType: string,
        subtableType: string
        ) {
        this.name = name;
        this.workflow = workflow;
        this.type = type;
        this.table = table;
        this.subtable =subtable;
        this.segAttributeValue = segAttributeValue;
        this.dynSegAttributeValue = dynSegAttributeValue;
        this.tabType = tabType;
        this.subtableType = subtableType;

    }
}