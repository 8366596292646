import { Component, OnInit, ViewEncapsulation, ChangeDetectorRef, OnDestroy, ViewRef, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { PackagesService } from '../../packages.service';
import { Subscription } from 'rxjs';
import { SharedGeneralFunctionsService } from 'src/app/shared/shared-general-functions.service';
import { SharedInitializationService } from 'src/app/shared/shared-common-initialization.service';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-workflow-topics',
  templateUrl: './workflow-topics.component.html',
  styleUrls: ['./workflow-topics.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkflowTopicsComponent implements OnInit, OnDestroy {

  //name of selected topic
  workflowName: string = "";
  //data (isActive, guitabs, workflows...)for selected topic
  workflowData: {
    workflowName: string,
    isActive: boolean,
    topics: any[],
  }[] = [];
  //subscription so that html elements are automatically updated
  workflowDataSubscription = new Subscription();
  //all the possible workflows
  topics: any[] = [];
  //subscription so that workflows are automatically updated
  topicsSubscription = new Subscription();

  //form controls
  //form control for the workflows assigned to the topic
  selectedTopics: any[] = []
  //check whether the admin has changed one of these categories
  topicsChanged: boolean = false;
  //sets data for update (converting from array of objetcs, to array of strings)
  newTopics: any[] = [];

  constructor(private route: ActivatedRoute,
    private packagesServices: PackagesService,
    private sharedFunctionsService: SharedGeneralFunctionsService,
    private confirmationService: ConfirmationService,
    private sharedService: SharedInitializationService,
    private cd: ChangeDetectorRef) { }

  ngOnInit() {

    //this is to get the topicname and topicdata according to the param passed in the url
    this.route.params
      .subscribe((params: Params) => {
        if (params['workflowname']) {
          this.workflowName = params['workflowname'];
          //this is to get the users, sources, workflows and webservices
          this.topics = this.packagesServices.returnAllTopics();

          if (this.cd && !(this.cd as ViewRef).destroyed) {
            this.cd.detectChanges();
          }
          //reset variables
          this.selectedTopics = [];
          this.changeTopics();

          if (this.cd && !(this.cd as ViewRef).destroyed) {
            this.cd.detectChanges();
          }
          this.packagesServices.getSingleWorkflowTopicsData(this.workflowName);
        }
      })

    //this is to automatically update workflow data information
    this.workflowDataSubscription = this.packagesServices.workflowDataChange
      .subscribe((workflowData: any[]) => {
        this.workflowData = workflowData;

        if (this.workflowData.length != 0) {

          if (this.topics.length != 0) {
            this.getSelectedData("Topics", "topicName")
          }

        }
        // reinitialisation when topic changes;
        this.topicsChanged = false;
        if (this.cd && !(this.cd as ViewRef).destroyed) {
          this.cd.detectChanges();
        }
      })


    this.topicsSubscription = this.packagesServices.topicsChange
      .subscribe((topics: any) => {
        this.topics = topics;
        if (this.cd && !(this.cd as ViewRef).destroyed) {
          this.cd.detectChanges();
        }

        if (this.workflowData.length != 0) {
          if (this.sharedFunctionsService.objectsEqual(this.workflowData[0].topics, this.selectedTopics) === false) {

            this.getSelectedData("Topics", "topicName");
          }
        }
      })


  }// end on init

  changeTopics() {
    this.newTopics = [];
    for (var i in this.selectedTopics) {
      this.newTopics.push(this.selectedTopics[i].topicName)
    }
    if (this.workflowData.length != 0) {
      if (this.sharedFunctionsService.arraysEqual(this.newTopics, this.workflowData[0].topics) === false) {
        this.topicsChanged = true;
      }
      else {
        this.topicsChanged = false;
      }
    }
    else {
      this.topicsChanged = true;
    }

  }
  //allows to update the selected workflows, users...for the topic in the list boxes
  getSelectedData(param, key) {

    let paramLowerCase = param.toLowerCase();

    let varSelected = "varSelected" + param;
    this[varSelected] = [];
    for (var i in this.workflowData[0][paramLowerCase]) {
      for (var j in this[paramLowerCase]) {
        if (this[paramLowerCase][j][key] === this.workflowData[0][paramLowerCase][i]) {
          this[varSelected].push(this[paramLowerCase][j]);

          break;
        }
      }
    }

    let selected = "selected" + param;
    this[selected] = this[varSelected];
  }

  //allows to update the topic data, delete the topic or reactivate the topic
  applyUpdateWorkflowTopicsData(param) {
    //let isActive = this.topicData[0].isActive;
    let isActive = true;

    if (param === "update") {
      isActive = true;
    }
    else if (param === "delete") {
      isActive = false;
    }

    if (param === "update") {
      this.packagesServices.createUpdateWorkflowTopicsData(
        this.workflowName,
        isActive,
        this.newTopics
      )

    }
  }

  //this allows to get the help documentation for editing a topic
  getHelpDocumentation() {
    let parameters = ["admin", "settings", "topics", "edittopic"];
    this.sharedService.showHelpDocumentation("Admin Settings - Edit Topic");

    this.sharedService.getHelpDocumentation(parameters)
  }

  //this is to make sure the subscription won't work once the component is destroyed
  ngOnDestroy() {
    this.workflowDataSubscription.unsubscribe();
    this.topicsSubscription.unsubscribe();

  }
}

