import { Injectable, ViewRef } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import * as d3 from 'd3';

import { DashboardService } from 'src/app/dashboard/dashboard.service';
import { SharedGeneralFunctionsService } from 'src/app/shared/shared-general-functions.service';
import { AppService } from 'src/app/app.service';
import { D } from '@angular/cdk/keycodes';


@Injectable()
export class DashboardTreemapChartService {

    constructor(private httpClient: HttpClient,
        private appService: AppService,
        private router: Router,
        private dashboardService: DashboardService,
        private sharedGeneralService: SharedGeneralFunctionsService
    ) { }


    setTreemap(vis) {
        vis.colorScale = d3.scaleOrdinal(vis.colorScheme)

        if (vis.treemapData.length > vis.top) {
            vis.treemapData.splice(vis.top);
        }

        let headers = vis.allHeaders.concat(vis.newHeaders)
        let nestObject = this.getHeaders(vis.treemapData, headers, vis, "computeAggMethod");
        let nest = this.getHeaders(vis.treemapData, headers, vis, "");

        console.log(nestObject);

        var treemap = d3.treemap()
            .size([vis.width, vis.height])
            .paddingOuter(3)
            .paddingTop(30)
            .paddingInner(1)
            .round(true);

        var root = this.getRoot(nest);
        var root2 = this.getRoot(nestObject)


        treemap(root);


        let id = "#" + vis.type + vis.header + vis.tabAlias + vis.mainTab + vis.graphIndex;
        d3.selectAll(id).selectAll(".node").remove();

        vis.treemap = d3.selectAll(id)
            .attr("style", "transform:translateY(15px)")
            .selectAll(".node")
            .data(root.descendants())
            .enter().append("rect")
            .attr("class", "node")
            .attr("id", function (d, i) { return vis.type + vis.mainTab + vis.tabAlias + vis.header + vis.graphIndex + i })
            .attr("title", function (d, i) {
                /*  if (d.data.value) {
                      return d.data.key + "\n" + d.data.value;
                  }
                  return d.data.key; */
            })
            .style("left", function (d) { return d.x0 + "px" })
            .style("top", function (d) { return d.y0 + "px" })
            .style("width", function (d) { return d.x1 - d.x0 + "px"; })
            .style("height", function (d) { return d.y1 - d.y0 + "px"; })
            .style("background", function (d) {
                // if (d.depth === 0) return "white";
                return vis.colorScale(d.height);
            })
            .style("opacity", function (d) {
                if (d.depth > 1) {
                    return 0.7;
                }
                return 1;
                // while (d.depth > 1) d = d.parent; return color(d.data.key);
            })
            .style("border", "1px solid white")

        vis.treemap.selectAll("rect")
            .on("click", function (d) {
                console.log(d);
            })

        vis.treemapTexts = vis.treemap
            .append("div")
            .attr("class", "node-label")
            .text(function (d, i) {
                if (d.depth === 0) {
                    return "Total:"
                }
                if (d.data.value === undefined && d.data.key === undefined) {
                    console.log(d);
                    return "";
                }

                return d.data.key
            })
            .append("span")
            .attr("class", "node-value")
            .text(function (d, i) {
                if (d.depth === 0) {
                    return vis.dashboardAxisFormattingService.getTickFormat(vis.dashboardTreemapChartService.getAggValue(vis, vis.treemapData), vis)
                }
                if (d.data.value === undefined && d.data.key === undefined) {
                    return "";
                }
                if (root2.descendants()[i]) {
                    return "     " + vis.dashboardAxisFormattingService.getTickFormat(root2.descendants()[i].data.aggMethod, vis)
                }
                else {
                    return "     "
                }

            })

        return vis;
    }

    cardabelNest(data, key) {

        var nest2 = d3.nest()
            .key(function (d) { return d[key]; })
            .rollup(function (leaves) {
                console.log(leaves);

                return leaves.length;
            })
            .entries(data)

        return nest2;
    }

    getHeaders(data, keys, vis, param) {

        let nestObject = this.cardabelNest(data, keys[0]);
        nestObject = this.getHeaderValues(0, keys, data, nestObject, vis, param)
        console.log(nestObject)
        return nestObject;

    }

    getHeaderValues(k, keys, filteredData, nestObject, vis, param) {
        for (var n in nestObject) {
            let valueFilteredData = filteredData.filter(function (item) {
                if (item[keys[k]] != null) {
                    return item[keys[k]].toString() === nestObject[n].key.toString();
                }
                else {
                    return item[keys[k]] === nestObject[n].key;
                }

            })

            if (param === "computeAggMethod") {
                nestObject[n].aggMethod = nestObject[n].value;
                if (vis.aggMethod != "count") {

                    nestObject[n].aggMethod = this.getAggValue(vis, valueFilteredData)

                }

            }

            if ((+k + 1) < keys.length) {
                if (param != "computeAggMethod") {
                    delete nestObject[n].value;
                }
                //delete nestObject[n].value;
                nestObject[n].values = this.cardabelNest(valueFilteredData, keys[k + 1]);
                nestObject[n].values = this.getHeaderValues(k + 1, keys, valueFilteredData, nestObject[n].values, vis, param)
            }

        }

        return nestObject;

    }

    getAggValue(vis, valueFilteredData) {
        let aggValue = 0;
        let aggMethod;
        if (vis.aggMethod === "min" || vis.aggMethod === "max") {
            aggValue = valueFilteredData[0][vis.numericHeader]
        }
        aggMethod = valueFilteredData.reduce(function (res, item) {
            if (vis.aggMethod === "sum" || vis.aggMethod === "average") {
                return res + item[vis.numericHeader];
            }
            else if (vis.aggMethod === "min" || (vis.aggMethod === "max")) {
                return Math[vis.aggMethod](res, item[vis.numericHeader])
            }

        }, aggValue)

        if (vis.aggMethod === "average") {
            aggMethod = aggMethod / valueFilteredData.length;
        }
        return aggMethod;
    }

    getRoot(object) {
        var root = d3.hierarchy({ values: object }, function (d) { return d.values; })
            .sum(function (d) {
                if (isNaN(d.value)) {
                    return 0;
                }
                return Math.abs(d.value);
            })
            .sort(function (a, b) { return Math.abs(b.value) - Math.abs(a.value); });

        return root;
    }

}