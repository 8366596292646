import { isDevMode, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './admin.component';
import { AdminStartComponent } from './admin-start/admin-start.component';
import { AuthorizationsComponent } from './authorizations/authorizations.component';
import { MarketDataComponent } from './market-data/market-data.component';
import { SourceManagementComponent } from './source-management/source-management.component';
import { AuthGuard } from '../auth/auth-guard.service';
import { SettingsModule } from './settings/settings.module';
import { AuthorizationsModule } from './authorizations/authorizations.module';
import { MarketDataModule } from './market-data/market-data.module';
import { SourceManagementModule } from './source-management/source-management.module';

const adminRoutes = [
        { path: '', component: AdminComponent, children: [
            { path: '', component: AdminStartComponent, canActivate: [AuthGuard] },
            { path: 'settings', loadChildren: "./settings/settings.module#SettingsModule"},
            { path: 'authorizations', loadChildren: "./authorizations/authorizations.module#AuthorizationsModule" },
            { path: 'marketdata', loadChildren: "./market-data/market-data.module#MarketDataModule"},
            { path: 'sourcemanagement', loadChildren: "./source-management/source-management.module#SourceManagementModule"},
           
          ] },
    ];


@NgModule({
    imports:[
        RouterModule.forChild(adminRoutes)
    ],
    exports: [RouterModule],
    providers: [
        
    ]
})
export class AdminRoutingModule {

}