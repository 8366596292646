/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./newtopic.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "../../../../shared/cardabel-selection-list/cardabel-selection-list.component.ngfactory";
import * as i4 from "../../../../shared/cardabel-selection-list/cardabel-selection-list.component";
import * as i5 from "./newtopic.component";
import * as i6 from "../../../authorizations/authorizations.service";
import * as i7 from "../../packages.service";
import * as i8 from "../../../../shared/shared-common-initialization.service";
var styles_NewtopicComponent = [i0.styles];
var RenderType_NewtopicComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_NewtopicComponent, data: {} });
export { RenderType_NewtopicComponent as RenderType_NewtopicComponent };
export function View_NewtopicComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "col-xs-12 col-md-12 col-sm-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "pi pi-question-circle piHelp"], ["id", "helpIcon"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.getHelpDocumentation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 7, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "col-md-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [["class", "col-md-10"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "btn-group cardabel-new-topic-btn-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "button", [["class", "btn btn-primary btn-sm"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.applyCreateTopicData() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "i", [["class", "fa fa-cog"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Apply "])), (_l()(), i1.ɵeld(11, 0, null, null, 20, "div", [["id", "newTopicContainer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 11, "div", [["class", "row margin-top-20"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "div", [["class", "col-md-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 2, "div", [["class", "col-md-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "h5", [["class", "topicTitle textAlignCenter"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["New Topic Name"])), (_l()(), i1.ɵeld(17, 0, null, null, 6, "div", [["class", "col-md-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 5, "input", [["autocomplete", "off"], ["class", "form-control"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 19)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 19).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 19)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 19)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.topicName = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(19, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(21, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(23, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵeld(24, 0, null, null, 7, "div", [["class", "row margin-top-40"]], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 0, "div", [["class", "col-md-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(26, 0, null, null, 4, "div", [["class", "col-md-5 textAlignCenter"]], null, null, null, null, null)), (_l()(), i1.ɵeld(27, 0, null, null, 1, "h5", [["class", "topicTitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Workflows"])), (_l()(), i1.ɵeld(29, 0, null, null, 1, "cardabel-selection-list", [["id", "workflowContainer"]], [[1, "data-cy", 0]], [[null, "changeSelectedOptions"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("changeSelectedOptions" === en)) {
        _co.selectedWorkflows = $event.value;
        var pd_0 = (_co.changeWorkflow() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_CardabelSelectionListComponent_0, i3.RenderType_CardabelSelectionListComponent)), i1.ɵdid(30, 638976, null, 0, i4.CardabelSelectionListComponent, [], { title: [0, "title"], multipleSelection: [1, "multipleSelection"], options: [2, "options"], optionLabel: [3, "optionLabel"], height: [4, "height"], inputSelectedOptions: [5, "inputSelectedOptions"] }, { changeSelectedOptions: "changeSelectedOptions" }), (_l()(), i1.ɵeld(31, 0, null, null, 0, "div", [["class", "col-md-1"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.topicName; _ck(_v, 21, 0, currVal_8); var currVal_10 = "workflows"; var currVal_11 = true; var currVal_12 = _co.workflows; var currVal_13 = "WFName"; var currVal_14 = 440; var currVal_15 = _co.selectedWorkflows; _ck(_v, 30, 0, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.workflowsChanged || (_co.topicName === "")); _ck(_v, 7, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 23).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 23).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 23).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 23).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 23).ngClassValid; var currVal_6 = i1.ɵnov(_v, 23).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 23).ngClassPending; _ck(_v, 18, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_9 = "admin-settings-newtopic-workflow-selection-list"; _ck(_v, 29, 0, currVal_9); }); }
export function View_NewtopicComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-newtopic", [], null, null, null, View_NewtopicComponent_0, RenderType_NewtopicComponent)), i1.ɵdid(1, 245760, null, 0, i5.NewtopicComponent, [i6.AuthorizationsService, i7.PackagesService, i8.SharedInitializationService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NewtopicComponentNgFactory = i1.ɵccf("app-newtopic", i5.NewtopicComponent, View_NewtopicComponent_Host_0, {}, {}, []);
export { NewtopicComponentNgFactory as NewtopicComponentNgFactory };
