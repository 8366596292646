import { Injectable } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CustomHttpParamEncoder } from 'src/app/shared/custom-http-param-encoder';

@Injectable()
export class PackagesService {
    constructor(private appService: AppService,
        private httpClient: HttpClient,
    ) { }

    webUrl = this.appService.getWebUrl();

    //all the inactive and active topics
    topics: any[] = [];
    //automatically updates the topics, called in topics and users components
    topicsChange = new Subject<any>();

    //set all the topic data (is active, workflow etc)
    topicData: any[] = [];
    //subject to update the relevant data in topic component when selected topic is changed
    topicDataChange = new Subject<any>();

    //set all the available workflows
    workflows: any[] = [];
    //subject to update workflows in topic and newtopic components
    workflowsChange = new Subject<any>();

    //sets the topic data for reports tab
    topicsReportData: any[] = [];
    //subject to load topic data for reports, it automatically updates it
    topicsReportDataChange = new Subject<any>();

    //set all the workflow data (is active, topics etc)
    workflowData: any[] = [];
    //subject to update the relevant data in workflowtopic component when selected workflow is changed
    workflowDataChange = new Subject<any>();

    //all the inactive and active topics
    owners: any[] = [];
    //automatically updates the topics, called in topics and users components
    ownersChange = new Subject<any>();
    //set all the topic data (is active, workflow etc)
    ownerData: any[] = [];
    //subject to update the relevant data in topic component when selected topic is changed
    ownerDataChange = new Subject<any>();
    //set all the available owner workflows
    ownerWorkflows: any[] = [];
    //subject to update workflows in topic and newtopic components
    ownerWorkflowsChange = new Subject<any>();

    ownersWorkflowChange = new Subject<any>();


    //loads the topic data for all topics, only admin has access to that
    //called in topic component
    getTopicData() {

        this.appService.startSpin2();

        const url = this.webUrl;
        const webservice = "AdminGetTopicsList";

        const completeUrl = url + webservice;

        const headers = this.appService.getHeaders();

        //create new http params
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })


        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(

                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {

                response = JSON.parse(response);
                if (response.statusCode > -1) {
                    this.topics = response.tableRows;
                    this.topicsChange.next(this.topics);
                }
                else {
                    this.appService.showMessage('Error', response.statusText)
                }
                this.appService.stopSpin2();
            }, (error) => {
                this.appService.showMessage('Error', error.statusText);
                this.appService.stopSpin2();
            });


    }

    //gets all the topic data for a specific topic
    //called in admin topic component
    getSingleTopicData(topicname) {
        this.appService.startSpin();

        const url = this.webUrl;
        const webservice = "AdminGetTopic";
        const completeUrl = url + webservice;

        const headers = this.appService.getHeaders();
        //create new http params
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("topicName", topicname)

        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(
                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {

                response = JSON.parse(response);
                if (response.statusCode > -1) {
                    this.topicData = response.tableRows;
                    this.topicDataChange.next(this.topicData);
                }
                else {
                    this.appService.showMessage('Error', response.statusText)
                }
                this.appService.stopSpin();
            }, (error) => {
                this.appService.showMessage('Error', error.statusText);
                this.appService.stopSpin();
            });


    }

    //gets all the topic data for a specific topic
    //called in admin topic component
    getSingleWorkflowTopicsData(workflowname) {
        this.appService.startSpin();

        const url = this.webUrl;
        const webservice = "AdminGetWorkflowTopics";
        const completeUrl = url + webservice;

        const headers = this.appService.getHeaders();
        //create new http params
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("workflowName", workflowname)

        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(
                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {

                response = JSON.parse(response);
                if (response.statusCode > -1) {
                    this.workflowData = response.tableRows;
                    this.workflowDataChange.next(this.workflowData);
                }
                else {
                    this.appService.showMessage('Error', response.statusText)
                }
                this.appService.stopSpin();
            }, (error) => {
                this.appService.showMessage('Error', error.statusText);
                this.appService.stopSpin();
            });


    }

    //gets all the available workflows
    //called in admin topics component
    getAllWorkflows() {
        this.appService.startSpin();
        this.workflows = [];

        const url = this.webUrl;
        const webservice = "AdminAccessGetAllWorkflows";
        const completeUrl = url + webservice;

        const headers = this.appService.getHeaders();

        //create new http params
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })

        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(
                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {

                response = JSON.parse(response);
                if (response.statusCode > -1) {

                    this.workflows = response.tableRows;
                    this.workflowsChange.next(this.workflows);
                }
                else {
                    this.appService.showMessage('Error', response.statusText)
                }
                this.appService.stopSpin();
            }, (error) => {
                this.appService.showMessage('Error', error.statusText);
                this.appService.stopSpin();
            });


    }

    //returns all the available workflows
    //called in admin topic and new topic component;
    returnAllWorkflows() {
        return this.workflows;
    }

    returnAllTopics() {
        return this.topics;
    }

    //updates the topic authorizations
    //called in admin topic component
    createUpdateTopicData(topicName, isActive, workflowsList) {
        this.appService.startSpin();

        const url = this.webUrl;
        const webservice = "AdminCreateUpdateTopic";
        const completeUrl = url + webservice;

        const headers = this.appService.getHeaders();

        workflowsList = JSON.stringify(workflowsList);

        //create new http params
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("topicName", topicName)
            .set("isActive", isActive)
            .set("workflowsList", workflowsList)

        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(

                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {

                response = JSON.parse(response);
                if (response.statusCode > -1) {
                    this.appService.showMessage("Success", response.statusText)
                    this.getTopicData();
                    this.getSingleTopicData(topicName);
                }
                else {
                    this.appService.showMessage('Error', response.statusText)
                }
                this.appService.stopSpin();
            }, (error) => {
                this.appService.showMessage('Error', error.statusText);
                this.appService.stopSpin();
            });


    }


    //updates the topic authorizations
    //called in admin topic component
    createUpdateWorkflowTopicsData(workflowName, isActive, topicsList) {
        this.appService.startSpin();

        const url = this.webUrl;
        const webservice = "AdminCreateUpdateWorkflowTopics";
        const completeUrl = url + webservice;

        const headers = this.appService.getHeaders();

        topicsList = JSON.stringify(topicsList);

        //create new http params
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("workflowName", workflowName)
            .set("isActive", isActive)
            .set("topicsList", topicsList)

        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(

                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {

                response = JSON.parse(response);
                if (response.statusCode > -1) {
                    this.appService.showMessage("Success", response.statusText)
                    this.getAllWorkflows();
                    this.getSingleWorkflowTopicsData(workflowName);
                }
                else {
                    this.appService.showMessage('Error', response.statusText)
                }
                this.appService.stopSpin();
            }, (error) => {
                this.appService.showMessage('Error', error.statusText);
                this.appService.stopSpin();
            });


    }

    /////OWNERS
    getOwnerData() {

        this.appService.startSpin2();
        const url = this.webUrl;
        const webservice = "WFGetUserNames";

        const completeUrl = url + webservice;

        const headers = this.appService.getHeaders();

        //create new http params
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })

        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(
                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {

                response = JSON.parse(response);
                if (response.statusCode > -1) {
                    this.owners = response.tableRows;
                    this.ownersChange.next(this.owners);
                }
                else {
                    this.appService.showMessage('Error', response.statusText)
                }
                this.appService.stopSpin2();
            }, (error) => {
                this.appService.showMessage('Error', error.statusText);
                this.appService.stopSpin2();
            });


    }
    getOwnerWorkflowList(userName) {
        this.appService.startSpin();
        this.workflows = [];
        const url = this.webUrl;
        const webservice = "AdminGetOwnerWorkflows";
        const completeUrl = url + webservice;

        const headers = this.appService.getHeaders();

        //create new http params
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("userName", userName)

        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(
                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {

                response = JSON.parse(response);
                if (response.statusCode > -1) {

                    this.ownerWorkflows = response.tableRows;
                    this.ownerWorkflowsChange.next(this.ownerWorkflows);
                }
                else {
                    this.appService.showMessage('Error', response.statusText)
                }
                this.appService.stopSpin();
            }, (error) => {
                this.appService.showMessage('Error', error.statusText);
                this.appService.stopSpin();
            });


    }
    //updates the topic authorizations
    //called in admin topic component
    createUpdateOwnerData(userName, isActive, workflowsList) {
        this.appService.startSpin();

        const url = this.webUrl;
        const webservice = "AdminCreateUpdateOwner";
        const completeUrl = url + webservice;

        const headers = this.appService.getHeaders();

        workflowsList = JSON.stringify(workflowsList);

        //create new http params
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("userName", userName)
            .set("isActive", isActive)
            .set("workflowsList", workflowsList)

        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(
                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {
                response = JSON.parse(response);
                if (response.statusCode > -1) {
                    this.appService.showMessage("Success", response.statusText)
                    this.getOwnerData();
                    this.getSingleOwnerData(userName);
                }
                else {
                    this.appService.showMessage('Error', response.statusText)
                }
                this.appService.stopSpin();
            }, (error) => {
                this.appService.showMessage('Error', error.statusText);
                this.appService.stopSpin();
            });


    }

    //gets all the Owner data for a specific Owner
    //called in admin Owner component
    getSingleOwnerData(UserName) {
        this.appService.startSpin();

        const url = this.webUrl;
        const webservice = "AdminGetOwner";
        const completeUrl = url + webservice;

        const headers = this.appService.getHeaders();
        //create new http params
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("userName", UserName)

        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(
                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {

                response = JSON.parse(response);
                if (response.statusCode > -1) {
                    this.ownerData = response.tableRows;
                    this.ownerDataChange.next(this.ownerData);
                }
                else {
                    this.appService.showMessage('Error', response.statusText)
                }
                this.appService.stopSpin();
            }, (error) => {
                this.appService.showMessage('Error', error.statusText);
                this.appService.stopSpin();
            });


    }

    //gets all the Owner data for a specific topic
    //called in admin Owner component
    getSingleWorkflowOwnersData(workflowname) {
        this.appService.startSpin();

        const url = this.webUrl;
        const webservice = "AdminGetWorkflowOwners";
        const completeUrl = url + webservice;

        const headers = this.appService.getHeaders();
        //create new http params
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("workflowName", workflowname)

        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(

                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {

                response = JSON.parse(response);
                if (response.statusCode > -1) {
                    this.workflowData = response.tableRows;
                    this.workflowDataChange.next(this.workflowData);
                }
                else {
                    this.appService.showMessage('Error', response.statusText)
                }
                this.appService.stopSpin();
            }, (error) => {
                this.appService.showMessage('Error', error.statusText);
                this.appService.stopSpin();
            });


    }
    //gets all the Owner data for a specific topic
    //called in admin Owner component
    getSingleWorkflowOwnersList(workflowname) {
        this.appService.startSpin();

        const url = this.webUrl;
        const webservice = "AdminGetWorkflowOwnersList";
        const completeUrl = url + webservice;

        const headers = this.appService.getHeaders();
        //create new http params
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("workflowName", workflowname)

        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(
                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {

                response = JSON.parse(response);
                if (response.statusCode > -1) {
                    this.owners = [...response.tableRows];
                    this.ownersWorkflowChange.next(this.owners);
                }
                else {
                    this.appService.showMessage('Error', response.statusText)
                }
                this.appService.stopSpin();
            }, (error) => {
                this.appService.showMessage('Error', error.statusText);
                this.appService.stopSpin();
            });


    }

    //updates the topic authorizations
    //called in admin topic component
    createUpdateWorkflowOwnersData(workflowName, isActive, ownersList) {
        this.appService.startSpin();

        const url = this.webUrl;
        const webservice = "AdminCreateUpdateWorkflowOwners";
        const completeUrl = url + webservice;

        const headers = this.appService.getHeaders();

        ownersList = JSON.stringify(ownersList);

        //create new http params
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("workflowName", workflowName)
            .set("isActive", isActive)
            .set("ownersList", ownersList)

        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(
                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {

                response = JSON.parse(response);
                if (response.statusCode > -1) {
                    this.appService.showMessage("Success", response.statusText)
                    this.getAllWorkflows();
                    this.getSingleWorkflowOwnersData(workflowName);
                }
                else {
                    this.appService.showMessage('Error', response.statusText)
                }
                this.appService.stopSpin();
            }, (error) => {
                this.appService.showMessage('Error', error.statusText);
                this.appService.stopSpin();
            });


    }

    returnAllOwners() {
        return this.owners;
    }


}