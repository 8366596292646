import { Component, OnInit, OnDestroy, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef, ViewRef } from '@angular/core';
import { LogsService } from './logs.service';
import { SharedInitializationService } from 'src/app/shared/shared-common-initialization.service';
import { Subscription } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.css'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogsComponent implements OnInit, OnDestroy {

  constructor(private logsService: LogsService,
    private cd: ChangeDetectorRef,
    private sharedService: SharedInitializationService
  ) { }
  //initialize nature of logs
  logsNatures = [
    { label: 'View', value: 'view' },
    { label: 'Download', value: 'download' }
  ];
  selectedLogsNature: string = 'view';
  //initialize nature of logs
  logsFiles = [
    { label: 'Cardabel Python', value: 'cardabel_python' },
    { label: 'Cardabel Web', value: 'cardabel_web' },
    { label: 'SQL Python', value: 'sql_python' }
  ];

  logText: string = "";
  logsFileSubscription = new Subscription();
  showLogText: boolean = false;
  selectedLogsFile: any[] = []

  nbLinesForm: FormGroup;
  nbLines: number = 500;

  ngOnInit() {
    this.logText = "";
    //reset variables
    this.selectedLogsFile = [this.logsFiles[0]];
    this.initForm();

    this.nbLinesForm.get('nbLines').setValue(500);
    this.logsFileSubscription = this.logsService.logsFileChange
      .subscribe((log: any[]) => {
        this.logText = "";
        for (var i in log) {
          this.logText = this.logText + log[i].line + "\n";
        }

        this.showLogText = true;
        if (this.cd && !(this.cd as ViewRef).destroyed) {
          this.cd.detectChanges();
        }
      })
  }
  private initForm() {

    this.nbLinesForm = new FormGroup({
      nbLines: new FormControl('', Validators.required),
    });

  }

  applyLogsFile() {

    if (this.selectedLogsNature === 'download') {
      this.logsService.adminDownloadLogs(this.selectedLogsFile[0].value);
    }
    else {//this.selectedLogsNature = 'view'
      this.logsService.adminExportLogs(this.selectedLogsFile[0].value, Math.round(+this.nbLinesForm.value.nbLines));

    }
  }

  //this is to get the documentation for the logs
  //this allows to get the help documentation for settings logout time
  getHelpDocumentation() {
    let parameters = ["admin", "settings", "logs"];
    this.sharedService.showHelpDocumentation("Admin Settings Logs");

    this.sharedService.getHelpDocumentation(parameters)
  }

  //this is to destroy subscriptions when component is destroyed
  ngOnDestroy() {
    this.logsFileSubscription.unsubscribe();
  }
}
