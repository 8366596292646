import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-cardabel-tooltip',
  templateUrl: './cardabel-tooltip.component.html',
  styleUrls: ['./cardabel-tooltip.component.scss']
})
export class CardabelTooltipComponent implements OnInit {
  @Input() text = '';
  constructor() { }

  ngOnInit() {
    console.log(this.text);
    if (this.text) {
      this.text= this.text.toString();
      if (this.text.indexOf("\n") === -1) {
        this.text = this.text + "\n";
      }
    }
    else {
      this.text = "";
    }
  }

}
