import { Injectable } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { WorkflowNameService } from './workflow-name.service';
import { catchError } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CustomHttpParamEncoder } from 'src/app/shared/custom-http-param-encoder';


@Injectable()
export class WorkflowSupervisedStepsService {
  constructor(private appService: AppService,
    private httpClient: HttpClient,
    private workflowService: WorkflowNameService) { }

  webUrl = this.appService.getWebUrl();

  //init root attributes
  allRootAttributes: any[] = [];
  allRootAttributesChange = new Subject<any[]>();
  //init target classes
  allClasses: number = 0;
  allClassesChange = new Subject<number>();
  //init supervised model parameters
  allModelParams: {
    Variable: string,
    Range: { Min: string, Max: string },
    DefaultValue: number,
    IsInt: number
  }[] = [];

  allModelParamsChange = new Subject<any[]>();

  bestParamsChange = new Subject<any[]>();
  testParamsChange = new Subject<any[]>();
  bestMatrixChange = new Subject<any[]>();
  testMatrixChange = new Subject<any[]>();
  crossValidChange = new Subject<any[]>();
  crossValidColumnsChange = new Subject<any[]>();

  trainDoneChange = new Subject<boolean>();

  //modelsPredictChange = new Subject<any[]>();
  predictClassesChange = new Subject<any[]>();
  //returns the root and shift attributes for supervised workflows
  //called in train-param component
  getSuperAttributesRootandShift(workflow, tableName) {

    const url = this.webUrl;
    const webservice = "WFSuperTableGetAttributesRootandShift";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      .set('segAttrValue', tableName)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {

          this.allRootAttributes = response.tableRows;
          this.allRootAttributesChange.next(this.allRootAttributes);
          this.appService.showMessage('Success', response.statusText);

        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });

  }

  //this allows to set the train param in a supervised workflow table
  //called in train param component
  setSuperTableTrainParam(apply_and_chain, workflow, tableName, subtableName, segmentationType, segmentationByRows, segmentationByAttrs, crossVal) {

    const url = this.webUrl;
    const webservice = "WFSuperTableSetTrainParam";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("apply_and_chain", apply_and_chain)
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subtableName)
      .set('segmentationType', segmentationType)
      .set('segmentationByRows', segmentationByRows)
      .set('segmentationByAttrs', segmentationByAttrs)
      .set('crossVal', crossVal)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.appService.showMessage('Success', response.statusText);
          this.workflowService.updateWorkflowTableSubTable(tableName, response.wfStructure, subtableName);

        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });
  }

  //this allows to add a target attribute in the supervised workflow table
  //called in target component
  addSuperTableTargetAttribute(apply_and_chain, workflow, tableName, subtableName, targetAttribute, targetDataSet) {

    const url = this.webUrl;
    const webservice = "WFSuperTableAddTargetAttribute";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();


    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("apply_and_chain", apply_and_chain)
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subtableName)
      .set('targetAttribute', targetAttribute)
      .set('targetDataSet', targetDataSet)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {

          this.appService.showMessage('Success', response.statusText);
          this.workflowService.updateWorkflowTableSubTable(tableName, response.wfStructure, subtableName);
          this.allClasses = this.workflowService.getSubTableTargetNbClasses();
          this.allClassesChange.next(this.allClasses);
        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });

  }

  //this allows to create/set a target rule in a supervised workflow table
  //called in target rules component
  setSuperTableTargetRules(apply_and_chain, workflow, tableName, subtableName, rulesList) {

    const url = this.webUrl;
    const webservice = "WFSuperTableSetTargetRules";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();

    //Create new HttpParams 
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("apply_and_chain", apply_and_chain)
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subtableName)
      .set('rulesList', rulesList)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {

          this.appService.showMessage('Success', response.statusText);
          this.workflowService.updateWorkflowTableSubTable(tableName, response.wfStructure, subtableName);

        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });

  }

  //this allows to set the encoding in the supervised workflow table
  //called in the encoding component
  setSuperTableEncoding(apply_and_chain, workflow, tableName, subtableName) {

    const url = this.webUrl;
    const webservice = "WFSuperTableEncoding";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("apply_and_chain", apply_and_chain)
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subtableName)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.appService.showMessage('Success', response.statusText);
          this.workflowService.updateWorkflowTableSubTable(tableName, response.wfStructure, subtableName);

        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });

  }

  //this allows to set the model in the supervised table
  //called in the model component
  setSuperTableModel(apply_and_chain, workflow, tableName, subtableName, model, modelParam) {

    const url = this.webUrl;
    const webservice = "WFSuperTableSetModel";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("apply_and_chain", apply_and_chain)
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subtableName)
      .set('model', model)
      .set('modelParam', modelParam)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {

          this.appService.showMessage('Success', response.statusText);
          this.workflowService.updateWorkflowTableSubTable(tableName, response.wfStructure, subtableName);

        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });

  }

  //this allows to return the supervised model parameters
  //called in the optimize component
  getSuperModelParams(model) {

    const url = this.webUrl;
    const webservice = "WFSuperGetModelParams";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set('model', model)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {

          this.allModelParams = response.tableRows;
          this.allModelParamsChange.next(this.allModelParams);

        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });

  }

  //this allows to launch the supervised algorithm to find anomalies
  //called in the optimize component
  launchSuperOptimizeTrain(workflow, tableName, subtableName, optimizeParams) {

    const url = this.webUrl;
    const webservice = "WFSuperTableOptimizeTrain";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subtableName)
      .set('optimizeParams', optimizeParams)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {

          this.appService.showMessage('Success', response.statusText);
          let bestParams = response.firstObj;
          this.bestParamsChange.next(bestParams);
          let testParams = response.secondObj;
          this.testParamsChange.next(testParams);
          let bestMatrix = response.tableRowsA2;
          this.bestMatrixChange.next(bestMatrix);
          let testMatrix = response.tableRowsB2;
          this.testMatrixChange.next(testMatrix);
          let crossValidColumns = response.tableRows;
          this.crossValidColumnsChange.next(crossValidColumns);
          let crossValid = response.tableRows2;
          this.crossValidChange.next(crossValid);
          this.trainDoneChange.next(true);
          this.workflowService.updateWorkflowTableSubTable(tableName, response.wfStructure, subtableName);

        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });

  }

  //this allows to launch the supervised algorithm to find anomalies
  //called in the optimize component
  launchSuperOptimizeLoad(workflow, tableName, subtableName) {

    const url = this.webUrl;
    const webservice = "WFSuperTableOptimizeLoad";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subtableName)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.appService.showMessage('Success', response.statusText);
          let bestParams = response.firstObj;
          this.bestParamsChange.next(bestParams);
          let testParams = response.secondObj;
          this.testParamsChange.next(testParams);
          let bestMatrix = response.tableRowsA2;
          this.bestMatrixChange.next(bestMatrix);
          let testMatrix = response.tableRowsB2;
          this.testMatrixChange.next(testMatrix);
          let crossValidColumns = response.tableRows;
          this.crossValidColumnsChange.next(crossValidColumns);
          let crossValid = response.tableRows2;
          this.crossValidChange.next(crossValid);
          this.trainDoneChange.next(false);
          this.workflowService.updateWorkflowTableSubTable(tableName, response.wfStructure, subtableName);

        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });

  }

  // get the results of the train by error and run
  applySupervisedTablePredict(workflow, tableName, subtableName, predictModel) {

    const url = this.webUrl;
    const webservice = "WFSuperTableApplyPredict";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subtableName)
      .set('predictModel', predictModel)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {

          this.appService.showMessage('Success', response.statusText);
          this.predictClassesChange.next(response.tableRows);
          this.workflowService.updateWorkflowTableSubTable(tableName, response.wfStructure, subtableName);

        }
        else {
          this.appService.showMessage("Error", response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage("Error", error.statusText);
        this.appService.stopSpin();
      });
  }

  getSupervisedStatsCount(workflow, segAttributeValue, subtableName, dataSetName) {

    const url = this.webUrl;
    const webservice = "StatSupervisedCountClasses";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    //Create new HttpParams
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      .set("segAttrValue", segAttributeValue)
      .set('subTableName', subtableName)
      .set("dataSet", dataSetName)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.predictClassesChange.next(response.tableRows);
        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
      });
  }
}