import { ViewRef } from '@angular/core';
import { AppService } from '../../../app.service';
import * as d3 from 'd3';
import { DashboardService } from '../../dashboard.service';
var DashboardFilterChartService = /** @class */ (function () {
    function DashboardFilterChartService(appService, dashboardService) {
        this.appService = appService;
        this.dashboardService = dashboardService;
    }
    /* FILTERING ALL THE DASHBOARD */
    //this allows to format the filters (by field, value and type )and then calls the filter function
    DashboardFilterChartService.prototype.formatAllFilters = function (event, vis) {
        var _a;
        vis.computeFilterWidth();
        for (var m in event.value) {
            var filterField = event.value[m].header;
            var filterValue = event.value[m].filter;
            var inputType = event.value[m].type;
            var filterType = event.value[m].filterType;
            var label = event.value[m].label;
            _a = this.returnFormattedFilters(vis.filters, vis.fieldExists, filterField, filterValue, inputType, filterType, label), vis.filters = _a[0], vis.fieldExists = _a[1];
        }
        this.filter(vis);
    };
    //this allows to filter the data and update all the graphs according to the given filters
    DashboardFilterChartService.prototype.filter = function (vis) {
        var filterSliceStream = vis.data;
        filterSliceStream = this.filterDataStream(vis, filterSliceStream, []);
        vis.filteredData = JSON.parse(JSON.stringify(filterSliceStream));
        this.appService.stopSpin2();
        if (vis.cd && !vis.cd.destroyed) {
            vis.cd.detectChanges();
        }
        this.dashboardService.setChartChanges(vis.mainTab, vis.tabAlias, vis.columns);
    };
    //remove All Filters
    DashboardFilterChartService.prototype.removeFilters = function (vis) {
        var _this = this;
        if (vis.cd && !vis.cd.destroyed) {
            vis.cd.detectChanges();
        }
        setTimeout(function () {
            vis.filters = [];
            if (vis.cd && !vis.cd.destroyed) {
                vis.cd.detectChanges();
            }
            _this.filter(vis);
        }, 10);
    };
    //this allows to remove a specific filter and keep the last drill down value chart
    DashboardFilterChartService.prototype.removeFilter = function (filter, vis) {
        var _this = this;
        this.appService.startSpin2();
        if (vis.cd && !vis.cd.destroyed) {
            vis.cd.detectChanges();
        }
        setTimeout(function () {
            if (typeof filter === "string") {
                var number = vis.filters.length - 1;
                vis.filters.splice(number, 1);
                _this.filter(vis);
            }
            else {
                for (var i in vis.filters) {
                    if (vis.filters[i].field === filter.field) {
                        vis.filters.splice(+i);
                    }
                }
                _this.filter(vis);
            }
        }, 10);
    };
    /* FILTERING IN CHART & FROM CHART */
    DashboardFilterChartService.prototype.resetFiltersArray = function (vis, type) {
        if (type === "bar") {
            vis.filtersInArray = [];
            vis.aggregFiltersInArray = [];
        }
        else if (type === "bubble") {
            for (var k in vis.keys) {
                var key = vis.keys[k];
                vis.filtersInArray[key] = [];
                vis.aggregFiltersInArray[key] = [];
            }
        }
        var aggMethod = vis.aggMethod;
        if (vis.aggregatedDimensionMethod === "count") {
            aggMethod = "count";
        }
        return [vis, aggMethod];
    };
    DashboardFilterChartService.prototype.filterIn = function (filters, header, filtersInArray, newHeader, vis) {
        if (vis.type === "bubble") {
            filters = [];
            for (var k in vis.keys) {
                var key = vis.keys[k];
                if (filtersInArray[key].length != 0) {
                    if (newHeader != "") {
                        header = newHeader;
                    }
                    if (key != "Categorie") {
                        header = vis[key];
                    }
                    filters.push({ header: header, filter: filtersInArray[key], type: "", filterType: 'in', label: filtersInArray[key] });
                }
            }
        }
        if (vis.type === "bar" || vis.type === "treemap" || vis.type === "line" || vis.type === "pie") {
            for (var k in filters) {
                filters.push({ header: filters[k].field, filter: filters[k].value, type: filters[k].inputType, filterType: 'contains', label: filters[k].label });
            }
            if ((newHeader != "") && (!newHeader)) {
                header = newHeader;
            }
            filters.push({ header: header, filter: filtersInArray, type: "", filterType: 'in', label: filtersInArray });
        }
        return filters;
    };
    DashboardFilterChartService.prototype.setClickFilter = function (vis, mouseThis, d, index, event) {
        var _a, _b;
        if (vis.tooltip) {
            vis.tooltip.style("display", "none");
        }
        if (vis.focus) {
            vis.focus.style("display", "none");
        }
        vis.displayContextMenu = false;
        var yValue;
        var lineValue;
        if (vis.type === "bubble") {
            var bubbleValue = d["Categorie"];
        }
        if (vis.type === "bar") {
            index = Math.floor((vis.height - mouseThis) / vis.y.step());
            yValue = vis.y.domain()[index];
        }
        if (vis.type === "treemap") {
            yValue = d.data.key;
        }
        var header = vis.dashboardChartService.getHeader(vis);
        if (vis.type === "line" && vis.dashboardInitTypesScalesService.isDimensionDate(header, vis.data, vis)) {
            yValue = d["Categorie"];
            vis.focus.style('display', "none");
            if (yValue) {
                var lineData = void 0;
                var systems = void 0;
                var removeDates = void 0;
                _a = this.dashboardService.formatDashboardData(vis.data, vis.header, vis.allHeaders, "", "", vis.aggMethod, "col", vis.numericHeader, vis.dateBucketing, vis.numericDecimal, vis.datePeriod, vis.datePeriodValue, vis.datePeriodFixedDate, vis.mainTab), lineData = _a[0], systems = _a[1], removeDates = _a[2];
                lineValue = yValue;
                if (vis.dateBucketing != 'Quarter') {
                    for (var i in lineData) {
                        if (yValue.toDateString() === new Date(lineData[i][vis.xVariable]).toDateString()) {
                            lineValue = lineData[i][vis.xVariable];
                        }
                    }
                }
            }
        }
        else if (vis.type === "line" && !vis.dashboardInitTypesScalesService.isDimensionDate(header, vis.data, vis)) {
            yValue = d[vis.xVariable];
        }
        if (vis.type === "combo") {
            yValue = d[vis.xVariable];
        }
        if (vis.type === "pie") {
            yValue = d.data[vis.yVariable];
        }
        var keys = ["Categorie", "xHeader", "yHeader"];
        if ((yValue === "Other") || (bubbleValue === "Other") || !vis.viewerMode) {
        }
        else if (event.shiftKey && vis.type != "combo") {
            if (vis.type != 'treemap') {
                d3.selectAll('#' + vis.type + vis.mainTab + vis.tabAlias + vis.header + vis.graphIndex + index).attr("fill", "#012B4E");
            }
            else {
                d3.selectAll('#' + vis.type + vis.mainTab + vis.tabAlias + vis.header + vis.graphIndex + index).style("background", "#012B4E");
            }
            if (vis.type === "line" && vis.dateheader != "") {
                vis = this.formatClickFilters(vis, vis.header, vis.allHeaders, "", "shiftkey", lineValue, index, d);
            }
            else {
                vis = this.formatClickFilters(vis, vis.header, vis.allHeaders, "", "shiftkey", yValue, index, d);
            }
        }
        else if (event.altKey && vis.view != 'sidebar') {
            if (vis.type != 'line') {
                _b = vis.dashboardTooltipContextMenuService.setContextMenu(vis, window, vis.xPosition, vis.yPosition, vis.height, vis.x, vis.y, vis.dataFiltered, vis.xVariable, vis.yVariable, vis.filtervalue, vis.displayContextMenu, mouseThis, d, d3.event), vis.xPosition = _b[0], vis.yPosition = _b[1], vis.filtervalue = _b[2], vis.displayContextMenu = _b[3], yValue = _b[4];
            }
            if (vis.cd && !vis.cd.destroyed) {
                vis.cd.detectChanges();
            }
            var element = document.getElementsByClassName("contextMenu");
            if (element.length != 0) {
                document.body.appendChild(element[0]);
            }
            // vis.expandedChartEmit.emit({ value: true });
            if (vis.cd && !vis.cd.destroyed) {
                vis.cd.detectChanges();
            }
        }
        else {
            var header_1 = vis.dashboardChartService.getHeader(vis);
            if (vis.type === "bar" || vis.type === "combo" || vis.type === "pie" || vis.type === "line" && !vis.dashboardInitTypesScalesService.isDimensionDate(header_1, vis.data, vis)) {
                if (yValue) {
                    for (var i in vis.dataFiltered) {
                        if (vis.dataFiltered[i]) {
                            var isEqual = false;
                            var filter = vis.yVariable;
                            if (vis.type === "combo" || vis.type === "line") {
                                filter = vis.xVariable;
                            }
                            if ((vis.type === "bar" || vis.type === "pie" || vis.dashboardInitTypesScalesService.isDimensionDate(header_1, vis.data, vis)) && vis.dataFiltered[i][vis.yVariable] === yValue) {
                                isEqual = true;
                            }
                            if ((vis.type === "combo" || vis.type === "line") && vis.dataFiltered[i][vis.xVariable] === yValue) {
                                isEqual = true;
                            }
                            if (isEqual) {
                                var type = typeof (vis.dataFiltered[i][vis.yVariable]);
                                var header_2 = vis.header;
                                if (vis.newHeader != "") {
                                    header_2 = vis.newHeader;
                                }
                                vis = this.formatClickFilters(vis, header_2, vis.allHeaders, vis.dataFiltered[i][filter], "nokey", yValue, index, d);
                                break;
                            }
                        }
                    }
                }
            }
            else if (vis.type === 'line' && vis.dashboardInitTypesScalesService.isDimensionDate(header_1, vis.data, vis)) {
                var header_3 = vis.header;
                if (vis.newHeader != "") {
                    header_3 = vis.newHeader;
                }
                vis = this.formatClickFilters(vis, header_3, vis.allHeaders, lineValue, "nokey", lineValue, index, d);
            }
            else if (vis.type === "bubble") {
                for (var i in keys) {
                    var key = keys[i];
                    var value = d[key];
                    var type = typeof (d[key]);
                    var header_4 = vis.header;
                    if (vis.newHeader != "") {
                        header_4 = vis.newHeader;
                    }
                    if (key != "Categorie") {
                        header_4 = vis[key];
                    }
                    vis = this.formatClickFilters(vis, header_4, vis.allHeaders, value, "nokey", yValue, index, d);
                }
            }
            else if (vis.type === "treemap") {
                var filters = this.getTreemapFilters(d, vis);
                for (var m in filters) {
                    vis = this.formatClickFilters(vis, filters[m].header, vis.allHeaders, filters[m].key, "nokey", filters[m].key, index, d);
                }
            }
        }
        return vis;
    };
    DashboardFilterChartService.prototype.formatClickFilters = function (vis, header, allHeaders, filter, type, yValue, index, d) {
        if (type === "nokey") {
            var filters = [];
            if (vis.type === "line" && vis.dateBucketing != "Days" && vis.dateBucketing != "Day") {
                for (var k in vis.filters) {
                    filters.push({ header: vis.filters[k].field, filter: vis.filters[k].value, type: vis.filters[k].inputType, filterType: 'contains', label: vis.filters[k].label });
                }
                var filterType = vis.dateBucketing;
                var label = vis.multiFormat(filter);
                if (vis.dateBucketing === 'Quarter') {
                    label = filter;
                }
                filters.push({ header: header, filter: filter, type: type, filterType: filterType, label: label });
                vis.filter.emit({ value: filters });
            }
            else if ((vis.aggregatedDimensionMethod === "") || (vis.aggregatedDimensionMethod === "none") || (!vis.aggregatedDimensionMethod)) {
                for (var k in vis.filters) {
                    filters.push({ header: vis.filters[k].field, filter: vis.filters[k].value, type: vis.filters[k].inputType, filterType: 'contains', label: vis.filters[k].label });
                }
                filters.push({ header: header, filter: filter, type: type, filterType: 'contains', label: filter });
                vis.filter.emit({ value: filters });
            }
            else {
                vis.filtersInArray = this.getAggregClickFilters(header, allHeaders, yValue, vis.aggMethod, vis.aggregatedDimensionMethod, vis.data, vis.numericHeader, vis.dateBucketing, vis.numericDecimal, vis.datePeriod, vis.datePeriodValue, vis.datePeriodFixedDate, vis.mainTab, vis.yVariable, vis.dateHeader);
                vis.filterIn();
            }
        }
        else if (type === "shiftkey") {
            if (vis.type === "bar" || vis.type === "treemap" || vis.type === "line" || vis.type === "pie") {
                if (vis.aggregatedDimensionMethod === "count") {
                    if (vis.aggregFiltersInArray.indexOf(yValue) === -1) {
                        vis.aggregFiltersInArray.push(yValue);
                    }
                    else {
                        vis.aggregFiltersInArray.splice(vis.aggregFiltersInArray.indexOf(yValue, 1));
                        d3.selectAll('#' + vis.type + vis.mainTab + vis.tabAlias + vis.header + vis.graphIndex + index).attr("fill", vis.color);
                    }
                    vis.filtersInArray = [];
                    for (var i in vis.aggregFiltersInArray) {
                        vis.filtersInArray = vis.filtersInArray.concat(this.getAggregClickFilters(vis.header, vis.allHeaders, vis.aggregFiltersInArray[i], vis.aggMethod, vis.aggregatedDimensionMethod, vis.data, vis.numericHeader, vis.dateBucketing, vis.numericDecimal, vis.datePeriod, vis.datePeriodValue, vis.datePeriodFixedDate, vis.mainTab, vis.yVariable, vis.dateHeader));
                    }
                }
                else {
                    if (vis.filtersInArray.indexOf(yValue) === -1) {
                        vis.filtersInArray.push(yValue);
                    }
                    else {
                        vis.filtersInArray.splice(vis.filtersInArray.indexOf(yValue, 1));
                        d3.selectAll('#' + vis.type + vis.mainTab + vis.tabAlias + vis.header + vis.graphIndex + index).attr("fill", vis.color);
                    }
                    if (vis.type === "bar") {
                        //  var canvas = document.getElementById(vis.type + vis.header + vis.tabAlias + vis.mainTab + vis.graphIndex + 'canvas');
                        //  var gl = vis.dashboardWebGLUtilitesService.getGLContext(canvas);
                        //let filteredData = vis.dataFiltered.filter(value => !vis.filtersInArray.some(e => e === value.Categorie));
                        /*   let filteredData = vis.dataFiltered;
                           let initCoords = [];
                           for (var k in filteredData) {
                               let d = filteredData[k];
                               if (vis.filtersInArray.includes(d.Categorie)) {
                                   let newcoords = vis.dashboardBarChartService.getCoords(d, [], gl, vis);
                                   console.log(newcoords)
                               }
                               initCoords = vis.dashboardBarChartService.getCoords(d, initCoords, gl, vis);
                           }
   
                           let coords = [];
                           for (var m in vis.filtersInArray) {
                               let d = vis.dataFiltered[vis.dataFiltered.findIndex(item => item.Categorie === vis.filtersInArray[m])]
                               coords = vis.dashboardBarChartService.getCoords(d, coords, gl, vis);
                           }
   
   
                           console.log(coords);
                           vis.dashboardBarChartService.drawRects(gl, initCoords, vis.color, vis)
                           vis.dashboardBarChartService.drawRects(gl, coords, "#dadada", vis)
                           */
                    }
                }
            }
            else if (vis.type === "bubble") {
                d3.selectAll('#' + vis.type + vis.mainTab + vis.tabAlias + vis.header + vis.graphIndex + index).attr("fill", "#012B4E");
                var name_1 = "";
                for (var t in vis.keys) {
                    var key = vis.keys[t];
                    var value = d[key];
                    name_1 = name_1 + value;
                }
                var keys = Object.keys(vis.shiftFilterValues);
                var itemExistsIndex = vis.shiftFilterValues.findIndex((function (item) { return item.id === name_1; }));
                if (itemExistsIndex > -1) {
                    vis.shiftFilterValues.splice(itemExistsIndex, 1);
                    d3.selectAll('#' + vis.type + vis.mainTab + vis.tabAlias + vis.header + vis.graphIndex + index).attr("fill", vis.color);
                }
                else {
                    var newdata = {};
                    newdata['id'] = name_1;
                    for (var t in vis.keys) {
                        var key = vis.keys[t];
                        var value = d[key];
                        newdata[key] = value;
                    }
                    vis.shiftFilterValues.push(newdata);
                }
                vis.filtersInArray = {};
                var _loop_1 = function () {
                    var key = vis.keys[t];
                    vis.filtersInArray[key] = [];
                    for (var k in vis.shiftFilterValues) {
                        vis.filtersInArray[key].push(vis.shiftFilterValues[k][key]);
                    }
                    vis.filtersInArray[key] = vis.filtersInArray[key].filter(function (item, index) { return vis.filtersInArray[key].indexOf(item) === index; });
                };
                for (var t in vis.keys) {
                    _loop_1();
                }
            }
        }
        else if (type === "alt") {
        }
        return vis;
    };
    DashboardFilterChartService.prototype.formatFilters = function (vis, header, allHeaders, newHeader, filters, filter, aggregatedDimensionMethod, fieldExists) {
        var _a;
        var filterField = header;
        if (newHeader != "") {
            filterField = newHeader;
        }
        var filterValue = filter.Categorie || filter.key;
        var inputType = "contains";
        var filterType = "contains";
        var label = vis.dashboardAxisFormattingService.getTickFormat(filterValue, vis);
        if (aggregatedDimensionMethod === "count") {
            inputType = "in";
            filterType = "in";
            filterValue = this.getAggregClickFilters(filterField, allHeaders, filterValue, vis.aggMethod, vis.aggregatedDimensionMethod, vis.data, vis.numericHeader, vis.dateBucketing, vis.numericDecimal, vis.datePeriod, vis.datePeriodValue, vis.datePeriodFixedDate, vis.mainTab, vis.yVariable, vis.dashboardInitTypesScalesService.isDimensionDate(header, vis.data, vis));
            aggregatedDimensionMethod = "";
        }
        if (vis.type === "line" && vis.dateBucketing != "Day" && vis.dateBucketing != "Days") {
            filterType = vis.dateBucketing;
        }
        _a = this.returnFormattedFilters(filters, fieldExists, filterField, filterValue, inputType, filterType, label), filters = _a[0], fieldExists = _a[1];
        return [filters, newHeader, fieldExists];
    };
    DashboardFilterChartService.prototype.formatChartFilterData = function (chartData, data, vis) {
        var filterSliceStream = data;
        filterSliceStream = this.filterDataStream(vis, filterSliceStream, []);
        chartData = JSON.parse(JSON.stringify(filterSliceStream));
        return chartData;
    };
    //get treemap filters
    DashboardFilterChartService.prototype.getTreemapFilters = function (d, vis) {
        var child = "";
        var parentData = d;
        var header = "";
        var filters = [];
        for (var k = d.depth - 1; k > -1; k--) {
            var key = d.data.key;
            var value = d.data.value;
            var height = d.data.height;
            if (child != "") {
                key = parentData.data.key;
                value = parentData.data.value;
                height = parentData.data.height;
            }
            if (vis.newHeader != "") {
                header = vis.newHeader;
            }
            filters.unshift({ key: key, value: value, header: vis.allHeaders[k], height: height });
            vis.cd.detectChanges();
            var position = 0;
            if (child.length - 5 > 0) {
                position = child.length - 5;
            }
            child = "parent";
            parentData = parentData[child];
        }
        return filters;
    };
    //this allows to return the filters when clicking on the aggregated value in a chart 
    DashboardFilterChartService.prototype.getAggregClickFilters = function (header, allHeaders, yValue, aggMethod, aggregatedDimensionMethod, data, numericHeader, dateBucketing, numericDecimal, datePeriod, datePeriodValue, datePeriodFixedDate, mainTab, yVariable, dateHeader) {
        if (aggregatedDimensionMethod === "count") {
            aggMethod = "count";
        }
        var filteredData = this.dashboardService.formatDashboardData(data, header, allHeaders, "", "", aggMethod, "col", numericHeader, dateBucketing, numericDecimal, datePeriod, datePeriodValue, datePeriodFixedDate, mainTab);
        var filtersInArray = [];
        filteredData = filteredData.filter(function (item) {
            return item['col'] === yValue;
        });
        for (var i in filteredData) {
            var type = typeof (filteredData[i][yVariable]);
            filtersInArray.push(filteredData[i][yVariable]);
        }
        return filtersInArray;
    };
    /* SHARED FUNCTIONS BTW ALL THE DASHBOARD & CHARTS */
    //allows to filter data when a chart is clicked or when using the drill down
    DashboardFilterChartService.prototype.filterDataStream = function (vis, filterSliceStream, filters) {
        var newFilters = vis.filters.concat(filters);
        var _loop_2 = function () {
            value = newFilters[i].value;
            if (newFilters[i].filterType === 'Quarter') {
                var year = value.substring(0, 4);
                if (vis.dateFormat === "Q-YYYY") {
                    year = value.substring(5, 9);
                }
                if (value.indexOf('Q1')) {
                    value = new Date("03-01-" + year);
                }
                else if (value.indexOf('Q2')) {
                    value = new Date("06-30-" + year);
                }
                else if (value.indexOf('Q3')) {
                    value = new Date('09-30-' + year);
                }
                else {
                    value = new Date("12-31-" + year);
                }
            }
            field = newFilters[i].field;
            type = newFilters[i].inputType;
            filterType = newFilters[i].filterType;
            var minDate;
            var maxDate;
            if (filterType === "Month" || filterType == "Week" || filterType === "Year" || filterType === "Quarter") {
                var year = new Date(value).getFullYear();
                var month = new Date(value).getMonth();
                var day = new Date(value).getDate();
                if (filterType === "Month") {
                    minDate = new Date(year, month, 1);
                    maxDate = new Date(year, month + 1, 0);
                }
                else if (filterType === "Quarter") {
                    maxDate = new Date(year, month + 1, 0);
                    minDate = new Date(year, month - 2, 1);
                }
                else if (filterType === "Year") {
                    minDate = new Date(year, 1, 1);
                    maxDate = new Date(year, 12, 0);
                }
                else if (filterType === 'Week') {
                    minDate = new Date(value);
                    maxDate = new Date(year, month, day + 7);
                }
            }
            filterSliceStream = filterSliceStream.filter(function (item) {
                if (item[field] === null) {
                    item[field] = " null";
                    if (field === "truestatus") {
                        item[field] = "Unknown";
                    }
                }
                else if (item[field] === "") {
                    item[field] = "Unknown";
                }
                if (value != undefined) {
                    if (filterType === "contains") {
                        var itemField = item[field].toString();
                        var valueField = value.toString();
                        if (itemField) {
                            if (itemField.toLowerCase() != valueField.toLowerCase()) {
                                return false;
                            }
                        }
                        else {
                            return false;
                        }
                    }
                    else if (filterType === "in") {
                        for (var i in value) {
                            if (typeof item[field] === 'string') {
                                if (item[field].toLowerCase().indexOf(value[i].toString().toLowerCase()) > -1) {
                                    return true;
                                }
                            }
                            else if (typeof item[field] === 'number') {
                                if ((value[i] != 'null') && (value[i] != null)) {
                                    value[i] = +value[i];
                                }
                                if (item[field] === value[i]) {
                                    return true;
                                }
                            }
                            else if (item[field] === null) {
                                if (value[i] === "null") {
                                    value[i] = null;
                                }
                                if (item[field] === value[i]) {
                                    return true;
                                }
                            }
                        }
                        return false;
                    }
                    else if (filterType === "Month" || filterType == "Week" || filterType === "Year" || filterType === "Quarter") {
                        if (item[field] && item[field] != null) {
                            if (new Date(item[field]) <= maxDate && new Date(item[field]) >= minDate) {
                                return true;
                            }
                            else {
                                return false;
                            }
                        }
                        else {
                            return false;
                        }
                    }
                }
                return true;
            });
        };
        var value, field, type, filterType;
        for (var i in newFilters) {
            _loop_2();
        }
        ;
        return filterSliceStream;
    };
    DashboardFilterChartService.prototype.returnFormattedFilters = function (filters, fieldExists, filterField, filterValue, inputType, filterType, label) {
        fieldExists = false;
        for (var i in filters) {
            if (filters[i].field === filterField) {
                fieldExists = true;
                filters[i].value = filterValue;
                filters[i].inputType = inputType;
                filters[i].filterType = filterType;
                filters[i].label = label;
            }
        }
        if (fieldExists === false) {
            filters.push({ field: filterField, value: filterValue, inputType: inputType, filterType: filterType, label: label });
        }
        for (var k = filters.length - 1; k > -1; k--) {
            if (((filters[k].value === "") && (filters[k].value2 === "")) || filters[k].value === null || filters[k].value2 === null) {
                filters.splice(k, 1);
            }
        }
        return [filters, fieldExists];
    };
    return DashboardFilterChartService;
}());
export { DashboardFilterChartService };
