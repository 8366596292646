<div class="row">
  <div class="col-md-12">

    <as-split [useTransition]="true" direction="horizontal" id="ownersSplit">
      <as-split-area size="30">
        <div class="row">
          <div class="col-md-12 justify-content-center margin-top-20 offset-md-2">
            <p-selectButton [options]="viewNatures" [(ngModel)]="selectedViewNature" (onChange)="viewNatureChange()" >
            </p-selectButton>
          </div>
        </div>

        <div *ngIf="selectedViewNature=='Owners view'" class="row margin-top-10">
          <div class="col-md-1"></div>
          <div class="col-md-10">
            <cardabel-selection-list [attr.data-cy]="'admin-settings-owners-selection-list'"  [inputSelectedOptions]="owner" [options]="filteredOwners" [height]="440" [title]="'owners'" [optionLabel]="'UserName'"
            [multipleSelection]="false" (changeSelectedOptions)="owner = $event.value; changeOwner()"></cardabel-selection-list>

          </div>
        </div>
        <div *ngIf="selectedViewNature=='Workflows view'" class="row margin-top-60">
          <div class="col-md-1"></div>
          <div class="col-md-10">
            <cardabel-selection-list [attr.data-cy]="'admin-settings-owners-workflows-selection-list'"  [inputSelectedOptions]="workflow" [options]="filteredWorkflows" [height]="440" [title]="'workflows'" [optionLabel]="'WFName'"
            [multipleSelection]="false" (changeSelectedOptions)="workflow = $event.value; changeWorkflow() "></cardabel-selection-list>
          </div>
        </div>
      </as-split-area>
      <as-split-area size="70">
        <router-outlet></router-outlet>
      </as-split-area>
    </as-split>
  </div>
</div>