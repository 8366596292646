import { Injectable } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CustomHttpParamEncoder } from 'src/app/shared/custom-http-param-encoder';

@Injectable()
export class AuthorizationsService {
    constructor(private appService: AppService,
        private httpClient: HttpClient,
    ) { }

    webUrl = this.appService.getWebUrl();

    //all the possible guiTabs
    guiTabs: any[] = [{ name: "Admin" }, { name: "Analysis" }, { name: "Dashboard" }, { name: "Execution" }, { name: "Workflow" }, { name: "Matching" }]
    //all the inactive and active users
    users: any[] = [];
    //automatically updates the inactive and active users
    usersChange = new Subject<any>();

    //set all the role data (is active, gui tabs, webservices, etc)
    userData: any[] = [];
    //subject to update the relevant data in role component when selected role is changed
    userDataChange = new Subject<any>();
    //all the inactive and active roles
    roles: any[] = [];
    //automatically updates the roles, called in roles and users components
    rolesChange = new Subject<any>();

    //set all the role data (is active, gui tabs, webservices, etc)
    roleData: any[] = [];
    //subject to update the relevant data in role component when selected role is changed
    roleDataChange = new Subject<any>();

    //set all the available workflows
    workflows: any[] = [];
    //subject to update workflows in role and newrole components
    workflowsChange = new Subject<any>();
    //set all the available webservices
    webservices: any[] = [];
    //subject to update webservices in role and newrole components
    webservicesChange = new Subject<any>();
    //set all the available source
    sources: any[] = [];
    //subject to update sources in role and newrole components
    sourcesChange = new Subject<any>();

    //sets the new webservice configuration
    webserviceConfiguration: any[] = [];
    //subject to update webservice config in role and newrole components
    webserviceConfigurationChange = new Subject<any>();
    //sets the new role webservice configuration (unauthorized webservices)
    roleWebservicesConfiguration: any[] = [];
    //updates the new role webservice configuration (unauthorized webservices)
    roleWebservicesConfigurationChange = new Subject<any>();
    //checks whether the webservice is authorized or not
    webserviceAuthorizationChange = new Subject<any>();
    //sets the role data for reports tab
    rolesReportData: any[] = [];
    //subject to load role data for reports, it automatically updates it
    rolesReportDataChange = new Subject<any>();

    //Returns guiTabs
    getGuiTabs() {
        return [...this.guiTabs];
    }
    //loads the user data for all the users, only admin has access to that
    //called in user and roles component
    getUserData() {
        this.appService.startSpin();

        const url = this.webUrl;
        const webservice = "AdminAccessGetAllUsers";
        const completeUrl = url + webservice;

        const headers = this.appService.getHeaders();

        //create new http params
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })

        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(

                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {

                response = JSON.parse(response);
                if (response.statusCode > -1) {
                    this.users = response.tableRows;
                    this.usersChange.next(this.users)
                }
                else {
                    this.appService.showMessage('Error', response.statusText)
                }
                this.appService.stopSpin();
            }, (error) => {
                this.appService.showMessage('Error', error.statusText);
                this.appService.stopSpin();
            });


    }

    //returns users for all the users
    returnUsers() {

        return this.users;
    }

    returnUserData(user) {
        for (var i in this.users) {
            if (this.users[i].username === user) {
                return this.users[i];
            }
        }
    }

    //loads the role data for all roles, only admin has access to that
    //called in role component
    getRoleData() {
        this.appService.startSpin();

        const url = this.webUrl;
        const webservice = "AdminAccessGetRoleList";
        const completeUrl = url + webservice;

        const headers = this.appService.getHeaders();
        //create new http params
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })

        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(

                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {

                response = JSON.parse(response);
                if (response.statusCode > -1) {
                    this.roles = response.tableRows;
                    this.rolesChange.next(this.roles)
                }
                else {
                    this.appService.showMessage('Error', response.statusText)
                }
                this.appService.stopSpin();
            }, (error) => {
                this.appService.showMessage('Error', error.statusText);
                this.appService.stopSpin();
            });


    }

    returnActiveRoles() {
        let roles = this.roles.filter((item => {
            return item.isActive;
        }))
        return roles;
    }

    //update the user data from admin role
    //called in admin user component
    updateUserData(username, email, isActive, rolesList, isLocked, isTemporary) {
        this.appService.startSpin();

        const url = this.webUrl;
        const webservice = "AdminAccessUpdateUser";
        const completeUrl = url + webservice;

        const headers = this.appService.getHeaders();
        //create new http params
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("userName", username)
            .set("email", email)
            .set("isActive", isActive)
            .set("rolesList", rolesList)
            .set("isLocked", isLocked)
            .set("isTemporary", isTemporary)

        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(

                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {

                response = JSON.parse(response);
                if (response.statusCode > -1) {
                    this.getUserData();

                    this.getSingleUserData(username);
                }
                else {
                    this.appService.showMessage('Error', response.statusText)
                }
                this.appService.stopSpin();
            }, (error) => {
                this.appService.showMessage('Error', error.statusText);
                this.appService.stopSpin();
            });


    }

    //update the user password data from admin role
    //called in admin user component
    updateUserPasswordFromAdmin(username, email, password) {
        this.appService.startSpin();

        const url = this.webUrl;
        const webservice = "AdminAccessUpdatePassword";
        const completeUrl = url + webservice;

        const headers = this.appService.getHeaders();

        //create new http params
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("userName", username)
            .set("email", email)
            .set("userPassword", password)


        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(
                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {

                response = JSON.parse(response);
                if (response.statusCode > -1) {
                    this.appService.showMessage('Success', response.statusText)
                }
                else {
                    this.appService.showMessage('Error', response.statusText)
                }
                this.appService.stopSpin();
            }, (error) => {
                this.appService.showMessage('Error', error.statusText);
                this.appService.stopSpin();
            });


    }

    //creates a new user from admin role
    //called in admin new user component
    createUser(username, email, password, isActive, rolesList) {
        this.appService.startSpin();

        const url = this.webUrl;
        const webservice = "AdminAccessCreateUser";
        const completeUrl = url + webservice;

        const headers = this.appService.getHeaders();

        //create new http params
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("userName", username)
            .set("email", email)
            .set("userPassword", password)
            .set("isActive", isActive)
            .set("rolesList", rolesList)

        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(
                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {

                response = JSON.parse(response);
                if (response.statusCode > -1) {
                    this.appService.showMessage('Success', response.statusText)
                    this.getUserData();
                }
                else {
                    this.appService.showMessage('Error', response.statusText)
                }
                this.appService.stopSpin();
            }, (error) => {
                this.appService.showMessage('Error', error.statusText);
                this.appService.stopSpin();
            });


    }

    //gets all the role data for a specific role
    //called in admin role component
    getSingleRoleData(rolename) {
        this.appService.startSpin();
        const url = this.webUrl;
        const webservice = "AdminAccessGetRole";
        const completeUrl = url + webservice;

        const headers = this.appService.getHeaders();
        //create new http params
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("roleName", rolename)

        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(
                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {

                response = JSON.parse(response);
                if (response.statusCode > -1) {
                    this.roleData = response.tableRows;
                    this.roleDataChange.next(this.roleData);
                }
                else {
                    this.appService.showMessage('Error', response.statusText)
                }
                this.appService.stopSpin();
            }, (error) => {
                this.appService.showMessage('Error', error.statusText);
                this.appService.stopSpin();
            });


    }
    //gets all the role data for a specific role
    //called in admin role component
    getSingleUserData(username) {
        this.appService.startSpin();

        const url = this.webUrl;
        const webservice = "AdminAccessGetUser";
        const completeUrl = url + webservice;

        const headers = this.appService.getHeaders();
        //create new http params
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("userName", username)

        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(
                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {

                response = JSON.parse(response);
                if (response.statusCode > -1) {
                    this.userData = response.tableRows;
                    this.userDataChange.next(this.userData);
                }
                else {
                    this.appService.showMessage('Error', response.statusText)
                }
                this.appService.stopSpin();
            }, (error) => {
                this.appService.showMessage('Error', error.statusText);
                this.appService.stopSpin();
            });


    }
    //gets all the available workflows
    //called in admin roles component
    getAllWorkflows() {
        this.appService.startSpin();
        this.workflows = [];

        const url = this.webUrl;
        const webservice = "AdminAccessGetAllWorkflows";
        const completeUrl = url + webservice;

        const headers = this.appService.getHeaders();

        //create new http params
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })

        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(
                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {

                response = JSON.parse(response);
                if (response.statusCode > -1) {

                    this.workflows = response.tableRows;
                    this.workflowsChange.next(this.workflows);
                }
                else {
                    this.appService.showMessage('Error', response.statusText)
                }
                this.appService.stopSpin();
            }, (error) => {
                this.appService.showMessage('Error', error.statusText);
                this.appService.stopSpin();
            });


    }

    //returns all the available workflows
    //called in admin role and new role component;
    returnAllWorkflows() {
        return this.workflows;
    }

    //gets all the available webservices
    //called in admin roles component
    getAllWebservices() {
        this.appService.startSpin();

        const url = this.webUrl;
        const webservice = "AdminAccessGetAllWebservices";
        const completeUrl = url + webservice;

        const headers = this.appService.getHeaders();

        //create new http params
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })

        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(
                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {

                response = JSON.parse(response);
                if (response.statusCode > -1) {
                    this.webservices = response.tableRows;
                    this.webservicesChange.next(this.webservices);
                }
                else {
                    this.appService.showMessage('Error', response.statusText)
                }
                this.appService.stopSpin();
            }, (error) => {
                this.appService.showMessage('Error', error.statusText);
                this.appService.stopSpin();
            });


    }

    //returns all the available webservices
    //called in admin role and new role component;
    returnAllWebservices() {
        return this.webservices;
    }

    //gets all the available sources
    //called in admin roles component
    getAllSources() {
        this.appService.startSpin();

        const url = this.webUrl;
        const webservice = "WFGetSourceNamesFilter";
        const completeUrl = url + webservice;

        const headers = this.appService.getHeaders();
        let varFilter = "";

        //create new http params
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("sourcefilter", varFilter)

        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(
                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {

                response = JSON.parse(response);
                if (response.statusCode > -1) {
                    this.sources = response.tableRows;
                    this.sourcesChange.next(this.sources);
                }
                else {
                    this.appService.showMessage('Error', response.statusText)
                }
                this.appService.stopSpin();
            }, (error) => {
                this.appService.showMessage('Error', error.statusText);
                this.appService.stopSpin();
            });


    }

    //returns all the available sources
    //called in admin role and new role component;
    returnAllSources() {
        return this.sources;
    }

    //get webservices configuration according to input params (allow edit, gui tabs, update and create workflow / users)
    //called in admin roles component
    getWebservicesConfiguration(dashboard, analysis, workflow, execution, admin, matching, canUpdateUser, canUpdateWorkflow, allowEdit) {
        this.appService.startSpin();

        const url = this.webUrl;
        const webservice = "AdminGetWebservicesConfiguration";
        const completeUrl = url + webservice;

        const headers = this.appService.getHeaders();

        //create new http params
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("dashboard", dashboard)
            .set("analysis", analysis)
            .set("workflow", workflow)
            .set("execution", execution)
            .set("admin", admin)
            .set("matching", matching)
            .set("canUpdateUser", canUpdateUser)
            .set("canUpdateWorkflow", canUpdateWorkflow)
            .set("allowEdit", allowEdit)

        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(
                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {

                response = JSON.parse(response);
                if (response.statusCode > -1) {
                    this.webserviceConfiguration = response.tableRows;
                    this.webserviceConfigurationChange.next(this.webserviceConfiguration)
                }
                else {
                    this.appService.showMessage('Error', response.statusText)
                }
                this.appService.stopSpin();
            }, (error) => {
                this.appService.showMessage('Error', error.statusText);
                this.appService.stopSpin();
            });


    }

    //updates the role authorizations
    //called in admin role component
    createUpdateRoleData(roleName, isActive, canUpdateUser, canUpdateWorkflow, allowEdit, usersList, guiTabList,
        webservicesList, workflowsList, sourceList, createOrUpdate) {
        this.appService.startSpin();

        const url = this.webUrl;
        const webservice = "AdminAccessCreateUpdateRole";
        const completeUrl = url + webservice;

        const headers = this.appService.getHeaders();

        usersList = "{\"roleUsers\":" + JSON.stringify(usersList) + "}";
        guiTabList = "{\"roleGuiTabs\":" + JSON.stringify(guiTabList) + "}";
        webservicesList = "{\"roleWebservices\":" + JSON.stringify(webservicesList) + "}";
        sourceList = "{\"roleSources\":" + JSON.stringify(sourceList) + "}";
        workflowsList = "{\"roleWorkflows\":" + JSON.stringify(workflowsList) + "}";


        //create new http params
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("roleName", roleName)
            .set("isActive", isActive)
            .set("canUpdateUser", canUpdateUser)
            .set("canUpdateWorkflow", canUpdateWorkflow)
            .set("allowEdit", allowEdit)
            .set("usersList", usersList)
            .set("guiTabList", guiTabList)
            .set("webservicesList", webservicesList)
            .set("workflowsList", workflowsList)
            .set("sourceList", sourceList)
            .set('createOrUpdate', createOrUpdate)
        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(
                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {

                response = JSON.parse(response);
                if (response.statusCode > -1) {
                    this.appService.showMessage("Success", response.statusText)
                    this.getRoleData();
                    this.getSingleRoleData(roleName);
                }
                else {
                    this.appService.showMessage('Error', response.statusText)
                }
                this.appService.stopSpin();
            }, (error) => {
                this.appService.showMessage('Error', error.statusText);
                this.appService.stopSpin();
            });


    }

    //gets the unauthorized webservices except for Cardabel Admin
    //called in admin configuration component
    getRoleWebservicesConfiguration() {
        this.appService.startSpin();
        const url = this.webUrl;
        const webservice = "AdminGetRoleWebservicesConfiguration";
        const completeUrl = url + webservice;

        const headers = this.appService.getHeaders();

        //create new http params
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })


        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(
                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {

                response = JSON.parse(response);
                if (response.statusCode > -1) {
                    this.roleWebservicesConfiguration = response.tableRows;
                    this.roleWebservicesConfigurationChange.next(this.roleWebservicesConfiguration);
                }
                else {
                    this.appService.showMessage('Error', response.statusText)
                }
                this.appService.stopSpin();
            }, (error) => {
                this.appService.showMessage('Error', error.statusText);
                this.appService.stopSpin();
            });


    }

    //sets the unauthorized webservices except for Cardabel Admin
    //called in admin configuration component
    setRoleWebservicesConfiguration(webservices) {
        this.appService.startSpin();

        const url = this.webUrl;
        const webservice = "AdminSetRoleWebservicesConfiguration";
        const completeUrl = url + webservice;

        const headers = this.appService.getHeaders();

        webservices = JSON.stringify(webservices)

        //create new http params
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("webservicesList", webservices)


        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(
                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {

                response = JSON.parse(response);
                if (response.statusCode > -1) {
                    this.appService.showMessage("Success", response.statusText)
                }
                else {
                    this.appService.showMessage('Error', response.statusText)
                }
                this.appService.stopSpin();
            }, (error) => {
                this.appService.showMessage('Error', error.statusText);
                this.appService.stopSpin();
            });


    }


    //checks if the webservice is authorized or not
    //called in admin role, user component
    checkWebserviceAuthorization(webserviceName) {
        this.appService.startSpin();

        const url = this.webUrl;
        const webservice = "AdminCheckWebserviceAuthorization";
        const completeUrl = url + webservice;

        const headers = this.appService.getHeaders();

        //create new http params
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("webservice", webserviceName)


        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(
                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {

                response = JSON.parse(response);
                if (response.statusCode > - 1) {
                    //this.appService.showMessage("Success", response.statusText)

                }
                else {
                    let data = [];
                    data.push({ webservice: webserviceName, authorized: false });
                    this.webserviceAuthorizationChange.next(data);
                }
                this.appService.stopSpin();
            }, (error) => {
                this.appService.showMessage('Error', error.statusText);
                this.appService.stopSpin();
            });


    }


    //loads all the role data for all roles with users and webservices, only admin has access to that
    //called in report component
    getAllRolesData() {
        this.appService.startSpin();

        const url = this.webUrl;
        const webservice = "AdminAccessGetAllRoles";
        const completeUrl = url + webservice;

        const headers = this.appService.getHeaders();

        //create new http params
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })


        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(
                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {

                response = JSON.parse(response);
                if (response.statusCode > -1) {

                    if (response.tableRows.length == 0) {
                        this.appService.showMessage('Warning', "no report available");
                    }
                    else {
                        this.rolesReportData = response.tableRows;
                        this.rolesReportDataChange.next(this.rolesReportData);
                    }
                }
                else {
                    this.appService.showMessage('Error', response.statusText)
                }
                this.appService.stopSpin();
            }, (error) => {
                this.appService.showMessage('Error', error.statusText);
                this.appService.stopSpin();
            });

    }

}