import { Injectable, ViewRef } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { AppService } from '../../../../app.service';

import { Router } from '@angular/router';
import * as d3 from 'd3';
import { DashboardService } from '../../../dashboard.service';
import { SharedGeneralFunctionsService } from '../../../../shared/shared-general-functions.service';


@Injectable()
export class DashboardComboChartService {

    constructor(private httpClient: HttpClient,
        private appService: AppService,
        private router: Router,
        private dashboardService: DashboardService,
        private sharedGeneralService: SharedGeneralFunctionsService
    ) { }


    //combo display
    setCombo(vis) {

        vis.g1.selectAll("rect").remove()

        vis.rects = vis.g1.selectAll("rect")
            .data(vis.dataFiltered)
            .enter().append("g");

        let rectColor = vis.color;
        if (vis.colorScheme[0]) {
            rectColor = vis.colorScheme[0];
        }
        // bar chart
        vis.rects.append("rect")
            .attr("x", function (d) { return vis.x(d["Categorie"]) + 0.1; })
            .attr("y", function (d) {
                if (isNaN(vis.y1(d["colCombo"]) || vis.y1(d["colCombo"])< vis.y1.domain()[0] || vis.y1(d["colCombo"] > vis.y1.domain()[1]))) {
                    return vis.y1.domain()[0];
                }
                return vis.y1(d["colCombo"]); })
            .attr("width", vis.x.bandwidth())
            .attr("height", function (d) {
                console.log(d);
            //console.log(vis.height - vis.y(d['colCombo']))
                console.log(vis.height);
                console.log(vis.y(vis.yVariable))
                if (isNaN(vis.y1(d["colCombo"]))) {
                    return 0;
                }
                else if ((vis.height - vis.y1(d["colCombo"])) < 0 ) {
                    return 0;
                }
                else {
                    return vis.height - vis.y1(d["colCombo"]);
                }

            })
            .attr("fill", rectColor)
        // .attr("sharp-rendering", "crispEdges")


        // labels on the bar chart

        vis.g1.selectAll(".lineChart").remove();
        vis.g1.selectAll(".dot").remove();

        let lineColor = vis.color;

        if (vis.colorScheme[1]) {
            lineColor = vis.colorScheme[1]
        }
        vis.g1.append("path")
            // 27/03/2019 .attr("class", "line")
            .attr("class", "lineChart")
            .attr("fill", "none")
            .attr("stroke", lineColor)
            .attr("stroke-width", "1px")
        // .attr("sharp-rendering", "crispEdges");


        var line = d3.line()
            .x(function (d, i) { return vis.x(d["Categorie"]) + vis.x.bandwidth() / 2; })
            .y(function (d) {
                if (isNaN(vis.y(d["col"]) || vis.y(d["col"])< vis.y.domain()[0] || vis.y(d[vis.yVariable] > vis.y.domain()[1]))) {
                    return vis.y.domain()[0];
                }
                return vis.y(d["col"]); })
            .curve(d3.curveMonotoneX);

        //27/03/2019 vis.g1.select(".line")
        vis.g1.select(".lineChart")
            .transition(vis.t)
            .attr("d", line(vis.dataFiltered));
        var dots = 'dots';
        vis[dots] = vis.g1.selectAll("dot")
            .data(vis.dataFiltered)
            .enter().append("circle")
            .attr('class', "dot")
            .attr('id', function (d, index) {
                return vis.type + vis.mainTab + vis.tabAlias + vis.header + vis.graphIndex + index
            })
            .attr("r", 4.5)
            .attr("cx", function (e) { 
                return vis.x(e['Categorie']) + vis.x.bandwidth() / 2 })
            .attr("cy", function (e) { 
                if (isNaN(vis.y(e["col"]) || vis.y(e["col"])< vis.y.domain()[0] || vis.y(e["col"] > vis.y.domain()[1]))) {
                    return vis.y.domain()[0];
                }
                return vis.y(e["col"]) })
            .attr("fill", lineColor)
            .attr("stroke", "white")
        //.attr("sharp-rendering", "crispEdges")

        return vis;
    }

    //comboAxis
    getComboXandY(vis) {

        let scales = [
            {graphType:"combo", name: "x", axisName:"xAxis", type: d3.scaleBand(), scaleType:"band", range: vis.xWidth, variable: vis.xVariable, axisOrientation: d3.axisBottom(), dividor: vis.xDividor},
            {graphType:"combo", name: "y", axisName: "yAxis", type: d3.scaleLinear(), scaleType: "linear", range: vis.height, variable: vis.yVariable, axisOrientation: d3.axisLeft(), dividor: vis.yDividor},
            {graphType:"combo",name: "y1", axisName:"y1Axis", type: d3.scaleLinear(), scaleType: "linear", range: vis.height, variable: "colCombo", axisOrientation: d3.axisRight(), dividor: vis.yDividor}
        ]
        vis.dashboardInitTypesScalesService.setScales(scales, vis, vis.dataFiltered)

        return vis;
    }

}