import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation, OnDestroy, ViewRef, ChangeDetectionStrategy } from '@angular/core';
import { Subscription } from 'rxjs';
import { PackagesService } from '../../packages.service';
import { AuthorizationsService } from '../../../authorizations/authorizations.service';
import { SharedInitializationService } from 'src/app/shared/shared-common-initialization.service';

@Component({
  selector: 'app-newtopic',
  templateUrl: './newtopic.component.html',
  styleUrls: ['./newtopic.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewtopicComponent implements OnInit, OnDestroy {
  //name of selected topic
  topicName: string = "";

  //all the possible workflows
  workflows: any[] = [];
  //subscription so that workflows are automatically updated
  workflowsSubscription = new Subscription();

  //form controls
  //form control for the workflows assigned to the topic
  selectedWorkflows: any[] = []

  //check whether the admin has changed one of these categories
  workflowsChanged: boolean = false;

  newWorkflows: any[] = [];


  constructor(
    private authorizationsServices: AuthorizationsService,
    private packagesServices: PackagesService,
    private sharedService: SharedInitializationService,
    private cd: ChangeDetectorRef) { }

  ngOnInit() {

    //this is to get the users, sources, workflows and webservices
    this.workflows = this.authorizationsServices.returnAllWorkflows();

    if (this.cd && !(this.cd as ViewRef).destroyed) {
      this.cd.detectChanges();
    }
    this.workflowsChanged = true;

    this.workflowsSubscription = this.authorizationsServices.workflowsChange
      .subscribe((workflows: any) => {
        this.workflows = workflows;
        if (this.cd && !(this.cd as ViewRef).destroyed) {
          this.cd.detectChanges();
        }
      })

  }// end onInit

  changeWorkflow() {
    this.newWorkflows = [];
    for (var i in this.selectedWorkflows) {
      this.newWorkflows.push(this.selectedWorkflows[i].WFName)
    }

    this.workflowsChanged = true;

  }

  //allows to create the new topic
  applyCreateTopicData() {
    let isActive = true;
    this.packagesServices.createUpdateTopicData(
      this.topicName,
      isActive,
      this.newWorkflows,
    )
  }

  //this allows to get the help documentation for a new topic
  getHelpDocumentation() {
    let parameters = ["admin", "settings", "topics", "newtopic"];
    this.sharedService.showHelpDocumentation("Admin Settings - New Topic");

    this.sharedService.getHelpDocumentation(parameters);
  }

  //this is to make sure the subscription won't work once the component is destroyed
  ngOnDestroy() {

    this.workflowsSubscription.unsubscribe();
  }

}
