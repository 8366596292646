import { Injectable } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { WorkflowNameService } from './workflow-name.service';
import { CustomHttpParamEncoder } from 'src/app/shared/custom-http-param-encoder';


@Injectable()
export class WorkflowTwinStepsService {
  constructor(private appService: AppService,
    private httpClient: HttpClient,
    private workflowService: WorkflowNameService) { }

  webUrl = this.appService.getWebUrl();

  twinChartStatusChange = new Subject<any[]>();
  twinMissingValuesChange = new Subject<any[]>();
  twinDuplicatesChange = new Subject<any[]>();
  twinDuplicatesNumberChange = new Subject<any[]>();
  twinResultChange = new Subject<any[]>();
  twinDuplicatesRecordsChange = new Subject<any[]>();


   //Sets the attrlist with new attrtypes in the workflow/table/and returns back the workflow
  //called in workflow twin steps
  setTwinTableAttrTypes(apply_and_chain, workflow, tableName, subtableName, attributesList) {

    const url = this.webUrl;
    const webservice = "WFTwinTableSetAttrTypes";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("apply_and_chain", apply_and_chain)
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subtableName)
      .set('attributesList', attributesList)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {

          this.appService.showMessage("Success", response.statusText);
          if (apply_and_chain) {
            //this.executionStatusChange.next();
          }
          this.workflowService.updateWorkflowTable(tableName, response.wfStructure);
          this.workflowService.updateWorkflowTableSubTable(tableName, response.wfStructure, subtableName);
        }
        else {
          this.appService.showMessage("Error", response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage("Error", error.statusText);
        this.appService.stopSpin();
      });
  }
  //Sets the originby and origin values list in the workflow/table/and returns back the workflow
  //called in workflow twin steps
  setTwinTableOrigin(apply_and_chain, workflow, tableName, subtableName, origin, originValuesList) {

    const url = this.webUrl;
    const webservice = "WFTwinTableSetOrigin";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("apply_and_chain", apply_and_chain)
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subtableName)
      .set('attributesList', originValuesList)
      .set('origin', origin)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {

          this.appService.showMessage("Success", response.statusText);
          if (apply_and_chain) {
            //this.executionStatusChange.next();
          }
          this.workflowService.updateWorkflowTable(tableName, response.wfStructure);
          this.workflowService.updateWorkflowTableSubTable(tableName, response.wfStructure, subtableName);
        }
        else {
          this.appService.showMessage("Error", response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage("Error", error.statusText);
        this.appService.stopSpin();
      });
  }

  setTwinTableFullMatch(apply_and_chain, workflow, tableName, subtableName, attributesList, similarity) {

    const url = this.webUrl;
    const webservice = "WFTwinTableSetFullMatch";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("apply_and_chain", apply_and_chain)
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subtableName)
      .set('attributesList', attributesList)
      .set('similarity', similarity)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.appService.showMessage("Success", response.statusText);
          if (apply_and_chain) {
            //this.executionStatusChange.next();
          }
          this.workflowService.updateWorkflowTable(tableName, response.wfStructure);
          this.workflowService.updateWorkflowTableSubTable(tableName, response.wfStructure, subtableName);
        }
        else {
          this.appService.showMessage("Error", response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage("Error", error.statusText);
        this.appService.stopSpin();
      });
  }
  setTwinTablePartialMatch(apply_and_chain, workflow, tableName, subtableName, attributesList, partialMin, userName) {

    const url = this.webUrl;
    const webservice = "WFTwinTableSetPartialMatch";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("apply_and_chain", apply_and_chain)
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subtableName)
      .set('attributesList', attributesList)
      .set('partialMin', partialMin)
      .set('user_name', userName)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.appService.showMessage("Success", response.statusText);
          if (apply_and_chain) {
            //this.executionStatusChange.next();
          }
          this.workflowService.updateWorkflowTable(tableName, response.wfStructure);
          this.workflowService.updateWorkflowTableSubTable(tableName, response.wfStructure, subtableName);
        }
        else {
          this.appService.showMessage("Error", response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage("Error", error.statusText);
        this.appService.stopSpin();
      });
  }
}