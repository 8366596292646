import { Component, OnInit, ViewEncapsulation, ChangeDetectorRef, OnDestroy, ViewRef, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { PackagesService } from '../../packages.service';
import { AuthorizationsService } from '../../../authorizations/authorizations.service';
import { Subscription } from 'rxjs';
import { SharedGeneralFunctionsService } from 'src/app/shared/shared-general-functions.service';
import { ConfirmationService } from 'primeng/api';
import { SharedInitializationService } from 'src/app/shared/shared-common-initialization.service';

@Component({
  selector: 'app-topic',
  templateUrl: './topic.component.html',
  styleUrls: ['./topic.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopicComponent implements OnInit, OnDestroy {

  //name of selected topic
  topicName: string = "";
  //data (isActive, guitabs, workflows...)for selected topic
  topicData: {
    topicName: string,
    isActive: boolean,
    workflows: any[],
  }[] = [];
  //subscription so that html elements are automatically updated
  topicDataSubscription = new Subscription();
  //all the possible workflows
  workflows: any[] = [];
  //subscription so that workflows are automatically updated
  workflowsSubscription = new Subscription();

  //form controls
  //form control for the workflows assigned to the topic
  selectedWorkflows: any[] = []
  //check whether the admin has changed one of these categories
  workflowsChanged: boolean = false;
  //sets data for update (converting from array of objetcs, to array of strings)
  newWorkflows: any[] = [];

  constructor(private route: ActivatedRoute,
    private authorizationsServices: AuthorizationsService,
    private packagesServices: PackagesService,
    private sharedFunctionsService: SharedGeneralFunctionsService,
    private confirmationService: ConfirmationService,
    private sharedService: SharedInitializationService,
    private cd: ChangeDetectorRef) { }

  ngOnInit() {

    //this is to get the topicname and topicdata according to the param passed in the url
    this.route.params
      .subscribe((params: Params) => {
        if (params['topicname']) {
          this.topicName = params['topicname'];
          //this is to get the users, sources, workflows and webservices
          this.workflows = this.authorizationsServices.returnAllWorkflows();
          if (this.cd && !(this.cd as ViewRef).destroyed) {
            this.cd.detectChanges();
          }
          //reset variables
          this.selectedWorkflows = [];
          this.changeWorkflow()

          if (this.cd && !(this.cd as ViewRef).destroyed) {
            this.cd.detectChanges();
          }

          this.packagesServices.getSingleTopicData(this.topicName);
        }
      })

    //this is to automatically update topic data information
    this.topicDataSubscription = this.packagesServices.topicDataChange
      .subscribe((topicData: any[]) => {
        this.topicData = topicData;

        if (this.topicData.length != 0) {

          if (this.workflows.length != 0) {
            this.getSelectedData("Workflows", "WFName")
          }

        }
        // reinitialisation when topic changes;
        this.workflowsChanged = false;
        if (this.cd && !(this.cd as ViewRef).destroyed) {
          this.cd.detectChanges();
        }

      })

    this.workflowsSubscription = this.authorizationsServices.workflowsChange
      .subscribe((workflows: any) => {
        this.workflows = workflows;
        if (this.cd && !(this.cd as ViewRef).destroyed) {
          this.cd.detectChanges();
        }

        if (this.topicData.length != 0) {
          if (this.sharedFunctionsService.objectsEqual(this.topicData[0].workflows, this.selectedWorkflows) === false) {

            this.getSelectedData("Workflows", "WFName");
          }
        }
      })


  }// end on init

  changeWorkflow() {
    this.newWorkflows = [];
    for (var i in this.selectedWorkflows) {
      this.newWorkflows.push(this.selectedWorkflows[i].WFName)
    }
    if (this.topicData.length != 0) {
      if (this.sharedFunctionsService.arraysEqual(this.newWorkflows, this.topicData[0].workflows) === false) {
        this.workflowsChanged = true;
      }
      else {
        this.workflowsChanged = false;
      }
    }
    else {
      this.workflowsChanged = false;
    }
  }
  //allows to update the selected workflows, users...for the topic in the list boxes
  getSelectedData(param, key) {

    let paramLowerCase = param.toLowerCase();

    let varSelected = "varSelected" + param;
    this[varSelected] = [];
    for (var i in this.topicData[0][paramLowerCase]) {
      for (var j in this[paramLowerCase]) {
        if (this[paramLowerCase][j][key] === this.topicData[0][paramLowerCase][i]) {
          this[varSelected].push(this[paramLowerCase][j]);

          break;
        }
      }
    }

    let selected = "selected" + param;
    this[selected] = this[varSelected];
  }

  //allows to update the topic data, delete the topic or reactivate the topic
  applyUpdateTopicData(param) {
    //let isActive = this.topicData[0].isActive;
    let isActive = true;

    if (param === "update") {
      isActive = true;
    }
    else if (param === "delete") {
      isActive = false;
    }

    if ((param === "update") || (param === "delete")) {

      if (param === "delete") {
        this.confirmationService.confirm({
          message: 'Are you sure that you want to delete ' + this.topicName + '?',
          header: 'Confirmation',

          accept: () => {
            let workflows = [];
            if (this.topicData[0].workflows) {
              workflows = this.topicData[0].workflows
            }
            this.packagesServices.createUpdateTopicData(
              this.topicName,
              isActive,
              workflows,
            )
          }
        });
      }
      else {
        this.packagesServices.createUpdateTopicData(
          this.topicName,
          isActive,
          this.newWorkflows
        )

      }

    }

  }

  //this allows to get the help documentation for editing a topic
  getHelpDocumentation() {
    let parameters = ["admin", "settings", "topics", "edittopic"];
    this.sharedService.showHelpDocumentation("Admin Settings - Edit Topic");

    this.sharedService.getHelpDocumentation(parameters)
  }


  //this is to make sure the subscription won't work once the component is destroyed
  ngOnDestroy() {
    this.topicDataSubscription.unsubscribe();
    this.workflowsSubscription.unsubscribe();
  }
}

