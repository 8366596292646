<div class="row">
  <div class="col-xs-12 col-md-2 col-sm-2" id="menuContainer">
      <div class="btn-group-vertical col-md-12 col-xs-12 col-sm-12" role="group" aria-label="Basic example">
          <button type="button" class="btn btn-light" [routerLink]="['./marketdatasource']" routerLinkActive="active">Market Data Source</button>
          <button type="button" class="btn btn-light" [routerLink]="['./logouttime']" routerLinkActive="active">Logout Time</button>
          <button type="button" [disabled]="false" class="btn btn-light" [routerLink]="['./emailconfiguration']" routerLinkActive="active">Email Configuration</button>
          <button type="button" class="btn btn-light" [routerLink]="['./truestatustype']" routerLinkActive="active">True Status Type</button>
          <button type="button" class="btn btn-light" [routerLink]="['./histogramcellthreshold']" routerLinkActive="active">Histogram Cell Threshold</button>
          <button type="button" class="btn btn-light" [routerLink]="['./logs']" routerLinkActive="active">Logs</button>
          <button type="button" class="btn btn-light" [routerLink]="['./scriptmanagement']" routerLinkActive="active">Script management</button>
          <button type="button" class="btn btn-light" [routerLink]="['./topics']" routerLinkActive="active">Topics</button>
          <button type="button" class="btn btn-light" [routerLink]="['./owners']" routerLinkActive="active">Owners</button>
          <button type="button" class="btn btn-light" [routerLink]="['./serverconfig']" routerLinkActive="active">Server Config</button>
        </div>
    
  </div>
  <div class="col-xs-12 col-md-10 col-sm-10">
      <router-outlet></router-outlet>
  </div>

</div>
