<div class="row">
  <div class="col-xs-12 col-md-12 col-sm-12">
    <i class="pi pi-question-circle piHelp" id="helpIcon" (click)="getHelpDocumentation()"></i>
  </div>
</div>
<div class="row">
  <div class="col-md-12 textAlignCenter">
    <h3>{{workflowName}}</h3>
  </div>
</div>

<div class="row">
  <div class="col-md-1"></div>
  <div class="col-md-2 offset-md-8">
    <div class="btn-group cardabel-owner-btn-group">

      <button class="btn btn-primary btn-sm" [disabled]="(!ownersChanged)"
        (click)="applyUpdateWorkflowOwnersData('update')">
        <span>
          <i class="fa fa-cog"></i>
        </span> Apply
      </button>
    </div>
  </div>
</div>
<div id="editOwnerContainer">

  <div class="row margin-top-40">
    <div class="col-md-1"></div>
    <div class="col-md-5 textAlignCenter">
      <h5 class="ownerTitle">Owners</h5>
      <cardabel-selection-list id="ownerContainer" [attr.data-cy]="'admin-settings-workflow-owner-selection-list'"
        [inputSelectedOptions]="selectedOwners" [options]="owners" [height]="440" [title]="'owners'"
        [optionLabel]="'UserName'" [multipleSelection]="false"
        (changeSelectedOptions)="selectedOwners = $event.value; changeOwner()"></cardabel-selection-list>

    </div>
    <div class="col-md-1"></div>
  </div>
</div>