import { LoginComponent } from './auth/login/login.component';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { AuthGuard } from './auth/auth-guard.service';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ExecutionComponent } from './execution/execution.component';
//import { GuitabGuard } from './auth/guitab-guard.service';
import { AdminModule } from './admin/admin.module';
import { WorkflowModule } from './workflow/workflow.module';
import { ExecutionModule } from './execution/execution.module';
import { UsersettingsComponent } from './usersettings/usersettings.component';
import { UserSettingsModule } from './usersettings/usersettings.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { isDevMode } from '@angular/core';

import { TopicsDashboardComponent } from './topics-dashboard/topics-dashboard.component';


const appRoutes = [
    { path: '', redirectTo: '/login', pathMatch: 'full' },
    {
        path: '',
        children: [
            { path: 'login', component: LoginComponent, pathMatch: 'full' },
            { path: 'topics', canActivate: [AuthGuard], component: TopicsDashboardComponent, pathMatch: 'full' },
            { path: 'analysis', loadChildren: "./analysis/analysis.module#AnalysisModule" },
           { path: 'admin', loadChildren: "./admin/admin.module#AdminModule" },
            { path: 'usersettings', loadChildren: "./usersettings/usersettings.module#UserSettingsModule" },
            { path: 'dashboard', canActivate: [AuthGuard], loadChildren: "./dashboard/dashboard.module#DashboardModule" },
            { path: 'workflow', loadChildren: "./workflow/workflow.module#WorkflowModule" },
            { path: 'execution', loadChildren: "./execution/execution.module#ExecutionModule" },
            { path: 'matching', loadChildren: "./matching/matching.module#MatchingModule", pathMatch: 'full' },
        ]
    },
    { path: '**', redirectTo: '', pathMatch: 'full' }


]

/* if (isInDevMode() === true) {
    appRoutes = [
        //{path: '', redirectTo:'/login', pathMatch:'full'},
        { path: '', redirectTo:'/login', pathMatch:'full'},
        { path: 'login', component: LoginComponent, pathMatch:'full'},
        { path: 'analysis',  canActivate: [AuthGuard], loadChildren:() => AnalysisModule },
        { path: 'admin', loadChildren: () => AdminModule},
        { path: 'usersettings', loadChildren: () => UserSettingsModule},
        { path: 'dashboard', canActivate: [AuthGuard], loadChildren: () => DashboardModule},
        { path: 'workflow', loadChildren: () => WorkflowModule },
        { path: 'execution', loadChildren: () => ExecutionModule },

    ]
}
export function isInDevMode():boolean {
    return isDevMode();
} */

@NgModule({
    imports: [RouterModule.forRoot(appRoutes)],
    exports: [RouterModule]
})

export class AppRoutingModule {

}
