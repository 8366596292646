import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth-service';

@Injectable()
export class ServerErrorsInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
  ) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request);

  }
}

 @Injectable()
export class TokenExpiredInterceptor implements HttpInterceptor {

  constructor(private router: Router, private authService: AuthService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
     return next.handle(request).pipe(
       tap(
         (event: HttpEvent<any>) => {},
         (error: any) => {
           if (error instanceof HttpErrorResponse && error.status === 401) {
             // Token expired, navigate to login page
             this.authService.isAuthenticatedChange.next(false);
             this.router.navigate(['/login']);
           }
         }
       )
     );
   }
 }
