import { Injectable } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { HttpClient, HttpParams} from '@angular/common/http';
import { Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CustomHttpParamEncoder } from 'src/app/shared/custom-http-param-encoder';

@Injectable()
export class HistogramCellThresholdService {

    constructor(
        private appService: AppService,
        private httpClient: HttpClient,
    ){}

    histogramCellThreshold:number;
    histogramCellThresholdChange =new Subject<number>();

    webUrl = this.appService.getWebUrl();

    //Gets the histogramCellThreshold
    //called in histogramCellThreshold component
    getHistogramCellThreshold() {
 
        const url = this.webUrl;
        const webservice = "AdminGetHistogramCellThreshold";
        const completeUrl = url+webservice;

        const headers = this.appService.getHeaders();
        this.appService.startSpin();

        //Create new HttpParams */
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() });
 
        return this.httpClient.post(completeUrl, params, {headers, responseType:'text', withCredentials:true})
          .pipe(
            catchError(this.appService.handleError)
          )
          .subscribe((response:any) => {

            response = JSON.parse(response);
            if (response.statusCode > -1 ) {
                this.histogramCellThreshold = response.tableRows[0].histogramCellThreshold;
                this.histogramCellThresholdChange.next(this.histogramCellThreshold);
            }
            else {
              this.appService.showMessage('Error', response.statusText)          
            }
            this.appService.stopSpin();
          }, (error) => {
            this.appService.showMessage('Error', error.statusText)          
            this.appService.stopSpin();
          });
    }

    //Sets the histogramCellThreshold.
    //called in histogramCellThreshold component
    setHistogramCellThreshold(histogramCellThreshold) { 

        const url = this.webUrl;
        const webservice = "AdminSetHistogramCellThreshold";
        const completeUrl = url+webservice;

        const headers = this.appService.getHeaders();

        this.appService.startSpin();

        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
        .set("histogramCellThreshold",histogramCellThreshold);
 
        return this.httpClient.post(completeUrl, params, {headers, responseType:'text', withCredentials:true})
          .pipe(
            catchError(this.appService.handleError)
          )
          .subscribe((response:any) => {

            response = JSON.parse(response);
            if (response.statusCode > -1 ) {
                this.appService.showMessage('Success', response.statusText); 
                this.histogramCellThresholdChange.next(histogramCellThreshold);       
            }
            else {
              this.appService.showMessage('Error', response.statusText)          
            }
            this.appService.stopSpin();
          }, (error) => {
            this.appService.showMessage('Error', error.statusText)          
            this.appService.stopSpin();
          });
    }
       
    
}