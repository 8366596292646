import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { StreamWithEffect } from "../../interfaces";

@Injectable({
    providedIn: 'root',
})
export class CardabelDatatableFilterService {

    public filterText: any;
    public filterTextChange = new Subject<any>();
    public numericMinChange = new Subject<any>();
    public numericMaxChange = new Subject<any>();
    public dateToChange = new Subject<any>();
    public dateFromChange = new Subject<any>();
    public selectAllRowsChange = new Subject<any>();
    public numericFilterAndOrChange = new Subject<any>();
    public inNotConditionChange = new Subject<any>();
    public numberSelectedCheckChange = new Subject<any>();
    public numberSelectedCheck = [];

    public filterStream(vis: any, streamWithEffect: StreamWithEffect) {


        const stream = streamWithEffect.stream.slice();
        let filter = streamWithEffect.effects && streamWithEffect.effects.filter && vis.streamSubscribe.filter;
        //  this.filterSliceStream = undefined;
        if (!filter || !(vis._config && vis.config.filter)) {
            vis.streamSubscribe.filter = true;
            return [stream, streamWithEffect.effects]
        }

        let filterSliceStream = JSON.parse(JSON.stringify(stream));

        filterSliceStream = this.returnFilters(filterSliceStream, vis.filters);
        //const filterString = filterStr.toLocaleLowerCase();
        if (vis.type === "Matching") {
            filterSliceStream = vis.cardabelDatatableMatchingService.returnMatchingFilters(filterSliceStream, vis.matchingArray, vis.groupField, vis.matchingRecGroup, vis.matchingSignedMeasure, vis.selectedMatchingView, vis.initRowGroupMetaData, vis.filterOnMatchingRec, vis.filterOnMatchingSize, vis.filterOnSignedMeasure, vis.filterMatchingRecANDOR, vis.filterMatchingRecMin, vis.filterMatchingRecMax, vis.filterMatchingSizeANDOR, vis.filterMatchingSizeMin, vis.filterMatchingSizeMax, vis.filterSignedMeasureANDOR, vis.filterSignedMeasureMin, vis.filterSignedMeasureMax)
        }

        vis.filterSliceStream = [...filterSliceStream];
        vis.filterLength = filterSliceStream.length;

        if ((filterSliceStream.length === 0) && vis.dataArray3.length != 0) {
            filterSliceStream.push(vis.dataArray3[0]);
            let keys = Object.keys(filterSliceStream[0]);
            for (var m in keys) {
                filterSliceStream[0][keys[m]] = "";
            }
        }

        if (vis.groupField != "") {
            streamWithEffect.effects.group = "[]"
        }


        return [filterSliceStream, streamWithEffect.effects]
    }

    returnFilters(filterSliceStream, filters) {

        for (var i in filters) {
            var value = filters[i].value;
            var field = filters[i].field;
            var type = filters[i].filterType;
            var value2 = filters[i].value2;
            var condition = filters[i].filterCondition;

            if ((type === "greater") || (type === "less")) {
                if (typeof value === "string") {
                    if (value.indexOf("K") > -1) {
                        value = value.substr(0, value.indexOf('K'));
                        value = +value * 1000;
                    }
                    else if (value.indexOf("k") > -1) {
                        value = value.substr(0, value.indexOf('k'));
                        value = +value * 1000;
                    }
                    else if (value.indexOf("M") > -1) {
                        value = value.substr(0, value.indexOf('M'));
                        value = +value * 1000000;
                    }
                    else if (value.indexOf("m") > -1) {
                        value = value.substr(0, value.indexOf('m'));
                        value = +value * 1000000;
                    }
                    else if (value.toString() != "") {
                        value = +value;
                    }
                }
                if (typeof value2 === "string") {
                    if (value2.indexOf("K") > -1) {
                        value2 = value2.substr(0, value2.indexOf('K'));
                        value2 = +value2 * 1000;
                    }
                    else if (value2.indexOf("k") > -1) {
                        value2 = value2.substr(0, value2.indexOf('k'));
                        value2 = +value2 * 1000;
                    }
                    else if (value2.indexOf("M") > -1) {
                        value2 = value2.substr(0, value2.indexOf('M'));
                        value2 = +value2 * 1000000;
                    }
                    else if (value2.indexOf("m") > -1) {
                        value2 = value2.substr(0, value2.indexOf('m'));
                        value2 = +value2 * 1000000;
                    }
                    else if (value2.toString() != "") {
                        value2 = +value2;
                    }
                }


            }

            if ((type === "dateFrom") || (type === "dateTo")) {
                value = new Date(value);

                if ((value2 != undefined) && (value2 != null) && (value2 != "")) {
                    value2 = new Date(value2)
                }
            }

            filterSliceStream = filterSliceStream.filter(function (item) {

                //if (type === 'isEmpty') {
                if (type === 'empty') {
                    if ((item[field] != undefined) && (item[field] != null) && (item[field] != '')) {
                        return false;
                    }
                }
                else if (type === 'notempty') {//if (type === 'isNotEmpty') {
                    if ((item[field] === undefined) || (item[field] === null) || (item[field] === '')) {
                        return false;
                    }
                }
                else if (type === '+') {
                    if ((item[field] === undefined) || (item[field] === null)) {
                        return false;
                    }
                    else if ((value != "") && (value != undefined)) {
                        if (item[field].toString().toLowerCase().indexOf(value.toString().toLowerCase()) === -1) {
                            return false;
                        }
                    }

                }
                else if (type === '-') {
                    if ((value != "") && (value != undefined) && (item[field] != undefined) && (item[field] != null)) {
                        if (item[field].toString().toLowerCase().indexOf(value.toString().toLowerCase()) > -1) {
                            return false;
                        }
                    }
                }
                else if (type === '=') {
                    if ((value != "") && (value != undefined) && (item[field] != undefined) && (item[field] != null)) {
                        if (item[field].toString().toLowerCase() != value.toString().toLowerCase()) {
                            return false;
                        }
                    }

                }
                else if (type === '!') {
                    if ((value != "") && (value != undefined) && (item[field] != undefined) && (item[field] != null)) {
                        if (item[field].toString().toLowerCase() === value.toString().toLowerCase()) {
                            return false;
                        }
                    }

                }
                else if (type === 'in') {
                    let returnValue1 = true;
                    let returnValue2 = false;
                    if (condition === 'NOT') {
                        returnValue1 = false;
                        returnValue2 = true;
                    }
                    for (var i in value) {
                        if (item[field] === null) {
                            if (value[i] === "null") {
                                value[i] = null;
                            }

                            if (item[field] === value[i]) {
                                return returnValue1;
                            }
                        }

                        else if (typeof item[field] === 'string') {
                            if ((value[i] != 'null') && (value[i] != null)) {
                                if (item[field].toString().toLowerCase() === (value[i].toLowerCase())) {
                                    return returnValue1;
                                }
                            }
                        }
                        else if (typeof item[field] === 'number') {
                            if ((value[i] != 'null') && (value[i] != null)) {
                                value[i] = +value[i];
                            }


                            if (item[field] === value[i]) {
                                return returnValue1;
                            }
                        }

                    }
                    return returnValue2;
                }
                else if (((type === 'greater') || (type === 'less')) && (item[field] != undefined) && (item[field] != null)) {

                    if ((value2 != undefined) && (value2 != null) && (value2.toString() != "")) {


                        if (condition === "AND" || condition === true) {
                            if (type === "greater") {
                                if (item[field] >= value) {
                                    if (item[field] <= value2) {
                                        return true;
                                    }
                                    else {
                                        return false;
                                    }
                                }
                                else {
                                    return false;
                                }
                            }
                            else {
                                if (item[field] <= value) {
                                    if (item[field] >= value2) {
                                        return true;
                                    }
                                    else {
                                        return false;
                                    }
                                }
                                else {
                                    return false;
                                }

                            }

                        }
                        else {
                            if (type === 'greater') {
                                if (item[field] >= value) {
                                    return true
                                }
                                else if (item[field] <= value2) {
                                    return true;
                                }
                                return false;
                            }
                            else if (type === 'less') {
                                if (item[field] <= value) {
                                    return true
                                }
                                else if (item[field] >= value2) {
                                    return true;
                                }
                                return false;
                            }
                        }
                    }
                    else if (type === "greater") {
                        if (value.toString() != "") {
                            if (item[field] < value) {
                                return false;
                            }
                        }

                    }
                    else if (type === 'less') {
                        if (value.toString() != "") {
                            if (item[field] > value) {
                                return false;
                            }
                        }
                    }

                }

                else if (((type === 'dateFrom') || (type === 'dateTo')) && (item[field] != undefined) && (item[field] != null)) {
                    let newDate = new Date(item[field]);

                    if ((value2) && (value2 != "") && (value2 != null)) {

                        if (type === 'dateFrom') {
                            if ((newDate >= value) && (newDate <= value2)) {
                                return true;
                            }
                            else {
                                return false;
                            }
                        }
                        else if (type === 'dateTo') {
                            if ((newDate <= value) && (newDate >= value2)) {
                                return true;
                            }
                            else {
                                return false;
                            }
                        }
                    }
                    else if (type === 'dateFrom') {
                        if (newDate <= value) {
                            return false;
                        }
                    }
                    else if (type === 'dateTo') {
                        if (newDate >= value) {
                            return false;
                        }
                    }
                }



                return true;
            })


        };

        return filterSliceStream;
    }

    formatFilters(filters, filterField, filterValue, filterType, filterValue2, filterCondition, inputType) {
        let fieldExists = false;

        let i = filters.findIndex(item => item.field === filterField);

        if (i != -1) {
            filters[i].value = filterValue;
            filters[i].value2 = filterValue2;

            filters[i].inputType = inputType;
            filters[i].filterType = filterType;
            filters[i].filterCondition = filterCondition;

            if ((filterType === 'greater') || (filterType === 'less')) {
                if (filters[i].value === "") {
                    if ((filterValue2 != null) && (filterValue2) && (filterValue2 != "")) {
                        filters[i].value = filterValue2;
                        filters[i].value2 = "";
                        if (filterType === "greater") {
                            filters[i].filterType = "less";
                        }
                        else {
                            filters[i].filterType = "greater";
                        }
                    }
                }
            }

            if ((filterType === 'dateFrom') || (filterType === 'dateTo')) {
                if (filters[i].value === "") {
                    if ((filterValue2 != null) && (filterValue2) && (filterValue2 != "")) {
                        filters[i].value = filterValue2;
                        filters[i].value2 = "";
                        if (filterType === "dateFrom") {
                            filters[i].filterType = "dateTo";
                        }
                        else {
                            filters[i].filterType = "dateFrom";
                        }
                    }
                }
            }
        }
        else {
            filters.push({ field: filterField, value: filterValue, filterType: filterType, value2: filterValue2, filterCondition: filterCondition, inputType: inputType })
        }

        for (var k = filters.length - 1; k > -1; k--) {
            if ((filters[k].filterType != 'empty') && (filters[k].filterType != 'notempty') && (((filters[k].value === "") && (filters[k].value2 === "")) || filters[k].value === null || filters[k].value2 === null)) {
                filters.splice(k, 1)
            }
        }

        return filters;
    }

    getFilterText() {
        return this.filterText;
    }

    setFilterText(filterText) {
        this.filterText = filterText;
        this.filterTextChange.next(filterText);
    }

    changeFilterText(filterText, numericMin, numericMax, dateTo, dateFrom, numericFilterAndOr, selectAllRows, inNotCondition, numberSelectedCheck) {
        this.filterText = filterText;
        this.filterTextChange.next(filterText);
        this.numericMinChange.next(numericMin);
        this.numericMaxChange.next(numericMax);
        this.dateToChange.next(dateTo);
        this.dateFromChange.next(dateFrom);
        this.numericFilterAndOrChange.next(numericFilterAndOr);
        this.selectAllRowsChange.next(selectAllRows);
        this.numberSelectedCheckChange.next(numberSelectedCheck)
        this.inNotConditionChange.next(inNotCondition);
        this.numberSelectedCheck = numberSelectedCheck;
    }

    resetFilterText(column, filterText, dateTo, dateFrom, numericMin, numericMax, numericFilterAndOr, inNotCondition, selectAllRows, numberSelectedCheck) {
        for (var i in column) {
            if (column[i].type === "string") {
                filterText[column[i].name] = "";
            }
            else if (column[i].type === "date") {
                dateTo[column[i].name] = "";
                dateFrom[column[i].name] = "";
            }
            else {
                numericMin[column[i].name] = "";
                numericMax[column[i].name] = "";
                numericFilterAndOr[column[i].name] = true;
            }

            if ((column[i].name != "C") && (column[i].name != "R") && (column[i].name != "guiIndex")) {
                selectAllRows[column[i].name] = false;
                //this.selectAll(this.column[i].name)
            }
            inNotCondition[column[i].name] = "IN";
            numberSelectedCheck[column[i].name] = "All";
        }
        this.numberSelectedCheck = [];
        this.filterText = filterText;
        return [filterText, dateTo, dateFrom, numericMin, numericMax, numericFilterAndOr, inNotCondition, selectAllRows, numberSelectedCheck]
    }

    removeFilter(header, type, from, filterType, groupedColumn, alreadyGrouped, filterText, filtered, numberSelectedCheck, globalFilterText, numericMin, numericMax, dateTo, dateFrom, selectAllRows, globalFiltersArray, globalFilter) {
        groupedColumn = false;
        alreadyGrouped = false;

        if (type === "string") {
            filterText[header] = '';
            filtered[header] = [];
            numberSelectedCheck[header] = 'All';
            globalFilterText[header] = "";

        }
        else if (type === "numeric") {
            if (from === "column") {
                numericMin[header] = "";
                numericMax[header] = "";
            }
            else {
                if (filterType === "greater") {
                    numericMin[header] = "";
                }
                else {
                    numericMax[header] = "";
                }
            }

        }
        else if (type === "date") {
            dateTo[header] = "";
            dateFrom[header] = "";
            if (filterType === "dateTo") {
                dateTo[header] = "";
            }
            else {
                dateFrom[header] = "";
            }
        }

        selectAllRows[header] = false;
        //this.selectAll(header)

        for (var i = globalFiltersArray.length - 1; i > -1; i--) {
            if (globalFiltersArray[i].field === header) {
                if (from === "column") {
                    globalFilter[globalFiltersArray[i].field] = false;
                    globalFilterText[globalFiltersArray[i].field] = "";
                    globalFiltersArray.splice(+i, 1);
                }
                else {
                    if (globalFiltersArray[i].filterType === filterType) {
                        globalFilter[globalFiltersArray[i].field] = false;
                        globalFilterText[globalFiltersArray[i].field] = "";
                        globalFiltersArray.splice(+i, 1);
                    }
                }

            }
        }

        this.numberSelectedCheck = numberSelectedCheck;
        return [groupedColumn, alreadyGrouped, filterText, filtered, numberSelectedCheck, globalFilterText, numericMin, numericMax, dateTo, dateFrom, selectAllRows, globalFiltersArray, globalFilter]
    }
}