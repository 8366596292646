import { Component, OnInit, OnDestroy, ChangeDetectionStrategy,ChangeDetectorRef,ViewRef } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { MarketDataSourceService } from './market-data-source.service';
import { Subscription } from 'rxjs';
import { SharedInitializationService } from 'src/app/shared/shared-common-initialization.service';
import { SharedGeneralFunctionsService } from 'src/app/shared/shared-general-functions.service';

@Component({
  selector: 'app-market-data-source',
  templateUrl: './market-data-source.component.html',
  styleUrls: ['./market-data-source.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarketDataSourceComponent implements OnInit, OnDestroy {

  optionsMarketDataSource: SelectItem[];
  selectedOptionSource: { label: 'None', value: 'None', icon: 'pi-ban' };
  visibleHelpSidebar;

  subscriptionMarketDataSource = new Subscription();


  constructor(private marketdatasourceservice: MarketDataSourceService,
    private sharedService: SharedInitializationService,
    private sharedFunctionService: SharedGeneralFunctionsService,
    private cd: ChangeDetectorRef) {

    this.optionsMarketDataSource = [
      { label: 'File', value: 'File', icon: 'pi-file' },
      { label: 'Web', value: 'Web', icon: 'pi-globe' },
      { label: 'None', value: 'None', icon: 'pi-ban' }
    ];

  }

  ngOnInit() {

    this.selectedOptionSource={ label: 'None', value: 'None', icon: 'pi-ban' };
    this.marketdatasourceservice.getMarketDataSource();

    this.subscriptionMarketDataSource = this.marketdatasourceservice.marketDataSourceChange
      .subscribe(
        (selectedOptionSource: string) => {
          for (var i in this.optionsMarketDataSource) {
            if (this.optionsMarketDataSource[i].label === selectedOptionSource) {           
              this.selectedOptionSource = this.sharedFunctionService.getElementSelected(this.optionsMarketDataSource, 'value', selectedOptionSource);
              if (this.cd && !(this.cd as ViewRef).destroyed) {
                this.cd.detectChanges();
              }
            }
          }
        
        }
      );
  }

  onSaveChanges() {
    this.marketdatasourceservice.setMarketDataSource(this.selectedOptionSource.value);
  }

  //this allows to get the help documentation for settings market data source
  getHelpDocumentation() {
    let parameters = ["admin", "settings", "marketdatasource"];
    this.sharedService.showHelpDocumentation("Admin Settings Market Data Source");

    this.sharedService.getHelpDocumentation(parameters)
  }
  //this is to destroy subscriptions when component is destroyed
  ngOnDestroy() {
    this.subscriptionMarketDataSource.unsubscribe();
  }
}
