<div class="loginContainer">
    <div class="wrapper" [hidden]="passwordChange">
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="login"  >
          <div class="row">
            <!--<img id="logo" src="assets/image/CardabelLogo.jpg">-->
            <img id="logo" src="assets/image/favicon-96x96.png">
            <p class="title">Cardabel Log in</p>
          </div>
          <input [attr.data-cy]="'login-username'" autocomplete="off" type="text" placeholder="Username" autofocus  formControlName="username"/>
          <i class="fa fa-user"></i>
          <input [attr.data-cy]="'login-password'" autocomplete="off" type="password" placeholder="Password"  formControlName="password" />
          <i class="fa fa-key"></i>
        
          <button [attr.data-cy]="'login-submit'">
            <i class="spinner"></i>
            <span class="state" id="state">Log in</span>
          </button>
     
        </form>
    </div>

    <div class="wrapper" [hidden]="!passwordChange"> 

        <form [formGroup]="passwordForm" (ngSubmit)="onSubmitPassword()" class="login" >
          <div class="row">
            <!--<img id="logo" src="assets/image/CardabelLogo.jpg">-->
            <img id="logo" src="assets/image/favicon-96x96.png">
            <p class="title">Cardabel Log in</p>
          </div>
          <input [attr.data-cy]="'login-passwordChange-currentPassword'" autocomplete="off" type="password" placeholder="Current Password" autofocus  formControlName="oldpassword"/>
          <i class="fa fa-key"></i>
          <input [attr.data-cy]="'login-passwordChange-newPassword1'" autocomplete="off" type="password" placeholder="New Password"  formControlName="newpassword1" />
          <i class="fa fa-key"></i>
          <input [attr.data-cy]="'login-passwordChange-newPassword2'" autocomplete="off" type="password" placeholder="Confirm New Password"  formControlName="newpassword2" />
          <i class="fa fa-key"></i>

          <button>
            <i class="spinner"></i>
            <span  [attr.data-cy]="'login-passwordChange-submit'" class="state" id="state">Change Password</span>
          </button>
     
        </form>
      
       
      </div>
</div>
