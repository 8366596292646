import { Component, OnInit, ViewEncapsulation, ChangeDetectorRef, OnDestroy, ViewRef, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { PackagesService } from '../../packages.service';
//import { AuthorizationsService } from '../../../authorizations/authorizations.service';
import { Subscription } from 'rxjs';
import { SharedGeneralFunctionsService } from 'src/app/shared/shared-general-functions.service';
import { ConfirmationService } from 'primeng/api';
import { SharedInitializationService } from 'src/app/shared/shared-common-initialization.service';

@Component({
  selector: 'app-owner',
  templateUrl: './owner.component.html',
  styleUrls: ['./owner.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OwnerComponent implements OnInit, OnDestroy {

  //name of selected owner
  userName: string = "";
  //data (isActive, guitabs, workflows...)for selected owner
  ownerData: {
    userName: string,
    isActive: boolean,
    workflows: any[],
  }[] = [];
  //subscription so that html elements are automatically updated
  ownerDataSubscription = new Subscription();
  //all the possible workflows
  workflows: any[] = [];
  //subscription so that workflows are automatically updated
  workflowsSubscription = new Subscription();

  //form controls
  //form control for the workflows assigned to the owner
  selectedWorkflows: any[] = []
  //check whether the admin has changed one of these categories
  workflowsChanged: boolean = false;
  //sets data for update (converting from array of objetcs, to array of strings)
  newWorkflows: any[] = [];

  constructor(private route: ActivatedRoute,
    //private authorizationsServices: AuthorizationsService,
    private packagesServices: PackagesService,
    private sharedFunctionsService: SharedGeneralFunctionsService,
    private confirmationService: ConfirmationService,
    private sharedService: SharedInitializationService,
    private cd: ChangeDetectorRef) { }

  ngOnInit() {

    //this is to get the username and ownerdata according to the param passed in the url
    this.route.params
      .subscribe((params: Params) => {
        this.selectedWorkflows = [{WFName: ''}]
        this.changeWorkflow();

        if (params['Username']) {
          this.userName = params['Username'];
          //this is to get the users, sources, workflows and webservices
          this.packagesServices.getOwnerWorkflowList(this.userName);
          if (this.cd && !(this.cd as ViewRef).destroyed) {
            this.cd.detectChanges();
          }
          //reset variables
       

          if (this.cd && !(this.cd as ViewRef).destroyed) {
            this.cd.detectChanges();
          }
          this.packagesServices.getSingleOwnerData(this.userName);
        }
      })

    //this is to automatically update owner data information
    this.ownerDataSubscription = this.packagesServices.ownerDataChange
      .subscribe((ownerData: any[]) => {
        this.ownerData = ownerData;
        this.selectedWorkflows = [{WFName: ''}];
        this.workflows = JSON.parse(JSON.stringify(this.workflows));
        this.cd.detectChanges()

        if (this.ownerData.length != 0) {

          if (this.workflows.length != 0) {
            this.getSelectedData("Workflows", "WFName");
          }

        }
        else {
          this.getSelectedData("Workflows", "WFName");
        }
        // reinitialisation when owner changes;
        this.workflowsChanged = false;
        if (this.cd && !(this.cd as ViewRef).destroyed) {
          this.cd.detectChanges();
        }

      })

    this.workflowsSubscription = this.packagesServices.ownerWorkflowsChange
      .subscribe((workflows: any) => {
        this.workflows = workflows;
        if (this.cd && !(this.cd as ViewRef).destroyed) {
          this.cd.detectChanges();
        }

        if (this.ownerData.length != 0) {
          if (this.sharedFunctionsService.objectsEqual(this.ownerData[0].workflows, this.selectedWorkflows) === false) {

            this.getSelectedData("Workflows", "WFName");
          }
        }
      })


  }// end on init

  changeWorkflow() {
    this.newWorkflows = [];
    for (var i in this.selectedWorkflows) {
      this.newWorkflows.push(this.selectedWorkflows[i].WFName)
    }
    if (this.ownerData.length != 0) {
      if (this.sharedFunctionsService.arraysEqual(this.newWorkflows, this.ownerData[0].workflows) === false) {
        this.workflowsChanged = true;
      }
      else {
        this.workflowsChanged = false;
      }
    }
    else {
      this.workflowsChanged = true;
    }

  }

  //allows to update the selected workflows, users...for the owner in the list boxes
  getSelectedData(param, key) {

    let paramLowerCase = param.toLowerCase();

    let varSelected = "varSelected" + param;
    this[varSelected] = [];
    this.cd.detectChanges()
    if (this.ownerData.length > 0) {
      for (var i in this.ownerData[0][paramLowerCase]) {
        for (var j in this[paramLowerCase]) {
          if (this[paramLowerCase][j][key] === this.ownerData[0][paramLowerCase][i]) {
            this[varSelected].push(this[paramLowerCase][j]);

            break;
          }
        }
      }
    }

    let selected = "selected" + param;
    this[selected] = [...this[varSelected]];
    this.cd.detectChanges()
  }

  //allows to update the owner data, delete the owner or reactivate the owner
  applyUpdateOwnerData(param) {

    let isActive = true;
  
    if (param === "update") {
      isActive = true;
    }
    else if (param === "delete") {
      isActive = false;
    }

    if ((param === "update") || (param === "delete")) {

      if (param === "delete") {
        let workflows = [];
        if (this.ownerData[0].workflows) {
          workflows = this.ownerData[0].workflows
        }
        this.confirmationService.confirm({
          message: 'Are you sure that you want to delete ' + this.userName + '?',
          header: 'Confirmation',

          accept: () => {
            this.packagesServices.createUpdateOwnerData(
              this.userName,
              isActive,
              this.ownerData[0].workflows,

            )
          }
        });
      }
      else {
        this.packagesServices.createUpdateOwnerData(
          this.userName,
          isActive,
          this.newWorkflows
        )

      }

    }
  }

  //this allows to get the help documentation for editing a owner
  getHelpDocumentation() {
    let parameters = ["admin", "settings", "owners", "editowner"];
    this.sharedService.showHelpDocumentation("Admin Settings - Edit Owner");

    this.sharedService.getHelpDocumentation(parameters)
  }


  //this is to make sure the subscription won't work once the component is destroyed
  ngOnDestroy() {
    this.ownerDataSubscription.unsubscribe();
    this.workflowsSubscription.unsubscribe();

  }
}


