import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { catchError } from "rxjs/operators";
import { AppService } from "src/app/app.service";
import { CustomHttpParamEncoder } from "src/app/shared/custom-http-param-encoder";
import { SharedInitializationService } from "src/app/shared/shared-common-initialization.service";
import { SharedGeneralFunctionsService } from "src/app/shared/shared-general-functions.service";
import { WorkflowNameService } from "src/app/workflow/services/workflow-name.service";
import { AnalysisService } from "../analysis.service";

@Injectable()
export class AnalysisTemporalService {
  constructor(private appService: AppService,
    private httpClient: HttpClient,
    private analysisService: AnalysisService
  ) {
  }

  webUrl = this.appService.getWebUrl();

  //temporal
  allModelsThresholdChange: { [index: string]: Subject<any> } = {};
  temporalViewNatureChange: { [index: string]: Subject<any> } = {};
  temporalLeafSeqLengthChange: { [index: string]: Subject<any> } = {};
  // temporal details
  enableTemporalDetailsButtonChange: { [index: string]: Subject<boolean> } = {};
  viewTemporalDetailsChange: { [index: string]: Subject<boolean> } = {};
  viewTemporalDetails: any = {};
  viewTemporalDetailsDataChange: { [index: string]: Subject<boolean> } = {};
  viewTemporalRedoButtonChange: { [index: string]: Subject<boolean> } = {};
  newshowTemporalDetailsChange: { [index: string]: Subject<boolean> } = {};

  leafSeqLength: any = {};

  initTabChanges(tabs) {
    for (var i in tabs) {
      this.viewTemporalDetails[tabs[i].name] = false;
      this.allModelsThresholdChange[tabs[i].name] = new Subject<any>();
      this.temporalViewNatureChange[tabs[i].name] = new Subject<any>();
      this.temporalLeafSeqLengthChange[tabs[i].name] = new Subject<any>();
      this.enableTemporalDetailsButtonChange[tabs[i].name] = new Subject<boolean>();
      this.viewTemporalDetailsChange[tabs[i].name] = new Subject<boolean>();
      this.viewTemporalRedoButtonChange[tabs[i].name] = new Subject<boolean>();
      this.viewTemporalDetailsDataChange[tabs[i].name] = new Subject<boolean>();
      this.newshowTemporalDetailsChange[tabs[i].name] = new Subject<boolean>();
      this.leafSeqLength[tabs[i].name] = 0;
    }

  }

  //determines whether the view temporal details button within analysis menu should be enable or not
  //called in analysis grid component
  enableViewTemporalDetailsButton(param, tab) {
    this.enableTemporalDetailsButtonChange[tab].next(param);
  }
  //sets the value of detection threshold
  // called in analysis menu temporal subtable selected and is automatically updated in grid
  setModelThresholdValue(model, tab) {
    this.allModelsThresholdChange[tab].next(model);
  }

  setNewShowTemporalDetails(param, tab) {
    this.newshowTemporalDetailsChange[tab].next(param)
  }
  setnewshowViewTemporalDetails(param, tab) {

    this.newshowTemporalDetailsChange[tab].next(param);
    this.viewTemporalDetails[tab] = !this.viewTemporalDetails[tab];
    this.viewTemporalDetailsChange[tab].next(this.viewTemporalDetails[tab]);
  }

  setViewTemporalDetails(param, tab) {
    this.viewTemporalDetails[tab] = param;
  };
  setTemporalView(value, tab) {// chart or data
    this.temporalViewNatureChange[tab].next(value);
  }
  setLeafSeqLength(leafSeqLength, tab) {
    this.leafSeqLength[tab] = leafSeqLength;
    this.temporalLeafSeqLengthChange[tab].next(leafSeqLength);
  }
  getLeafSeqLength(tab) {
    return this.leafSeqLength[tab];
  }

  // temporal
  //analysis get temporal drill 
  //called in analysis grid component when temporal details is enabled

  getAnalysisTemporalDrill(workflow, table, subtable, startRec, endRec, selectedRow, tab) {

    const url = this.webUrl;
    const webservice = "AnalysisTemporalDrill";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();

    var leafName = selectedRow[0].leaf_name;

    var seqLength = this.leafSeqLength[tab].toString();
    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      .set("segAttrValue", table)
      .set("subTableName", subtable)
      .set("startRec", startRec)
      .set("endRec", endRec)
      .set('leafName', leafName)
      .set('seqLength', seqLength)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.analysisService.gridDataDetails[tab] = [...response.tableRows];

          this.analysisService.gridDataDetailsChange[tab].next(response.tableRows);
          this.analysisService.gridTotalRecordsDetailsChange[tab].next(response.tableRows.length)
          this.viewTemporalDetailsDataChange[tab].next(response.tableRows)
          // change seq legnth only if model intra_seq selected ie intra_seq_length exist in file
          this.viewTemporalRedoButtonChange[tab].next(true);
          if (response.tableRows.length == 0) {
            this.appService.showMessage('Success', response.statusText);
          }

        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();

      }, (error) => {
        this.appService.stopSpin();
        this.appService.showMessage('Error', error.statusText);

      });
  }

  //this sets the temporal data for a measure or model with seqkey
  //called in analysis grid component
  setTemporalData(temporalData, seqKey, model_or_measure) {

    var data = [];

    for (var j in temporalData) {

      var newdata = {
        Categorie: temporalData[j][seqKey],
        col: temporalData[j][model_or_measure],
      };
      data.push(newdata)
    }

    data = data.sort(this.compareSeqKey);

    return data;
  }

  //this function allows to compare 2 objects
  compareSeqKey(a, b) {

    var A = a.Categorie.toLowerCase();
    var B = b.Categorie.toLowerCase();
    if ((A !== undefined) && (B !== undefined)) {
      if (A < B) {
        return -1;
      } else if (A > B) {
        return 1;
      } else {
        return 0;
      }
    }
    else { return 0 }

  }
}