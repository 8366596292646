import { Injectable } from "@angular/core";

@Injectable()
export class ExecutionMenuService {


    initializeMenuVariables(vis) {
        vis.selectedWorkflow = vis.appService.getWorkflow();
        let workflow = {WFName: vis.selectedWorkflow};
        vis.changeWorkflow(workflow);
        vis.selectedTable = vis.appService.getTable();
        vis.selectedSubtable = vis.appService.getSubtable();
        vis.type = vis.appService.getType();
        vis.tables = vis.workflowNameService.getWorkflowTables("");
        vis.typeTables = vis.workflowNameService.gettypeTables("");
        
        if (vis.selectedWorkflow != "" && vis.selectedTable != "" && vis.workflows.length != 0) {
            vis.singleMode = true;
            vis.selectedTableExecutionType = "ONE";
            vis.setTableSubtableVariables();
            for (var i in vis.subtableExecutionTypes) {
                vis.subtableExecutionTypes[i].disabled = false;
            }
            for (var i in vis.tableExecutionTypes) {
                vis.tableExecutionTypes[i].disabled = false;
            }

            if ((vis.type == "Matching") || (vis.type == "Supervised")) {
                vis.selectedTableExecutionType = "ONE";
                for (var i in vis.tableExecutionTypes) {
                    if (vis.tableExecutionTypes[i].value == "ALL") {
                        vis.tableExecutionTypes[i].disabled = true;
                    }
                }
                for (var i in vis.subtableExecutionTypes) {
                    if (vis.subtableExecutionTypes[i].value == "ALL") {
                        vis.subtableExecutionTypes[i].disabled = true;
                    }
                }
            }
        }
        return vis;
    }

    getExecutionWorkflows(workflows) {
        let executionWorkflows = workflows.map(item => item.WFName);
        return executionWorkflows
    }

    setTableSubtableVariables(vis) {
        let selectedTableData = vis.workflowNameService.getWorkflowTable(vis.selectedTable)

        if (selectedTableData) {
            vis.nbRecordsTable = selectedTableData["NbRecords"];
            let tableDoneExists = false;
            if (selectedTableData.Status === 'DONE') {
                tableDoneExists = true;
            }
            if (tableDoneExists === false) {
                vis.selectedTable = "";
                vis.nbRecordsTable = 0;
            }

            if (vis.selectedSubtable) {
                vis.selectedSubtableExecutionType = "ONE";
            }
            vis.subtables = vis.workflowNameService.getSubTables(vis.selectedTable, "");

            if (!(vis.sharedGeneralService.isWithSubtables(vis.type))) {
                if (vis.subtables && vis.subtables.length != 0) {
                    vis.nbRecordsSubtable = vis.subtables[0]["NbRecords"];
                    vis.subtablefilters = vis.subtables[0].Steps['Filters'];

                    for (var s in vis.subtables) {
                        if (vis.subtables[s][vis.type + "Name"] === vis.selectedSubtable) {
                            let subtableDoneExists = false;
                            if (vis.subtables[s].Status === 'DONE') {
                                subtableDoneExists = true;
                                vis.nbRecordsSubtable = vis.subtables[s]["NbRecords"];
                                vis.subtablefilters = vis.subtables[s].Steps['Filters'];
                            }

                            if (subtableDoneExists === false) {
                                vis.selectedSubtable = "";
                                vis.nbRecordsTable = 0;
                            }
                        }
                    }
                }
            }
        }

        return vis;
    }

    getScope(type: string, selectedSubtable: string, subtables: any) {
        let scope = "Full";
        if ((type === "Supervised") || (type === "Gauss")) {
            for (var s in subtables) {
                if (subtables[s][type + "Name"] === selectedSubtable) {
                    if (subtables[s][type + "Type"] === "Predict") {
                        scope = "to_predict";
                        break;
                    }
                }
            }
        }

        if (type === "Matching") {
            for (var s in subtables) {
                if (subtables[s].MatchingName === selectedSubtable) {
                    if (subtables[s].MatchingType === "Test") {
                        scope = "to_predict";
                        break;
                    }
                }
            }
        }
        return scope;
    }

}
