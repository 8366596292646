<form class="example-form cardabel-example-form">
  <div class="mat-header">
    {{title}}
  </div>
  <div class="mat-header cardabel-mat-header">
    <div class="row">
      <div class="col-md-1" id="checkboxAll">
        <mat-checkbox [formControl]="allChecked" [disabled]="disabledOption" *ngIf="multiple===true"
          class="example-margin" (change)="selectAllOptions()"></mat-checkbox>

      </div>
      <div class="col-md-10">
        <mat-form-field>
          <input class="mat-radio-label-content" type="text" aria-label="Number" matInput [formControl]="myControl"
            autocomplete="off" ngDefaultControl>
          <mat-placeholder class="placeholder">{{placeholder}}</mat-placeholder>
          <button class="cardabel-listbox-button" mat-button matSuffix mat-icon-button aria-label="search">
            <i class="fa fa-search mat-icon notranslate material-icon"></i>

          </button>
          <button class="cardabel-listbox-button" mat-button matSuffix mat-icon-button aria-label="sort" (click)="onSortBySelectedRows()">
            <i class="fa fa-sort mat-icon notranslate material-icon"></i>

          </button>
        </mat-form-field>
      </div>
    </div>


  </div>

</form>



<div class="cardabel-listbox-list" style="overflow-y:hidden;" *ngIf="!((name1 != '') && (name2 != '')) && menuOpened">
  <div class="cardabelSelectionList" >
    <div style="height: 100%; overflow-y: auto;" *ngIf="filteredOptions.length < 1000">
      <div class="table-row" *ngFor="let item of filteredOptions; let itemIndex2 = index  trackBy: trackByIdx">

        <app-cardabel-listbox-item [option]="item" [tooltipText]="tooltipText" [tooltipPosition]="position.value"
          [tooltipDisabled]="disabled.value" [disabledOption]="disabledOption" [multiple]="multiple"
          [item]="item[filterBy]" [checked]="selectedRows[item[filterBy]]" [strong]="strong[item[filterBy]]" (change)="selectionChange($event.value)">
        </app-cardabel-listbox-item>

      </div>
    </div>

    <cdk-virtual-scroll-viewport #virtualScroll itemSize="29" *ngIf="filteredOptions.length >= 1000">

      <div class="table-row" *cdkVirtualFor="let item of filteredOptions; trackBy: trackByIdx; let itemIndex2 = index">

        <app-cardabel-listbox-item [option]="item" [tooltipText]="tooltipText" [tooltipPosition]="position.value"
          [tooltipDisabled]="disabled.value" [disabledOption]="disabledOption" [multiple]="multiple"
          [item]="item[filterBy]" [checked]="selectedRows[item[filterBy]]" [strong]="strong[item[filterBy]]" (change)="selectionChange($event.value)">
        </app-cardabel-listbox-item>

      </div>


    </cdk-virtual-scroll-viewport>
  </div>
</div>

<div  class="cardabel-listbox-list"  style="overflow-y:hidden;" *ngIf="((name1 != '') && (name2 != '')) && menuOpened">
  <div class="cardabelSelectionList" >
    <div style="height: 100%; overflow-y: auto;" *ngIf="filteredOptions.length < 1000">
      <div class="table-row" *ngFor="let item of filteredOptions; let itemIndex2 = index; trackBy: trackByIdx">

        <app-cardabel-listbox-item [option]="item" [tooltipText]="tooltipText" [tooltipPosition]="position.value"
          [tooltipDisabled]="disabled.value" [disabledOption]="disabledOption" [multiple]="multiple"
          [item]="item[name1][name2]" [checked]="selectedRows[item[name1][name2]]" [strong]="strong[item[name1][name2]]"
          (change)="selectionChange($event.value)"></app-cardabel-listbox-item>
      </div>
    </div>
    <cdk-virtual-scroll-viewport itemSize="29" *ngIf="filteredOptions.length >= 1000">

      <div class="table-row" *cdkVirtualFor="let item of filteredOptions; trackBy: trackByIdx; let itemIndex2 = index">
        <app-cardabel-listbox-item [option]="item" [tooltipText]="tooltipText" [tooltipPosition]="position.value"
          [tooltipDisabled]="disabled.value" [disabledOption]="disabledOption" [multiple]="multiple"
          [item]="item[name1][name2]" [checked]="selectedRows[item[name1][name2]]" [strong]="strong[item[name1][name2]]"
          (change)="selectionChange($event.value)"></app-cardabel-listbox-item>

      </div>


    </cdk-virtual-scroll-viewport>
  </div>
</div>