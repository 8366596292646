import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppService } from '../app.service';
import { SharedInitializationService } from '../shared/shared-common-initialization.service';

@Component({
  selector: 'app-topics-dashboard',
  templateUrl: './topics-dashboard.component.html',
  styleUrls: ['./topics-dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TopicsDashboardComponent implements OnInit, OnDestroy {

  topicNames: any[] = [];
  topics: any[] = [];
  topicsSubcription = new Subscription();
  selectedTopic: string = "";
  selectedTopicData: any;

  selectedTopicSubscription = new Subscription();

  constructor(private sharedInitializationService: SharedInitializationService, private appService: AppService) { }

  ngOnInit() {

    this.sharedInitializationService.getTopicData();
    this.topics = this.sharedInitializationService.getTopics();
    this.setTopicData();

    this.topicsSubcription = this.sharedInitializationService.topicsChange
      .subscribe((data: any) => {
        this.topics = data;

        this.setTopicData();
        let selectedTopic = this.appService.getSelectedTopic();
        this.sharedInitializationService.checkIfMatchingWorkflowExists(selectedTopic);

      })

    this.selectedTopicSubscription = this.appService.selectedTopicChange
      .subscribe((selectedTopicData: any) => {
        if (selectedTopicData.from != "topics") {
          this.selectedTopic = "";
        }

      })

  }

  setTopicData() {
    for (var k in this.topics) {
      let name = Object.keys(this.topics[k]);
      this.topicNames.push(name[0])
      this.topics[k][name[0]][0].AnomalyPredictions = this.topics[k][name[0]][0]['Anomaly predictions'];
   }

  }

  filterCard(topicName, topic) {
    this.selectedTopic = topicName;
    this.selectedTopicData = topic;

    this.appService.setSelectedTopic(topicName, "topics")

    if (this.appService.workflow != '') {
      let workflowExists = this.sharedInitializationService.checkIfWorkflowExistsInTopic(topic, this.appService.workflow);
      if (!workflowExists) {
        this.appService.setWorkflow('');
        this.appService.setTable('');
        this.appService.setSegAttribute('');
        this.appService.setSubtable('');
        this.appService.setType('');
        this.appService.setSource('');

      }
    }
  }

  applyRefresh() {
    this.topicNames = [];
    this.topics = [];
    this.sharedInitializationService.getTopicData();

  }

  //this allows to get the help documentation for settings logout time
  getHelpDocumentation() {
    let parameters = ["general", "topics", "dashboard"];
    if (this.selectedTopic != "") {
      parameters = ["general", "topics", "dashboard","detail"];
      this.sharedInitializationService.showHelpDocumentation("Topics Details");
    }else {
      this.sharedInitializationService.showHelpDocumentation("Topics Dashboard");
    }
    

    this.sharedInitializationService.getHelpDocumentation(parameters)
  }


  ngOnDestroy() {
    this.topicsSubcription.unsubscribe();
  }

}
