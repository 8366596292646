import { Component, OnInit, ViewChild, ElementRef, OnDestroy, ChangeDetectorRef, ViewEncapsulation, ViewRef, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { EmailConfigurationService } from './email-configuration.service';
import { Subscription } from 'rxjs';
import { EmailTitleOption } from './emailTitleOption.model';
import { SharedInitializationService } from 'src/app/shared/shared-common-initialization.service';

@Component({
  selector: 'app-email-configuration',
  templateUrl: './email-configuration.component.html',
  styleUrls: ['./email-configuration.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class EmailConfigurationComponent implements OnInit, OnDestroy {
  //showForm
  displayEmail = false;

  //initialize form and subscriptions
  emailConfigurationForm: FormGroup;
  emailConfigurationFormValue: any;
  subscriptionEmailData = new Subscription();
  subscriptionDashboardData = new Subscription();

  allFields: any;

  //initialize sender email and variables for email body 

  emailAddress: string;
  password: string;
  showEmailPassword: boolean = false;
  companyText: string;
  emailTitle: string;
  signatureText: string;
  //initialize values for email object
  titleOptions: EmailTitleOption[];
  previousEmailObject: String[];
  pendingEmailObject: String[];
  nextEmailObject: { name: string, type: string }[];
  showEmailNewText = false;
  @ViewChild('emailNewText') emailTextValue: ElementRef;
  value;

  //initialize values for frequencies

  trueStatus = [
    { label: 'True Status', value: 'trueStatus' },
    { label: 'True Status Type', value: 'trueStatusType' },
  ]
  trueStatusSelected: string = 'trueStatus';
  allFieldsRC = [
    { label: 'Workflow', value: 'wfname' , order:1},
    { label: 'Table', value: 'wftable' , order:2},
    { label: 'Sub-Table', value: 'wfsubtable', order:3},
  ]
  emailBodyRC: SelectItem[];
  bodyRC: any;
  selectedFieldRC: SelectItem;

  nbDays: number= 1;

  constructor(private emailService: EmailConfigurationService,
    private sharedService: SharedInitializationService,
    private cd: ChangeDetectorRef) { }

  //on initialization            
  ngOnInit() {
    this.initForm();
    this.emailService.getEmailConfigurationData();
    //create the subscription that will fire each time the configuration data is changed. Once the data is sent, we set the configuration values in the html form
    this.subscriptionEmailData = this.emailService.emailConfigurationDataChange
      .subscribe((response: any) => {

        this.bodyRC = [];
        for (var i in this.allFieldsRC) {
          for (var m in response.recapFields) {
            if (this.allFieldsRC[i].value === response.recapFields[m]) {
              this.bodyRC.push(this.allFieldsRC[i])
            }
          }
        }
        this.emailAddress = response.emailSender;
        this.password = response.emailPassword;
        this.emailTitle = response.emailTitle;
        this.companyText = response.companyText;
        this.signatureText = response.signatureText;
        this.trueStatusSelected = response.recapStatus;
        this.nbDays = response.nbDays;
        this.previousEmailObject = [];

        this.emailConfigurationForm.get('emailSender').setValue(this.emailAddress);
        this.emailConfigurationForm.get('emailPassword').setValue(this.password);
        this.emailConfigurationForm.get('emailTitle').setValue(this.emailTitle);
        this.emailConfigurationForm.get('companyText').setValue(this.companyText);
        this.emailConfigurationForm.get('signatureText').setValue(this.signatureText);
        this.emailConfigurationForm.get('selectedFieldRC').setValue(this.bodyRC);
        this.emailConfigurationForm.get('emailTrueStatus').setValue(this.trueStatusSelected);
        this.emailConfigurationForm.get('nbDays').setValue(this.nbDays);

        if (this.cd && !(this.cd as ViewRef).destroyed) {
          this.cd.detectChanges();
        }
      })

  
  }// end onInit

  //initializes the form without any values. The subscription will then update the values within.
  private initForm() {
    this.emailConfigurationForm = new FormGroup({
      emailSender: new FormControl('', Validators.required),
      emailPassword: new FormControl('', Validators.required),
      emailTitle: new FormControl(''),
      companyText: new FormControl(''),
      signatureText: new FormControl(''),
      emailTrueStatus: new FormControl('', Validators.required),
      selectedFieldRC: new FormControl('', Validators.required),
      nbDays: new FormControl('', Validators.required),

    });
    this.emailConfigurationForm.get('emailTrueStatus').setValue(this.trueStatusSelected);
    this.emailConfigurationForm.get('nbDays').setValue(this.nbDays);
  }

  //on submit, change the data to the webservice format and send in the request
  onSubmit() {

    this.emailConfigurationFormValue = this.emailConfigurationForm.value;
    this.emailConfigurationFormValue.selectedFieldRC = this.emailConfigurationFormValue.selectedFieldRC.sort(this.compareFields)
    
    this.emailService.setEmailConfigurationData(this.emailConfigurationFormValue)

  }

  sendEmail(){
    this.emailService.sendEmailToUsers();   
  }
  compareFields(a, b) {

    var A = a.order;
    var B = b.order;
    if (A < B) {
      return -1;
    } else if (A > B) {
      return 1;
    } else {
      return 0;
    }

  }

  //if the sender's email is changed please enter a new password

  onChangeEmailAddress() {
 
    if ((this.emailConfigurationForm.value.emailSender != this.emailAddress) && (this.emailConfigurationForm.value.emailSender != "")) {

      this.showEmailPassword = true;
      this.emailConfigurationForm.get('emailPassword').setValue('');

    }
    else {

      this.showEmailPassword = false;
      this.emailConfigurationForm.get('emailPassword').setValue(this.password);
    }
  }
  //check if element exists in array of objects

  //this allows to get the help documentation for settings logout time
  getHelpDocumentation() {
    let parameters = ["admin", "settings", "emailconfiguration"];
    this.sharedService.showHelpDocumentation("Admin Settings Email Configuration");

    this.sharedService.getHelpDocumentation(parameters)
  }
  //this is to destroy subscriptions when component is destroyed
  ngOnDestroy() {
    this.subscriptionEmailData.unsubscribe();
  }
}
