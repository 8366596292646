<nav class="navbar navbar-expand-lg navbar-dark background-navbar" *ngIf="isAuthenticated">

  <a class="navbar-brand" (click)="isAuthenticated = false; onLogout()">Cardabel</a>
  <img id="logo" src="assets/image/favicon-32x32.png">
  <button class="navbar-toggler" type="button" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed"
    aria-controls="navbarSupportedContent" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent" [ngbCollapse]="isCollapsed">
    <ul class="navbar-nav left">
      <li [attr.data-cy]="'guitab-topics'" class="nav-item" [ngClass]="isSelected === 'topics'? 'active':''"
        routerLinkActive="active">
        <a class="nav-link" (click)="navigate('topics')">Topics: {{selectedTopic}} <span
            class="sr-only">(current)</span></a>
      </li>
      <li [attr.data-cy]="'guitab-dashboard'" class="nav-item" [ngClass]="isSelected === 'dashboard'? 'active':''"
        routerLinkActive="active">
        <a class="nav-link" (click)="navigate('dashboard')" *ngIf="isGuitabAuthorised('Dashboard')">Dashboard <span
            class="sr-only">(current)</span></a>
      </li>
      <li [attr.data-cy]="'guitab-analysis'" class="nav-item" [ngClass]="isSelected === 'analysis'? 'active':''"
        routerLinkActive="active">
        <a class="nav-link" (click)="navigate('analysis')" *ngIf="isGuitabAuthorised('Analysis')">Analysis <span
            class="sr-only">(current)</span></a>
      </li>
      <li [attr.data-cy]="'guitab-workflow'" class="nav-item" [ngClass]="isSelected === 'workflow'? 'active':''"
        routerLinkActive="active">
        <a class="nav-link" (click)="navigate('workflow')" *ngIf="isGuitabAuthorised('Workflow')">Workflow <span
            class="sr-only">(current)</span></a>
      </li>
      <li [attr.data-cy]="'guitab-matching'" class="nav-item" [ngClass]="isSelected === 'matching'? 'active':''"
        routerLinkActive="active">
        <a class="nav-link" (click)="navigate('matching')"
          *ngIf="isGuitabAuthorised('Matching')&&displayMatchingTab">Matching <span class="sr-only">(current)</span></a>
      </li>
      <li [attr.data-cy]="'guitab-execution'" class="nav-item" [ngClass]="isSelected === 'execution'? 'active':''"
        routerLinkActive="active">
        <a class="nav-link" (click)="navigate('execution')" *ngIf="isGuitabAuthorised('Execution')">Execution <span
            class="sr-only">(current)</span></a>
      </li>
      <li [attr.data-cy]="'guitab-admin'" class="nav-item" [ngClass]="isSelected === 'admin'? 'active':''"
        routerLinkActive="active">
        <a class="nav-link" (click)="navigate('admin')" *ngIf="isGuitabAuthorised('Admin')">Admin <span
            class="sr-only">(current)</span></a>
      </li>

    </ul>

  </div>

  <div class="navbar-nav flex-row ml-md-auto d-none d-md-flex" id="">
    <ul class="navbar-nav right">
      <li class="" routerLinkActive="active">
        <a class="nav-link" [attr.data-cy]="'link-logout'" (click)="onLogout()">Logout <i
            class="fa fa-sign-out-alt"></i> <span class="sr-only">(current)</span></a>
      </li>
      <li class="" routerLinkActive="active" *ngFor="let userConnected of user">
        <a class="nav-link" id="userLink" [matMenuTriggerFor]="menuUser" [matMenuTriggerData]="getUserData()"><i
            class="fa fa-user"></i> {{userConnected.username}} <span class="sr-only">(current)</span></a>
      </li>
      <li>
        <i class="pi pi-question-circle piHelpHeader" id="helpIcon" (click)="getHelpDocumentation()"></i>
      </li>
      <mat-menu #menuUser="matMenu">
        <ng-template matMenuContent let-user="user">
          <h6 class="textAlignCenter" *ngFor="let userConnected of user "> {{ userConnected.lastConnection}}</h6>
          <button mat-menu-item routerLink="/usersettings">
            <i class="fa fa-cog mat-icon notranslate material-icons mat-icon-no-color"></i>
            <span> User Settings</span>
          </button>

        </ng-template>

      </mat-menu>
    </ul>
  </div>


</nav>

<!--  <iframe src="http://152.228.213.4:8072/" frameborder="0" style="width: 100vw; height: 100vh;"></iframe> -->