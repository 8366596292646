import { Injectable } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { HttpClient, HttpParams} from '@angular/common/http';
import { Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SharedInitializationService } from 'src/app/shared/shared-common-initialization.service';
import { CustomHttpParamEncoder } from 'src/app/shared/custom-http-param-encoder';


@Injectable()
export class WorkflowNewService{
    constructor(private appService: AppService, 
                private httpClient: HttpClient,
                private sharedInitService: SharedInitializationService){}
    
    webUrl = this.appService.getWebUrl();

    segregationAttributes: {AttrName: string}[];
    //this is called in the new workflow component and the segregation attribute is automatically updated
    segregationAttributeChange = new Subject<{AttrName: string}[]>();
    dynamicSegregationAttributes: {AttrName: string}[];
    //this is called in the new workflow component and the dynamic segregation attribute is automatically updated
    dynamicSegregationAttributeChange = new Subject<{AttrName: string}[]>();
    navigation = { nav: false, type: "" };
    newWorkflowChange = new Subject<string>();
    workflowsKernelChange = new Subject<{Name: string}[]>();
    
    //Returns all the attribute names, type and status (active by default) of the source system passed as argument.
    //called in new workflow component
    getSourceAttributes(source, sourceFromWFName) {

        const url = this.webUrl;
        const webservice = "WFGetSourceAttributes";
        const completeUrl = url+webservice;

        const headers = this.appService.getHeaders();

        this.appService.startSpin();
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
        .set("srcName", source)
        .set("sourceFromWFName", sourceFromWFName)
         //Create new HttpParams
  
        return this.httpClient.post(completeUrl, params, {headers, responseType:'text', withCredentials:true})
          .pipe(
            catchError(this.appService.handleError)
          )
          .subscribe((response:any) => {

            response = JSON.parse(response);
            if (response.statusCode > -1) {
              this.segregationAttributes = response.tableRows;
              this.segregationAttributes.push({AttrName: 'COMMON'});
              this.segregationAttributeChange.next(this.segregationAttributes);
              this.dynamicSegregationAttributes = response.tableRows;
              this.dynamicSegregationAttributeChange.next(this.dynamicSegregationAttributes);
            }
            else {
              this.appService.showMessage('Error', response.statusText) 
            }
                  this.appService.stopSpin();
          }, (error) => {
            this.appService.showMessage('Error', error.statusText);
            this.appService.stopSpin();
        });
    }

    //Generates a new workflow and sends it back.
    //called in new workflow component
    createNewWorkflow(source, name, type,segregationAttribute,dynSegAttr, topicsList, fromWorkflow, attributesList, sourceFromWFName) {
    
        this.appService.startSpin();

        const url = this.webUrl;
        const webservice = "WFNewWorkflow";
        const completeUrl = url+webservice;

        const headers = this.appService.getHeaders();
        
        //Create new HttpParams
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })            
        .set("wfNameNew", name)
        .set("wfType", type)
        .set("srcName", source)
        .set("segAttr",segregationAttribute) 
        .set("dynSegAttr",dynSegAttr)
        .set("topicsList", topicsList)
        .set("fromWorkflow", fromWorkflow)
        .set("attributesList", attributesList)
        .set("sourceFromWFName", sourceFromWFName )
        
        return this.httpClient.post(completeUrl, params, {headers, responseType:'text', withCredentials:true})
          .pipe(
            catchError(this.appService.handleError)
          )
          .subscribe((response:any) => {
           
            response = JSON.parse(response);
            if (response.statusCode > -1) {
              this.appService.showMessage('Success', response.statusText) 
              this.appService.setWorkflow("");
              this.appService.setTable("");
              this.appService.setType("");
              this.appService.setSegAttribute("");
              this.appService.setSource("");
              this.appService.setSubtable("");
              this.newWorkflowChange.next(name);
              this.sharedInitService.getAuthorizedWorkflows("workflow");
              }
            else {
              this.appService.showMessage('Error', response.statusText) 
            }
                  this.appService.stopSpin();
          }, (error) => {
            this.appService.showMessage('Error', error.statusText);
            this.appService.stopSpin();
        });
    
    }
    
}