<div class="row">
  <div class="col-xs-12 col-md-12 col-sm-12">
    <i class="pi pi-question-circle piHelp" id="helpIcon" (click)="getHelpDocumentation()"></i>
  </div>
</div>

<div class="row">
  <div class="col-xs-12 col-md-12 col-sm-12 justify-content-center">
    <div class="col-xs-10 offset-xs-1 col-sm-6 offset-sm-3 col-md-4 offset-md-4">
      <h3 class="sectionTitle">Email Configuration</h3>
    </div>

  </div>
</div>

<div class="row" [hidden]="displayEmail">
  <div class="col-md-10 offset-md-1 col-xs-12 col-sm-12 form">
    <div class="container">
      <form [formGroup]="emailConfigurationForm" (ngSubmit)="onSubmit()">
        <div class="row">
          <div class="col-md-12">
            <div class="btn-group form-btn-group">
              <button class="btn btn-primary btn-sm marginRight10" type="button" (click)="sendEmail()"
                [disabled]="!emailConfigurationForm.valid">Send Email to users</button>
              <button class="btn btn-primary btn-sm marginRight10" type="submit"
                [disabled]="!emailConfigurationForm.valid">Save Changes</button>
            </div>
          </div>
        </div>
        <div id="form" class="margin-top-10">
          <!--Email & password-->
          <div class="row margin-top-10">
            <label class="col-md-5" for="emailSender">Sender's Email Address</label>
            <div class="col-md-7 col-sm-12 col-xs-12">
              <input autocomplete="off" type="email" class="form-control" id="emailSender" placeholder="Email"
                formControlName="emailSender" (change)="onChangeEmailAddress()">
            </div>
          </div>
          <div class="row margin-top-10">
            <label class="col-md-5" for="password">Password</label>
            <div class="col-md-7 col-sm-12 col-xs-12">
              <input autocomplete="off" class="form-control" type="password" formControlName="emailPassword"
                placeholder="Password" class="form-control">
            </div>
          </div>

          <!--Email Object -->
          <div class="row margin-top-10">
            <label for="nbDays" class="col-md-5">Business days before today</label>
            <div class="col-md-7 col-sm-12 col-xs-12">
              <div class="col-md-4 col-sm-12 margin-top-5 nopadding">
                <input autocomplete="off" type="number" id="nbDays" formControlName="nbDays" class="form-control">
              </div>

            </div>
          </div>
          <div class="row margin-top-10">
            <label for="emailTitle" class="col-md-5">Email Title</label>
            <div class="col-md-7 col-sm-12 col-xs-12">
              <textarea name="emailTitle" id="" class="form-control" formControlName="emailTitle"
                placeholder="email Title" style="height: 50px"></textarea>
            </div>
          </div>

          <!--personnalized company text-->
          <div class="row margin-top-10">
            <label for="companyText" class="col-md-5">Personnalized company text</label>

            <div class="col-md-7 col-xs-12 col-sm-12">
              <textarea name="companyText" id="" class="form-control" formControlName="companyText"
                placeholder="Personnalized text" style="height: 130px"></textarea>

            </div>
          </div>
          <!-- cols for recap chart-->
          <!-- truestatus-->

          <div class="row margin-top-10">
            <label for="trueStatus" class="col-md-5">Recap chart status</label>
            <div class="col-md-4 col-xs-4 col-sm-7">
              <p-selectButton [options]="trueStatus" id="emailTrueStatus" formControlName="emailTrueStatus">
              </p-selectButton>
            </div>
          </div>
          <div class="row margin-top-10">
            <label for="emailFieldsRC" class="col-md-5">Recap chart Fields</label>
            <div class="col-md-4 col-xs-12 col-sm-12">

              <app-cardabel-listbox id="fieldsRCContainer"
                [formControl]="emailConfigurationForm.controls.selectedFieldRC" [options]="allFieldsRC"
                [multiple]="true" [filterBy]="'label'" [placeholder]="''" [tooltipPosition]="'below'"
                [tooltipDisabled]="true" [tooltipText]="[ 'description']" [title]="''">
              </app-cardabel-listbox>
            </div>
          </div>
          <div class="row margin-top-10">
            <label for="signatureText" class="col-md-5">Signature company text</label>

            <div class="col-md-7 col-xs-12 col-sm-12">
              <textarea name="signatureText" id="" class="form-control" formControlName="signatureText"
                placeholder="Signature text" style="height: 130px"></textarea>

            </div>
          </div>

        </div>
      </form>

    </div>

  </div>

</div>
<div [hidden]="!displayEmail">
  <div class="row">
    <div class="col-md-12">
      <div class="btn-group applyButtonGroup">
        <button class="btn btn-default" (click)="displayEmail = false"><i class="pi pi-times"></i></button>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-10 offset-md-1">
      <div class="container">
        <app-email-configuration-display [emailConfigurationForm]="emailConfigurationForm">
        </app-email-configuration-display>
      </div>
    </div>
  </div>

</div>