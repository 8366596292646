import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { catchError } from "rxjs/operators";
import { AppService } from "src/app/app.service";
import { CustomHttpParamEncoder } from "src/app/shared/custom-http-param-encoder";
import { WorkflowNameService } from "src/app/workflow/services/workflow-name.service";

@Injectable()
export class AnalysisSupervisedService {
  constructor(private appService: AppService,
    private httpClient: HttpClient,
    private workflowNameService: WorkflowNameService
  ) {
  }

  webUrl = this.appService.getWebUrl();

  superRocClassSelection: any = {};
  superRocCurveChange: { [index: string]: Subject<boolean> } = {};
  superRocCurveChartChange: { [index: string]: Subject<any> } = {};
  superRocCurveThresholdChange: { [index: string]: Subject<any> } = {};
  // supervised class
  superClassesChange: { [index: string]: Subject<any> } = {};
  viewSuperRocAucButtonChange: { [index: string]: Subject<boolean> } = {};
  disableSuperRocAucButtonChange: { [index: string]: Subject<boolean> } = {};
  superFeatureImportanceChange: { [index: string]: Subject<boolean> } = {};
  superFeatureImportanceChartChange: { [index: string]: Subject<boolean> } = {};
  bestMatrixChange: { [index: string]: Subject<boolean> } = {};
  testMatrixChange: { [index: string]: Subject<boolean> } = {};
  trainMatrixChange: { [index: string]: Subject<boolean> } = {};

  statModeChange: { [index: string]: Subject<string> } = {};

  initTabChanges(tabs) {
    for (var i in tabs) {
      this.superRocClassSelection[tabs[i].name] = [];
      this.superRocCurveChange[tabs[i].name] = new Subject<boolean>();
      this.superRocCurveChartChange[tabs[i].name] = new Subject<any>();
      this.superRocCurveThresholdChange[tabs[i].name] = new Subject<any>();
      this.superClassesChange[tabs[i].name] = new Subject<any>();
      this.viewSuperRocAucButtonChange[tabs[i].name] = new Subject<boolean>();
      this.disableSuperRocAucButtonChange[tabs[i].name] = new Subject<boolean>();
      this.superFeatureImportanceChange[tabs[i].name] = new Subject<boolean>();
      this.superFeatureImportanceChartChange[tabs[i].name] = new Subject<boolean>();
      this.bestMatrixChange[tabs[i].name] = new Subject<boolean>();
      this.testMatrixChange[tabs[i].name] = new Subject<boolean>();
      this.trainMatrixChange[tabs[i].name] = new Subject<boolean>();
      this.statModeChange[tabs[i].name] = new Subject<string>();
    }
  }

  // supervised
  // this functio loads the roc curves data
  // called in analysis menu component
  getSupervisedRocCurves(workflow, tableName, subtableName, classList, sample, tab) {

    const url = this.webUrl;
    const webservice = "AnalysisSuperLoadRocCurves";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subtableName)
      .set('classList', classList)
      .set('sample', sample)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {

          this.superRocCurveChange[tab].next(response.tableRows);
          this.superRocCurveThresholdChange[tab].next(response.tableRows3);
          var chartFile = response.tableRows2;
          var data = [];

          for (var k in chartFile) {

            for (var j in chartFile[k]["fpr"]) {
              var newdata = {
                tpr: "",
                fpr: "",
                threshold: ""
              };
              newdata.tpr = chartFile[k]["tpr"][j];
              newdata.fpr = chartFile[k]["fpr"][j];
              newdata.threshold = chartFile[k]["threshold"][j];
              data.push(newdata);

            }
          }

          this.superRocCurveChartChange[tab].next(data);
        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });

  }

  //supervised  
  // this dfunction loads the clas selection
  // caled 

  setSuperRocClassSelection(classesselected, tab) {
    this.superRocClassSelection[tab] = [];
    for (var i in classesselected) {
      this.superRocClassSelection[tab].push(classesselected[i].Class.toString());
    }

  }
  getSuperRocClassSelection(tab) {
    return this.superRocClassSelection[tab];
  }

  //this sets and formats the data for gaussian line charts once the data has arrived
  //Called in analysis grid component and gauss param component in workflow
  setSuperFeatureImportanceChartData(data2) {

    let data = [];

    for (var j in data2[0]) {
      var newdata2 = {
        'Categorie': '',
        'Count': 0,

      };
      var number = +j;

      newdata2.Categorie = j;
      newdata2.Count = data2[0][j];

      data.push(newdata2);
    }//end for data2

    return data;
  }


  //determines whether the rocauc Selected button within analysis menu should be disabled or not
  //called in analysis menu
  disableSuperRocAucButton(param, tab) {
    this.disableSuperRocAucButtonChange[tab].next(param);
  }
  //determines whether the rocauc Selected button within analysis menu should be disabled or not
  //called in analysis menu
  visibleSuperRocAucButton(param, tab) {
    this.viewSuperRocAucButtonChange[tab].next(param);
  }
  // the #values of the target are in workflow/table/subtable step Target
  // where the subtable is the 'Train' one from wich the predict subtable is issued

  getTrueStatus(type, table, predict_subtable, name) {
    let trueStatuses: { label: string, value: string }[] = [];
    let subtables = this.workflowNameService.getSubTables(table, name);
    let train_subtable = "";
    for (var i in subtables) {
      if (subtables[i][type + "Name"] == predict_subtable) {
        if (subtables[i].Steps["Predict"]) {
          train_subtable = subtables[i].Steps["Predict"].Model;
          break;
        }
      }
    }
    if (train_subtable !== "") {
      for (var i in subtables) {
        if (subtables[i][type + "Name"] == train_subtable) {
          let subtable = subtables[i];
          for (var j in subtable.Steps["Target"].Values) {
            let newvalue = subtable.Steps["Target"].Values[j]
            let newdata = { label: newvalue, value: newvalue };
            trueStatuses.push(newdata);
          }
          break;
        }
      }
    }
    return trueStatuses;
  }
  /// supervised
  /// this function loads the table statistic: Nbers by class with percentage + Total,
  //  called in analysis menu 

  getSupervisedStatsCount(workflow, segAttributeValue, subtableName, dataSetName, tab) {

    const url = this.webUrl;
    const webservice = "StatSupervisedCountClasses";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    //   this.appService.startSpin();
    //Create new HttpParams
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      .set("segAttrValue", segAttributeValue)
      .set('subTableName', subtableName)
      .set("dataSet", dataSetName)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.superClassesChange[tab].next(response.tableRows);
        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }

      }, (error) => {
        this.appService.showMessage('Error', error.statusText);

      });
  }

  //supervised
  //this functions loads the feature importance statistics
  // called in analysis menu component
  getSupervisedFeatureImportance(workflow, segAttributeValue, subtableName, tab) {

    const url = this.webUrl;
    const webservice = "AnalysisSuperLoadFeatureImportance";
    const completeUrl = url + webservice;
    this.appService.startSpin();
    const headers = this.appService.getHeaders();

    //Create new HttpParams
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      .set("segAttrValue", segAttributeValue)
      .set('subTableName', subtableName)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {

          this.superFeatureImportanceChange[tab].next(response.tableRows);
          this.superFeatureImportanceChartChange[tab].next(response.tableRows2);
        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });
  }

  //supervised 
  // this function loads the confusion matrixx
  // called in the analysis menu component
  getSupervisedConfusionMatrix(workflow, tableName, subtableName, tab) {

    const url = this.webUrl;
    const webservice = "WFSuperTableOptimizeLoad"; // same as in workflow (but taking just part of the result)
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subtableName)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {

          let bestMatrix = response.tableRowsA2;
          this.bestMatrixChange[tab].next(bestMatrix);
          let testMatrix = response.tableRowsB2;
          this.testMatrixChange[tab].next(testMatrix);
          let trainMatrix = response.tableRowsC2;
          this.trainMatrixChange[tab].next(trainMatrix);
        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });

  }

  setstatMode(dataSetName, tab) {
    this.statModeChange[tab].next(dataSetName);
  }
}