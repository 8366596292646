import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule} from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

//import submodules

import { AdminModule } from './admin/admin.module';
import { AuthModule } from './auth/auth.module';
import { AppRoutingModule } from './app-routing.module';

//import essential services & guards

import { AuthGuard } from './auth/auth-guard.service';
import { AuthService } from './auth/auth-service';
import { AppService } from './app.service';
import { MarketDataSourceService } from './admin/settings/market-data-source/market-data-source.service';
import { LogoutTimeService } from './admin/settings/logout-time/logout-time.service';
import { HistogramCellThresholdService } from './admin/settings/histogram-cell-threshold/histogram-cell-threshold.service';
import { DashboardService } from './dashboard/dashboard.service';
import { TrueStatusTypeService } from './admin/settings/true-status-type/true-status-type.service';
import { LogsService } from './admin/settings/logs/logs.service';
import { SharedGeneralFunctionsService } from './shared/shared-general-functions.service';
import { ServerConfigService } from './admin/settings/server-config/server-config.service';

//import essential components
import { AppComponent } from './app.component';

import { HeaderComponent } from './header/header.component';

//imports from primeng
import { MessageService, MenuItem } from 'primeng/api';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { EmailConfigurationService } from './admin/settings/email-configuration/email-configuration.service';
import { MultiSelectModule } from 'primeng/multiselect';

import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { ContextMenuModule } from 'primeng/contextmenu';
import { AngularSplitModule } from 'angular-split';

import { DropdownDirective } from './shared/dropdown.directive';


import { NgbCollapseModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { WorkflowNewService } from './workflow/new-workflow/new-workflow.service';
import { CardabelListBoxModule } from './shared/cardabel-listbox/cardabel-listbox.component';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule, MatProgressSpinnerModule, MatPaginatorModule, MatCardModule } from '@angular/material';

import { SharedInitializationService } from './shared/shared-common-initialization.service';
import { AnalysisService } from './analysis/services/analysis.service';
import { WorkflowMainStepsService } from './workflow/services/workflow-main-steps.service';
import { DatePipe, DecimalPipe } from '@angular/common';
import { WorkflowBayesianStepsService } from './workflow/services/workflow-bayesian-steps.service';
import { WorkflowGaussStepsService } from './workflow/services/workflow-gauss-steps.service';

import { WorkflowNameService } from './workflow/services/workflow-name.service';
import { WorkflowAggregStepsService } from './workflow/services/workflow-aggreg-steps.service';
import { SidebarModule } from 'primeng/sidebar';
import { DataViewModule } from 'primeng/dataview';
import { PanelModule } from 'primeng/panel';
import { WorkflowTemporalStepsService } from './workflow/services/workflow-temporal-steps.service';
import { WorkflowSupervisedStepsService } from './workflow/services/workflow-supervised-steps.service';
import { WorkflowMatchingStepsService } from './workflow/services/workflow-matching-steps.service';
import { WorkflowKernelStepsService } from './workflow/services/workflow-kernel-steps.service';
import { WorkflowTwinStepsService } from './workflow/services/workflow-twin-steps.service';
import { WorkflowKmodeStepsService } from './workflow/services/workflow-kmode-steps.service';

import { SourceComponent } from './source/source/source.component';
import { SourceService } from './source/services/source.service';
import { ChartsModule } from './shared/charts/charts.component';
import { ExecutionService } from './execution/execution.service';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DynamicModule } from 'ng-dynamic-component';
import { AuthorizationsService } from './admin/authorizations/authorizations.service';
import { MarketDataService } from './admin/market-data/market-data.service';
import { SourceManagementService } from './admin/source-management/source-management.service';
import { SlideMenuModule } from 'primeng/slidemenu';
import { HeroFilterPipe } from './shared/filter.pipe';
import { CardabelVirtualTableModule } from './shared/cardabel-virtual-datatable/ngVirtualTable.module';
import { UserSettingsService } from './usersettings/usersettings.service';
import { CardabelMultilineChartComponent } from './shared/d3/cardabel-multiline-chart/cardabel-multiline-chart.component';
import { CardabelMultiStackedBarChartComponent } from './shared/d3/cardabel-multi-stacked-bar-chart/cardabel-multi-stacked-bar-chart.component';

import { ConfigurationModule } from "./configuration/configuration.module";
import { CardabelDropdownListboxComponent, CardabelDropdownListBoxModule } from './shared/cardabel-dropdown-listbox/cardabel-dropdown-listbox.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { CardabelTooltipDirective, CardabelTooltipModule } from './shared/cardabel-tooltip/cardabel-tooltip.directive';
import { ErrorsModule } from './errors';
import { AnalysisGridSharedService } from './analysis/services/analysis-grid-shared.service';
import { AnalysisBayesService } from './analysis/services/types/analysis-bayes.service';
import { AnalysisGaussService } from './analysis/services/types/analysis-gauss.service';
import { AnalysisTemporalService } from './analysis/services/types/analysis-temporal.service';
import { AnalysisAggregateService } from './analysis/services/types/analysis-aggregate.service';
import { AnalysisMatchingService } from './analysis/services/types/analysis-matching.service';
import { AnalysisSupervisedService } from './analysis/services/types/analysis-supervised.service';
import { AnalysisKernelService } from './analysis/services/types/analysis-kernel.service';
import { AnalysisTwinService } from './analysis/services/types/analysis-twin.service';
import { AnalysisKmodeService } from './analysis/services/types/analysis-kmode.service';

import { AnalysisNavigationService } from './analysis/services/analysis-navigation.service';
import { AnalysisAuditService } from './analysis/services/analysis-audit.service';
import { AnalysisInitializationService } from './analysis/services/analysis-initialization.service';
import { AnalysisLabelSelectedService } from './analysis/services/analysis-label-selected.service';
import { DragDropDirective } from './shared/appDragDrop.directive';
import { CardabelScatterPlotModule } from './shared/d3/cardabel-scatter-plot/cardabel-scatter-plot.component';
import { MatchingService } from './matching/services/matching.service';
import { MatchingGridService } from './matching/services/matching-grid.service';
import { MatchingInitializationService } from './matching/services/matching-initialization.service';
import { TopicsDashboardComponent } from './topics-dashboard/topics-dashboard.component';
import { TopicsDashboardItemComponent } from './topics-dashboard/topics-dashboard-item/topics-dashboard-item.component';
import { TableModule } from 'primeng/table';
import { DashboardFilterChartService } from './dashboard/dashboard-body/dashboard-services/dashboard-filter-chart.service';
import { DashboardTooltipContextMenuService } from './dashboard/dashboard-body/dashboard-chart/dashboard-chart-services/shared/dashboard-tooltip-contextmenu.service';
import { DashboardAxisFormattingService } from './dashboard/dashboard-body/dashboard-chart/dashboard-chart-services/shared/dashboard-axis-formatting.service';
import { DashboardChartDimensionsService } from './dashboard/dashboard-body/dashboard-services/dashboard-chart-dimensions.service';
import { DashboardEditingChartService } from './dashboard/dashboard-body/dashboard-services/dashboard-editing-chart.service';
import { DashboardPDFService } from './dashboard/dashboard-body/dashboard-services/dashboard-pdf.service';
import { DashboardInitTypesScalesService } from './dashboard/dashboard-body/dashboard-services/dashboard-init-types-scales.service';
import { DashboardComboChartService } from './dashboard/dashboard-body/dashboard-chart/dashboard-chart-services/dashboard-combo-chart.service';
import { DashboardBarChartService } from './dashboard/dashboard-body/dashboard-chart/dashboard-chart-services/dashboard-bar-chart.service';
import { DashboardBubbleChartService } from './dashboard/dashboard-body/dashboard-chart/dashboard-chart-services/dashboard-bubble-chart.service';
import { DashboardLineChartService } from './dashboard/dashboard-body/dashboard-chart/dashboard-chart-services/dashboard-line-chart.service';
import { DashboardPieChartService } from './dashboard/dashboard-body/dashboard-chart/dashboard-chart-services/dashboard-pie-chart.service';
import { DashboardTreemapChartService } from './dashboard/dashboard-body/dashboard-chart/dashboard-chart-services/dashboard-treemap-chart.service';
import { DashboardChartService } from './dashboard/dashboard-body/dashboard-chart/dashboard-chart-services/shared/dashboard-chart.service';
import { DashboardWebGLUtilitiesService } from './dashboard/dashboard-body/dashboard-chart/dashboard-chart-services/shared/dashboard-webgl-utilities.service';
import { DashboardWebGLEventsService } from './dashboard/dashboard-body/dashboard-chart/dashboard-chart-services/shared/dashboard-webgl-events.service';
import { ExecutionMenuService } from './execution/execution-menu/execution-menu.service';
import { TopicsDashboardTopicCardItemComponent } from './topics-dashboard/topics-dashboard-topic-card/topics-dashboard-topic-card-item/topics-dashboard-topic-card-item.component';
import { TopicsDashboardTopicCardComponent } from './topics-dashboard/topics-dashboard-topic-card/topics-dashboard-topic-card.component';
import { WorkflowNewDimService } from './workflow/services/workflow-new-dim.service';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    DropdownDirective,
    SourceComponent,
    DragDropDirective,
    TopicsDashboardComponent,
    TopicsDashboardItemComponent,
    TopicsDashboardTopicCardComponent,
    TopicsDashboardTopicCardItemComponent,
    
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    AuthModule,
    AppRoutingModule,
    HttpClientModule,
    ErrorsModule,
    BrowserAnimationsModule,
    MessagesModule,
    MessageModule,
    ToastModule,
    OverlayPanelModule,
    AdminModule,
    FormsModule,
    SelectButtonModule,
    MultiSelectModule,
    NgbModule,
    ConfirmDialogModule,
    ContextMenuModule,
    AngularSplitModule.forRoot(),
    ReactiveFormsModule,
    MatFormFieldModule,
    MatRadioModule,
    MatListModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    SidebarModule,
    DataViewModule,
    PanelModule,
    CardabelListBoxModule,
    ChartsModule,
    DragDropModule,
    DynamicModule,
    MatPaginatorModule,
    SlideMenuModule,
    CardabelVirtualTableModule,
    ConfigurationModule,
    NgbCollapseModule,
    CardabelDropdownListBoxModule,
    OverlayModule,
    CardabelTooltipModule,
    CardabelScatterPlotModule,
    MatCardModule,
    TableModule
  ],
  providers: [
    AuthGuard,
    AuthService,
    MessageService,
    MarketDataSourceService,
    AppService,
    LogoutTimeService,
    HistogramCellThresholdService,
    LogsService,
    EmailConfigurationService,
    DashboardService,
    DashboardChartService,
    DashboardComboChartService,
    DashboardLineChartService, 
    DashboardBubbleChartService, 
    DashboardPieChartService, 
    DashboardTreemapChartService,
    DashboardBarChartService,
    DashboardFilterChartService,
    DashboardTooltipContextMenuService,
    DashboardWebGLUtilitiesService,
    DashboardWebGLEventsService,
    DashboardAxisFormattingService,
    DashboardChartDimensionsService,
    DashboardEditingChartService,
    DashboardPDFService,
    DashboardInitTypesScalesService,
    TrueStatusTypeService,
    ServerConfigService,
    SourceManagementService,
    SharedGeneralFunctionsService,
    ConfirmationService,
    WorkflowNameService,
    WorkflowNewService,
    SharedInitializationService,
    AnalysisService,
    AnalysisGridSharedService,
    WorkflowMainStepsService,
    WorkflowBayesianStepsService,
    WorkflowGaussStepsService,
    WorkflowAggregStepsService,
    WorkflowTemporalStepsService,
    WorkflowSupervisedStepsService,
    WorkflowMatchingStepsService,
    WorkflowKernelStepsService,
    WorkflowTwinStepsService,
    WorkflowKmodeStepsService,
    WorkflowNewDimService,
    DatePipe,
    DecimalPipe,
    SourceService,
    ExecutionService,
    ExecutionMenuService,
    AuthorizationsService,
    MarketDataService,
    HeroFilterPipe,
    UserSettingsService,
    AnalysisBayesService,
    AnalysisGaussService,
    AnalysisTemporalService,
    AnalysisAggregateService,
    AnalysisMatchingService,
    AnalysisSupervisedService,
    AnalysisKernelService,
    AnalysisTwinService,
    AnalysisKmodeService,
    AnalysisNavigationService,
    AnalysisAuditService,
    AnalysisLabelSelectedService,
    AnalysisInitializationService,
    MatchingGridService,
    MatchingService,
    MatchingInitializationService

  ],
  exports: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
