import { Injectable, ViewRef } from '@angular/core';
import { AppService } from 'src/app/app.service';

@Injectable()
export class DashboardChartDimensionsService {
    constructor(private appService: AppService) { }

    /* COMPUTING TAB DASHBOARD FILTER POSITIONS */
    computeFilterWidth(vis) {

        let nameId = "dashboard-main-subtab-row" + vis.mainTab;
        let id = document.getElementById(nameId);

        setTimeout(() => {
            if (id != null) {
                vis.leftWidth = Math.max(id.getBoundingClientRect().width + 15, 200);
                vis.filterWidth = vis.windowWidth - vis.leftWidth - 50 - 10;
            }
        }, 10)
    }

    /* COMPUTING CHART HEIGHTS & WIDTHS */

    resetColumnDimensions(columns, vis) {
        //this.maxDragPosition = 0;
        for (var i in columns) {

            if (columns[i].margin === undefined) {
                columns[i].margin = { top: 10, bottom: 20, right: 20, left: 70, changed: false };
            }
            if (!columns[i].margin.changed) {
                columns[i].margin.changed = false;
            }
            if (columns[i].xAxis === undefined) {
                columns[i].xAxis = { minimum: undefined, maximum: undefined, changed: false }
            }
            if (columns[i].yAxis === undefined) {
                columns[i].yAxis = { minimum: undefined, maximum: undefined, changed: false }
            }
            if (columns[i].y1Axis === undefined) {
                columns[i].y1Axis = { minimum: undefined, maximum: undefined, changed: false }
            }

            if (columns[i].displayOther === undefined) {
                columns[i].displayOther = true;
            }
            columns[i].dragPosition.bx = columns[i].dragPosition.x + columns[i].width;
            columns[i].dragPosition.by = columns[i].dragPosition.y + columns[i].height;

            columns[i].topPosition = JSON.parse(JSON.stringify(columns[i].dragPosition.y));
            columns[i].leftPosition = JSON.parse(JSON.stringify(columns[i].dragPosition.x));
            columns[i].bottomPosition = JSON.parse(JSON.stringify(columns[i].dragPosition.by));
            columns[i].rightPosition = JSON.parse(JSON.stringify(columns[i].dragPosition.bx));

            vis.dashboardChartDimensionsService.setBottomPosition(columns[i].bottomPosition + 90 / vis.windowHeight, vis)

            if (columns[i].aggMethod === '') {
                columns[i].aggMethod = "count";
            }
            if (columns[i].aggMethodCombo === '') {
                columns[i].aggMethodCombo = "count";
            }
        }

        return columns;
    }

    getTopLeftPositions(vis) {
        let maxTopPosition = 50 / vis.windowHeight;
        let newTopPosition = 50 / vis.windowHeight;
        let newLeftPosition = 33 / vis.windowWidth;

        let goToLine = false;
        let goToLineIndex = 0;
        let leftPosition = 33 / vis.windowWidth;
        let number = vis.columns.length - 1;



        for (var i in vis.columns) {
            if (vis.columns[i].topPosition + vis.columns[i].height >= maxTopPosition) {
                if (vis.columns[i].topPosition > maxTopPosition) {
                    maxTopPosition = vis.columns[i].topPosition;
                }


                leftPosition = vis.columns[i].leftPosition + vis.columns[i].width + 350 / vis.windowWidth;

                if (leftPosition > 1) {
                    goToLineIndex = +i;
                    goToLine = true;
                    newTopPosition = maxTopPosition + vis.columns[i].height + 33 / vis.windowHeight;
                    newLeftPosition = 33 / vis.windowWidth;
                }
                else {
                    newLeftPosition = vis.columns[i].leftPosition + vis.columns[i].width + 33 / vis.windowWidth;
                }

                let bottomPosition = newTopPosition + 390 / vis.windowHeight;
                this.setBottomPosition(bottomPosition, vis)

            }
        }
        return [newTopPosition, newLeftPosition]
    }

    setBottomPosition(bottomPosition, vis) {
       // vis.maxDragPosition = Math.max(vis.maxDragPosition, bottomPosition * vis.windowHeight + 50);
    }

    changeColumnDimensions(vis, param, event) {

        let divParam = "windowHeight";

        if (param === "marginLeft") {
            vis.columns[vis.index].margin = { top: 10, bottom: 20, right: 20, left: event.target.valueAsNumber, changed: true };
        }
        else {
            if (param === "width" || param === "leftPosition") {
                divParam = "windowWidth"
            }
            vis.columns[vis.index][param] = event.target.valueAsNumber / vis[divParam];

            if (param === "leftPosition") {
                vis.columns[vis.index].dragPosition.x = event.target.valueAsNumber / vis[divParam];
                vis.columns[vis.index].dragPosition.bx = (event.target.valueAsNumber + vis.columns[vis.index].width) / vis[divParam];
            }
            if (param === "topPosition") {
                vis.columns[vis.index].dragPosition.y = event.target.valueAsNumber / vis[divParam];
                vis.columns[vis.index].dragPosition.by = (event.target.valueAsNumber + vis.columns[vis.index].height) / vis[divParam];
            }
        }

        vis.dashboardService.setChartChange(vis.mainTab, vis.tabAlias, vis.columns, vis.index, vis.index);

    }

    /*TEMPLATES*/

    //this allows to change the templates
    changeTemplate(name, vis) {

        let containerWidth = vis.dashContainer.nativeElement.clientWidth - 50;
        let containerHeight = window.innerHeight - 160;

        vis.columns = [];

        let templates = [
            {
                name: "template1",
                dimensions: [
                    {
                        width: (containerWidth / 2) / vis.windowWidth,
                        height: (containerHeight / 2) / vis.windowHeight,
                        xValue: 17 / vis.windowWidth,
                        yValue: 15 / vis.windowHeight
                    },
                    {
                        width: (containerWidth / 2) / vis.windowWidth,
                        height: (containerHeight / 2) / vis.windowHeight,
                        xValue: (containerWidth / 2 + 34) / vis.windowWidth,
                        yValue: 15 / vis.windowHeight
                    }
                    ,
                    {
                        width: (containerWidth / 2) / vis.windowWidth,
                        height: (containerHeight / 2) / vis.windowHeight,
                        xValue: 17 / vis.windowWidth,
                        yValue: (containerHeight / 2 + 35) / vis.windowHeight
                    },
                    {
                        width: (containerWidth / 2) / vis.windowWidth,
                        height: (containerHeight / 2) / vis.windowHeight,
                        xValue: (containerWidth / 2 + 34) / vis.windowWidth,
                        yValue: (containerHeight / 2 + 35) / vis.windowHeight
                    }
                ]
            },
            {
                name: "template2",
                dimensions: [
                    {
                        width: (containerWidth / 2) / vis.windowWidth,
                        height: (containerHeight + 20) / vis.windowHeight,
                        xValue: 17 / vis.windowWidth,
                        yValue: 15 / vis.windowHeight
                    },
                    {
                        width: (containerWidth / 2) / vis.windowWidth,
                        height: (containerHeight / 2) / vis.windowHeight,
                        xValue: (containerWidth / 2 + 34) / vis.windowWidth,
                        yValue: 15 / vis.windowHeight
                    }
                    ,
                    {
                        width: (containerWidth / 2) / vis.windowWidth,
                        height: (containerHeight / 2) / vis.windowHeight,
                        xValue: (containerWidth / 2 + 34) / vis.windowWidth,
                        yValue: (containerHeight / 2 + 35) / vis.windowHeight
                    }
                ]
            }
            ,
            {
                name: "template3",
                dimensions: [
                    {
                        width: (containerWidth / 2) / vis.windowWidth,
                        height: (containerHeight / 2) / vis.windowHeight,
                        xValue: 17 / vis.windowWidth,
                        yValue: 15 / vis.windowHeight
                    },
                    {
                        width: (containerWidth / 2) / vis.windowWidth,
                        height: (containerHeight / 2) / vis.windowHeight,
                        xValue: 17 / vis.windowWidth,
                        yValue: (containerHeight / 2 + 35) / vis.windowHeight
                    }
                    ,
                    {
                        width: (containerWidth / 2) / vis.windowWidth,
                        height: (containerHeight + 20) / vis.windowHeight,
                        xValue: (containerWidth / 2 + 34) / vis.windowWidth,
                        yValue: 15 / vis.windowHeight
                    }
                ]
            }
            ,
            {
                name: "template4",
                dimensions: [
                    {
                        width: ((containerWidth - 22) / 3) / vis.windowWidth,
                        height: (containerHeight / 2) / vis.windowHeight,
                        xValue: 17 / vis.windowWidth,
                        yValue: 15 / vis.windowHeight

                    },
                    {
                        width: ((containerWidth - 22) / 3) / vis.windowWidth,
                        height: (containerHeight / 2) / vis.windowHeight,
                        xValue: ((containerWidth - 22) / 3 + 34) / vis.windowWidth,
                        yValue: 15 / vis.windowHeight

                    
                    }
                    ,
                    {
                        width: ((containerWidth - 22) / 3) / vis.windowWidth,
                        height: (containerHeight / 2) / vis.windowHeight,
                        xValue: ((containerWidth - 22) / 3 * 2 + 51) / vis.windowWidth,
                        yValue: 15 / vis.windowHeight
                    }
                    ,
                    {
                        width: (containerWidth + 21) / vis.windowWidth,
                        height: (containerHeight / 2) / vis.windowHeight,
                        xValue: 17 / vis.windowWidth,
                        yValue: (containerHeight / 2 + 35) / vis.windowHeight
                    }


                ]
            },
            {
                name: "template5",
                dimensions: [
                    {
                        width: ((containerWidth - 19) / 3) / vis.windowWidth,
                        height: (containerHeight / 2) / vis.windowHeight,
                        xValue: 17 / vis.windowWidth,
                        yValue: (containerHeight / 2 + 35) / vis.windowHeight
                    },
                    {
                        width: ((containerWidth - 19) / 3) / vis.windowWidth,
                        height: (containerHeight / 2) / vis.windowHeight,
                        xValue: ((containerWidth - 20) / 3 + 34) / vis.windowWidth,
                        yValue: (containerHeight / 2 + 35) / vis.windowHeight
                    }
                    ,
                    {
                        width: ((containerWidth - 19) / 3) / vis.windowWidth,
                        height: (containerHeight / 2) / vis.windowHeight,
                        xValue: ((containerWidth - 19) / 3 * 2 + 51) / vis.windowWidth,
                        yValue: (containerHeight / 2 + 35) / vis.windowHeight
                    }
                    ,
                    {
                        width: (containerWidth + 21) / vis.windowWidth,
                        height: (containerHeight / 2) / vis.windowHeight,
                        xValue: 17 / vis.windowWidth,
                        yValue: 15 / vis.windowHeight
                    }

                ]
            }
        ]

        for (var i in templates) {
            if (templates[i].name === name) {
                for (var j in templates[i].dimensions) {
                    let newdata = {
                        dimension: '',
                        xHeader: '',
                        yHeader: '',
                        type: '',
                        top: 3,
                        aggMethod: 'count',
                        aggregatedDimensionMethod: '',
                        numericHeader: '',
                        numericDecimal: 1,
                        dateHeader: '',
                        dateBucketing: { name: '' },
                        dateFormat: { name: 'DD-MM-YYYY' },
                        datePeriod: { name: "Days" },
                        datePeriodValue: 20,
                        datePeriodFixedDate: new Date(),
                        displayOther: true,
                        activeResize: false,
                        width: templates[i].dimensions[j].width,
                        height: templates[i].dimensions[j].height,
                        dragPosition: { x: templates[i].dimensions[j].xValue, y: templates[i].dimensions[j].yValue, bx: templates[i].dimensions[j].xValue + templates[i].dimensions[j].width, by: templates[i].dimensions[j].yValue + templates[i].dimensions[j].height },
                        topPosition: templates[i].dimensions[j].yValue,
                        leftPosition: templates[i].dimensions[j].xValue,
                        colorScheme: this.appService.cardabel_chart_color_scheme,
                        commentBox: "",
                        xAxisLabel: "",
                        yAxisLabel: "",
                        title: "",
                        tooltip: "",
                        margin: { top: 10, bottom: 20, right: 20, left: 70, changed: false },
                        xAxis: { minimum: undefined, maximum: undefined, changed: false },
                        yAxis: { minimum: undefined, maximum: undefined, changed: false },
                        yAxis1: { minimum: undefined, maximum: undefined, changed: false },
                    }

                    vis.columns.push(newdata)
                }

            }
        }

        vis.dashboardService.initChartChanges(vis.mainTab, vis.tabAlias, vis.columns);
    }

    /* DRAGGING */

    //return dragged positions
    getDraggedPositionsAndDragArray(i, vis) {

        vis.draggedPositions = {
            x: [],
            y: [],
            deviations: []
        }

        for (var k in vis.columns) {
            if (k.toString() != i.toString()) {
                vis.draggedPositions.x.push(Math.ceil(vis.columns[k].dragPosition.x * vis.windowWidth));
                vis.draggedPositions.x.push(Math.ceil(vis.columns[k].dragPosition.bx * vis.windowWidth));
                vis.draggedPositions.y.push(Math.ceil(vis.columns[k].dragPosition.y * vis.windowHeight));
                vis.draggedPositions.y.push(Math.ceil(vis.columns[k].dragPosition.by * vis.windowHeight));
            }

        }

        vis.dragArray = [{ name: "draggedX", param: 'x', transformIndex: 0, windowValue: vis.windowWidth }, { name: "draggedBottomX", param: 'x', transformIndex: 0, windowValue: vis.windowWidth }, { name: "draggedY", param: 'y', transformIndex: 1, windowValue: vis.windowHeight }, { name: "draggedBottomY", param: 'y', transformIndex: 1, windowValue: vis.windowHeight }]

    }

    setDraggedLinePositions(transform, i, vis) {
        for (var k in vis.dragArray) {
            vis[vis.dragArray[k].name] = false;
            let bottomValue = 0;
            if (vis.dragArray[k].name === "draggedBottomX") {
                bottomValue = vis.columns[i].width;
            }
            else if (vis.dragArray[k].name === "draggedBottomY") {
                bottomValue = vis.columns[i].height;

            }

            if (vis.draggedPositions[vis.dragArray[k].param].indexOf(Math.ceil(vis.columns[i].dragPosition[vis.dragArray[k].param] * vis.dragArray[k].windowValue + bottomValue * vis.dragArray[k].windowValue + transform[vis.dragArray[k].transformIndex])) > -1) {
                vis[vis.dragArray[k].name] = true;
                let position = vis.dragArray[k].name + 'Position';

                vis[position] = vis.columns[i].dragPosition[vis.dragArray[k].param] + bottomValue + transform[vis.dragArray[k].transformIndex] / vis.dragArray[k].windowValue
                if (vis.cd && !(vis.cd as ViewRef).destroyed) {
                    vis.cd.detectChanges();
                }
            }
        }
    }



}