import { CommonModule } from '@angular/common';
import { Component, Input, Output, OnInit, EventEmitter, NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatFormFieldModule, MatIconModule, MatInputModule } from '@angular/material';


@Component({
  selector: 'cardabel-search-box',
  templateUrl: './cardabel-search-box.component.html',
  styleUrls: ['./cardabel-search-box.component.css']
})
export class CardabelSearchBoxComponent implements OnInit {

  @Input() title: string = '';
  @Input() options: any[] = [];
  @Input() optionLabel: string = '';
  @Output() filter = new EventEmitter<any>();

  filteredOptions: any[] = [];
  filterValue: string = "";

  constructor() { }

  ngOnInit() {
    if (this.options) {
      this.filteredOptions = []
      this.filteredOptions = [...this.options];
    }
  }

  filterOptions() {
    this.filteredOptions = this.options.filter(el => {
      if (this.optionLabel != '') {
        return el[this.optionLabel].toLowerCase().indexOf(this.filterValue.toLowerCase()) > -1
      }
      else {
        return el.toLowerCase().indexOf(this.filterValue.toLowerCase()) > -1
      }
      
    })

    this.filter.emit({value: this.filteredOptions})
  }

}

@NgModule({
  imports:[
   CommonModule, ReactiveFormsModule,FormsModule, MatIconModule, MatFormFieldModule,  MatInputModule
  ],
  exports: [CardabelSearchBoxComponent, ReactiveFormsModule,FormsModule], 
  declarations: [CardabelSearchBoxComponent],
  providers: [
      
  ]
})
export class CardabelSearchBoxModule {

}
