<div class="row">
  <div class="col-xs-12 col-md-12 col-sm-12">
    <i class="pi pi-question-circle piHelp" id="helpIcon" (click)="getHelpDocumentation()"></i>
  </div>
</div>
<div class="row">
  <div class="col-md-12 textAlignCenter">
    <h3>{{userName}}</h3>
  </div>
</div>

<div class="row">
  <div class="col-md-1"></div>
  <div class="col-md-5 offset-md-5">
    <div class="btn-group cardabel-owner-btn-group">
      <button class="btn btn-primary btn-sm" (click)="applyUpdateOwnerData('delete')">
        <span>
          <i class="fa fa-minus-circle"></i>
        </span> Reset
      </button>

      <button class="btn btn-primary btn-sm" [disabled]="(!workflowsChanged)" (click)="applyUpdateOwnerData('update')">
        <span>
          <i class="fa fa-cog"></i>
        </span> Apply
      </button>
    </div>
  </div>
</div>
<div id="editOwnerContainer">

  <div class="row margin-top-40">
    <div class="col-md-1"></div>
    <div class="col-md-5 textAlignCenter">
      <h5 class="ownerTitle">Workflows</h5>
      <cardabel-selection-list [attr.data-cy]="'admin-settings-owner-workflows-selection-list'"
        [inputSelectedOptions]="selectedWorkflows" [options]="workflows" [height]="440" [title]="'workflows'"
        [optionLabel]="'WFName'" [multipleSelection]="true"
        (changeSelectedOptions)="selectedWorkflows = $event.value; changeWorkflow()"></cardabel-selection-list>

    </div>

    <div class="col-md-1"></div>
  </div>

</div>