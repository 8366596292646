import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LogoutTimeService } from './logout-time.service';
import { Subscription } from 'rxjs';
import { SharedInitializationService } from 'src/app/shared/shared-common-initialization.service';

@Component({
  selector: 'app-logout-time',
  templateUrl: './logout-time.component.html',
  styleUrls: ['./logout-time.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogoutTimeComponent implements OnInit, OnDestroy {
  visibleHelpSidebarLT = false;
  logoutTimeForm: FormGroup;
  logoutTime: number = 3600;
  subscriptionLogoutTime = new Subscription();


  constructor(private logoutTimeService: LogoutTimeService,
    private sharedService: SharedInitializationService) { }

  ngOnInit() {
    this.logoutTimeService.getLogoutTime();
    this.initForm();

    this.subscriptionLogoutTime = this.logoutTimeService.logoutTimeChange
      .subscribe(
        (logoutTime: number) => {
          this.logoutTime = logoutTime;

          this.logoutTimeForm.get('logoutTime').setValue(this.logoutTime);

        }
      );


  }// end On Init

  onSubmit() {
    this.logoutTimeService.setLogoutTime(Math.round(+this.logoutTimeForm.value.logoutTime));
  }

  private initForm() {

    this.logoutTimeForm = new FormGroup({
      logoutTime: new FormControl('', Validators.required),
    });

  }

  //this allows to get the help documentation for settings logout time
  getHelpDocumentation() {
    let parameters = ["admin", "settings", "logouttime"];
    this.sharedService.showHelpDocumentation("Admin Settings Logout Time");

    this.sharedService.getHelpDocumentation(parameters)
  }

  //this is to destroy subscriptions when component is destroyed
  ngOnDestroy() {
    this.subscriptionLogoutTime.unsubscribe();
  }
}
