import { Injectable, ViewRef } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { AppService } from '../../../../../app.service';

import { Router } from '@angular/router';
import * as d3 from 'd3';
import { DashboardService } from '../../../../dashboard.service';
import { SharedGeneralFunctionsService } from '../../../../../shared/shared-general-functions.service';


@Injectable()
export class DashboardTooltipContextMenuService {

    constructor(private dashboardService: DashboardService) { }

    // setContextMenu(vis, window, vis.xPosition, vis.yPosition, vis.height, vis.x, vis.y, vis.dataFiltered, vis.xVariable, vis.yVariable, vis.filtervalue, vis.displayContextMenu);
    setContextMenu(vis, window, xPosition, yPosition, height, x, y, dataFiltered, xVariable, yVariable, filtervalue, displayContextMenu, mouseThis, d, event) {

        var windowWidth = window.outerWidth;
        var maxWidth = windowWidth * 3 / 4;

        if (event.pageX > maxWidth) {
            xPosition = event.pageX - 240;
        }
        else {
            xPosition = event.pageX + 50;
        }
        yPosition = event.pageY - 80;
        let index;
        let yValue;
        let header = vis.dashboardChartService.getHeader(vis)
        if (vis.type === "bar") {
            index = Math.floor((height - mouseThis) / y.step());
            yValue = vis.y.domain()[index];
        }
        else if (vis.type === "treemap") {

            yValue = d.data.key;

        }
        else if (vis.type === "line" && vis.dashboardInitTypesScalesService.isDimensionDate(header, vis.data, vis)) {
            let lineData;
            let systems;
            let removeDates
            [lineData, systems, removeDates] = this.dashboardService.formatDashboardData(vis.data, vis.header, vis.allHeaders, "", "", vis.aggMethod, "col", vis.numericHeader, vis.dateBucketing, vis.numericDecimal, vis.datePeriod, vis.datePeriodValue, vis.datePeriodFixedDate, vis.mainTab);
            yValue = d["Categorie"];
            for (var i in lineData) {
                let date = new Date(lineData[i][vis.xVariable])

                if (yValue.toDateString() === date.toDateString()) {

                    filtervalue = lineData[i]

                }
            }
        }
        else if (vis.type === "bubble" || vis.type === "line" || vis.type === "combo" || (vis.type === "line" && !vis.dashboardInitTypesScalesService.isDimensionDate(header, vis.data, vis))) {
            yValue = d["Categorie"]
        }
        else if (vis.type === "pie") {
            yValue = d.data["Categorie"]
        }
        else if (vis.type === "treemap") {
            yValue = d.data.key
        }

        if ((yValue) && (yValue != 'Other')) {
            let header = vis.dashboardChartService.getHeader(vis);
            if (vis.type === "combo" || vis.type === "pie" || vis.type === "bar" || vis.type === "line" && !vis.dashboardInitTypesScalesService.isDimensionDate(header, vis.data, vis)) {
                for (var i in dataFiltered) {
                    if (dataFiltered[i]["Categorie"].toString() === yValue.toString()) {
                        filtervalue = dataFiltered[i];
                    }
                }
            }
            else if (vis.type === "bubble") {
                filtervalue = d;
            }
            else if (vis.type === "treemap") {
                filtervalue = vis.dashboardFilterChartService.getTreemapFilters(d, vis)
            }

            displayContextMenu = true;

        }

        if (event != null) {
            event.preventDefault();
        }


        return [xPosition, yPosition, filtervalue, displayContextMenu, yValue];
    }

    mousemoved(vis, position, pageX, pageY, d) {
        var index;

        var yValue;
        if ((vis.type === "line") || (vis.type === "combo")) {
            yValue = d["Categorie"]
        }
        else if ((vis.type === "bubble")) {
            yValue = d[0]["Categorie"]
        }
        else if (vis.type === "bar") {
            index = Math.floor((vis.height - position[1]) / vis.y.step());
            yValue = vis.y.domain()[index];

            d = vis.dataFiltered[vis.dataFiltered.findIndex(item => item[vis.yVariable] === yValue)]
        }
        else if (vis.type === "pie") {
            yValue = d.data["Categorie"]
        }
        else if (vis.type === "treemap") {
            yValue = d.data.key;
        }
        if (yValue) {
            let orientation = "left";
            let orientationPx = pageX + 3;
            let topPx = pageY - 50;

            let windowWidth = window.innerWidth;
            let windowHeight = window.innerHeight;

            if (windowWidth - orientationPx < 150) {
                orientation = "right";
                orientationPx = windowWidth - orientationPx + 20;
                topPx = pageY - 50;
            }


            if (vis.tooltip) {
                vis.tooltip
                    //.style(orientation, orientationPx + "px")
                    .style("display", "inline-block")
                    .style("z-index", 10000)
                    .html(function () {
                        return vis.dashboardTooltipContextMenuService.setTooltip(vis.type, yValue, vis, d, position);
                    })


                if (vis.tooltip.node()) {
                    if (vis.tooltip.node().getClientRects()[0]) {
                        if (windowWidth - orientationPx < vis.tooltip.node().getClientRects()[0].width + 50) {
                            orientation = "right";
                            orientationPx = windowWidth - pageX;
                        }

                        if (pageY + vis.tooltip.node().getClientRects()[0].height + 10 > vis.maxDragPosition) {
                            // topPx = pageY;
                            topPx = vis.maxDragPosition - 10 - vis.tooltip.node().getClientRects()[0].height
                        }

                        vis.tooltip
                            .attr("width", vis.tooltip.node().getClientRects()[0].width + 50 + 'px')
                    }
                }
                vis.tooltip
                    .style(orientation, orientationPx + "px")
                    .style("top", topPx + "px")

            }

            vis.cd.detectChanges();
            setTimeout(() => {
                console.log(vis.tooltip)
                return vis;
            }, 10)
        }

        return vis;
    }

    setTooltip(param, yValue, vis, d, mouseThis) {

        let header = vis.dashboardChartService.getHeader(vis)

        if (param === "bar" || param === "pie" || param === "line" && !vis.dashboardInitTypesScalesService.isDimensionDate(header, vis.data, vis) || vis.dateBucketing === 'Quarter' && vis.type === 'line') {

            if (param === "line" && vis.dateBucketing != 'Quarter') {

                vis.focus.style('display', null);
                var x = vis.x(d[vis.xVariable])
                let xDomain = vis.x.domain();
                var y = vis.y(d[vis.yVariable]);

                vis = vis.dashboardTooltipContextMenuService.getFocusLine(x, xDomain, y, vis)
            }
            let colVariable = vis.xVariable;
            if (vis.dateBucketing === "Quarter") {
                colVariable = "col"
            }

            return this.getTooltipHtml(vis, yValue, param, d, [{ aggMethod: vis.aggMethod, aggHeader: vis.numericHeader, name: colVariable, color: vis.color, param: "aggMethod" }])

        }
        else if (param === "bubble") {
            var tableBodyCells = "";
            //  let keys = ["xVariable", "yVariable", "zVariable"]
            console.log(d);

            let bubbleKeyValues = [
                { aggMethod: vis.xHeader, aggHeader: vis.numericHeader, name: "xHeader", color: vis.colorScale[0], param: "string" },
                { aggMethod: vis.yHeader, aggHeader: vis.numericHeader, name: "yHeader", color: vis.colorScale[0], param: "string" },
                { aggMethod: vis.aggMethod, aggHeader: vis.numericHeader, name: "col", color: vis.colorScheme[0], param: "string" },
            ]
            return this.getTooltipHtml(vis, yValue, param, d, bubbleKeyValues)


        }
        else if (param === "line" && vis.dashboardInitTypesScalesService.isDimensionDate(header, vis.data, vis)) {
            vis.focus.style('display', null);

            let header = vis.dashboardChartService.getHeader(vis);

            var mouseDate = vis.x.invert(mouseThis[0]);

            let i = vis.bisectDate(vis.dataFiltered, mouseDate, 1); // returns the index to the current data ite

            var d0 = vis.dataFiltered[i - 1]
            var d1 = vis.dataFiltered[i];

            if ((d0 && d1) || (vis.dataFiltered.length === 1 && d0) || (vis.dataFiltered.length === 1 && d1) || !vis.dashboardInitTypesScalesService.isDimensionDate(header, vis.data, vis)) {
                var dDate;
                if (vis.dataFiltered.length === 1) {
                    dDate = vis.dataFiltered[0]
                }
                else {
                    dDate = mouseDate - d0[vis.xVariable] > d1[vis.xVariable] - mouseDate ? d1 : d0;
                }

                var x = vis.x(dDate[vis.xVariable])
                var xDomain = d3.extent(vis.dataFiltered, function (e) { return e[vis.xVariable]; })
                var y = vis.y(dDate[vis.yVariable]);

                vis = vis.dashboardTooltipContextMenuService.getFocusLine(x, xDomain, y, vis)


                var tableBodyCells = "";
                var date = d[vis.xVariable];
                date = vis.multiFormat(date);

                if (!vis.dashboardInitTypesScalesService.isDimensionDate(header, vis.data, vis)) {
                    date = d[vis.xVariable]
                }

                return this.getTooltipHtml(vis, date, param, d, [{ aggMethod: vis.aggMethod, aggHeader: vis.numericHeader, name: vis.yVariable, color: vis.color, param: "aggMethod" }])

                return "<div style='border-radius: 10px; min-width: fit-content; background-color: black; opacity:0.9; padding: 9px; color:" + vis.color + "'><div class=''><h6>" + date + "</h6></div><div class=''>" + tableBodyCells + "</div>"



            }
        }
        else if (param === "combo") {
            var tableBodyCells = "";
            vis.focus.style('display', null);
            let comboKeyValues = [
                { aggMethod: vis.aggMethod, aggHeader: vis.numericHeader, name: "col", color: vis.colorScheme[1], param: "aggMethod" },
                { aggMethod: vis.aggMethodCombo, aggHeader: vis.comboHeader, name: "colCombo", color: vis.colorScheme[0], param: "aggMethod" }
            ]

            vis.focus.selectAll("circle").remove();
            vis.focusCircle = "focusCircle";

            vis.focus.append('circle')
                .attr('id', 'focusCircle')
                // .attr("transform", "translate(" + vis.margin.left + ", " + vis.margin.top + ")")
                .attr('r', 8)
                .attr('class', 'circle focusCircle')
                .attr("fill", vis.colorScheme[1])
                .style("opacity", 0.5)
                .attr('cx', vis.x(yValue) + vis.x.bandwidth() / 2)
                .attr('cy', vis.y(d["col"]));

            return this.getTooltipHtml(vis, yValue, param, d, comboKeyValues)


        }
        else if (param === "treemap") {
            let filters = vis.dashboardFilterChartService.getTreemapFilters(d, vis);
            let formattedFilters = [];
            let newHeader = "";
            let fieldExists;
            for (var k in filters) {
                [formattedFilters, newHeader, fieldExists] = vis.dashboardFilterChartService.formatFilters(vis, filters[k].header, [], newHeader, formattedFilters, filters[k], vis.aggregatedDimensionMethod, fieldExists)
            }

            let filteredData = vis.dashboardFilterChartService.filterDataStream(vis, vis.treemapData, formattedFilters)

            let aggValue = vis.dashboardTreemapChartService.getAggValue(vis, filteredData)
            if (vis.aggMethod != "count" && vis.aggMethod != "") {
                yValue = vis.aggMethod + " of " + vis.numericHeader + ": " + vis.dashboardAxisFormattingService.getTickFormat(aggValue, vis);;
            }
            else {
                yValue = "count: " + vis.dashboardAxisFormattingService.getTickFormat(aggValue, vis);
                aggValue
            }
            let treemapKeys = [
                { aggMethod: "header", aggHeader: vis.numericHeader, name: "key", color: vis.colorScheme[1], param: "string" },
            ]

            return this.getTooltipHtml(vis, yValue, param, filters, treemapKeys)

        }
    }

    getTooltipHtml(vis, yValue, param, d, keys) {
        let tableBodyCells = "";
        let color = vis.color;
        if (vis.type === "pie") {
            d = d.data;
            color = vis.colorScale(yValue);
        }

        if (d[0]) {
            for (var n in d) {
                if (+n > 0) {
                    tableBodyCells = tableBodyCells + "<br>"
                }
                tableBodyCells = this.getTableBodyCells(vis, keys, param, tableBodyCells, d[n], n)
            }
        }
        else {
            tableBodyCells = this.getTableBodyCells(vis, keys, param, tableBodyCells, d, 0)
        }

        return "<div style='border-radius: 10px; min-width: fit-content; background-color: black; opacity:0.8; padding: 9px; color:" + color + "'><div class=''><h6>" + yValue + "</h6></div><div class=''>" + tableBodyCells + "</div>"
    }

    getTableBodyCells(vis, keys, param, tableBodyCells, d, n) {
        for (var i in keys) {
            let name = keys[i].aggMethod;
            let value = d[keys[i].name];

            if (keys.length > 1 && param != "bubble") {
                tableBodyCells = tableBodyCells + "<br>"
            }
            if (name != "count" && keys[i].param === "aggMethod") {
                name = name + " of " + keys[i].aggHeader;
            }
            if (keys[i].param === "aggMethod") {
                value = Intl.NumberFormat('en-US', { minimumFractionDigits: vis.numericDecimal, maximumFractionDigits: vis.numericDecimal }).format(d[keys[i].name])
            }
            let color = keys[i].color;
            if (param === "treemap") {
                name = d[keys[i].aggMethod]
                color = vis.colorScale(d.height);
            }

            if (param === "bubble" || param === "pie") {
                color = vis.colorScale(d.Categorie)
            }
            if (param === "treemap") {
                color = vis.colorScale(d[keys[i].aggMethod])
            }

            if ((name != "") && (value != "") && (color != "")) {
                tableBodyCells = tableBodyCells + "<div style='border-left: 3px solid " + color + ";padding-left:15px;height:28px'><div style='text-align:left; display:inline-flex; height:28px'><div style='color: #ffffff; padding:0px'><div style='position: relative; top:48%; transform: translateY(-50%)'>" + name + ": </div></div><div style='font-weight: bold; font-size:1rem'><div style='position: relative; top:48%; transform: translateY(-50%); padding-left:10px;color:" + color + "'>" + value + "</div></div></div></div>"

            }
        }

        return tableBodyCells;
    }

    getFocusLine(x, xDomain, y, vis) {
        vis.focusLineY = "focusLineY";
        vis.focusCircle = "focusCircle";

        vis.focus.selectAll('circle').remove();
        vis.focus.selectAll('line').remove();

        vis.focus.append('line')
            .attr('id', 'focusLineX')
            //.attr("transform", "translate(" + vis.margin.left + ", " + vis.margin.top + ")")
            .attr('class', 'focusLine')
            .style('fill', 'none')
            .style("stroke", vis.color)
            .style("opacity", 0.5)
            .style("stroke-width", "1px")
        vis.focus.append('line')
            .attr('id', 'focusLineY')
            /// .attr("transform", "translate(" + vis.margin.left + ", " + vis.margin.top + ")")
            .attr('class', 'focusLine')
            .style('fill', 'none')
            .style("stroke", vis.color)
            .style("opacity", 0.5)
            .style("stroke-width", "1px")

        vis.focus.append('circle')
            .attr('id', 'focusCircle')
            // .attr("transform", "translate(" + vis.margin.left + ", " + vis.margin.top + ")")
            .attr('r', 8)
            .attr('class', 'circle focusCircle')
            .attr("fill", vis.color)
            .style("opacity", 0.5)
            .attr('cx', x)
            .attr('cy', y);

        vis.focus.select('#focusLineX')
            .attr('x1', x).attr('y1', 0)
            .attr('x2', x).attr('y2', vis.height);

        /*    vis.focus.select('#' + vis.focusLineY)
            .attr('x1', function () {
                if (xDomain[0] < 0) {
                    return vis.x(xDomain[0])
                }
                else {
                    return 0;
                }

            })
            .attr('y1', y)
            .attr('x2', function () {
                return vis.x(xDomain[1])
            })
            .attr('y2', y);
*/
        return vis;
    }

    setChartTooltip(vis, type) {
        let param = "";
        if ((type === "bar")) {
            param = "rects";

            vis.svg.selectAll(".overlay").remove();

            /*   vis[param] = vis.svg.append(param)
                   .attr("transform", "translate(" + vis.margin.left + "," + vis.margin.top + ")")
                   .attr("class", "overlay zoom")
                   .attr("fill", "transparent")
                   .attr("width", vis.width)
                   .attr("height", function (d) { return vis.height }) */
            vis = this.setClickMousemoveContextMenu(vis, "svg")
        }
        else if (type === "bubble") {
            param = "bubblesCircles";
        }
        else if (type === "treemap") {
            param = "treemap";
        }
        else if (type === "line") {
            param = "dots";
        }
        else if (type === "combo") {
            param = "rects";
            vis = this.setClickMousemoveContextMenu(vis, "rects");
            vis = this.setClickMousemoveContextMenu(vis, "dots")
        }
        else if (type === "pie") {
            param = "pies"
        }

        if (type != "combo") {
            vis = this.setClickMousemoveContextMenu(vis, param)
        }

        return vis;
    }

    setClickMousemoveContextMenu(vis, param) {
        if (vis[param]) {
            vis[param]
                .on("mouseover", function (d) { vis.tooltip = d3.selectAll('body').append("div").attr("class", "toolTip") })
                .on("mouseout", function () {
                    d3.selectAll(".toolTip").remove()
                    if (vis.focus) {
                        vis.focus.style('display', "none");

                    }

                })
                .on("mouseenter", function (d) {
                    //  vis.mousemoved(vis, d3.mouse(this), d3.event.pageX, d3.event.pageY, d)
                })

                .on("mousemove", function (d) {
                    console.log(d3.event.target);

                    vis.dashboardTooltipContextMenuService.mousemoved(vis, d3.mouse(this), d3.event.pageX, d3.event.pageY, d)
                })
                .on("contextmenu", function (d) {
                    let yValue;
                    if (vis.view != 'sidebar' && vis.type != 'line') {
                        [vis.xPosition, vis.yPosition, vis.filtervalue, vis.displayContextMenu, yValue] = vis.dashboardTooltipContextMenuService.setContextMenu(vis, window, vis.xPosition, vis.yPosition, vis.height, vis.x, vis.y, vis.dataFiltered, vis.xVariable, vis.yVariable, vis.filtervalue, vis.displayContextMenu, d3.mouse(this)[1], d, d3.event);
                        if (vis.cd && !(vis.cd as ViewRef).destroyed) {
                            vis.cd.detectChanges();
                        }
                        let element = document.getElementsByClassName("contextMenu") as HTMLCollectionOf<HTMLElement>;
                        if (element.length != 0) {
                            document.body.appendChild(element[0]);
                        }

                        // vis.expandedChartEmit.emit({ value: true });
                        if (vis.cd && !(vis.cd as ViewRef).destroyed) {
                            vis.cd.detectChanges();

                        }
                    }

                })
                .on("click", function (d, i) {
                    vis = vis.dashboardFilterChartService.setClickFilter(vis, d3.mouse(this)[1], d, i, d3.event)
                })
        }
        return vis;
    }


}