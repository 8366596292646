import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { SharedInitializationService } from 'src/app/shared/shared-common-initialization.service';

@Component({
  selector: 'app-ownerstart',
  templateUrl: './ownerstart.component.html',
  styleUrls: ['./ownerstart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OwnerstartComponent implements OnInit {

  constructor(private sharedService: SharedInitializationService) { }

  ngOnInit() {
  }
   //this allows to get the help documentation for owners
   getHelpDocumentation() {
    let parameters = ["admin", "settings", "owners"];
    this.sharedService.showHelpDocumentation("Admin Settings - Owners");

    this.sharedService.getHelpDocumentation(parameters);
  }
}
