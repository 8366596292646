import { Component, OnInit, Input, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-settings-table-body',
  templateUrl: './settings-table-body.component.html',
  styleUrls: ['./settings-table-body.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingsTableBodyComponent implements OnInit {
@Input('tableData') tableData = [];
@Input('groupField1') groupField1;
@Input('groupField2') groupField2;
@Input('title1') title1;
@Input('title2') title2;

 //this is the variable used to create the first group (i.e first by groupField1)
 rowGroupMetadata: any;
 //this allows to display the second group row
 displaySecondRow: any = {};
 editing2: any = {};
  constructor(private appService: AppService, private confirmationService: ConfirmationService) { }

  ngOnInit() {

  }

   //allows to save the editions made to the row
   onRowEditSave(row, index: number) {

    if (row.myNewGroupField1) {
    if (row.myNewGroupField1.length > 0) {
      row.myNewGroupField1 = row.myNewGroupField1.toUpperCase();
      // verify that the groupField1 does not already exists
      let sameGroupField1 = this.tableData.filter((item => {
        if (item[this.groupField1].toUpperCase() == row.myNewGroupField1) {
          return item;
        }
      }))
      if (sameGroupField1.length > 0) {
        this.appService.showMessage('Error', this.title1+" "+ row.myNewGroupField1 +" already exists.");
      }
      else {
        this.tableData.splice(0, 0, { [this.groupField1]: row.myNewGroupField1, [this.groupField2]: "", editing: false });
      }
    }

    this.updateRowGroupMetaData();
  }
  }
  //allows to save the editions made to the row
  onRowEditSave2(row, index: number) {
    if (row.myNewGroupField2) {
    if (row.myNewGroupField2.length > 0) {
      
      this.tableData[index][this.groupField2] = row.myNewGroupField2;
    }
    this.updateRowGroupMetaData();
  }
  }
  //cancels the editions made to the row
  onRowEditCancel(row, index: number) {
    // remove the row,
    // confirm cancel groupField1 if a groupfield2 is already created
    // delete all the corresponding true statustype
    if ( row[this.groupField1]!==""){
      row[this.groupField1]= row[this.groupField1].toUpperCase();
      let sameGroupField1 = this.tableData.filter((item => {
        if (item[this.groupField1].toUpperCase() === row[this.groupField1]) {
          return item;
        }
      }))
      if (sameGroupField1.length > 0) {
        this.confirmationService.confirm({
          message: 'All the '+ this.title2.toLowerCase() +' for the '+this.title1.toLowerCase()+ ' ' + row[this.groupField1] + " will be deleted.",
          header: 'Delete '+this.groupField1,

          accept: () => {

            this.tableData = this.tableData.filter((item => {
              if (item[this.groupField1].toUpperCase() !== row[this.groupField1]) {
                return item;
              }
            }))
            this.updateRowGroupMetaData();
          },
          reject : ()=>{
            this.displaySecondRow[row[this.groupField1]] = !this.displaySecondRow[row[this.groupField1]]
          }

        });
      }

    }
  }
  //cancels the editions made to the row
  onRowEditCancel2(row, index: number) {
    // remove the row,
    // confirm cancel if groupField2 already created
    // delete all the true statustype corresponding
    this.tableData.splice(index, 1);
    this.updateRowGroupMetaData();
  } 
  
  //new group by groupfield1
  public updateRowGroupMetaData() {
    this.rowGroupMetadata = {};


    if (this.tableData) {
      // remove from tableData where groupfield1 and groupField2 = ""
      this.tableData = this.tableData.filter((item => {
        if ((item[this.groupField1] !== "") || (item[this.groupField2] !== "")) {
          return item;
        }
      }))

      // add a record for each groupField1
      for (let i = 0; i < this.tableData.length; i++) {
        let rowData = this.tableData[i];
        let groupField1 = rowData[this.groupField1];
        if (rowData[this.groupField2] !== "") {
          this.tableData.splice(i, 0, { [this.groupField1]: groupField1, [this.groupField2]: "", editing: false });
          i = i + 1;
        }
        for (let j = i + 1; j < this.tableData.length; j++) {
          if (this.tableData[j][this.groupField1] !== groupField1) {
            i = j - 1;
            break;
          }
          else{
            i=j;
          }
        }

      }
      // add a record to possibly add a groupField1
      this.tableData.splice(0, 0, { [this.groupField1]: "", [this.groupField2]: "", editing: false });

      // repare the group 
      for (let i = 0; i < this.tableData.length; i++) {
        let rowData = this.tableData[i];
        let groupField1 = rowData[this.groupField1];
        if (i == 0) {
          this.rowGroupMetadata[groupField1] = { index: 0, size: 1 };
        }
        else {
          let previousRowData = this.tableData[i - 1];
          let previousRowGroup = previousRowData[this.groupField1];
          if (groupField1 === previousRowGroup)
            this.rowGroupMetadata[groupField1].size++;
          else
            this.rowGroupMetadata[groupField1] = { index: i, size: 1 };
        }
      }
    }

  }

  //return new table data to parent component
  public returnTableData() {
    return this.tableData;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.tableData) {
      if (changes.tableData.currentValue) {
        this.updateRowGroupMetaData();
      }
    }
  }

}
