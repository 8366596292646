<div class="row">
  <div class="col-xs-12 col-md-12 col-sm-12">
    <i class="pi pi-question-circle piHelp" id="helpIcon" (click)="getHelpDocumentation()"></i>
  </div>
</div>

<div class="row">
  <div class="col-xs-12 col-md-12 col-sm-12 justify-content-center">
    <div class="col-xs-10 offset-xs-1 col-sm-6 offset-sm-3 col-md-4 offset-md-3">
      <h3 class="sectionTitle">Logs File</h3>
    </div>

  </div>
</div>

<div class="row">
  <div class="col-md-12 col-xs-12 col-sm-12">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-xs-12 col-sm-12">
          <div class="btn-group applyButtonGroup">
            <button class=" btn btn-primary btn-sm" (click)="applyLogsFile()">Apply</button>
          </div>

        </div>
      </div>
      <div class="row margin-top-20">
        <div class="col-md-3 col-xs-12 col-sm-12">
          <div class="row">
            <div class="col-md-12 col-xs-12 col-sm-12">
              <p-selectButton [options]="logsNatures" [(ngModel)]="selectedLogsNature">
              </p-selectButton>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-xs-12 col-sm-12" *ngIf="selectedLogsNature==='view'">
              <form [formGroup]="nbLinesForm">

                <div class="row">
                  <label class="col-md-7 col-sm-12 col-xs-12 margin-top-20 nopadding margin-left-10"># most recent
                    lines: </label>
                  <div class="col-md-4 col-sm-12 margin-top-20 nopadding">
                    <input autocomplete="off" type="number" id="nbLines" formControlName="nbLines" class="form-control">
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="row margin-top-20">
            <h6 class="margin-left-10">See your sys admin to configure SQL_Python</h6>
            <div class="col-md-12 col-xs-12 col-sm-12 margin-top-20">
              <cardabel-selection-list id="logsFileListbox" [attr.data-cy]="'admin-settings-logs-selection-list'"
                [inputSelectedOptions]="selectedLogsFile" [options]="logsFiles" [height]="480" [title]="'logs'"
                [optionLabel]="'label'" [multipleSelection]="false"
                (changeSelectedOptions)="selectedLogsFile = $event.value; "></cardabel-selection-list>
            </div>

          </div>

        </div>
        <div class="col-md-9 col-xs-12 col-sm-12">
          <div id="logContainer" *ngIf="showLogText">
            <div>
              <button class="btn btn-primary btn-sm" style="float: right;" (click)="showLogText=!showLogText"><i
                  class="fas fa-times"></i></button>
            </div>
            <textarea pInputTextarea [(ngModel)]="logText" class="logTextArea" autoResize="autoResize"></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>