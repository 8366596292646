import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { AuthService } from '../auth/auth-service';
import { Subscription } from 'rxjs';
import { User } from '../auth/user.model';
import { AppService } from '../app.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedInitializationService } from '../shared/shared-common-initialization.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit, OnDestroy {
  isAuthenticated: boolean;
  subscriptionAuthenticated = new Subscription();
  subscriptionUserConnected = new Subscription();
  tabSubscription = new Subscription();
  guitabs = [];
  user: User[];

  isSelected: string = "";
  tabSelectedSubscription = new Subscription();

  isCollapsed: boolean = false;

  selectedTopic: string = "ALL";
  selectedTopicSubscription = new Subscription();

  displayMatchingTab: boolean = true;
  existMatchingTabSubscription = new Subscription();

  constructor(private authService: AuthService,
    private appService: AppService,
    private route: ActivatedRoute,
    private router: Router,
    private sharedService: SharedInitializationService
  ) { }

  ngOnInit() {

    this.selectedTopic = this.appService.getSelectedTopic();
    this.sharedService.checkIfMatchingWorkflowExists(this.selectedTopic);
    this.selectedTopicSubscription = this.appService.selectedTopicChange
      .subscribe((selectedTopicData: any) => {
        this.selectedTopic = selectedTopicData.topic;
        this.sharedService.checkIfMatchingWorkflowExists(this.selectedTopic);
      })

    this.existMatchingTabSubscription = this.sharedService.existMatchingChange
      .subscribe((existMatching: boolean) => {
        this.displayMatchingTab = existMatching;
      })
    //this is to subscribe to changes in authentication
    this.subscriptionAuthenticated = this.authService.isAuthenticatedChange
      .subscribe(
        (isAuthenticated: boolean) => {
          this.isAuthenticated = isAuthenticated;

        }
      );

    this.isAuthenticated = this.authService.isLoggedin();

    //this is to subscribe to changes in the connected user
    this.subscriptionUserConnected = this.authService.userConnectedChange
      .subscribe(
        (user: User[]) => {
          this.user = user;
        }
      )

    //this is to set active class to tab when user is logged in 
    this.tabSelectedSubscription = this.authService.tabSelectedChange
      .subscribe((tab: string) => {
        this.isSelected = tab;
      })
    this.user = this.authService.getUser();

  }//end onInit


  //this is to make sure gui tab is authorized
  isGuitabAuthorised(guitab: string) {
    this.guitabs = this.authService.getGuiTabs();

    if (this.guitabs.indexOf(guitab) > -1) {
      return true;
    }
    else {
      return false;
    }
  }

  //this is to log out
  onLogout() {
    this.authService.logoutUser(true);

  }

  //this is to get user data for settings
  getUserData() {
    let userdata = {
      user: [
        {
          username: this.user[0].username,
          lastConnection: this.user[0].lastConnection
        }
      ]

    }
    return userdata;
  }

  //this is to navigate to chosen tab
  navigate(param) {

    this.appService.stopSpin();
    let paramIsInExistingRoute = false;
    if (this.route.snapshot.firstChild) {
      if (this.route.snapshot.firstChild.url[0]) {
        if (this.route.snapshot.firstChild.url[0].path === param) {
          paramIsInExistingRoute = true;
        }
      }
    }

    if (paramIsInExistingRoute === false) {
      this.router.navigate(["/" + param]);
    }

    this.isSelected = param;

    if (param === "topics") {
      this.appService.setSelectedTopic("ALL", "header")
    }
  }

  //this allows to get the help documentation for the start
  getHelpDocumentation() {
    let parameters = ["gettingstarted"];
    this.sharedService.showHelpDocumentation("Getting Started");

    this.sharedService.getHelpDocumentation(parameters)
  }

  //this is to destroy subscriptions when component is destroyed
  ngOnDestroy() {
    this.subscriptionAuthenticated.unsubscribe();
    this.subscriptionUserConnected.unsubscribe();
    this.tabSelectedSubscription.unsubscribe();
    this.tabSubscription.unsubscribe();
    this.selectedTopicSubscription.unsubscribe();
    this.existMatchingTabSubscription.unsubscribe();
  }

}
