import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from '../auth-service';
import { Subscription, Observable, interval } from 'rxjs';
import { SubjectSubscriber, Subject } from 'rxjs/internal/Subject';
import { ActivatedRoute, Router } from '@angular/router';
import { UserSettingsService } from 'src/app/usersettings/usersettings.service';
import { AppService } from 'src/app/app.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm: FormGroup;
  subject = new Subject<any>();
  loginUserSubscription = new Subscription;
  changePasswordSubscription = new Subscription;
  loginFormSubscription = new Subscription;

  username: string = "";

  passwordForm: FormGroup;
  passwordChange: boolean = false;

  constructor(private authService: AuthService,
    private appService: AppService,
    private userSettingsService: UserSettingsService
  ) { }

  ngOnInit() {
    this.initForm();
    this.initPasswordForm();

    this.changePasswordSubscription = this.authService.changePasswordChange
      .subscribe((data: any) => {
        this.passwordChange = true;
        let element = document.getElementsByTagName('form')[0];
        let state = document.getElementById('state');
        element.classList.remove('loading');
        element.classList.remove('ok');
        state.innerHTML = "Log in";
        
      })

      this.loginFormSubscription = interval(300000).subscribe((x => {
        console.log("login interval")
        this.loginForm.reset();
      }))

  }


  //submit login value
  onSubmit() {

    let element = document.getElementsByTagName('form')[0];
    element.classList.add('loading');
    let state = document.getElementById('state');
    state.innerHTML = "Authenticating";
    this.username = this.loginForm.value.username;

    this.authService.loginUser(this.loginForm.value)

    this.loginUserSubscription = this.authService.isAuthenticatedChange
      .subscribe((data: any) => {

        if (data === true) {
          element.classList.add('ok');
          state.innerHTML = "Authenticated";
        }
        else {
          element.classList.remove('loading');
          element.classList.remove('ok');
          state.innerHTML = "Log in";
        }
        // the screen is not refreshed this.loginForm.value.username = "";
        // this.loginForm.value.password = "";
        this.loginForm.reset();
        console.log(data);
      })


  }

  private initForm() {
    this.loginForm = new FormGroup({
      'username': new FormControl('', Validators.required),
      'password': new FormControl('', Validators.required),
    });
  }

  private initPasswordForm() {
    this.passwordForm = new FormGroup({
      'oldpassword': new FormControl('', Validators.required),
      'newpassword1': new FormControl('', Validators.required),
      'newpassword2': new FormControl('', Validators.required),
    });
  }

  onSubmitPassword() {
    if (this.passwordForm.value.newpassword1 != this.passwordForm.value.newpassword2) {
        this.appService.showMessage("Error", "The two passwords do not match.");

        this.passwordForm.get('newpassword1').reset();
        this.passwordForm.get('newpassword2').reset();
    }
    else {
      this.userSettingsService.updateUserPassword(this.username, this.passwordForm.value.newpassword1, this.passwordForm.value.oldpassword)
      this.passwordChange = false;
      this.passwordForm.reset();
    }
  }
  
  //this is to destroy subscriptions when component is destroyed
  ngOnDestroy() {
    this.loginUserSubscription.unsubscribe();
    this.changePasswordSubscription.unsubscribe();
    this.loginFormSubscription.unsubscribe();
  }
}
