import { Component, OnInit, Input, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'cardabel-selection-list-item',
  templateUrl: './cardabel-selection-list-item.component.html',
  styleUrls: ['./cardabel-selection-list-item.component.css']
})
export class CardabelSelectionListItemComponent implements OnInit {

  @Input() option: any;
  @Input() optionLabel: string = "";
  @Input() selectedOption: boolean = false;
  @Output() emitClick = new EventEmitter<any>();
  

  constructor(private cd: ChangeDetectorRef) { }

  ngOnInit() {
  }

  click() {
    this.cd.detectChanges();
    this.emitClick.emit({});
  }

}
