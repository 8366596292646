import { HttpParams, HttpHeaders, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { catchError } from "rxjs/operators";
import { Bookmark } from "src/app/analysis/models/analysis-bookmark.model";
import { AppService } from "src/app/app.service";
import { CustomHttpParamEncoder } from "src/app/shared/custom-http-param-encoder";

@Injectable()
export class MatchingService {

  constructor(private appService: AppService, private httpClient: HttpClient) { }
  gridData: any = {};
  //this is to change bookmarks both main and details
  bookmarkDataChange: { [index: string]: Subject<Bookmark[]> } = {};
  gridDataChange: { [index: string]: Subject<any[]> } = {};
  fieldIDChange: { [index: string]: Subject<string> } = {};
  gridTotalRecordsChange: { [index: string]: Subject<any> } = {};
  fieldID: any = {};
  multiSelectChange: { [index: string]: Subject<any> } = {};
  matchingCheckedRowChange: { [index: string]: Subject<any> } = {};
  varianceChange: { [index: string]: Subject<any> } = {};
  ongoingModificationChange: { [index: string]: Subject<any> } = {};
  accountNumberChange: { [index: string]: Subject<any> } = {};
  varianceThresholdChange: { [index: string]: Subject<any> } = {};
  varianceThreshold: any = {};
  localFiltersChange: { [index: string]: Subject<any> } = {};
  showMenu: {} = {};
  dateHub: any = {};
  dateHubChange: { [index: string]: Subject<string> } = {};
  nbRecords: any = {};
  nbRecordsChange: { [index: string]: Subject<string> } = {};

  webUrl = this.appService.getWebUrl();

  initMatchingTabChanges(tabs) {
    for (var i in tabs) {
      this.showMenu[tabs[i].name] = true;
      this.gridDataChange[tabs[i].name] = new Subject<any[]>();
      this.gridTotalRecordsChange[tabs[i].name] = new Subject<any>();
      this.fieldIDChange[tabs[i].name] = new Subject<any>();
      this.bookmarkDataChange[tabs[i].name] = new Subject<Bookmark[]>();
      this.matchingCheckedRowChange[tabs[i].name] = new Subject<any>();
      this.multiSelectChange[tabs[i].name] = new Subject<any>();
      this.varianceChange[tabs[i].name] = new Subject<any>();
      this.ongoingModificationChange[tabs[i].name] = new Subject<any>();
      this.accountNumberChange[tabs[i].name] = new Subject<any>();
      this.varianceThresholdChange[tabs[i].name] = new Subject<any>();
      this.varianceThreshold[tabs[i].name] = 0.001;
      this.localFiltersChange[tabs[i].name] = new Subject<any>();
      this.dateHubChange[tabs[i].name] = new Subject<any>();
      this.nbRecordsChange[tabs[i].name] = new Subject<any>();
    }
  }

  //  withCharts is a boolean that indicates if the table counts must be done or not for the charts
  // withLoad is a boolean that indicates if the table must be loaded or not
  getMatchingLoadGrid(workflow, table, subTable, startRec, endRec, mode, proMode, filters, sorts, withLoad, withCharts, matchingDetails, tab) {
    this.appService.startSpin2();
    // this.appService.startSpin();
   // const url = this.url;
    const url =this.webUrl;
    const webservice = "AnalysisLoadTables";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    if (filters === undefined) { filters = "[]"; }
    if (sorts === undefined) { sorts = "[]"; }

    const varFilter = "{\"listOfFilters\":" + filters + "}";

    //let varFilter2 = encodeURIComponent(varFilter);

    const varSort = "{\"listOfSorts\":" + sorts + "}";

    //create new http params
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      .set("segAttrValue", table)
      .set("subTableName", subTable)
      //.set("subTable", subTable)
      //.set("fieldID", fieldID)
      .set("startRec", startRec)
      .set("endRec", endRec)
      .set("mode", mode)
      .set("proMode", proMode)
      .set("Filter", varFilter)
      .set("Sort", varSort)
      .set("withLoad", withLoad)
      .set("withCharts", withCharts)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        //map(response => new NmUser(response)),
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

       // response = response.substring(1, response.length - 1);
        response = JSON.parse(response);
        if (response.statusCode > -1) {
          if (matchingDetails === false) {
            if (withLoad) {
              this.gridTotalRecordsChange[tab].next(response.tableRows2[0].TotalRecords)
              response.tableRows = response.tableRows.map(function (el, i) {
                return { ...el, matchingGuiIndex: +i };
              });
              this.gridData[tab] = response.tableRows;


              this.gridDataChange[tab].next(response.tableRows);
            }

            //
          }

        }
        else {
          this.appService.showMessage('Error', response.statusText)
        }
        this.appService.stopSpin2();
        // this.appService.stopSpin();
      }, (error) => {

        this.appService.showMessage('Error', error.statusText);
        //  this.appService.stopSpin();
        this.appService.stopSpin2();
      });


  }

  
  //loads the table referenced by the parameters for the analysis grid
  //called in analysis grid, analysis menu, and analysis menu bayes components
  //  withCharts is a boolean that indicates if the table counts must be done or not for the charts
  // withLoad is a boolean that indicates if the table must be loaded or not
  getMatchingLoadGridDataHub(workflow, fileName, date, startRec, endRec, filters, sorts, withLoad, tab) {
    this.appService.startSpin2();
    // this.appService.startSpin();
    //const url = this.url;
    const url = this.webUrl;
    const webservice = "AnalysisLoadTablesDataHub";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    if (filters === undefined) { filters = "[]"; }
    if (sorts === undefined) { sorts = "[]"; }

    const varFilter = "{\"listOfFilters\":" + filters + "}";

    date = date.replace(/-/g, '');

    //let varFilter2 = encodeURIComponent(varFilter);

    const varSort = "{\"listOfSorts\":" + sorts + "}";

    //create new http params
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      .set("fileName", fileName)
      .set("date", date)
      //.set("fieldID", fieldID)
      .set("startRec", startRec)
      .set("endRec", endRec)
      .set("Filter", varFilter)
      .set("Sort", varSort)
      .set("withLoad", withLoad)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        //map(response => new NmUser(response)),
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        // response = response.substring(1, response.length - 1);
        response = JSON.parse(response);
        if (response.statusCode > -1) {

          if (withLoad) {
            this.gridTotalRecordsChange[tab].next(response.tableRows2[0].TotalRecords)
            response.tableRows = response.tableRows.map(function (el, i) {
              return { ...el, matchingGuiIndex: +i };
            });
            this.gridData[tab] = response.tableRows;
            this.gridDataChange[tab].next(response.tableRows);
          }

        }
        else {
          this.appService.showMessage('Error', response.statusText)
        }
        this.appService.stopSpin2();
        // this.appService.stopSpin();
      }, (error) => {

        this.appService.showMessage('Error', error.statusText);
        //  this.appService.stopSpin();
        this.appService.stopSpin2();
      });


  }

  getMatchingAccountNumberValues(tab) {
    this.appService.startSpin2();
    // this.appService.startSpin();
    let subtabletype = tab.matchingType;
    if (tab.matchingType ==="Test"){
       subtabletype="predicted";
    }
    //const url = this.url;
    const url = this.webUrl;
    const webservice = "WFBaseTableGetAttributeValuesSubTable";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    //create new http params
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", tab.workflow)
      .set("segAttrValue", tab.segAttributeValue)
      .set("subTableName", tab.subtable)
      .set("subTableType", subtabletype)
      .set("attrName", tab.accountNumber)
      .set("attrType", "string")

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        //map(response => new NmUser(response)),
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        //response = response.substring(1, response.length - 1);
        response = JSON.parse(response);
        console.log(response)
        if (response.statusCode > -1) {
          console.log(response)
          this.accountNumberChange[tab.name].next(response.tableRows)
          //


        }
        else {
          this.appService.showMessage('Error', response.statusText)
        }
        this.appService.stopSpin2();
        // this.appService.stopSpin();
      }, (error) => {

        this.appService.showMessage('Error', error.statusText);
        //  this.appService.stopSpin();
        this.appService.stopSpin2();
      });
  }

  getMatchingAccountNumberValuesDataHub(tab) {
    this.appService.startSpin2();
    // this.appService.startSpin();
    let subtabletype = tab.matchingType;
   
    //const url = this.url;
    const url = this.webUrl;
    const webservice = "WFBaseTableGetAttributeValuesSubTableDataHub";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    //create new http params
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", tab.workflow)
      .set("segAttrValue", tab.segAttributeValue)
      .set("subTableName", tab.subtable)
      .set("subTableType", subtabletype)
      .set("attrName", tab.accountNumber)
      .set("attrType", "string")
      .set("nb_records_max", tab.nbRecordsMax)


    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        //map(response => new NmUser(response)),
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        //response = response.substring(1, response.length - 1);
        response = JSON.parse(response);
        console.log(response)
        if (response.statusCode > -1) {
          console.log(response)
          this.accountNumberChange[tab.name].next(response.tableRows);
          this.setDateHub(response.tableRows2[0]["date_hub"], tab.name);
          this.setNbRecords(response.tableRows2[0]["nb_records"], tab.name);
          //


        }
        else {
          this.appService.showMessage('Error', response.statusText)
        }
        this.appService.stopSpin2();
        // this.appService.stopSpin();
      }, (error) => {

        this.appService.showMessage('Error', error.statusText);
        //  this.appService.stopSpin();
        this.appService.stopSpin2();
      });
  }

  //updateCardabelRec(tab, cardabel_rec, matchingAction, records)
  updateCardabelRec(tab, records, checkedIndexes, reconciliationComment) {
    let subtabletype = tab.matchingType;
    if (tab.matchingType ==="Test"){
       subtabletype="predicted";
    }
    this.appService.startSpin2();
    // this.appService.startSpin();
    //const url = this.url;
    const url = this.webUrl;
    const webservice = "AnalysisMatchingUpdateCardabelRec";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();
    
    let matchingRecords = JSON.stringify(records);
    //create new http params
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", tab.workflow)
      .set("segAttrValue", tab.segAttributeValue)
      .set("subTableName", tab.subtable)
      .set("subTableType", subtabletype)
      .set("segregation", tab.accountNumber)
      //.set("matchingAction", matchingAction)
      //.set("fieldID", fieldID)
      //.set("cardabel_rec", cardabel_rec)
      .set("matchingRecords", matchingRecords)
      .set("reconciliationComment", reconciliationComment)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        //map(response => new NmUser(response)),
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        //response = response.substring(1, response.length - 1);
        response = JSON.parse(response);

        console.log(response)
        if (response.statusCode > -1) {
          console.log(response)
          if (response.tableRows.length > 0) {
            for (var m in response.tableRows) {
              let new_cardabel_rec = +response.tableRows[+m].new_cardabel_rec;
              let trueStatus = response.tableRows[+m].true_status;
              let trueStatusType = response.tableRows[+m].true_status_type;
              let transactions = response.tableRows[+m].transactions;
              for (var n in transactions) {
                let index = this.gridData[tab.name].findIndex(item => item[this.fieldID[tab.name]] === transactions[n])
                this.gridData[tab.name][index]["cardabel_rec"] = new_cardabel_rec;
                this.gridData[tab.name][index]["TrueStatus"] = trueStatus;
                this.gridData[tab.name][index]["TrueStatusType"] = trueStatusType;
              }
            }
  
              this.gridDataChange[tab.name].next(this.gridData[tab.name]);
            
            
           
          }
    
          //


        }
        else {
          this.gridDataChange[tab.name].next(this.gridData[tab.name]);
          this.appService.showMessage('Error', response.statusText)
        }
        this.appService.stopSpin2();
        // this.appService.stopSpin();
      }, (error) => {

        this.appService.showMessage('Error', error.statusText);
        //  this.appService.stopSpin();
        this.appService.stopSpin2();
      });
  }



  //sets the field id
  setFieldID(table, tab) {
    this.fieldID[tab] = table.TableDescription.IDField;
    this.fieldIDChange[tab].next(this.fieldID[tab]);
  }

  //returns the field id
  getFieldID(tab) {
    return this.fieldID[tab];
  }

  setDateHub(date, tab){
    this.dateHub[tab] = date;
    this.dateHubChange[tab].next(this.dateHub[tab]);
  }
  getDateHub(tab){
    return this.dateHub[tab];
  }
  setNbRecords(nrec, tab){
    this.nbRecords[tab] = JSON.parse(JSON.stringify(nrec));
    this.nbRecordsChange[tab].next(this.nbRecords[tab]);
  }
  getNbRecordsb(tab){
    return this.nbRecords[tab];
  }
  setVarianceThreshold(tab, varianceThreshold) {
    this.varianceThreshold[tab] = varianceThreshold;
    this.varianceThresholdChange[tab].next(this.varianceThreshold[tab])
  }

  getVarianceThreshold(tab) {
    return this.varianceThreshold[tab];
  }

  setShowMenu(showMenu: boolean, tab: string) {
    this.showMenu[tab] = showMenu;
  }
  getShowMenu(tab: string) {
    return this.showMenu[tab];
  }
}