import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { catchError } from "rxjs/operators";
import { AppService } from "src/app/app.service";
import { CustomHttpParamEncoder } from "src/app/shared/custom-http-param-encoder";
import { BayesErrors } from "../../models/bayes/bayes-error.model";

@Injectable()
export class AnalysisKmodeService {
    constructor(private appService: AppService,
        private httpClient: HttpClient
        ) {
    }

    webUrl = this.appService.getWebUrl();

    //anomaly count by type (rule, simple, combined)
    errorsDetected: {} = {};
    errorsDetectedChange: { [index: string]: Subject<BayesErrors[]> } = {};

    //this is for the kmode menu, it gives the total number of anomalies
    kmodeAnomaliesChange: { [index: string]: Subject<any> } = {}
    scoreChange: { [index: string]: Subject<number> } = {}

    //this is to set up the bayes tab changes 
    initTabChanges(tabs) {
        for (var i in tabs) {
            this.scoreChange[tabs[i].name] = new Subject<number>();
            this.kmodeAnomaliesChange[tabs[i].name] = new Subject<any>();
            this.errorsDetectedChange[tabs[i].name] = new Subject<any[]>();
            this.errorsDetected[tabs[i].name] = [];
        }
    }

    setScoreValue(score, tab) {
        this.scoreChange[tab].next(score);
    }
    //Returns the number of anomalies and correct records for the bayesian workflows
    //called in analysis menu 
    getKmodeStatsCount(workflow, segAttributeValue, tab) {

        const url = this.webUrl;
        const webservice = "StatKmodeCountTables";
        const completeUrl = url + webservice;

        const headers = this.appService.getHeaders();

        this.appService.startSpin();
        //Create new HttpParams
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("wfName", workflow)
            .set("segAttrValue", segAttributeValue)

        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(
                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {

                response = JSON.parse(response);

                if (response.statusCode > -1) {
                    if (response.statusCode > -1) {
                        this.kmodeAnomaliesChange[tab].next(response.tableRows);
                    }
                    else {
                        this.appService.showMessage('Error', response.statusText);
                    }
                    this.errorsDetected[tab] = response.tableRows;
                    this.errorsDetectedChange[tab].next([...this.errorsDetected[tab]]);
                }
                else {
                    this.appService.showMessage('Error', response.statusText);
                }
                this.appService.stopSpin();
            }, (error) => {
                this.appService.showMessage('Error', error.statusText);
                this.appService.stopSpin();
            });
    }


    //this gets the data for the gaussian chart and the gaussian 3D plot
    //called in analysis service
    getKmodeScatterData(workflow, table, subTableName, dynSegAttrValue, tab) {

        const url = this.webUrl;
        const webservice = "StatKmodeSubTableScatter";
        const completeUrl = url + webservice;

        const headers = this.appService.getHeaders();

        this.appService.startSpin();
        //Create new HttpParams */
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("wfName", workflow)
            .set('segAttrValue', table)
            .set('subTableName', subTableName)
            .set('dynSegAttrValue', dynSegAttrValue);

        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(
                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {

                response = JSON.parse(response);

                if (response.statusCode > -1) {

                }
                else {
                    this.appService.showMessage('Error', response.statusText);
                }
                this.appService.stopSpin();
            }, (error) => {
                this.appService.showMessage('Error', error.statusText);
                this.appService.stopSpin();

            });
    }
}