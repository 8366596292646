import { Injectable } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { WorkflowNameService } from './workflow-name.service';
import { catchError } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CustomHttpParamEncoder } from 'src/app/shared/custom-http-param-encoder';


@Injectable()
export class WorkflowKmodeStepsService {
  constructor(private appService: AppService,
    private httpClient: HttpClient,
    private workflowService: WorkflowNameService) { }

  webUrl = this.appService.getWebUrl();

  kmodeIsoForestPercentChange = new Subject<any[]>();

  //Sets the kmode attributes in the workflow/table/subtable and returns back the workflow
  //called in workflow kmode steps
  setKmodeSubTableKmodeAttributes(apply_and_chain, workflow, tableName, subtableName, kmodeAttrList, kmodeWeightMethod) {

    const url = this.webUrl;
    const webservice = "WFKmodeSubTableSetKmodeAttributes";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();
    ''
    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("apply_and_chain", apply_and_chain)
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subtableName)
      .set('kmodeAttr', kmodeAttrList)
      .set('kmodeWeightMethod', kmodeWeightMethod)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.appService.showMessage("Success", response.statusText);
          this.workflowService.updateWorkflowTableSubTable(tableName, response.wfStructure, subtableName);
        }
        else {
          this.appService.showMessage("Error", response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage("Error", error.statusText);
        this.appService.stopSpin();
      });
  }

  //Generates the kmodeian table which will be used for the anomaly detection and returns its name.
  //called in model component

  generateKmodeSubTable(workflow, tableName, subtableName, model, nbClusters,
    elbowSilhouette, sigma, isoForestPercent, wfNameBayes) {

    const url = this.webUrl;
    const webservice = "WFKmodeSubTableGenerate";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();

    //Create new HttpParams 
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subtableName)
      .set('kmodeModel', model)
      .set('nbClusters', nbClusters)
      .set('elbowSilhouette', elbowSilhouette)
      .set('sigma', sigma)
      .set('isoForestPercent', isoForestPercent)
      .set('wfNameBayes', wfNameBayes)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {

          for (var i in response.wfStructure.WFConfigStatus.KmodeTables.TablesWF) {
            if (tableName === response.wfStructure.WFConfigStatus.KmodeTables.TablesWF[i].TableDescription.TableName) {
              for (var j in response.wfStructure.WFConfigStatus.KmodeTables.TablesWF[i].TableDescription.WFSequence) {
                if (subtableName === response.wfStructure.WFConfigStatus.KmodeTables.TablesWF[i].TableDescription.WFSequence[j].KmodeName) {
                  // this.kmodeMixturesData = response.wfStructure.WFConfigStatus.KmodeTables.TablesWF[i].TableDescription.WFSequence[j];
                }
              }
            }
          }
          this.appService.showMessage("Success", response.statusText);
          this.workflowService.updateWorkflowTableSubTable(tableName, response.wfStructure, subtableName);
        }
        else {
          this.appService.showMessage("Error", response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage("Error", error.statusText);
        this.appService.stopSpin();
      });
  }

  getIsoForestPercent(sigma) {

    const url = this.webUrl;
    const webservice = "WFKmodeGetIsoForestPercent";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();

    //Create new HttpParams 
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })

      .set('sigma', sigma)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.kmodeIsoForestPercentChange.next(response.tableRows[0]);
        }
        else {
          this.appService.showMessage("Error", response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage("Error", error.statusText);
        this.appService.stopSpin();
      });
  }
}