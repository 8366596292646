import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { catchError } from "rxjs/operators";
import { TrueStatusTypeService } from "src/app/admin/settings/true-status-type/true-status-type.service";
import { AnalysisAuditService } from "src/app/analysis/services/analysis-audit.service";
import { AnalysisService } from "src/app/analysis/services/analysis.service";
import { AppService } from "src/app/app.service";
import { AuthService } from "src/app/auth/auth-service";
import { CustomHttpParamEncoder } from "src/app/shared/custom-http-param-encoder";
import { TypeListModel } from "src/app/shared/models/gui/type-list.model";
import { MatchingStepsModel } from "src/app/shared/models/gui/type-table.model.ts/matching-steps.model";
import { SharedInitializationService } from "src/app/shared/shared-common-initialization.service";
import { WorkflowNameService } from "src/app/workflow/services/workflow-name.service";
import { MatchingTab } from "../models/matchingtab.model";
import { MatchingGridService } from "./matching-grid.service";
import { MatchingService } from "./matching.service";

@Injectable()
export class MatchingInitializationService {

    constructor(private appService: AppService, private authService: AuthService, private httpClient: HttpClient, private sharedInitService: SharedInitializationService, private workflowNameService: WorkflowNameService, private matchingService: MatchingService, private matchingGridService: MatchingGridService, private analysisAuditService: AnalysisAuditService, private trueStatusTypeService: TrueStatusTypeService) {

    }
    tabs: MatchingTab[] = []
    allTabs: MatchingTab[] = []
    tabsChange = new Subject<MatchingTab[]>();
    activeTabChange = new Subject<string>();
    //changes in tabs 
    tabChange: { [index: string]: Subject<MatchingTab> } = {};

    webUrl = this.appService.getWebUrl();

    username = this.authService.getUsername();
    types: TypeListModel[] = []

    getTabs() {
        return [...this.tabs];
    }

    addTab(tabName) {
        let tabNameExists = this.allTabs.find(item => item.name === tabName);
        if (tabNameExists) {
            let i = 0;

            while (tabNameExists) {
                tabName = tabName+i;
                tabNameExists = this.allTabs.find(item => item.name === tabName);
                i++;
            }
            
        }
        if (!tabNameExists) {
            let tab = {
                name: tabName,
                workflow: "",
                type: "",
                table: "",
                subtable: "",
                segAttributeValue: "",
                dynSegAttributeValue: "",
                matchingType: "",
                accountNumber: "",
                accountNumberValue:"",
                gridSegregationAttribute: "",
                reconciliationAttribute: "cardabel_rec",
                nbRecordsMax: 0 ,
                tabType: "normal"
            }
            this.tabs.push(tab);
            this.allTabs.push(tab)
            this.tabsChange.next([...this.tabs]);
           // this.saveTabs(this.username, this.tabs);
        }
        else {
            this.appService.showMessage("Error", "This tab name already exists. Please choose another one. Thank you.")
        }
    }

    editTab(tab, i, allTabsIndex) {
        this.tabs[i] = tab;
        this.initTabSubjects([tab]);
        this.tabsChange.next([...this.tabs]);

        this.allTabs[allTabsIndex] = tab;
        let tabs = this.allTabs.filter(item => item.tabType != "navigation");
        tabs = tabs.filter(item => item.name.indexOf("FromWorkflow") === -1)
        
        this.saveTabs(this.username, tabs,'Matching');
        this.matchingGridService.setMultiSelect(tab.name, false);
        this.matchingGridService.setOngoingModification(tab.name, false, undefined);
    }

    //change tab
    changeTab(tab, tabName) {
        this.tabChange[tabName].next(tab);
        let tabIndex = this.tabs.findIndex(item => item.name === tabName);
        let allTabsIndex = this.allTabs.findIndex(item => item.name === tabName);
        this.editTab(tab, tabIndex, allTabsIndex);
      
        
    }

    deleteTab(index, username, allTabsIndex) {
        let tabName = this.tabs[index].name;
        this.tabs.splice(index, 1);
        this.tabsChange.next([...this.tabs]);
        if (allTabsIndex) {
            this.tabs.splice(allTabsIndex, 1);
        }
        
        let tabs = this.allTabs.filter(item => item.tabType != "navigation");
        tabs = tabs.filter(item => item.name.indexOf("FromWorkflow") === -1)
        this.saveTabs(this.username, tabs,'Matching');
        



    }

    //Sets the last user bookmark grid parameters for the workflow/ table/ subtable.
    //called in analysis grid component
    saveTabs(username, tabs, fromTab) {

        const url = this.webUrl;
 
        const webservice = "UserTabsSave";
        const completeUrl = url + webservice;

        const headers = this.appService.getHeaders();

        tabs = JSON.stringify(tabs);
  
        //Create new HttpParams */
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("userName", username)
            .set("tabs", tabs)
            .set("fromTab", fromTab)

        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(
                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {

                response = JSON.parse(response);
                if (response.statusCode > -1) {

                }
                else {
                    this.appService.stopSpin();
                }

            }, (error) => {
                this.appService.showMessage('Error', error.statusText);
                this.appService.stopSpin();

            });
    }

    initTabSubjects(tabs) {
        this.types = this.sharedInitService.getTypes();
        this.trueStatusTypeService.getTrueStatusTypes();
        this.analysisAuditService.getCurrency();

        if (tabs.length != 0) {
            this.workflowNameService.initAnalysisTabSubjects(tabs);
            this.matchingService.initMatchingTabChanges(tabs);
            for (var k in tabs) {
                this.tabChange[tabs[k].name] = new Subject<MatchingTab>();;
            }
        }
    }
}