<div class="row" (click)="showContextMenu($event)" [ngClass]="theme">
    <div class="col-md-12 col-sm-12 col-xs-12" id="moduleContainer" >

        <app-header></app-header>
        <p-toast position="center" [style]="  {'white-space': 'pre-line'}" [baseZIndex]="40000"></p-toast>
        <p-confirmDialog #cd  [baseZIndex]="20000" [attr.data-cy]="'cardabel-confirm-dialog'">
            <p-footer>
                <button [attr.data-cy]="'cardabel-confirm-dialog-confirm-button'" type="button" pButton label="Confirm" (click)="cd.accept()"></button>
                <button [attr.data-cy]="'cardabel-confirm-dialog-cancel-button'" type="button" pButton label="Cancel" (click)="cd.reject()"></button>
            </p-footer>
        </p-confirmDialog>

        <div class="row">
            <div class="col-md-12 col-xs-12 col-sm-12" id="spinningContainer">
                <div class="generalSpinner">
                    <mat-spinner></mat-spinner>
                </div>
             
                <router-outlet></router-outlet>
            </div>
       </div>

    </div>
</div>

<p-sidebar [(visible)]="visibleHelpSidebar" position="right" [baseZIndex]="40000">
    <h3 #helpTitleStyle style="font-weight:normal">{{helpTitle}}</h3>
    <hr>
    <div id="helpDocumentation" style ="overflow-y: auto;" [style.maxHeight.px]="getDocumentationHeight()">
       <div *ngIf="visibleAggregateButton">
           <button class="btn btn-primary" [ngClass]="aggregParam === 'simple'?'active':''" (click)="getHelpDocumentation('simple')">Simple</button>
           <button class="btn btn-primary" [ngClass]="aggregParam === 'cycles'?'active':''"  (click)="getHelpDocumentation('cycles')">Cycles</button>
       </div>
        <div [innerHtml]="helpData"></div> 
    </div>
   
    
    <p-footer style="position: absolute; bottom: 10px;">
        <button pButton type="button" (click)="visibleSidebar2 = false" label="Download" class="ui-button-success" (click)="exportAsPDF('specific')" ></button>
        <button pButton type="button" (click)="displayFullScreen = true;" label="Full Screen"
            class="ui-button-success"></button>
        <button pButton type="button" (click)="visibleHelpSidebar = false" label="Cancel"
            class="ui-button-secondary"></button>
    </p-footer>
    
</p-sidebar>


<p-sidebar [(visible)]="displayFullScreen" [fullScreen]="true" [baseZIndex]="50000">
    <div class="row">
        <div class="col-md-3">
            <h5 style="font-weight: normal"> Documentation Menu</h5>
            <p-slideMenu #slideMenu [style]="{'height': '100vh', 'width':'100%'}" [model]="items"></p-slideMenu>
    
        </div>
        <div class="col-md-9">
            <h3 style="font-weight:normal">{{helpTitle}}</h3>
            <hr>
           <div id="fullScreenHelpDocumentation"  class="innerDoc" [scrollTop]="scrollTop" [innerHtml]="helpData" style="overflow: auto;"></div>
            
        </div>
    </div>
</p-sidebar>