import { Injectable, ViewRef } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import * as d3 from 'd3';
import { DashboardService } from 'src/app/dashboard/dashboard.service';
import { SharedGeneralFunctionsService } from 'src/app/shared/shared-general-functions.service';



@Injectable()
export class DashboardPieChartService {

    constructor(private httpClient: HttpClient,
        private router: Router,
        private dashboardService: DashboardService,
        private sharedGeneralService: SharedGeneralFunctionsService
    ) { }

    setPie(vis) {
        vis.colorScale = d3.scaleOrdinal(vis.colorScheme);
        vis.pieG
            .attr("transform", "translate(" + (vis.width + vis.margin.right + vis.margin.left) / 2 +
                ", " + (vis.height + vis.margin.top + vis.margin.bottom) / 2 + ")")

        var pie = d3.pie()
            .sort(null)
            .value(function (d) { return Math.abs(d["col"]); });

        var radius = Math.min(vis.width, vis.height + vis.margin.top + vis.margin.bottom) / 2 - 20;
        console.log(radius);
        console.log(vis.width);
        console.log(vis.margin);
        console.log(vis.height);
        console.log(vis.width + vis.margin.right + vis.margin.left);
        console.log(vis.height + vis.margin.top + vis.margin.bottom);

        var path = d3.arc()
            .outerRadius(radius)
            .innerRadius(0);


        var label = d3.arc()
            .innerRadius(radius * 0.9)
            .outerRadius(radius * 0.9)

        vis.pieG.selectAll(".arc").remove();
        vis.pieG.selectAll('.polyline').remove();
        vis.pieG.selectAll('.pietext').remove()

        let data = JSON.parse(JSON.stringify(vis.dataFiltered))
        let filteredData = vis.dataFiltered.filter(item => item.col != 0);
        if (filteredData.length === 0) {
            let newdata = { Categorie: "Empty", col: 1 };
            data.push(newdata);
        }
        vis.pies = vis.pieG.selectAll(".arc")
            .data(pie(data))

            .enter().append("g")
            .attr("class", "arc")


        vis.pies.append("path")
            .attr("d", path)
            .attr("id", function (d, i) { return vis.type + vis.mainTab + vis.tabAlias + vis.header + vis.graphIndex + i })
            .attr("fill", function (d) {
                if (filteredData.length === 0) {
                    return '#fff';
                }
                return vis.colorScale(d.data["Categorie"]);
            })
            .attr("stroke", function (d) {
                if (filteredData.length === 0) {
                    return vis.colorScale(d.data["Categorie"]);
                }
                return '#fff';

            })

        /* vis.pies
             .append('text')
             .text(function (d) { return d.data["Categorie"] })
             .attr("transform", function (d) { return "translate(" + label.centroid(d) + ")"; })
             .style("text-anchor", "middle")
             .style("font-size", 10) */

        // Add the polylines between chart and labels:
        let sumOfCols = 0;
        for (var n in data) {
            sumOfCols = sumOfCols + data[n].col
        }

        vis.pieG
            .selectAll('allPolylines')
            .data(pie(data))
            .enter()
            .append('polyline')
            .attr('class', 'polyline')
            .attr("stroke", "#bbbbbb")
            .style("fill", "none")
            .attr("stroke-width", 1)
            .attr('points', function (d) {
                if ((vis.data.length <= 5) || (d.value / sumOfCols * 100 > 10)) {
                    var posA = path.centroid(d) // line insertion in the slice
                    var posB = label.centroid(d) // line break: we use the other arc generator that has been built only for that
                    var posC = label.centroid(d); // Label position = almost the same as posB
                    var midangle = d.startAngle + (d.endAngle - d.startAngle) / 2 // we need the angle to see if the X position will be at the extreme right or extreme left
                    posC[0] = radius * 0.95 * (midangle < Math.PI ? 1 : -1); // multiply by 1 or -1 to put it on the right or on the left
                    return [posA, posB, posC]
                }
                return null
            })


        // Add the polylines between chart and labels:
        vis.pieG
            .selectAll('allLabels')
            .data(pie(data))
            .enter()
            .append('text')
            .attr('class', 'pietext')
            .style("fill", "var(---text)")
            .text(function (d) {

                if ((data.length <= 5) || (d.value / sumOfCols * 100 > 10)) {
                    var pos = label.centroid(d);
                    var midangle = d.startAngle + (d.endAngle - d.startAngle) / 2
                    pos[0] = radius * 0.99 * (midangle < Math.PI ? 1 : -1);
                    let remainingDistance = vis.width/2 - Math.abs(pos[0]);
                    let textLength = vis.dashboardAxisFormattingService.getTextLength(d.data['Categorie'])
                    let text =  d.data['Categorie']
                    let newText = text;
                  while (textLength > remainingDistance && text.length > 0) {
                    text = text.slice(0, -1);
                    newText = text+'...'
                    textLength = vis.dashboardAxisFormattingService.getTextLength(newText)
                  }

                    return newText;
                    
                }
                return ''
            })
            .attr('transform', function (d) {
                var pos = label.centroid(d);
                var midangle = d.startAngle + (d.endAngle - d.startAngle) / 2
                pos[0] = radius * 0.99 * (midangle < Math.PI ? 1 : -1);
                return 'translate(' + pos + ')';
            })
            .style('text-anchor', function (d) {
                var midangle = d.startAngle + (d.endAngle - d.startAngle) / 2
                return (midangle < Math.PI ? 'start' : 'end')
            })

        // .attr("transform", "translate(60,60)")


        return vis;
    }

}