import { Injectable } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { HttpClient, HttpParams} from '@angular/common/http';
import { Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CustomHttpParamEncoder } from '../shared/custom-http-param-encoder';
import { WorkflowNameService } from '../workflow/services/workflow-name.service';


@Injectable()
export class ExecutionService {
  constructor(private appService: AppService,
    private workflowService: WorkflowNameService,
    private httpClient: HttpClient) { }

  webUrl = this.appService.getWebUrl();

  gridData: any[] = [];
  //this is for the execution grid, allows to automatically update execution table
  gridDataLoadedChange = new Subject<any>();
  //this is for the analysis grid footer + in the analysis gauss menu
  gridTotalRecordsChange = new Subject<any>();

  // this is for the start date and time when launching an execution
  startData: any[] = [];
  startDataChange = new Subject<any>();

  // this is for the status of an execution
  executionStatusChange = new Subject<any>();
  // this is for the status of execution of ALL table and/or ALL subtables
  executionALLStatusChange = new Subject<any>();

  // this is for the status when deleting processes
  deleteStatusChange = new Subject<any>();

  // this is for the status of log for displaying the log of the execution process
  logStatusChange = new Subject<any>();

  // number of records of the workflow;
  numberRecordsChange = new Subject<any>();

  //set list for workflows
  workflows: any[] = [];
  workflowListChange = new Subject<any>();

  //returns allt the chosen workflows if the mutliple mode has been chosen
  //called in execution menu component
  getWorkflows() {
    return this.workflows;
  }

  //sets the workflows if the right format if mulitple mode has been chosen
  setWorkflows(workflows) {
    this.workflows = [...workflows];
    this.workflowListChange.next(this.workflows);
  }

  // returns all the executed processes between startRec and endRec to laod the grid
  //called in execution grid and execution menu components
  getExecutedProcess(startRec, endRec, workflowList) {
    // startRec and endRec for pagination

    const webUrl = this.webUrl;
    const webservice = "ExecuteGetExecutedProcess";
    const completeUrl = webUrl + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();
    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("startRec", startRec)
      .set('endRec', endRec)
      .set('workflowList', workflowList)


    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(

        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.gridData = response.tableRows;
          if (this.gridData.length == 0) {
            this.appService.showMessage('Warning', response.statusText);
          }
          this.gridDataLoadedChange.next(this.gridData);
          this.gridTotalRecordsChange.next(response.tableRows2);

        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });
  }

  //Launches the execute process of the workflow on the indicated table and subtable.
  //called in execution menu
  executeLaunchProcess(workflow, table, subTable, scope) {

    const url = this.webUrl;
    const webservice = "ExecuteLaunchProcess";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();
    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      .set('segAttrValue', table)
      .set('subTable', subTable)
      .set('scope', scope)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(

        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.startData = response.tableRows;
          this.startDataChange.next(this.startData);
        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });
  }

  //the execution has already been launched and then this process is called continued
  // for asynchronous reasons
  //to display start date and time and the spinner
  // called in execution menu
  executeContinueProcess(workflow, table, subTable, scope, startDate, startTime, firstSubTable) {

    const url = this.webUrl;

    const webservice = "ExecuteContinueProcess";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();
    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      .set('segAttrValue', table)
      .set('subTable', subTable)
      .set('scope', scope)
      .set('startDate', startDate)
      .set('startTime', startTime)
      .set('isFirstSubTable', firstSubTable)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.executionStatusChange.next(response.tableRows2);
          this.workflowService.updateWorkflowTableSubTable(table, response.wfStructure, subTable);
        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });
  }

  //this is to execute all the tables or one specific table and associated subtables in one specific workflow to get the new anomaleis
  //called in execution menu component
  executeWorkflowALLTables(workflow, table) {

    const url = this.webUrl;
    const webservice = "ExecuteWorkflowALLTables";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();
    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      .set('wfTable', table)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(

        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.executionALLStatusChange.next();

          if (table != "ALL") {
            this.workflowService.updateWorkflowTable(table, response.wfStructure);
          }
          else {
            this.workflowService.updateWorkflowAllTables(response.wfStructure);
          }

        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });
  }

  //this is to delete the execution process for one workflow, table and associate subtable
  //called in execution grid component
  executeDeleteProcess(workflow, table, subTable, scope, startDate, startTime, endDate, endTime) {

    const url = this.webUrl;
    const webservice = "ExecuteDeleteProcess";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();
    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      .set('segAttrValue', table)
      .set('subTableName', subTable)
      .set('scope', scope)
      .set('startDate', startDate)
      .set('startTime', startTime)
      .set('endDate', endDate)
      .set('endTime', endTime)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {
        
        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.deleteStatusChange.next();
        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });
  }

  //this is to read the log for a specific line (workflow, table, subtable) in execution grid
  //called in execution grid component
  executeReadLogProcess(workflow, table, subTable, scope, startDate, startTime, endDate, endTime) {

    const url = this.webUrl;
    const webservice = "ExecuteReadLogProcess";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();
    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      .set('segAttrValue', table)
      .set('subTableName', subTable)
      .set('scope', scope)
      .set('startDate', startDate)
      .set('startTime', startTime)
      .set('endDate', endDate)
      .set('endTime', endTime)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.logStatusChange.next(response.tableRows);
        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });
  }
  // execute all selected workflows//all tables//all subtables full mode //
  //called in execution menu component
  executeListOfWorkflows(workflowList) {

    const url = this.webUrl;
    const webservice = "ExecuteListOfWorkflows";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();
    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("ListOfWfNames", workflowList)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.appService.showMessage('Success', response.statusText);
          this.executionALLStatusChange.next();
        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });
  }
}
