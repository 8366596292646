import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { catchError } from "rxjs/operators";
import { AppService } from "src/app/app.service";
import { AuthService } from "src/app/auth/auth-service";
import { CustomHttpParamEncoder } from "src/app/shared/custom-http-param-encoder";
import { TypeListModel } from "src/app/shared/models/gui/type-list.model";
import { SharedInitializationService } from "src/app/shared/shared-common-initialization.service";
import { WorkflowBayesianStepsService } from "src/app/workflow/services/workflow-bayesian-steps.service";
import { WorkflowNameService } from "src/app/workflow/services/workflow-name.service";
import { AnalysisTab } from "../models/analysis-tab.model";
import { AnalysisAuditService } from "./analysis-audit.service";
import { AnalysisLabelSelectedService } from "./analysis-label-selected.service";
import { AnalysisNavigationService } from "./analysis-navigation.service";
import { AnalysisService } from "./analysis.service";
import { AnalysisAggregateService } from "./types/analysis-aggregate.service";
import { AnalysisBayesService } from "./types/analysis-bayes.service";
import { AnalysisGaussService } from "./types/analysis-gauss.service";
import { AnalysisMatchingService } from "./types/analysis-matching.service";
import { AnalysisSupervisedService } from "./types/analysis-supervised.service";
import { AnalysisTemporalService } from "./types/analysis-temporal.service";
import { TrueStatusTypeService } from 'src/app/admin/settings/true-status-type/true-status-type.service';
import { AnalysisKernelService } from "./types/analysis-kernel.service";
import { AnalysisTwinService } from "./types/analysis-twin.service";
import { AnalysisKmodeService } from "./types/analysis-kmode.service";

@Injectable()
export class AnalysisInitializationService {

    types: TypeListModel[] = [];
    tabs: AnalysisTab[] = [];
    allTabs: AnalysisTab[] = [];

    constructor(
        public httpClient: HttpClient,
        private sharedInitService: SharedInitializationService,
        private workflowNameService: WorkflowNameService,
        private analysisService: AnalysisService,
        private analysisBayesService: AnalysisBayesService,
        private analysisGaussService: AnalysisGaussService,
        private analysisTemporalService: AnalysisTemporalService,
        private analysisSupervisedService: AnalysisSupervisedService,
        private analysisAggregateService: AnalysisAggregateService,
        private analysisMatchingService: AnalysisMatchingService,
        private analysisKernelService: AnalysisKernelService,
        private analysisTwinService: AnalysisTwinService,
        private analysisKmodeService: AnalysisKmodeService,
        private bayesianStepsService: WorkflowBayesianStepsService,
        private analysisLabelSelectedService: AnalysisLabelSelectedService,
        private analysisAuditService: AnalysisAuditService,
        private analysisNavigationService: AnalysisNavigationService,
        private trueStatusTypesService: TrueStatusTypeService,
        private authService: AuthService,
        public appService: AppService) {
        this.types = this.sharedInitService.getTypes();

    }


    tabsChange = new Subject<AnalysisTab[]>();
    activeTabChange = new Subject<string>();
    //changes in tabs 
    tabChange: { [index: string]: Subject<AnalysisTab> } = {};

    webUrl = this.appService.getWebUrl();

    username = this.authService.getUsername();

    getTabs() {
        return [...this.tabs];
    }

    addTab(tabName) {
        let tabNameExists = this.allTabs.find(item => item.name === tabName);
        if (tabNameExists) {
            let i = 0;

            while (tabNameExists) {
                tabName = tabName + i;
                tabNameExists = this.allTabs.find(item => item.name === tabName);
                i++;
            }

        }
        if (!tabNameExists) {
            let tab = {
                name: tabName,
                workflow: "",
                type: "",
                table: "",
                subtable: "",
                segAttributeValue: "",
                dynSegAttributeValue: "",
                tabType: "normal",
                subtableType: ""
            }
            this.tabs.push(tab);
            this.allTabs.push(tab)
            this.tabsChange.next([...this.tabs]);
        }
        else {
            this.appService.showMessage("Error", "This tab name already exists. Please choose another one. Thank you.")
        }

    }

    editTab(tab, i, allTabsIndex) {
        this.tabs[i] = tab;
        this.initTabSubjects([tab]);
        this.tabsChange.next([...this.tabs]);

        this.allTabs[allTabsIndex] = tab;
        let tabs = this.allTabs.filter(item => item.tabType != "navigation");
        tabs = tabs.filter(item => item.name.indexOf("FromWorkflow") === -1)

        this.saveTabs(this.username, tabs, "Analysis");
    }

    //change tab
    changeTab(tab, tabName) {
        this.tabChange[tabName].next(tab);
        let tabIndex = this.tabs.findIndex(item => item.name === tabName);
        let allTabsIndex = this.allTabs.findIndex(item => item.name === tabName);
        this.editTab(tab, tabIndex, allTabsIndex);
    }

    deleteTab(index, username, allTabsIndex) {
        let tabName = this.tabs[index].name;
        this.tabs.splice(index, 1);
        this.tabsChange.next([...this.tabs]);
        if (allTabsIndex) {
            this.tabs.splice(allTabsIndex, 1);
        }

        let tabs = this.allTabs.filter(item => item.tabType != "navigation");
        tabs = tabs.filter(item => item.name.indexOf("FromWorkflow") === -1)
        this.saveTabs(this.username, tabs, "Analysis");
        this.analysisNavigationService.deleteTab(tabName, username);

    }

    addNavTab(tab) {
        this.tabs.push(tab);
        let tabIndex = this.tabs.length - 1;
        let tabName = "Tab" + tabIndex;
        this.activeTabChange.next(tabName);
        this.initTabSubjects([tab]);
        this.tabsChange.next([...this.tabs]);

    }

    initTabSubjects(tabs) {
        this.types = this.sharedInitService.getTypes();
        this.trueStatusTypesService.getTrueStatusTypes();
        this.analysisAuditService.getCurrency();

        if (tabs.length != 0) {
            this.workflowNameService.initAnalysisTabSubjects(tabs);
            this.analysisService.initAnalysisTabChanges(tabs);
            this.analysisLabelSelectedService.initTabChanges(tabs);
            this.analysisAuditService.initTabChanges(tabs);
            this.analysisNavigationService.iniTabSubjects(tabs);

            for (var i in this.types) {
                let filteredTabs = tabs.filter((item => item.type === this.types[i].name));

                if (filteredTabs.length != 0) {
                    let service = "analysis" + this.types[i].name + "Service";
                    this[service].initTabChanges(filteredTabs);

                    if (this.types[i].name === "Bayes") {
                        this.bayesianStepsService.initTabChanges(filteredTabs);
                    }
                }


            }

            for (var k in tabs) {
                this.tabChange[tabs[k].name] = new Subject<AnalysisTab>();;
            }
        }
    }

    setNavigationTab(navData, globalFilterData, globalFilterDataForGrid, tab, username) {
        let tabs = this.getTabs();
        let targetTabName = "nav" + tab;
        let targetTab = tabs.find(item => item.name === targetTabName);
        let targetTabIndex = tabs.findIndex(item => item.name === targetTabName)

        if (targetTab) {
            if ((navData.targetWorkflow === targetTab.workflow) && (navData.targetTable === targetTab.table) && (navData.targetSubTable === targetTab.subtable)) {

                let tabName = "Tab" + targetTabIndex;
                this.activeTabChange.next(tabName);
            }
            else {
                this.deleteTab(targetTabIndex, username, undefined);
                targetTab = undefined;
            }

        }
        if (!targetTab) {
            targetTab = new AnalysisTab(targetTabName, navData.targetWorkflow, navData.targetType, navData.targetTable, navData.targetSubTable, navData.segAttribute, "", "navigation", "")
            this.addNavTab(targetTab);
        }

        this.analysisNavigationService.setNavigationGlobalFilters(globalFilterData, globalFilterDataForGrid, targetTab);

    }

    //Sets the last user bookmark grid parameters for the workflow/ table/ subtable.
    //called in analysis grid component
    saveTabs(username, tabs, fromTab) {

        const url = this.webUrl;

        const webservice = "UserTabsSave";
        const completeUrl = url + webservice;

        const headers = this.appService.getHeaders();

        tabs = JSON.stringify(tabs);

        //Create new HttpParams */
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("userName", username)
            .set("tabs", tabs)
            .set("fromTab", fromTab)

        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(
                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {

                response = JSON.parse(response);
                if (response.statusCode > -1) {
                    // this.appService.showMessage('Success', response.statusText);
                }
                else {
                    this.appService.stopSpin();
                }

            }, (error) => {
                this.appService.showMessage('Error', error.statusText);
                this.appService.stopSpin();

            });
    }
 
}