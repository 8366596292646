import { Injectable } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { WorkflowNameService } from './workflow-name.service';
import { catchError } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CustomHttpParamEncoder } from 'src/app/shared/custom-http-param-encoder';


@Injectable()
export class WorkflowGaussStepsService {
  constructor(private appService: AppService,
    private httpClient: HttpClient,
    private workflowService: WorkflowNameService) { }

  webUrl = this.appService.getWebUrl();

  //init gauss mixtures data
  gaussMixturesData: any[] = [];
  gaussMixturesDataChange = new Subject<any>();

  gaussPredictClassesChange = new Subject<any>();
  //Sets the gauss attributes in the workflow/table/subtable and returns back the workflow
  //called in workflow gauss steps
  setGaussSubTableGaussAttributes(apply_and_chain, workflow, tableName, subtableName, gaussAttrList) {

    const url = this.webUrl;
    const webservice = "WFGaussSubTableSetGaussAttributes";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("apply_and_chain", apply_and_chain)
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subtableName)
      .set('gaussAttr', gaussAttrList)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.appService.showMessage("Success", response.statusText);
          this.workflowService.updateWorkflowTableSubTable(tableName, response.wfStructure, subtableName);
        }
        else {
          this.appService.showMessage("Error", response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage("Error", error.statusText);
        this.appService.stopSpin();
      });
  }

  //Generates the gaussian table which will be used for the anomaly detection and returns its name.
  //called in model component
  generateGaussSubTable(workflow, tableName, subtableName, isMultiVar, bestMixtures, nbMixtures, AICMixtures, alpha, preference, models) {

    const url = this.webUrl;
    const webservice = "WFGaussSubTableGenerate";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subtableName)
      .set('isMultiVar', isMultiVar)
      .set('bestMixtures', bestMixtures)
      .set('nbMixtures', nbMixtures)
      .set('AICMixtures', AICMixtures)
      .set('alpha', alpha)
      .set('preference', preference)
      .set('models', models)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {

          for (var i in response.wfStructure.WFConfigStatus.GaussTables.TablesWF) {
            if (tableName === response.wfStructure.WFConfigStatus.GaussTables.TablesWF[i].TableDescription.TableName) {
              for (var j in response.wfStructure.WFConfigStatus.GaussTables.TablesWF[i].TableDescription.WFSequence) {
                if (subtableName === response.wfStructure.WFConfigStatus.GaussTables.TablesWF[i].TableDescription.WFSequence[j].GaussName) {
                  this.gaussMixturesData = response.wfStructure.WFConfigStatus.GaussTables.TablesWF[i].TableDescription.WFSequence[j];
                }
              }
            }
          }

          this.gaussMixturesDataChange.next(this.gaussMixturesData);
          let dynSegAttribute = this.workflowService.getDynSegregationAttribute();
          if (dynSegAttribute) {
            this.workflowService.getDynSegAttributeValues(workflow, tableName, subtableName, "Train", "");

          }
          this.appService.showMessage("Success", response.statusText);
          this.workflowService.updateWorkflowTableSubTable(tableName, response.wfStructure, subtableName);
        }
        else {
          this.appService.showMessage("Error", response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage("Error", error.statusText);
        this.appService.stopSpin();
      });
  }

  // get the results of the train by error and run
  applyGaussTablePredict(workflow, tableName, subtableName, predictModels) {

    const url = this.webUrl;
    const webservice = "WFGaussTableApplyPredict";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subtableName)
      .set('predictModels', predictModels)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.gaussPredictClassesChange.next(response.tableRows);
          this.appService.showMessage('Success', response.statusText);
          this.workflowService.updateWorkflowTableSubTable(tableName, response.wfStructure, subtableName);

        }
        else {
          this.appService.showMessage("Error", response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage("Error", error.statusText);
        this.appService.stopSpin();
      });
  }

  // get the prediction results
  getGaussStatsCountClasses(workflow, segAttributeValue, subtableName, dataSetName) {

    const url = this.webUrl;
    const webservice = "StatGaussCountClasses";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    //Create new HttpParams
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      .set("segAttrValue", segAttributeValue)
      .set('subTableName', subtableName)
      .set("dataSet", dataSetName)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.gaussPredictClassesChange.next(response.tableRows);
        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
      });
  }
}