import { NgModule } from '@angular/core';
import { EmailConfigurationComponent } from './email-configuration/email-configuration.component';
import { LogoutTimeComponent } from './logout-time/logout-time.component';
import { MarketDataSourceComponent } from './market-data-source/market-data-source.component';
import { TrueStatusTypeComponent } from './true-status-type/true-status-type.component';
import { CommonModule } from '@angular/common';
import { SettingsRoutingModule } from './settings-routing.module';
import { SettingsStartComponent } from './settings-start/settings-start.component';
import { SettingsComponent } from './settings.component';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {SidebarModule} from 'primeng/sidebar';
import { MarketDataSourceService } from './market-data-source/market-data-source.service';
import { SelectButtonModule } from 'primeng/selectbutton';
import {ListboxModule} from 'primeng/listbox';
import { MultiSelectModule } from 'primeng/multiselect';
import { EmailConfigurationDisplayComponent } from './email-configuration/email-configuration-display/email-configuration-display.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularSplitModule } from 'angular-split';
import { TableModule } from 'primeng/table';
import { CardabelListBoxModule } from 'src/app/shared/cardabel-listbox/cardabel-listbox.component';
import { HistogramCellThresholdComponent } from './histogram-cell-threshold/histogram-cell-threshold.component';
import { LogsComponent } from './logs/logs.component';
import { CardabelDropdownListBoxModule } from 'src/app/shared/cardabel-dropdown-listbox/cardabel-dropdown-listbox.component';
import { SettingsTableBodyComponent } from './shared/settings-table-body/settings-table-body.component';
import { ScriptManagementComponent } from './script-management/script-management.component';
import { ScriptManagementService } from './script-management/script-management.service';
import { TopicsComponent } from './topics/topics.component';
import { PackagesService } from './packages.service';
import { NewtopicComponent } from './topics/newtopic/newtopic.component';
import { TopicstartComponent } from './topics/topicstart/topicstart.component';
import { TopicComponent } from './topics/topic/topic.component';
import { WorkflowTopicsComponent } from './topics/workflow-topics/workflow-topics.component';
import { OwnerstartComponent } from './owners/ownerstart/ownerstart.component';
import { WorkflowOwnerComponent } from './owners/workflow-owner/workflow-owner.component';
import { OwnerComponent } from './owners/owner/owner.component';
import { OwnersComponent } from './owners/owners.component';
import { ServerConfigComponent } from './server-config/server-config.component';
import { CardabelSelectionListModule } from 'src/app/shared/cardabel-selection-list/cardabel-selection-list.component';


@NgModule({
    declarations: [
        EmailConfigurationComponent,
        LogoutTimeComponent,
        MarketDataSourceComponent,
        TrueStatusTypeComponent,
        SettingsStartComponent,
        SettingsComponent,
        EmailConfigurationDisplayComponent,
        HistogramCellThresholdComponent,
        LogsComponent,
        SettingsTableBodyComponent,
        ScriptManagementComponent,
        TopicsComponent,
        NewtopicComponent,
        TopicstartComponent,
        TopicComponent,
        WorkflowTopicsComponent,
        OwnerstartComponent,
        WorkflowOwnerComponent,
        OwnerComponent,
        OwnersComponent,
        ServerConfigComponent
    ],
    imports: [
        CommonModule,
        SettingsRoutingModule,
        DropdownModule,
        FormsModule,
        SidebarModule,
        ReactiveFormsModule,
        SelectButtonModule,
        ListboxModule,
        MultiSelectModule,
        NgbModule,
        TableModule,
        CardabelListBoxModule,
        CardabelDropdownListBoxModule,
        AngularSplitModule.forRoot(),
        CardabelSelectionListModule

    ],
    providers : [
        MarketDataSourceService,
        ScriptManagementService,
        PackagesService
    ]
})
export class SettingsModule{}