import { HttpClient } from "@angular/common/http";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ConfigurationLoader = /** @class */ (function () {
    function ConfigurationLoader(_http) {
        this._http = _http;
        this.CONFIGURATION_URL = "./assets/configurations/config.json";
        this._configuration = { cardabelUrl: "", appNavigationUrl: "", appUrl: "", webUrl: "", scatterUrl: "", graphUrl: "" };
    }
    ConfigurationLoader.prototype.loadConfiguration = function () {
        var _this = this;
        return this._http
            .get(this.CONFIGURATION_URL)
            .toPromise()
            .then(function (configuration) {
            _this._configuration = configuration;
            return configuration;
        })
            .catch(function (error) {
            console.error(error);
        });
    };
    ConfigurationLoader.prototype.getConfiguration = function () {
        return this._configuration;
    };
    ConfigurationLoader.ngInjectableDef = i0.defineInjectable({ factory: function ConfigurationLoader_Factory() { return new ConfigurationLoader(i0.inject(i1.HttpClient)); }, token: ConfigurationLoader, providedIn: "root" });
    return ConfigurationLoader;
}());
export { ConfigurationLoader };
