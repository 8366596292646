import { Injectable } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SharedInitializationService } from '../../shared/shared-common-initialization.service';
import { CustomHttpParamEncoder } from '../../shared/custom-http-param-encoder';
import { Bookmark } from '../models/analysis-bookmark.model';
import { MatchingService } from 'src/app/matching/services/matching.service';

@Injectable()
export class AnalysisService {
  constructor(private appService: AppService,
    private httpClient: HttpClient,
    private sharedService: SharedInitializationService,
    private matchingService: MatchingService) {
  }

  webUrl = this.appService.getWebUrl();

  //fieldID
  fieldID: any = {};
  //this is for the analysis grid, allows to automatically update the field id
  fieldIDChange: { [index: string]: Subject<string> } = {};
  gridData: any = {};
  gridDataDetails: any = {};
  //this is for the analysis grid, allows to automatically update analysis table
  gridDataChange: { [index: string]: Subject<any[]> } = {};
  gridDataDetailsChange: { [index: string]: Subject<any[]> } = {};

  //this is for the analysis grid footer + in the analysis gauss menu
  gridTotalRecordsChange: { [index: string]: Subject<any> } = {};
  gridTotalRecordsDetailsChange: { [index: string]: Subject<any> } = {};

  //this is to change the necessary grid display if bookmark is changed

  //this is to change bookmarks both main and details
  bookmarkDataChange: { [index: string]: Subject<Bookmark[]> } = {};
  bookmarkDataDetailsChange: { [index: string]: Subject<Bookmark[]> } = {};

  //init mode
  mode: {} = {}
  //this is called in analysis grid component and automatically updates the mode
  modeChange: { [index: string]: Subject<any> } = {};

  showMenu: {} = {};

  histogramCellThreshold: number = 0;
  displayCharts: any = {};

  //this is called in the analysis-grid component and automatically updates the chart data
  statChartTableAttributesChange: { [index: string]: Subject<any[]> } = {};
  columnChartData: any = {};

  //fieldID_audit
  fieldID_audit: any = {};

  //trueStatusTypePrefix
  trueStatusTypePrefix: any = {};
  //trueStatusTypePrefixChange: { [index: string]: Subject<string> } = {};
  //init analysis tab changes
  initAnalysisTabChanges(tabs) {
    for (var i in tabs) {
      this.showMenu[tabs[i].name] = true;
      this.displayCharts[tabs[i].name] = false;
      this.columnChartData[tabs[i].name] = [];
      this.gridData[tabs[i].name] = [];
      this.gridDataChange[tabs[i].name] = new Subject<any[]>();
      this.gridTotalRecordsChange[tabs[i].name] = new Subject<any>();
      this.fieldIDChange[tabs[i].name] = new Subject<any>();
      // this.trueStatusTypePrefixChange[tabs[i].name] = new Subject<any>();
      this.bookmarkDataChange[tabs[i].name] = new Subject<Bookmark[]>();
      this.statChartTableAttributesChange[tabs[i].name] = new Subject<any[]>();
      this.mode[tabs[i].name] = "";
      this.modeChange[tabs[i].name] = new Subject<string>();
      if ((tabs[i].type != "Supervised") && (tabs[i].type != 'Bayes') && (tabs[i].type != "Gauss") && (tabs[i].type != "Kernel")) {
        this.bookmarkDataDetailsChange[tabs[i].name] = new Subject<Bookmark[]>();
        this.gridDataDetails[tabs[i].name] = [];
        this.gridDataDetailsChange[tabs[i].name] = new Subject<any[]>();
        this.gridTotalRecordsDetailsChange[tabs[i].name] = new Subject<any>();
      }
    }

  }


  setHistogramCellThreshold(histogramCellThreshold) {
    this.histogramCellThreshold = histogramCellThreshold;
  }
  getHistogramCellThreshold() {
    return this.histogramCellThreshold;
  }
  setDisplayCharts(withCharts, tab) {
    // if withCharts is true ie: the stats are done at the beginning
    // and the logo of chart is banned
    // the chart arre accesible for each column
    this.displayCharts[tab] = withCharts;
  }
  getDisplayCharts(tab) {
    return this.displayCharts[tab];
  }
  //loads the table referenced by the parameters for the analysis grid
  //called in analysis grid, analysis menu, and analysis menu bayes components
  //  withCharts is a boolean that indicates if the table counts must be done or not for the charts
  // withLoad is a boolean that indicates if the table must be loaded or not
  getAnalysisLoadGrid(workflow, table, subTable, fieldID, startRec, endRec, mode, proMode, filters, sorts, withLoad, withCharts, matchingDetails, tab) {
    this.appService.startSpin2();

    const url = this.webUrl;
    const webservice = "AnalysisLoadTables";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    if (filters === undefined) { filters = "[]"; }
    if (sorts === undefined) { sorts = "[]"; }

    const varFilter = "{\"listOfFilters\":" + filters + "}";
    const varSort = "{\"listOfSorts\":" + sorts + "}";

    //create new http params
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      .set("segAttrValue", table)
      .set("subTableName", subTable)
      .set("startRec", startRec)
      .set("endRec", endRec)
      .set("mode", mode)
      .set("proMode", proMode)
      .set("Filter", varFilter)
      .set("Sort", varSort)
      .set("withLoad", withLoad)
      .set("withCharts", withCharts)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {
          if (matchingDetails === false) {
            if (withLoad) {
              this.gridTotalRecordsChange[tab].next(response.tableRows2[0].TotalRecords)
              this.gridData[tab] = response.tableRows;
              this.gridDataChange[tab].next(response.tableRows);
            }
            if (this.mode[tab] != mode) {
              this.mode[tab] = mode;
              let data = { 'mode': mode, 'changeMode': true };
              this.modeChange[tab].next(data)
            }

            // load stat for chart at the same time
            if (withCharts) {
              this.setColumnChartData(response.tableRows3, tab)
            }
            //
          }
          else {
            this.gridDataDetails[tab] = response.tableRows;
            this.gridDataDetailsChange[tab].next(response.tableRows);
            this.gridTotalRecordsDetailsChange[tab].next(response.tableRows2[0].TotalRecords);
          }
        }
        else {
          if ((mode == 'Anomaly') && (proMode == "normal") && (subTable == "") && (response.statusText.trim() == "empty table")) {
            // for bayes 
            this.appService.showMessage('Warning', response.statusText)
          }
          else {
            this.appService.showMessage('Error', response.statusText)
          }
        }
        this.appService.stopSpin2();

      }, (error) => {

        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin2();
      });

  }

  //loads the table referenced by the parameters for the analysis grid
  //called in analysis grid, analysis menu, and analysis menu bayes components
  //  withCharts is a boolean that indicates if the table counts must be done or not for the charts
  // withLoad is a boolean that indicates if the table must be loaded or not
  getAnalysisLoadGridDataHub(workflow, fileName, date, startRec, endRec, filters, sorts, withLoad, tab) {
    this.appService.startSpin2();

    const url = this.webUrl;
    const webservice = "AnalysisLoadTablesDataHub";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    if (filters === undefined) { filters = "[]"; }
    if (sorts === undefined) { sorts = "[]"; }

    const varFilter = "{\"listOfFilters\":" + filters + "}";

    date = date.replace(/-/g, '');

    const varSort = "{\"listOfSorts\":" + sorts + "}";

    //create new http params
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      .set("fileName", fileName)
      .set("date", date)
      .set("startRec", startRec)
      .set("endRec", endRec)
      .set("Filter", varFilter)
      .set("Sort", varSort)
      .set("withLoad", withLoad)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {

          if (withLoad) {
            this.gridTotalRecordsChange[tab].next(response.tableRows2[0].TotalRecords)
            this.gridDataChange[tab].next(response.tableRows);
          }
        }
        else {
          this.appService.showMessage('Error', response.statusText)
        }
        this.appService.stopSpin2();

      }, (error) => {

        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin2();
      });

  }

  setColumnChartData(data, tab) {
    this.columnChartData[tab] = [...data];
    this.statChartTableAttributesChange[tab].next(this.columnChartData[tab]);
  }
  getColumnChartData(tab) {
    return this.columnChartData[tab];
  }

  //exports the table referenced by the parameters to a csv file
  //called in analysis grid

  exportAnalysisGrid(username, workflow, table, subTable, startRec, endRec, mode, proMode, filters, sorts, fileType, alpha, columnsOrder) {

    this.appService.startSpin();

    const url = this.webUrl;
    const webservice = "AnalysisExportTables";
    const completeUrl = url + webservice;

    const varFilter = "{\"listOfFilters\":" + filters + "}";
    const varSort = "{\"listOfSorts\":" + sorts + "}";

    //create new http params
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("userName", username)
      .set("wfName", workflow)
      .set("segAttrValue", table)
      .set("subTableName", subTable)
      .set("startRec", startRec)
      .set("endRec", endRec)
      .set("mode", mode)
      .set("proMode", proMode)
      .set("Filter", varFilter)
      .set("Sort", varSort)
      .set("fileType", fileType)
      .set("alpha", alpha)
      .set("columnsOrder", columnsOrder)

    const headers = this.appService.getHeaders();

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {
          let filename = response.tableRows[0].fileName;
          this.analysisDownloadFile(username, filename, fileType, workflow, table)
        }
        else {
          this.appService.showMessage('Error', response.statusText)
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });

  }

  exportAnalysisGridDataHub(username, workflow, date, fileName, startRec, endRec, filters, sorts, fileType, columnsOrder) {

    this.appService.startSpin();

    const url = this.webUrl;
    const webservice = "AnalysisExportTablesDataHub";
    const completeUrl = url + webservice;

    const varFilter = "{\"listOfFilters\":" + filters + "}";
    const varSort = "{\"listOfSorts\":" + sorts + "}";
    date = date.replace(/-/g, '');
    //create new http params
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("userName", username)
      .set("wfName", workflow)
      .set("date", date)
      .set("fileName", fileName)
      .set("startRec", startRec)
      .set("endRec", endRec)
      .set("Filter", varFilter)
      .set("Sort", varSort)
      .set("fileType", fileType)
      .set("columnsOrder", columnsOrder)

    const headers = this.appService.getHeaders();

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {
          let filename = response.tableRows[0].fileName;
          this.analysisDownloadFile(username, filename, fileType, workflow, date)
        }
        else {
          this.appService.showMessage('Error', response.statusText)
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });

  }


  analysisDownloadFile(username, filename, filetype, workflow, table) {

    const url = this.webUrl;
    const webservice = "AnalysisDownloadFile";
    const completeUrl = url + webservice;

    this.appService.startSpin();
    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("userName", username)
      .set("fileName", filename)
      .set("fileType", filetype)

    const headers = this.appService.getHeaders();

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'blob', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        if (response["type"] == 'application/json') {
          this.appService.showMessage('Error', "unable to download the file ");
        }
        else {
          let name = workflow + "_" + table;
          if (filetype === "CSV") {
            this.sharedService.downloadCSVFile(response, name);
          }
          else {
            if (filetype === "Excel") {
              this.sharedService.downloadExcelFile(response, name);
            }
          }
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });
  }


  analysisDownloadTypeFile(username, workflow, table, subtable, filename, fileType, csvData) {

    const url = this.webUrl;
    const webservice = "AnalysisDownloadTypeFile";
    const completeUrl = url + webservice;

    this.appService.startSpin();

    let dataList = JSON.stringify(csvData);

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("userName", username)
      .set("wfName", workflow)
      .set("segAttrValue", table)
      .set("subTableName", subtable)
      .set("fileName", filename)
      .set("fileType", fileType)
      .set("dataList", dataList)

    const headers = this.appService.getHeaders();

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {
        response = JSON.parse(response);

        if (response.statusCode > -1) {
          let filename = response.tableRows[0].fileName;
          this.analysisDownloadFile(username, filename, fileType, workflow, table)
        }
        else {
          this.appService.showMessage('Error', response.statusText)
        }
        this.appService.stopSpin();

      }, (error) => {
        if (error.statusText) {
          this.appService.showMessage('Error', error.statusText);
        }
        else {
          this.appService.showMessage('Error', 'The error may be due to exporting too many rows. Please try to export fewer rows. Thank you.')
        }

        this.appService.stopSpin();
      });
  }

  //this is to download the selected rows, 

  downloadSelectedRows(data, workflow, table) {
    let csvData = this.sharedService.ConvertToCSV(data, ";");
    let name = workflow + "_" + table;
    this.sharedService.downloadCSVFile(csvData, name)
  }
  //sets the field id
  setFieldID(table, tab) {
    this.fieldID[tab] = table.TableDescription.IDField;
    this.fieldIDChange[tab].next(this.fieldID[tab]);
  }

  //returns the field id
  getFieldID(tab) {
    return this.fieldID[tab];
  }
  //sets the TrueStatusTypePrefix
  setTrueStatusTypePrefix(table, tab) {
    if (table.TableDescription.TrueStatusTypePrefix) {
      this.trueStatusTypePrefix[tab] = table.TableDescription.TrueStatusTypePrefix;
    }
    else {
      this.trueStatusTypePrefix[tab] = "All";
    }

  }
  //returns the trueStatusTypePrefix
  getTrueStatusTypePrefix(tab) {
    return this.trueStatusTypePrefix[tab];
  }
  //sets the field id used for the audit
  setFieldID_audit(fieldID_audit, tab) {
    this.fieldID_audit[tab] = fieldID_audit;
  }

  //returns the field id
  getFieldID_audit(tab) {
    return this.fieldID_audit[tab];
  }
  //Sets the last user bookmark grid parameters for the workflow/ table/ subtable.
  //called in analysis grid component
  saveBookmark(username, tab, workflow, table, subtable, bookmark, aggregateDetails, tabParam, tabType) {

    const url = this.webUrl;
    const webservice = "AnalysisUserBookmarkSave";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();;

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("userName", username)
      .set("tabName", tab)
      .set("wfName", workflow)
      .set("segAttrValue", table)
      .set("subTableName", subtable)
      .set('bookmark', bookmark)
      .set('aggregateDetails', aggregateDetails)
      .set('tabParam', tabParam)
      .set('tabType', tabType)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {

        }
        else {

          this.appService.stopSpin();
        }

      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();

      });
  }

  //Loads the last user bookmark grid parameters for the workflow/ table/ subtable.
  //called in analysis grid component
  loadBookmark(username, workflow, table, subtable, details, tab, tabType, tabParam) {

    const url = this.webUrl;
    const webservice = "AnalysisUserBookmarkLoad";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    let navtab = "";
    if (tabType === "navigation") {
      navtab = tab;
      tab = tab.substring(3);
    }

 
    // this.appService.startSpin();
    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("userName", username)
      .set("tabName", tab)
      .set("wfName", workflow)
      .set("segAttrValue", table)
      .set("subTableName", subtable)
      .set('aggregateDetails', details)
      .set("tabParam", tabParam)
      .set("tabType", tabType)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {
          if (tabType === "navigation") {
            tab = navtab;
          }
          if (tabParam === "Analysis") {
            if (details === true) {
              this.bookmarkDataDetailsChange[tab].next(response.tableRows);
            }
            else {
              this.bookmarkDataChange[tab].next(response.tableRows);
            }
          }
          else {
            this.matchingService.bookmarkDataChange[tab].next(response.tableRows);
          }

        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }

      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
      });
  }

  //sets mode for analysis services 
  setMode(mode, changeMode, tab) {

    this.mode[tab] = mode;
    let data = { 'mode': mode, 'changeMode': changeMode };
    this.modeChange[tab].next(data);

  }
  //for analysis grid (supervised)
  getMode(tab) {
    return this.mode[tab]
  }

  setShowMenu(showMenu, tab) {
    this.showMenu[tab] = showMenu;
  }

  getShowMenu(tab) {
    return this.showMenu[tab];
  }

}
