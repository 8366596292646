import { OnInit, SimpleChanges } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { ConfirmationService } from 'primeng/api';
var SettingsTableBodyComponent = /** @class */ (function () {
    function SettingsTableBodyComponent(appService, confirmationService) {
        this.appService = appService;
        this.confirmationService = confirmationService;
        this.tableData = [];
        //this allows to display the second group row
        this.displaySecondRow = {};
        this.editing2 = {};
    }
    SettingsTableBodyComponent.prototype.ngOnInit = function () {
    };
    //allows to save the editions made to the row
    SettingsTableBodyComponent.prototype.onRowEditSave = function (row, index) {
        var _this = this;
        var _a;
        if (row.myNewGroupField1) {
            if (row.myNewGroupField1.length > 0) {
                row.myNewGroupField1 = row.myNewGroupField1.toUpperCase();
                // verify that the groupField1 does not already exists
                var sameGroupField1 = this.tableData.filter((function (item) {
                    if (item[_this.groupField1].toUpperCase() == row.myNewGroupField1) {
                        return item;
                    }
                }));
                if (sameGroupField1.length > 0) {
                    this.appService.showMessage('Error', this.title1 + " " + row.myNewGroupField1 + " already exists.");
                }
                else {
                    this.tableData.splice(0, 0, (_a = {}, _a[this.groupField1] = row.myNewGroupField1, _a[this.groupField2] = "", _a.editing = false, _a));
                }
            }
            this.updateRowGroupMetaData();
        }
    };
    //allows to save the editions made to the row
    SettingsTableBodyComponent.prototype.onRowEditSave2 = function (row, index) {
        if (row.myNewGroupField2) {
            if (row.myNewGroupField2.length > 0) {
                this.tableData[index][this.groupField2] = row.myNewGroupField2;
            }
            this.updateRowGroupMetaData();
        }
    };
    //cancels the editions made to the row
    SettingsTableBodyComponent.prototype.onRowEditCancel = function (row, index) {
        var _this = this;
        // remove the row,
        // confirm cancel groupField1 if a groupfield2 is already created
        // delete all the corresponding true statustype
        if (row[this.groupField1] !== "") {
            row[this.groupField1] = row[this.groupField1].toUpperCase();
            var sameGroupField1 = this.tableData.filter((function (item) {
                if (item[_this.groupField1].toUpperCase() === row[_this.groupField1]) {
                    return item;
                }
            }));
            if (sameGroupField1.length > 0) {
                this.confirmationService.confirm({
                    message: 'All the ' + this.title2.toLowerCase() + ' for the ' + this.title1.toLowerCase() + ' ' + row[this.groupField1] + " will be deleted.",
                    header: 'Delete ' + this.groupField1,
                    accept: function () {
                        _this.tableData = _this.tableData.filter((function (item) {
                            if (item[_this.groupField1].toUpperCase() !== row[_this.groupField1]) {
                                return item;
                            }
                        }));
                        _this.updateRowGroupMetaData();
                    },
                    reject: function () {
                        _this.displaySecondRow[row[_this.groupField1]] = !_this.displaySecondRow[row[_this.groupField1]];
                    }
                });
            }
        }
    };
    //cancels the editions made to the row
    SettingsTableBodyComponent.prototype.onRowEditCancel2 = function (row, index) {
        // remove the row,
        // confirm cancel if groupField2 already created
        // delete all the true statustype corresponding
        this.tableData.splice(index, 1);
        this.updateRowGroupMetaData();
    };
    //new group by groupfield1
    SettingsTableBodyComponent.prototype.updateRowGroupMetaData = function () {
        var _this = this;
        var _a, _b;
        this.rowGroupMetadata = {};
        if (this.tableData) {
            // remove from tableData where groupfield1 and groupField2 = ""
            this.tableData = this.tableData.filter((function (item) {
                if ((item[_this.groupField1] !== "") || (item[_this.groupField2] !== "")) {
                    return item;
                }
            }));
            // add a record for each groupField1
            for (var i = 0; i < this.tableData.length; i++) {
                var rowData = this.tableData[i];
                var groupField1 = rowData[this.groupField1];
                if (rowData[this.groupField2] !== "") {
                    this.tableData.splice(i, 0, (_a = {}, _a[this.groupField1] = groupField1, _a[this.groupField2] = "", _a.editing = false, _a));
                    i = i + 1;
                }
                for (var j = i + 1; j < this.tableData.length; j++) {
                    if (this.tableData[j][this.groupField1] !== groupField1) {
                        i = j - 1;
                        break;
                    }
                    else {
                        i = j;
                    }
                }
            }
            // add a record to possibly add a groupField1
            this.tableData.splice(0, 0, (_b = {}, _b[this.groupField1] = "", _b[this.groupField2] = "", _b.editing = false, _b));
            // repare the group 
            for (var i = 0; i < this.tableData.length; i++) {
                var rowData = this.tableData[i];
                var groupField1 = rowData[this.groupField1];
                if (i == 0) {
                    this.rowGroupMetadata[groupField1] = { index: 0, size: 1 };
                }
                else {
                    var previousRowData = this.tableData[i - 1];
                    var previousRowGroup = previousRowData[this.groupField1];
                    if (groupField1 === previousRowGroup)
                        this.rowGroupMetadata[groupField1].size++;
                    else
                        this.rowGroupMetadata[groupField1] = { index: i, size: 1 };
                }
            }
        }
    };
    //return new table data to parent component
    SettingsTableBodyComponent.prototype.returnTableData = function () {
        return this.tableData;
    };
    SettingsTableBodyComponent.prototype.ngOnChanges = function (changes) {
        if (changes.tableData) {
            if (changes.tableData.currentValue) {
                this.updateRowGroupMetaData();
            }
        }
    };
    return SettingsTableBodyComponent;
}());
export { SettingsTableBodyComponent };
