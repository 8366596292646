import { Injectable } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { WorkflowNameService } from './workflow-name.service';
import { catchError } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CustomHttpParamEncoder } from 'src/app/shared/custom-http-param-encoder';


@Injectable()
export class WorkflowMatchingStepsService {
  constructor(private appService: AppService,
    private httpClient: HttpClient,
    private workflowService: WorkflowNameService) { }

  webUrl = this.appService.getWebUrl();

  resultsTrainChange = new Subject<any[]>();

  resultsTestChange = new Subject<any[]>();
  modelsTestChange = new Subject<any[]>();

  // this is for the status of an execution
  executionStatusChange = new Subject<any>();

  resultsStatChange = new Subject<any[]>();

  //Sets the segregation, signed_measure, target, sortdatesList in the workflow/table/and returns back the workflow
  //called in workflow matching steps
  setMatchingTableFeatures1(apply_and_chain, workflow, tableName, subtableName, signed_measure, signed_measure_null_values, segregation, targetLabel, sortDatesList) {

    const url = this.webUrl;
    const webservice = "WFMatchingTableSetFeatures1";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("apply_and_chain", apply_and_chain)
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subtableName)
      .set('segregation', segregation)
      .set('signedMeasure', signed_measure)
      .set('signedMeasureNullValues', signed_measure_null_values)
      .set('targetLabel', targetLabel)
      .set('sortDatesList', sortDatesList)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {

          this.appService.showMessage("Success", response.statusText);
          if (apply_and_chain) {
            this.executionStatusChange.next();
          }
          this.workflowService.updateWorkflowTableSubTable(tableName, response.wfStructure, subtableName);
        }
        else {
          this.appService.showMessage("Error", response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage("Error", error.statusText);
        this.appService.stopSpin();
      });
  }
  //Sets the feature attributes  and filter in the workflow/table/and returns back the workflow
  //called in workflow matching steps
  setMatchingTableFeatures2(apply_and_chain, workflow, tableName, subtableName, featureAttributes, featureFilter, featureValue) {

    const url = this.webUrl;
    const webservice = "WFMatchingTableSetFeatures2";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("apply_and_chain", apply_and_chain)
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subtableName)
      .set('featureAttributes', featureAttributes)
      .set('featureFilter', featureFilter)
      .set('featureValue', featureValue)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.appService.showMessage("Success", response.statusText);
          if (apply_and_chain) {
            this.executionStatusChange.next();
          }
          this.workflowService.updateWorkflowTableSubTable(tableName, response.wfStructure, subtableName);
        }
        else {
          this.appService.showMessage("Error", response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage("Error", error.statusText);
        this.appService.stopSpin();
      });
  }
  // train the model 
  applyMatchingTableTrain(workflow, tableName, subtableName, trainNature, trainDataSet, train, period, nextCardRec,
    tpThreshold, timeOut, splitDate, priorityDate, maxRecoSize, toleranceError, toleranceCcy, datesSpanMax) {

    const url = this.webUrl;
    const webservice = "WFMatchingTableApplyTrain";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subtableName)
      .set('trainNature', trainNature)
      .set('trainDataSet', trainDataSet)
      .set('trainDate', train)
      .set('trainPeriod', period)
      .set('nextCardRec', nextCardRec)
      .set('tpThreshold', tpThreshold)
      .set('timeOut', timeOut)
      .set('splitDate', splitDate)
      .set('priorityDate', priorityDate)
      .set('maxRecoSize', maxRecoSize)
      .set('toleranceError', toleranceError)
      .set('toleranceCcy', toleranceCcy)
      .set('datesSpanMax', datesSpanMax)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.appService.showMessage("Success", response.statusText);
          this.resultsTrainChange.next(response.tableRows);

          this.executionStatusChange.next();
          this.workflowService.updateWorkflowTableSubTable(tableName, response.wfStructure, subtableName);
        }
        else {
          this.appService.showMessage("Error", response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {

        this.appService.showMessage("Error", error.statusText);
        this.appService.stopSpin();
      });
  }
  // get the results of the train by error and run
  getMatchingTableResultsMode(workflow, tableName, subtableName, mode) {

    const url = this.webUrl;
    const webservice = "WFMatchingTableGetResultsMode";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin2();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subtableName)
      .set('matchingMode', mode)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {

          if (mode == 'train') {
            this.resultsTrainChange.next(response.tableRows);
          }
          else {
            this.resultsTestChange.next(response.tableRows);
          }
        }
        else {
          this.appService.showMessage("Error", response.statusText);
        }
        this.appService.stopSpin2();
      }, (error) => {
        this.appService.showMessage("Error", error.statusText);
        this.appService.stopSpin2();
      });
  }

  // get the results of the train by error and run
  getMatchingTableModels(workflow, tableName, subtableName) {

    const url = this.webUrl;
    const webservice = "WFMatchingTableGetModels";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subtableName)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {

          this.modelsTestChange.next(response.tableRows);
        }
        else {
          this.appService.showMessage("Error", response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage("Error", error.statusText);
        this.appService.stopSpin();
      });
  }

  // train the model 
  applyMatchingTableTest(workflow, tableName, subtableName, testNature, dynamicDate, testDate, testModels,
    datesSpanMax) {

    const url = this.webUrl;
    const webservice = "WFMatchingTableApplyTest";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })

      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subtableName)
      .set('dynamicDate', dynamicDate)
      .set('testModels', testModels)
      .set('datesSpanMax', datesSpanMax)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.appService.showMessage("Success", response.statusText);
          this.resultsTestChange.next(response.tableRows);
          this.executionStatusChange.next();
          this.workflowService.updateWorkflowTableSubTable(tableName, response.wfStructure, subtableName);
        }
        else {
          this.appService.showMessage("Error", response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {

        this.appService.showMessage("Error", error.statusText);
        this.appService.stopSpin();
      });
  }

  // get the results of the train by error and run
  getMatchingTableResultsStat(workflow, tableName, subtableName, statDate) {

    const url = this.webUrl;
    const webservice = "WFMatchingTableGetResultsStat";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin2();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subtableName)
      .set('statDate', statDate)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.resultsStatChange.next(response.tableRows);
        }
        else {
          this.appService.showMessage("Error", response.statusText);
        }
        this.appService.stopSpin2();
      }, (error) => {
        this.appService.showMessage("Error", error.statusText);
        this.appService.stopSpin2();
      });
  }

  // set matching exception
  setMatchingTableException(workflow, tableName, subtableName, exceptions, whereClause, exceptBatch) {

    const url = this.webUrl;
    const webservice = "WFMatchingTableSetException";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })

      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subtableName)
      .set('exceptionsList', exceptions)
      .set('whereClause', whereClause)
      .set('exceptBatch', exceptBatch)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.appService.showMessage("Success", response.statusText);

          this.workflowService.updateWorkflowTableSubTable(tableName, response.wfStructure, subtableName);
        }
        else {
          this.appService.showMessage("Error", response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {

        this.appService.showMessage("Error", error.statusText);
        this.appService.stopSpin();
      });
  }
  // set matching rule
  setMatchingTableRule(workflow, tableName, subtableName, rules, whereClause, segregation, script, errorMax, errorMaxTol, comboType) {

    const url = this.webUrl;
    const webservice = "WFMatchingTableSetRule";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })

      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subtableName)
      .set('rulesList', rules)
      .set('whereClause', whereClause)
      .set('segregation', segregation)
      .set('script', script)
      .set('errorMax', errorMax)
      .set('errorMaxTol', errorMaxTol)
      .set('comboType', comboType)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.appService.showMessage("Success", response.statusText);

          this.workflowService.updateWorkflowTableSubTable(tableName, response.wfStructure, subtableName);
        }
        else {
          this.appService.showMessage("Error", response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {

        this.appService.showMessage("Error", error.statusText);
        this.appService.stopSpin();
      });
  }
}