<div class="row">
  <div class="col-xs-12 col-md-12 col-sm-12">
    <i class="pi pi-question-circle piHelp" id="helpIcon" (click)="getHelpDocumentation()"></i>
  </div>
</div>
<div class="row">
  <div class="col-md-12 textAlignCenter">
    <h3>{{topicName}}</h3>
  </div>
</div>

<div class="row">
  <div class="col-md-1"></div>
  <div class="col-md-5 offset-md-5">
    <div class="btn-group cardabel-topic-btn-group">
      <button class="btn btn-primary btn-sm" (click)="applyUpdateTopicData('delete')">
        <span>
          <i class="fa fa-minus-circle"></i>
        </span> Delete
      </button>
      <button class="btn btn-primary btn-sm" [disabled]="(!workflowsChanged)" (click)="applyUpdateTopicData('update')">
        <span>
          <i class="fa fa-cog"></i>
        </span> Apply
      </button>
    </div>
  </div>
</div>
<div id="editTopicContainer">

  <div class="row margin-top-40">
    <div class="col-md-1"></div>
    <div class="col-md-5 textAlignCenter">
      <h5 class="topicTitle">Workflows</h5>
      <cardabel-selection-list id="workflowContainer" [attr.data-cy]="'admin-settings-topic-workflow-selection-list'"
        [inputSelectedOptions]="selectedWorkflows" [options]="workflows" [height]="440" [title]="'workflows'"
        [optionLabel]="'WFName'" [multipleSelection]="true"
        (changeSelectedOptions)="selectedWorkflows = $event.value;  changeWorkflow()"></cardabel-selection-list>
    </div>

    <div class="col-md-1"></div>
  </div>

</div>