import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'cardabel-topics-dashboard-topic-card',
  templateUrl: './topics-dashboard-topic-card.component.html',
  styleUrls: ['./topics-dashboard-topic-card.component.css']
})
export class TopicsDashboardTopicCardComponent implements OnInit {

  @Input() topic: any;
  @Input() topicNames: any;
  @Input() index: number = 0;
  @Input() openClosed: any;
  @Input() showOpenClosed: boolean = false;
  
  constructor() { }

  ngOnInit() {
  }

}
