import { Component, OnInit, Input, forwardRef, NgModule, ChangeDetectorRef, SimpleChanges, ViewEncapsulation, ViewRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DataViewModule } from 'primeng/dataview';
import { PanelModule } from 'primeng/panel';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { BarChart } from '../d3/d3barchart';
import { TableModule } from 'primeng/table';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { WorkflowNameService } from 'src/app/workflow/services/workflow-name.service';
import { AppService } from 'src/app/app.service';
import { CardabelSelectionListModule } from '../cardabel-selection-list/cardabel-selection-list.component';


@Component({
  selector: 'app-chart-data-view',
  templateUrl: './chart-data-view.component.html',
  styleUrls: ['./chart-data-view.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ChartDataViewComponent),
    multi: true
  }],
})
export class ChartDataViewComponent implements OnInit {
  
  @Input() numberRecords: number;
  @Input() data:any[];
  @Input() attributesChartData: any[];
  @Input()  detailData: any[];
  @Input() detailTableData: any[];
  @Input() visible: boolean;
  @Input() active: boolean;
  attribute: any = {};
  displayDialog: boolean = false;
  margin: {top: number, bottom: number, left: number, right: number} = { left:40, right:5, top:3, bottom:2 };
  height:number = 100;
  width: number = 290;
  heightBig: number = 200;
  widthBig: number = 950;//555
  marginBig: {top: number, bottom: number, left: number, right: number} = { left:50, right:5, top:10, bottom:40 };
  chart1;
  chartData:any={};
  detailChartData: any = {};
  processedDetailTableData: any = {};
  visibleAttr: any = {};
  activeAttr: any = {};
  attributes: any[] = [];
  sortField;
  sortOrder;
  theme: string = "theme-light"
  

  constructor(private cd: ChangeDetectorRef,
              private workflowService: WorkflowNameService, private appService: AppService) { }

  ngOnInit() {
    this.theme = this.appService.getTheme();

    if ((this.visible)|| (this.active)) {
      this.attributes = this.workflowService.getTableAttributesAll();

      for (var i in this.attributes) {
        this.visibleAttr[this.attributes[i].AttrName] = false;
        this.activeAttr[this.attributes[i].AttrName] = false;
        if (this.attributes[i].AttrVisible === "Visible") {
          this.visibleAttr[this.attributes[i].AttrName] = true;
        }

        if (this.attributes[i].AttrStatus === "Active") {
          this.activeAttr[this.attributes[i].AttrName] = true;
        }

      }
    }

    console.log(this.visibleAttr);
    console.log(this.activeAttr);
  

    
  }
  
public  returnAttributes() {
    for (var i in this.attributes) {
      if (this.visibleAttr[this.attributes[i].AttrName] === true) {
        this.attributes[i].AttrVisible = "Visible"
        if (this.activeAttr[this.attributes[i].AttrName] === true) {
          this.attributes[i].AttrStatus = "Active"
        }
        else {
          this.attributes[i].AttrStatus = "Inactive";
        }
      
      }
      else {
        this.attributes[i].AttrVisible = "Invisible";
        this.attributes[i].AttrStatus = "Inactive";
      }

   /*   if (this.activeAttr[this.attributes[i].AttrName] === true) {
        this.attributes[i].AttrStatus = "Active"
      }
      else {
        this.attributes[i].AttrStatus = "Inactive"
      }*/
    }
    return this.attributes;
  }
  selectCar(event: Event, car: any) {
    this.attribute = car;
    this.displayDialog = true;
    if (this.cd && !(this.cd as ViewRef).destroyed) {
      this.cd.detectChanges();
    }
    event.preventDefault();
    let domain = this.chartData[this.attribute.AttrName].map(a => a["Categorie"]);
    let id = this.attribute.AttrName+"big";
    this.getCharts(id, this.chartData[this.attribute.AttrName], domain, this.heightBig, this.widthBig, this.marginBig );
  }

  getCharts(id, data, domain, height, width, margin) {
     this.chart1 = new BarChart("#"+id, 'col', '', height, width, domain, 'Categorie',this.appService.cardabel_chart_color_scheme[0], false , '', '', false, '', '','' , false , margin, false, [],'', data)
     this.chart1.initVis();
   }
   getUpdatedCharts() {
     console.log(this.data);
   }

   prepareCharts() {
    for (var i in this.data) {
      let chartData = [];
      this.chartData[this.data[i].AttrName]=[];
      for (var j in this.attributesChartData) {
        if (this.attributesChartData[j].VariableName === this.data[i].AttrName) {
          chartData.push({Categorie: this.attributesChartData[j].InstanceName, col: +this.attributesChartData[j].InstanceCount})
          this.chartData[this.data[i].AttrName].push({Categorie: this.attributesChartData[j].InstanceName, col: +this.attributesChartData[j].InstanceCount});
        }
      }
    
      let domain = chartData.map(a => a["Categorie"]);
      console.log(chartData);
      console.log(domain)
      this.getCharts(this.data[i].AttrName, chartData, domain, this.height, this.width, this.margin);
    } 
   }
   prepareDetailData() {
    for (var i in this.data) {
        
      this.detailChartData[this.data[i].AttrName]=[];
      for (var j in this.detailData) {
        if (this.detailData[j].VariableName === this.data[i].AttrName) {
          this.detailChartData[this.data[i].AttrName].push({Categorie: this.detailData[j].ValueName, col: +this.detailData[j].ValueCount});
        }
     
      }
    }
   }

   prepareDetailTableData() {
     for (var i in this.detailTableData) {
        let keys = Object.keys(this.detailTableData[i]);
        for (var j in keys) {
          this.processedDetailTableData[keys[j]]= this.detailTableData[i][keys[j]];
          console.log(this.processedDetailTableData[keys[j]])
        }
        
     }
   }

   
  ngOnChanges(changes: SimpleChanges) {
  //  let domain = changes.chartData.currentValue.map(a => a["Categorie"]);
    if (changes.data) {
      this.data = changes.data.currentValue;
      if (this.cd && !(this.cd as ViewRef).destroyed) {
        this.cd.detectChanges();
      }
      this.prepareCharts();
      this.prepareDetailData();
    }
    if (changes.attributesChartData) {
      this.attributesChartData = changes.attributesChartData.currentValue;
      this.prepareCharts();
    }
    if (changes.detailData) {
      this.detailData = changes.detailData.currentValue;
      this.prepareDetailData(); 
    }

    if (changes.detailTableData) {
      this.detailTableData = changes.detailTableData.currentValue;
      this.prepareDetailTableData();
    }
   // console.log(domain);

   // this.getCharts(this.segregationAttributes[i].AttrName, chartData, domain, this.height, this.width, this.margin);
  }
}

@NgModule({
  imports:[
    ToggleButtonModule, DialogModule, CommonModule, ReactiveFormsModule, DataViewModule, PanelModule,FormsModule, TableModule, CardabelSelectionListModule
  ],
  exports: [RouterModule, ChartDataViewComponent,DialogModule, ReactiveFormsModule, DataViewModule, PanelModule,FormsModule], 
  declarations: [ChartDataViewComponent],
  providers: [
      
  ]
})
export class ChartDataViewModule {

}
