import { Injectable } from '@angular/core';



@Injectable()
export class DashboardWebGLEventsService {

    initializeEvents = (gl, vis) => {
      //  var canvas = gl.canvas;
      var canvas = gl;
        var isDown = false;
        var startX, startY, endX, endY;
        /*   canvas.addEventListener('mouseup', (e) => {
               isDown = false;
               endX = e.offsetX; endY = e.offsetY;
               if (method2) {
                   method2(startX, startY, endX, endY);
               }
           });
           canvas.addEventListener('mousedown', (e) => {
               startX = e.offsetX; startY = e.offsetY;
               isDown = true;
           });
           canvas.addEventListener('mousemove', (e) => {
               if (isDown) {
                   //dragging
                   endX = e.offsetX; endY = e.offsetY;
                   if (methodName) {
                       methodName(startX, startY, endX, endY);
                   }
               }
           });
           canvas.addEventListener('mousewheel', (e) => {
               console.log(e.deltaY);
               if (zoom) {
                   zoom(e.deltaY);
               }
           }); */
           vis.svg.on("click", function(d) {
            console.log(d)
           })
     /*   canvas.addEventListener("click", (e) => {
            console.log(e)
        })
        canvas.addEventListener("mousemove", (e) => {
            console.log(e)
            //var y = vis.y.invert(e.offsetY);

          //  vis.x.range([0, vis.width * transform.k]);
            //rescale x axis because of zoom
           // vis.xAxisCall.scale(vis.x);

            var index = Math.floor((Math.abs(1 / vis.y.step())) +  e.offsetY / vis.y.step())
            let pagedata = true;

            var yValue = vis.y.domain()[index];
            console.log(yValue)
        })
        canvas.addEventListener("contextmenu", (e) => {
            console.log(e)
        }) */
    };

}