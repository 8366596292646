import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { SharedInitializationService } from 'src/app/shared/shared-common-initialization.service';
import { HistogramCellThresholdService } from './histogram-cell-threshold.service';

@Component({
  selector: 'app-histogram-cell-threshold',
  templateUrl: './histogram-cell-threshold.component.html',
  styleUrls: ['./histogram-cell-threshold.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HistogramCellThresholdComponent implements OnInit, OnDestroy {
  visibleHelpSidebarLT = false;
  histogramCellThresholdForm: FormGroup;
  histogramCellThreshold: number;
  histogramCellThresholdSubscription = new Subscription();

  constructor(
    private histogramCellThresholdService: HistogramCellThresholdService,
    private sharedService: SharedInitializationService) { }

  ngOnInit() {
    this.histogramCellThresholdService.getHistogramCellThreshold();
    this.initForm();

    this.histogramCellThresholdSubscription = this.histogramCellThresholdService.histogramCellThresholdChange
      .subscribe(
        (histogramCellThreshold: number) => {
          this.histogramCellThreshold = histogramCellThreshold;

          this.histogramCellThresholdForm.get('histogramCellThreshold').setValue(this.histogramCellThreshold);

        }
      );


  }// end On Init

  onSubmit() {
    this.histogramCellThresholdService.setHistogramCellThreshold(Math.round(+this.histogramCellThresholdForm.value.histogramCellThreshold));
  }

  private initForm() {
    this.histogramCellThresholdForm = new FormGroup({
      histogramCellThreshold: new FormControl('', Validators.required),
    });

  }

  //this allows to get the help documentation for settings histogramCellThreshold
  getHelpDocumentation() {
    let parameters = ["admin", "settings", "histogramCellThreshold"];
    this.sharedService.showHelpDocumentation("Admin Settings Histogram Cell Threshold");

    this.sharedService.getHelpDocumentation(parameters)
  }

  //this is to destroy subscriptions when component is destroyed
  ngOnDestroy() {
    this.histogramCellThresholdSubscription.unsubscribe();
  }

}
