import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { catchError } from "rxjs/operators";
import { AppService } from "src/app/app.service";
import { CustomHttpParamEncoder } from "src/app/shared/custom-http-param-encoder";
import { KernelErrors } from "../../models/kernel/kernel-error.model";

@Injectable()
export class AnalysisKernelService {
    constructor(private appService: AppService,
        private httpClient: HttpClient
    ) {
    }

    webUrl = this.appService.getWebUrl();

    errorsDetected: {} = {};
    errorsDetectedChange: { [index: string]: Subject<KernelErrors[]> } = {};

    //this is to set up the kernel tab changes 
    initTabChanges(tabs) {
        for (var i in tabs) {

            this.errorsDetectedChange[tabs[i].name] = new Subject<any[]>();
            this.errorsDetected[tabs[i].name] = [];
        }
    }

    //Returns the number of anomalies and correct records for the kernelian workflows
    //called in analysis menu 
    getKernelStatsCount(workflow, segAttributeValue, tab) {

        const url = this.webUrl;
        const webservice = "StatKernelCountTables";
        const completeUrl = url + webservice;

        const headers = this.appService.getHeaders();

        this.appService.startSpin();
        //Create new HttpParams
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("wfName", workflow)
            .set("segAttrValue", segAttributeValue)

        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(
                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {

                response = JSON.parse(response);

                if (response.statusCode > -1) {
                    this.errorsDetected[tab] = response.tableRows;
                    this.errorsDetectedChange[tab].next([...this.errorsDetected[tab]]);
                }
                else {
                    this.appService.showMessage('Error', response.statusText);
                }
                this.appService.stopSpin();
            }, (error) => {
                this.appService.showMessage('Error', error.statusText);
                this.appService.stopSpin();
            });
    }

    //get the number of records for kernel combinations
    //called in 
    getKernelCombinationNbRec(tab) {

        let errorFile = { "Rules": 0, "Missing": 0, "Duplicates": 0, "Benford": 0, "Correct": 0, "AllAnomaly": 0 };
        for (var i in this.errorsDetected[tab]) {

            switch (Object.keys(this.errorsDetected[tab][i])[0]) {
                case 'Rules':
                    errorFile.Rules = this.errorsDetected[tab][i]["Rules"];
                    break;
                case 'Missing':
                    errorFile.Missing = this.errorsDetected[tab][i]["Missing"];
                    break;
                case 'Duplicates':
                    errorFile.Duplicates = this.errorsDetected[tab][i]["Duplicates"];
                    break;
                case 'Benford':
                    errorFile.Benford = this.errorsDetected[tab][i]["Benford"];
                    break;
                case 'Correct':
                    errorFile.Correct = this.errorsDetected[tab][i]["Correct"];
                    break;
                case 'AllAnomaly':
                    errorFile.AllAnomaly = this.errorsDetected[tab][i]["AllAnomaly"];
                    break;
                case 'FielID':
                    break;
                default:
                    break;
            }
        }
        var nbRec = 0;
        if (this.errorsDetected[tab] !== undefined) {
            nbRec = errorFile.AllAnomaly + errorFile.Correct - errorFile.Missing - errorFile.Rules;
        }
        return nbRec;
    }

}