import { Injectable } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CustomHttpParamEncoder } from 'src/app/shared/custom-http-param-encoder';


@Injectable()
export class SourceService {

  constructor(private appService: AppService,
    private httpClient: HttpClient) { }


  webUrl = this.appService.getWebUrl();

  statAllSourceAttributes: any[];
  //called in new workflow component
  statAllSourceAttributesChange = new Subject<any[]>();
  statAllSourceAttributesData: any[];
  //called in new workflow component
  statAllSourceAttributesDataChange = new Subject<any[]>();
  statAllSourceAttributesTableData: any[];
  //called in new workflow component
  statAllSourceAttributesTableDataChange = new Subject<any[]>();

  //Returns data for histograms of all the attribute values.
  //called in new workflow component
  getStatSourceAllAttributesHistos(source, sourceFromWFName) {

    const url = this.webUrl;

    const webservice = "StatSourceAllAttributesHistos";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();
    //Create new HttpParams
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("srcName", source)
      .set("sourceFromWFName", sourceFromWFName)


    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.statAllSourceAttributes = response.tableRows;
          this.statAllSourceAttributesChange.next(this.statAllSourceAttributes);
          this.statAllSourceAttributesData = response.tableRows2;
          this.statAllSourceAttributesDataChange.next(this.statAllSourceAttributesData);
          this.statAllSourceAttributesTableData = response.tableRows3;
          this.statAllSourceAttributesTableDataChange.next(this.statAllSourceAttributesTableData);
        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });
  }

}