/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./cardabel-selection-list.component";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "@angular/cdk/observers";
import * as i5 from "@angular/material/core";
import * as i6 from "primeng/components/progressbar/progressbar";
import * as i7 from "@angular/cdk/bidi";
import * as i8 from "@angular/platform-browser";
import * as i9 from "@angular/material/icon";
import * as i10 from "@angular/material/form-field";
import * as i11 from "@angular/cdk/platform";
import * as i12 from "@angular/cdk/text-field";
import * as i13 from "@angular/material/input";
import * as i14 from "../tools/cardabel-search-box/cardabel-search-box.component";
import * as i15 from "../cardabel-tooltip/cardabel-tooltip.directive";
import * as i16 from "./cardabel-selection-list.component.scss.ngstyle";
import * as i17 from "./cardabel-selection-list-item/cardabel-selection-list-item.component.ngfactory";
import * as i18 from "./cardabel-selection-list-item/cardabel-selection-list-item.component";
import * as i19 from "../tools/cardabel-search-box/cardabel-search-box.component.ngfactory";
var CardabelSelectionListModuleNgFactory = i0.ɵcmf(i1.CardabelSelectionListModule, [], function (_l) { return i0.ɵmod([i0.ɵmpd(512, i0.ComponentFactoryResolver, i0.ɵCodegenComponentFactoryResolver, [[8, []], [3, i0.ComponentFactoryResolver], i0.NgModuleRef]), i0.ɵmpd(4608, i2.NgLocalization, i2.NgLocaleLocalization, [i0.LOCALE_ID, [2, i2.ɵangular_packages_common_common_a]]), i0.ɵmpd(4608, i3.FormBuilder, i3.FormBuilder, []), i0.ɵmpd(4608, i3.ɵangular_packages_forms_forms_j, i3.ɵangular_packages_forms_forms_j, []), i0.ɵmpd(4608, i4.MutationObserverFactory, i4.MutationObserverFactory, []), i0.ɵmpd(4608, i5.ErrorStateMatcher, i5.ErrorStateMatcher, []), i0.ɵmpd(1073742336, i2.CommonModule, i2.CommonModule, []), i0.ɵmpd(1073742336, i3.ɵangular_packages_forms_forms_bc, i3.ɵangular_packages_forms_forms_bc, []), i0.ɵmpd(1073742336, i3.ReactiveFormsModule, i3.ReactiveFormsModule, []), i0.ɵmpd(1073742336, i3.FormsModule, i3.FormsModule, []), i0.ɵmpd(1073742336, i6.ProgressBarModule, i6.ProgressBarModule, []), i0.ɵmpd(1073742336, i7.BidiModule, i7.BidiModule, []), i0.ɵmpd(1073742336, i5.MatCommonModule, i5.MatCommonModule, [[2, i5.MATERIAL_SANITY_CHECKS], [2, i8.HAMMER_LOADER]]), i0.ɵmpd(1073742336, i9.MatIconModule, i9.MatIconModule, []), i0.ɵmpd(1073742336, i4.ObserversModule, i4.ObserversModule, []), i0.ɵmpd(1073742336, i10.MatFormFieldModule, i10.MatFormFieldModule, []), i0.ɵmpd(1073742336, i11.PlatformModule, i11.PlatformModule, []), i0.ɵmpd(1073742336, i12.TextFieldModule, i12.TextFieldModule, []), i0.ɵmpd(1073742336, i13.MatInputModule, i13.MatInputModule, []), i0.ɵmpd(1073742336, i14.CardabelSearchBoxModule, i14.CardabelSearchBoxModule, []), i0.ɵmpd(1073742336, i15.CardabelTooltipModule, i15.CardabelTooltipModule, []), i0.ɵmpd(1073742336, i1.CardabelSelectionListModule, i1.CardabelSelectionListModule, [])]); });
export { CardabelSelectionListModuleNgFactory as CardabelSelectionListModuleNgFactory };
var styles_CardabelSelectionListComponent = [i16.styles];
var RenderType_CardabelSelectionListComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CardabelSelectionListComponent, data: {} });
export { RenderType_CardabelSelectionListComponent as RenderType_CardabelSelectionListComponent };
function View_CardabelSelectionListComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "button", [["class", "nopadding"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectAllOptions(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-check"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.multipleSelection; _ck(_v, 0, 0, currVal_0); }); }
function View_CardabelSelectionListComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "button", [["class", "nopadding"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectAllOptions(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-square"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.multipleSelection; _ck(_v, 0, 0, currVal_0); }); }
function View_CardabelSelectionListComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cardabel-selection-list-item", [], null, [[null, "emitClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("emitClick" === en)) {
        var pd_0 = (_co.selectOption(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i17.View_CardabelSelectionListItemComponent_0, i17.RenderType_CardabelSelectionListItemComponent)), i0.ɵdid(1, 114688, null, 0, i18.CardabelSelectionListItemComponent, [i0.ChangeDetectorRef], { option: [0, "option"], optionLabel: [1, "optionLabel"], selectedOption: [2, "selectedOption"] }, { emitClick: "emitClick" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.optionLabel; var currVal_2 = ((_co.optionLabel != "") ? _co.activeSelectedOptions[_v.context.$implicit[_co.optionLabel]] : _co.activeSelectedOptions[_v.context.$implicit]); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_CardabelSelectionListComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 15, "div", [["class", "cardabel-selection-list-container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 9, "div", [["class", "row cardabel-selection-list-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "div", [["class", "col-md-1"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CardabelSelectionListComponent_1)), i0.ɵdid(4, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CardabelSelectionListComponent_2)), i0.ɵdid(6, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 2, "div", [["class", "col-md-9"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "cardabel-search-box", [], null, [[null, "filter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("filter" === en)) {
        _co.filteredOptions = $event.value;
        var pd_0 = (_co.checkAllOptionsSelected() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i19.View_CardabelSearchBoxComponent_0, i19.RenderType_CardabelSearchBoxComponent)), i0.ɵdid(9, 114688, null, 0, i14.CardabelSearchBoxComponent, [], { title: [0, "title"], options: [1, "options"], optionLabel: [2, "optionLabel"] }, { filter: "filter" }), (_l()(), i0.ɵeld(10, 0, null, null, 0, "div", [["class", "col-md-1"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 4, "div", [["class", "cardabel-selection-list"]], null, null, null, null, null)), i0.ɵdid(12, 278528, null, 0, i2.NgStyle, [i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i0.ɵpod(13, { "height": 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CardabelSelectionListComponent_3)), i0.ɵdid(15, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.allOptionsSelected; _ck(_v, 4, 0, currVal_0); var currVal_1 = !_co.allOptionsSelected; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.title; var currVal_3 = _co.options; var currVal_4 = _co.optionLabel; _ck(_v, 9, 0, currVal_2, currVal_3, currVal_4); var currVal_5 = _ck(_v, 13, 0, (("calc(100vh - " + _co.height) + "px)")); _ck(_v, 12, 0, currVal_5); var currVal_6 = _co.filteredOptions; _ck(_v, 15, 0, currVal_6); }, null); }
export function View_CardabelSelectionListComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cardabel-selection-list", [], null, null, null, View_CardabelSelectionListComponent_0, RenderType_CardabelSelectionListComponent)), i0.ɵdid(1, 638976, null, 0, i1.CardabelSelectionListComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CardabelSelectionListComponentNgFactory = i0.ɵccf("cardabel-selection-list", i1.CardabelSelectionListComponent, View_CardabelSelectionListComponent_Host_0, { title: "title", multipleSelection: "multipleSelection", options: "options", optionLabel: "optionLabel", height: "height", inputSelectedOptions: "inputSelectedOptions" }, { changeSelectedOptions: "changeSelectedOptions" }, []);
export { CardabelSelectionListComponentNgFactory as CardabelSelectionListComponentNgFactory };
