import { ApplicationRef, enableProdMode } from '@angular/core';
import { enableDebugTools } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

 if (environment.production) {
  enableProdMode();
  if(window){
    window.console.log = window.console.warn = window.console.info = function(){
      // Don't log anything.
   };
  }
} 
platformBrowserDynamic().bootstrapModule(AppModule)
  .then(moduleRef => {
    const applicationRef = moduleRef.injector.get(ApplicationRef);
    const componentRef = applicationRef.components[0];
    // allows to run `ng.profiler.timeChangeDetection();`
    enableDebugTools(componentRef);
  })
  .catch(err => console.log(err));
  
/* platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err)); */
