import { Injectable } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { HttpClient, HttpParams} from '@angular/common/http';
import { Subject } from 'rxjs';
import { AdminGetLogoutTime } from './admin-get-logout-time.model';
import { AdminSetLogoutTime } from './admin-set-logout-time.model';
import { catchError } from 'rxjs/operators';
import { CustomHttpParamEncoder } from 'src/app/shared/custom-http-param-encoder';

@Injectable()
export class LogoutTimeService {

    constructor(
        private appService: AppService,
        private httpClient: HttpClient,
    ){}

    logoutTime:number = 40000;
    logoutTimeChange =new Subject<number>();

    webUrl = this.appService.getWebUrl();
    //Gets the logout time.
    //called in logout time component
    getLogoutTime() {
  
        const url = this.webUrl;
        const webservice = "AdminGetLogoutTime";
        const completeUrl = url+webservice;

        const headers = this.appService.getHeaders();
        this.appService.startSpin();

        //Create new HttpParams */
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() });
 
        return this.httpClient.post(completeUrl, params, {headers, responseType:'text', withCredentials:true})
          .pipe(
            catchError(this.appService.handleError)
          )
          .subscribe((response:any) => {

            response = JSON.parse(response);
            if (response.statusCode > -1 ) {
                this.logoutTime = response.tableRows[0].logoutTime;
                this.logoutTimeChange.next(this.logoutTime);
            }
            else {
              this.appService.showMessage('Error', response.statusText)          
            }
            this.appService.stopSpin();
          }, (error) => {
            this.appService.showMessage('Error', error.statusText)          
            this.appService.stopSpin();
          });
    }

    //Sets the logout time.
    //called in logout time component
    setLogoutTime(logoutTime) { 

        const url = this.webUrl;
        const webservice = "AdminSetLogoutTime";
        const completeUrl = url+webservice;

        const headers = this.appService.getHeaders();
        
        this.appService.startSpin();

        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
        .set("logoutTime",logoutTime);

        return this.httpClient.post(completeUrl, params, {headers, responseType:'text', withCredentials:true})
          .pipe(
            catchError(this.appService.handleError)
          )
          .subscribe((response:any) => {

            response = JSON.parse(response);
            if (response.statusCode > -1 ) {
                this.appService.showMessage('Success', response.statusText); 
                this.logoutTimeChange.next(logoutTime);       
            }
            else {
              this.appService.showMessage('Error', response.statusText)          
            }
            this.appService.stopSpin();
          }, (error) => {
            this.appService.showMessage('Error', error.statusText)          
            this.appService.stopSpin();
          });
    }
       
    
}