import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { SharedInitializationService } from 'src/app/shared/shared-common-initialization.service';

@Component({
  selector: 'app-admin-start',
  templateUrl: './admin-start.component.html',
  styleUrls: ['./admin-start.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminStartComponent implements OnInit {

  constructor(private sharedService: SharedInitializationService) { }

  ngOnInit() {
  }
 //this allows to get the help documentation for the admin start
 getHelpDocumentation() {
  let parameters = ["admin"];
  this.sharedService.showHelpDocumentation("Admin");

  this.sharedService.getHelpDocumentation(parameters)
}
}
