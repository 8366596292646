import { Component, OnInit, OnDestroy, ViewRef, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AuthorizationsService } from '../../authorizations/authorizations.service';
import { PackagesService } from '../packages.service';


@Component({
  selector: 'app-topics',
  templateUrl: './topics.component.html',
  styleUrls: ['./topics.component.scss']
})
export class TopicsComponent implements OnInit, OnDestroy {

  //initialize nature of logs
  viewNatures = [
    { label: 'Topics view', value: 'Topics view' },
    { label: 'Workflows view', value: 'Workflows view' }
  ];
  selectedViewNature: string = 'Topics view';
  //variable to get all the topics (active and inactive)
  topics: any[] = [];
  filteredTopics: any[] = [];
  allShownTopics: boolean = true;
  //subscription to get all the topics when updated
  topicsSubscription = new Subscription();

  //form control to initialize the selected topic in the list
  topic: any[] = []

  //all the possible workflows
  workflows: any[] = [];
  filteredWorkflows: any[] = [];
  //subscription so that workflows are automatically updated
  workflowsSubscription = new Subscription();
  //form control to initialize the selected topic in the list
  workflow: any[] = []


  constructor(private authorizationsServices: AuthorizationsService,
    private packagesService: PackagesService,
    private router: Router,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef) { }

  ngOnInit() {
    //allows to get all the active and inactive topics, all the sources, webservices, workflows and users
    this.authorizationsServices.getAllWorkflows();
    this.packagesService.getTopicData();

    //allows to update the topics when one is changed // created
    this.topicsSubscription = this.packagesService.topicsChange
      .subscribe((topics: any) => {
        this.topics = topics;

        this.filterActiveWorkflowTopic('topic', 'Topics', 'topicName');
        for (var i in this.route.children) {
          if (this.route.children[i].snapshot.url[0]) {
            if (this.route.children[i].snapshot.url[0].path === "new") {
              this.router.navigate(["admin", "settings", "topics"]);
            }
          }
        }
        this.cd.detectChanges();

      })

    this.workflowsSubscription = this.authorizationsServices.workflowsChange
      .subscribe((workflows: any) => {
        this.workflows = workflows;
        this.filterActiveWorkflowTopic('workflow', 'Workflows', 'WFName');
        if (this.cd && !(this.cd as ViewRef).destroyed) {
          this.cd.detectChanges();
        }
      })

  }// ng_init


  changeWorkflowTopic(param, optionLabel) {
    //allows to update the selected topic and navigate to its specific page
    if (this[param].length != 0) {
      this.router.navigate(["admin", "settings", "topics", param, this[param][0][optionLabel]])
    }

    else {
      this.router.navigate(["admin", "settings", "topics"])
    }
  }

  //filter active or all topics
  filterActiveWorkflowTopic(param, data_name, option_label) {
    this[param] = [];
    let filteredData = "filtered" + data_name
    this[filteredData] = JSON.parse(JSON.stringify(this[data_name.toLowerCase()]))
    this.changeWorkflowTopic(param, option_label)
  }

  //allows to clear the form control when clicking on new
  navigateNewTopic() {
    this.topic = [];
    this.changeWorkflowTopic('topic', 'topicName')
    this.router.navigate(['admin', 'settings', 'topics', 'new'])
  }
  viewNatureChange() {
    this.topic = []
    this.changeWorkflowTopic('topic', 'topicName')
    this.workflow = []
    this.changeWorkflowTopic('workflow', 'WFName')
    this.router.navigate(['admin', 'settings', 'topics'])
  }
  //this is to destroy subscriptions when component is destroyed
  ngOnDestroy() {
    this.topicsSubscription.unsubscribe();
    this.workflowsSubscription.unsubscribe();
  }

}
