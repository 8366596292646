/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./topics-dashboard-topic-card.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./topics-dashboard-topic-card-item/topics-dashboard-topic-card-item.component.ngfactory";
import * as i3 from "./topics-dashboard-topic-card-item/topics-dashboard-topic-card-item.component";
import * as i4 from "../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i5 from "@angular/material/card";
import * as i6 from "../../shared/cardabel-tooltip/cardabel-tooltip.directive";
import * as i7 from "@angular/cdk/overlay";
import * as i8 from "@angular/common";
import * as i9 from "./topics-dashboard-topic-card.component";
var styles_TopicsDashboardTopicCardComponent = [i0.styles];
var RenderType_TopicsDashboardTopicCardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TopicsDashboardTopicCardComponent, data: {} });
export { RenderType_TopicsDashboardTopicCardComponent as RenderType_TopicsDashboardTopicCardComponent };
function View_TopicsDashboardTopicCardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "row topics-dashboard-secondary-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "cardabel-topics-dashboard-topic-card-item", [["class", "col-md-6"]], null, null, null, i2.View_TopicsDashboardTopicCardItemComponent_0, i2.RenderType_TopicsDashboardTopicCardItemComponent)), i1.ɵdid(2, 114688, null, 0, i3.TopicsDashboardTopicCardItemComponent, [], { itemTitle: [0, "itemTitle"], itemNumber: [1, "itemNumber"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "cardabel-topics-dashboard-topic-card-item", [["class", "col-md-6"]], null, null, null, i2.View_TopicsDashboardTopicCardItemComponent_0, i2.RenderType_TopicsDashboardTopicCardItemComponent)), i1.ɵdid(4, 114688, null, 0, i3.TopicsDashboardTopicCardItemComponent, [], { itemTitle: [0, "itemTitle"], itemNumber: [1, "itemNumber"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Anomaly predictions"; var currVal_1 = _co.topic[_co.topicNames[_co.index]][0]["AnomalyPredictions"]; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = "Workflows owned"; var currVal_3 = _co.topic[_co.topicNames[_co.index]][0]["Owned"]; _ck(_v, 4, 0, currVal_2, currVal_3); }, null); }
function View_TopicsDashboardTopicCardComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "row topics-dashboard-secondary-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "cardabel-topics-dashboard-topic-card-item", [["class", "col-md-6"]], null, null, null, i2.View_TopicsDashboardTopicCardItemComponent_0, i2.RenderType_TopicsDashboardTopicCardItemComponent)), i1.ɵdid(2, 114688, null, 0, i3.TopicsDashboardTopicCardItemComponent, [], { itemTitle: [0, "itemTitle"], itemNumber: [1, "itemNumber"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "cardabel-topics-dashboard-topic-card-item", [["class", "col-md-6"]], null, null, null, i2.View_TopicsDashboardTopicCardItemComponent_0, i2.RenderType_TopicsDashboardTopicCardItemComponent)), i1.ɵdid(4, 114688, null, 0, i3.TopicsDashboardTopicCardItemComponent, [], { itemTitle: [0, "itemTitle"], itemNumber: [1, "itemNumber"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Total Records"; var currVal_1 = _co.topic[_co.topicNames[_co.index]][0]["Records in"]; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = "Sources"; var currVal_3 = _co.topic[_co.topicNames[_co.index]][0]["Sources"]; _ck(_v, 4, 0, currVal_2, currVal_3); }, null); }
export function View_TopicsDashboardTopicCardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "mat-card", [["class", "example-card topics-dashboard-card mat-card"]], null, null, null, i4.View_MatCard_0, i4.RenderType_MatCard)), i1.ɵdid(1, 49152, null, 0, i5.MatCard, [], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 8, "mat-card-header", [["class", "mat-card-header"]], null, null, null, i4.View_MatCardHeader_0, i4.RenderType_MatCardHeader)), i1.ɵdid(3, 49152, null, 0, i5.MatCardHeader, [], null, null), (_l()(), i1.ɵeld(4, 0, null, 1, 6, "mat-card-title", [["class", "topics-dashboard-title mat-card-title"]], null, null, null, null, null)), i1.ɵdid(5, 16384, null, 0, i5.MatCardTitle, [], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "span", [["class", "topics-dashboard-title-name"]], null, [[null, "mouseenter"], [null, "mouseleave"], [null, "click"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).show() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 7).hide() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (i1.ɵnov(_v, 7).hideClick() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 81920, null, 0, i6.CardabelTooltipDirective, [i7.Overlay, i7.OverlayPositionBuilder, i1.ElementRef, i1.Renderer2], { text: [0, "text"] }, null), (_l()(), i1.ɵted(8, null, ["", " "])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["class", "topics-dashboard-title-content"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", " workflows "])), (_l()(), i1.ɵeld(11, 0, null, 0, 8, "mat-card-content", [["class", "mat-card-content"]], null, null, null, null, null)), i1.ɵdid(12, 16384, null, 0, i5.MatCardContent, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TopicsDashboardTopicCardComponent_1)), i1.ɵdid(14, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 2, "div", [["class", "row topics-dashboard-secondary-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "div", [["class", "offset-md-4 col-md-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TopicsDashboardTopicCardComponent_2)), i1.ɵdid(19, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.topicNames[_co.index]; _ck(_v, 7, 0, currVal_0); var currVal_3 = !_co.showOpenClosed; _ck(_v, 14, 0, currVal_3); var currVal_4 = !_co.showOpenClosed; _ck(_v, 19, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.topicNames[_co.index]; _ck(_v, 8, 0, currVal_1); var currVal_2 = _co.topic[_co.topicNames[_co.index]][0].Workflows.length; _ck(_v, 10, 0, currVal_2); }); }
export function View_TopicsDashboardTopicCardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "cardabel-topics-dashboard-topic-card", [], null, null, null, View_TopicsDashboardTopicCardComponent_0, RenderType_TopicsDashboardTopicCardComponent)), i1.ɵdid(1, 114688, null, 0, i9.TopicsDashboardTopicCardComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TopicsDashboardTopicCardComponentNgFactory = i1.ɵccf("cardabel-topics-dashboard-topic-card", i9.TopicsDashboardTopicCardComponent, View_TopicsDashboardTopicCardComponent_Host_0, { topic: "topic", topicNames: "topicNames", index: "index", openClosed: "openClosed", showOpenClosed: "showOpenClosed" }, {}, []);
export { TopicsDashboardTopicCardComponentNgFactory as TopicsDashboardTopicCardComponentNgFactory };
