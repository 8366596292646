<mat-card class="example-card topics-dashboard-card">
  <mat-card-header>
    <mat-card-title class="topics-dashboard-title">
      <span class="topics-dashboard-title-name" [cardabelTooltip]="topicNames[index]">{{topicNames[index]}} </span> <span
        class="topics-dashboard-title-content">{{topic[topicNames[index]][0].Workflows.length}} workflows
      </span></mat-card-title>
  </mat-card-header>
  <mat-card-content>


    <div class="row topics-dashboard-secondary-container" *ngIf="!showOpenClosed">
        <cardabel-topics-dashboard-topic-card-item class="col-md-6" [itemNumber]="topic[topicNames[index]][0]['AnomalyPredictions']" [itemTitle]="'Anomaly predictions'"></cardabel-topics-dashboard-topic-card-item>
        <cardabel-topics-dashboard-topic-card-item class="col-md-6" [itemNumber]="topic[topicNames[index]][0]['Owned']" [itemTitle]="'Workflows owned'"></cardabel-topics-dashboard-topic-card-item>
    </div>

    <div class="row topics-dashboard-secondary-container">
      <div class="offset-md-4 col-md-4">
        <hr>
      </div>
    </div>

   

    
    <!--<div class="row topics-dashboard-secondary-container topics-dashboard-progress-bar-container">
      <div class="col-md-12 topics-dashboard-progress-bar">

        <div class="topics-dashboard-progress-bar-content"
          [style.minWidth]="topic[topicNames[index]][0].OwnerProgress+'%'" [style.maxWidth]="100+'%'"></div>
        <div class="topics-dashboard-progress-bar-text">Owned & Assigned</div>
      </div>

    </div>
    <div class="row topics-dashboard-secondary-container">
      <div class="col-md-6 ">
        <div class="row">
          <div class="col-md-12 topics-dashboard-secondary-number">
            {{topic[topicNames[index]][0]['Open topic'] | number: '1.0-0'}}
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 topics-dashboard-predictions-text">
            Open
          </div>
        </div>

      </div>
      <div class="col-md-6 ">
        <div class="row">
          <div class="col-md-12 topics-dashboard-secondary-number">
            {{topic[topicNames[index]][0]['Closed topic'] | number: '1.0-0'}}
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 topics-dashboard-predictions-text">
            Closed
          </div>
        </div>

      </div>
    </div>
    <div class="row topics-dashboard-secondary-container topics-dashboard-progress-bar-container">
      <div class="col-md-12 topics-dashboard-progress-bar">

        <div class="topics-dashboard-progress-bar-content"
          [style.minWidth]="topic[topicNames[index]][0].TopicProgress+'%'" [style.maxWidth]="100+'%'"></div>
        <div class="topics-dashboard-progress-bar-text">All users</div>
      </div>

    </div>-->
    <div class="row topics-dashboard-secondary-container" *ngIf="!showOpenClosed">
        <cardabel-topics-dashboard-topic-card-item class="col-md-6" [itemNumber]="topic[topicNames[index]][0]['Records in']" [itemTitle]="'Total Records'"></cardabel-topics-dashboard-topic-card-item>
        <cardabel-topics-dashboard-topic-card-item class="col-md-6" [itemNumber]="topic[topicNames[index]][0]['Sources']" [itemTitle]="'Sources'"></cardabel-topics-dashboard-topic-card-item>
    </div>
  </mat-card-content>

</mat-card>