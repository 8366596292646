import { Injectable, ViewRef } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import * as d3 from 'd3';
import { AppService } from 'src/app/app.service';
import { DashboardService } from 'src/app/dashboard/dashboard.service';
import { SharedGeneralFunctionsService } from 'src/app/shared/shared-general-functions.service';



@Injectable()
export class DashboardBarChartService {

    constructor(private httpClient: HttpClient,
        private appService: AppService,
        private router: Router,
        private dashboardService: DashboardService,
        private sharedGeneralService: SharedGeneralFunctionsService
    ) { }


    //bar axis
    getBarXandY(vis) {
        
        if (!vis.barYaxis) {
            vis.barYaxis = vis.barG
                .append("g")
                .attr("class", "y axis bar")
                .attr("text-anchor", "end");
        }

        let scales = [
            {graphType:"bar", name: "y", axisName:"barYaxis", type: d3.scaleBand(), scaleType:"band", range: vis.height, variable: vis.yVariable, axisOrientation: d3.axisLeft(), dividor: vis.yDividor},
            {graphType:"bar", name: "x", axisName: "xAxis", type: d3.scaleLinear(), scaleType: "linear", range: vis.width, variable: vis.xVariable, axisOrientation: d3.axisBottom(), dividor: vis.xDividor},
         
        ]
        vis.dashboardInitTypesScalesService.setScales(scales, vis, vis.dataFiltered)



        if (d3.min(vis.dataFiltered, function (d) { return d[vis.xVariable] }) >= 0) {
            vis.yAxisCall = d3.axisLeft()
                .ticks(4)
        }
        else {
            vis.yAxisCall = d3.axisRight()
                .ticks(4)

        }

       // vis.yDividor = Math.ceil(vis.chartData.length / (vis.height / 15));

        console.log(vis.x.domain())
        if (vis.barYaxis) {
            vis.barYaxis
            .attr("transform", "translate(" + '0' + ",0)")
        }

        if (vis.x.domain()[0] < 0 && vis.barYaxis) {

            if (!vis.xAxisValues.changed) {
                vis.x.domain()[0] = -Math.max(Math.abs(vis.x.domain()[0]), Math.abs(vis.x.domain()[1]));
                vis.x.domain()[1] = -vis.x.domain()[0];
            }
            vis.barYaxis
                .attr("transform", "translate(" + vis.x(0) + ",0)")


        }



        return vis;

    }


    // set bar chart
    setRects(vis) {

        let data = vis.dataFiltered.filter(function (k) {
            if (vis.filtered) {
                if (vis.filtered.indexOf(vis.xVariable) === -1) {
                    return k[vis.yVariable]
                }

            }
            else {
                return k[vis.yVariable]
            };
        });

        vis.g1.selectAll("rect").remove()

        vis.rects = vis.g1
            .selectAll("rect").data(data)


        // EXIT old elements not present in new data.
        vis.rects.exit()
            .attr("class", "exit")
            .transition(vis.t())
            .attr("height", 0)
            .attr("y", vis.height)
            .style("fill-opacity", "0.1")
            .remove();

        // UPDATE old elements present in new data.
        vis.rects.attr("class", "update")
            .transition(vis.t())
            .attr("y", function (d) { return vis.y(d[vis.yVariable]); })
            .attr("id", function (d, i) { return vis.type + vis.mainTab + vis.tabAlias + vis.header + vis.graphIndex + i }) // return d[vis.yVariable] })
            .attr("height", vis.y.bandwidth)
            .attr("x", function (d) { return vis.x(Math.min(0, d[vis.xVariable])); })
            .attr("width", function (d) { return Math.abs(vis.x(d[vis.xVariable]) - vis.x(0)); })

        let colorChart = vis.color;
        console.log(colorChart);

        vis.rects.enter()
            .append("rect")
            .attr("class", "enter")
            .attr("id", function (d, i) { return vis.type + vis.mainTab + vis.tabAlias + vis.header + vis.graphIndex + i })
            .attr("y", function (d) { return (vis.y(d[vis.yVariable])) })
            .attr("width", 0)
            .attr("height", vis.y.bandwidth)
            .attr("x", 0)
            .attr("fill", vis.color)
            .on("mouseout", function (d) {
                d3.selectAll(".toolTip").remove()

            })


            .transition(vis.t())
            .attr("width", function (d) { return Math.abs(vis.x(d[vis.xVariable]) - vis.x(0)); })
            .attr("x", function (d) { return vis.x(Math.min(0, d[vis.xVariable])); })


        // vis.yAxis
        //    .attr("transform", "translate(" + vis.x(0) + ",0)")



        vis.yAxisCall
            .tickFormat(function (d, i) {
                /*  if (!d) {
                      return d;
                  }
                  else if ((typeof d === 'number') || (d.toString() === "0") || (!isNaN(d))) {
                      d = Intl.NumberFormat('en-US', { minimumFractionDigits: vis.numericDecimal, maximumFractionDigits: vis.numericDecimal }).format(d)
                      //return d;
                  }
                  else if (!isNaN(new Date(d).getDate())) {
                      d = vis.multiFormat(d);
                  } */
                d = vis.dashboardAxisFormattingService.getTickFormat(d, vis)

                d = vis.dashboardAxisFormattingService.getValueEllipsis(d, vis);

                return i % vis.yDividor == 0 ? d : "";
            })


        /* .html("")
         .append('tspan').text(function(d) {
           return d;
         })*/

        // vis.g.selectAll(".y axis").remove();
        //vis.g1.selectAll(".y axis").remove();

        if (!vis.barYaxis) {
            vis.barYaxis = vis.barG
                .append("g")
                .attr("class", "y axis bar")
                .attr("text-anchor", "end");
        }


        vis.yAxisCall.scale(vis.y);

        console.log(vis.x.domain())
        vis.barYaxis.transition(vis.t()).call(vis.yAxisCall);

        vis.barYaxis.selectAll("text")
            .attr("transform", "translate(" + 0 + ",0)")
            .attr("text-anchor", "end");

        if (vis.x.domain()[0] < 0) {
            vis.x.domain()[0] = -Math.max(Math.abs(vis.x.domain()[0]), Math.abs(vis.x.domain()[1]));
            vis.x.domain()[1] = -vis.x.domain()[0];

            vis.barYaxis
                .attr("transform", "translate(" + vis.x(0) + ",0)")
            //.attr("text-anchor", "end");

            let textPosition = -vis.x(0) - vis.margin.left;
            vis.barYaxis
                .selectAll("text")
                .attr("transform", "translate(" + textPosition + ",0)")
                .attr("text-anchor", "start")
        }

   /*     vis.barYaxis.selectAll("line")
            .style("stroke", "#bbbbbb");
        vis.barYaxis.selectAll("path")
            .style("stroke", "#bbbbbb");
        vis.barYaxis.selectAll("text")
            .style("fill", "rgb(84, 84, 84)")
        // .attr("text-anchor", "end"); */


        return vis;

    }
}