import { Injectable } from '@angular/core';


@Injectable()
export class WorkflowNewDimService {
  constructor() { }


  getNewNum(vis, newDim: any, formatDate) {

    if (vis.newNumComponent.selectedNewNum.length != 0)
      newDim.formula = vis.newNumComponent.selectedNewNum[0].formula;
    if (newDim.formula.toLowerCase().indexOf('fixed') > -1) {
      newDim.dateFixed = formatDate(vis.newNumComponent.newNum_dateFixed, 'yyyyMMdd', 'en');
    }
    else {
      newDim.dateFixed = "";
    }

    let attributes = [
      { newNumField: 'newNum_ccyField', newDimField: 'ccyField' },
      { newNumField: 'newNum_dateField', newDimField: 'dateField' },
      { newNumField: 'newNum_ccyREP', newDimField: 'ccyREP' }
    ]
    if (newDim.formula === "Copy") {
      attributes.push({ newNumField: 'newNum_copyField', newDimField: 'numField' });
    }
    else {
      attributes.push({ newNumField: 'newNum_numField', newDimField: 'numField' });
    }

    for (var i in attributes) {
      if (vis.newNumComponent[attributes[i].newNumField].length != 0) {
        newDim[attributes[i].newDimField] = vis.newNumComponent[attributes[i].newNumField][0].AttrName
      }
      else {
        newDim[attributes[i].newDimField] = "";
      }
    }

    newDim.fxPair = vis.newNumComponent.newNumPairBtn;


    if (newDim.formula === "Custom") {

      var numField = [];
      for (var i in vis.newNumComponent.newNumFunctionArray) {
        numField.push(vis.newNumComponent.newNumFunctionArray[i].AttrName);
      }

      newDim.numField = { 'AttrName': numField };

    }
    else if (newDim.formula === "Copy" && vis.newNum_copyField.length != 0) {
      newDim.numField = vis.newNumComponent.newNum_copyField[0].AttrName;
    }

    newDim.customParam = {};
    if (newDim.formula.toLowerCase().indexOf('sequential') > -1) {
      if (vis.newNumComponent.newNum_XAttr.length > 0) {
        newDim.customParam.XAttr = vis.newNumComponent.newNum_XAttr[0].AttrName;
      }
      else {
        newDim.customParam.XAttr = ""
      }
      if (vis.newNumComponent.newNum_SeqAttr.length > 0) {
        newDim.customParam.SeqAttr = vis.newNumComponent.newNum_SeqAttr[0].AttrName;
      }
      else {
        newDim.customParam.SeqAttr = "";
      }

      newDim.customParam.XStart = vis.newNumComponent.newNum_XStart.value;
      newDim.customParam.XEnd = vis.newNumComponent.newNum_XEnd.value;
      newDim.customParam.Segreg = vis.newNumComponent.newNumSeqBtn;
      newDim.customParam.SegregAttr = [];
      for (var i in vis.newNumSegregArray) {
        newDim.customParam.SegregAttr.push(vis.newNumSegregArray[i].AttrName);
      }
    }

    newDim.customFunc = vis.newNumComponent.newDim_customFunc;
    return newDim;
  }
}