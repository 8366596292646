import { Injectable } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { WorkflowNameService } from './workflow-name.service';
import { CustomHttpParamEncoder } from 'src/app/shared/custom-http-param-encoder';
import { StackedBarChart } from 'src/app/shared/d3/d3stackedbarchart';


@Injectable()
export class WorkflowBayesianStepsService {
  constructor(private appService: AppService,
    private httpClient: HttpClient,
    private workflowService: WorkflowNameService) { }

  webUrl = this.appService.getWebUrl();

  multiBayesChartDataChange = new Subject<any[]>();
  preparedDataChange = new Subject<any[]>();
  analysisMultiBayesChartDataChange: { [index: string]: Subject<any[]> } = {};
  analysisPreparedDataChange: { [index: string]: Subject<any[]> } = {};

  //init tabs
  initTabChanges(tabs) {
    for (var i in tabs) {
      this.analysisMultiBayesChartDataChange[tabs[i].name] = new Subject<any[]>();
      this.analysisPreparedDataChange[tabs[i].name] = new Subject<any[]>();
    }
  }

  // allows to discover anomalies from the use of rules
  //called in rules component
  launchBayesTableRules(apply_and_chain, workflow, tableName, rulesList) {

    const url = this.webUrl;
    const webservice = "WFBayesGenerateFromRules";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();
    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("apply_and_chain", apply_and_chain)
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('rulesList', rulesList)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {

          this.multiBayesChartDataChange.next(response.tableRows);
          this.appService.showMessage('Success', response.statusText);
          this.workflowService.updateWorkflowTableSubTable(tableName, response.wfStructure, "");

        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });
  }
  //
  //Identifies simple anomalies, creates the corresponding tables and returns back the anomaly table and a table with the counts of anomalies by type.
  // called in detect simple component
  launchBayesTableDetectSimple(apply_and_chain, workflow, tableName, alpha, nbbins, inactiveForSpl, alphaTol, ignoredCombinations) {

    const url = this.webUrl;
    const webservice = "WFBayesTableDetectSimple";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();
    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("apply_and_chain", apply_and_chain)
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('alpha', alpha)
      .set('nbbins', nbbins)
      .set('inactiveForSpl', inactiveForSpl)
      .set('alphaTol', alphaTol)
      .set('ignoredCombinations', ignoredCombinations)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {

          this.multiBayesChartDataChange.next(response.tableRows);
          this.appService.showMessage('Success', response.statusText);
          this.workflowService.updateWorkflowTableSubTable(tableName, response.wfStructure, "");

        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });
  }

  //Identifies complex anomalies, updates the corresponding tables.
  //called in detect complex component
  launchBayesTableDetectComplex(workflow, tableName, K, alpha, algo, inactivePairs, gamma, alphaTol, ignoredCombinations) {

    const url = this.webUrl;
    const webservice = "WFBayesTableDetectComplex";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();
    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('K', K)
      .set('alpha', alpha)
      .set('algo', algo)
      .set('inactivePairs', inactivePairs)
      .set('gamma', gamma)
      .set('alphaTol', alphaTol)
      .set('ignoredCombinations', ignoredCombinations)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {

          this.multiBayesChartDataChange.next(response.tableRows);
          this.appService.showMessage('Success', response.statusText);
          this.workflowService.updateWorkflowTableSubTable(tableName, response.wfStructure, "");

        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });
  }

  //returns the distribution of anomalies by attribute name for the Bayes workflow
  //called in analysis grid component and in input of rule,detectpsl and detectcpx
  //getMultiBayesBarChartData(workflow, source, segAttribute, table, proMode) {
  getMultiBayesBarChartData(workflow, tableName, proMode, postfix, isCompletedNecessary, tab) {
    // from analysis neccessary to be completed

    const url = this.webUrl;
    const webservice = "AnalysisBayesGetAnomalyByVariable";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();
    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      //.set('proMode', proMode)
      .set('postfix', postfix)
      .set('isCompletedNecessary', isCompletedNecessary)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {
          if (tab === "") {
            this.multiBayesChartDataChange.next(response.tableRows);
          }
          else {
            this.analysisMultiBayesChartDataChange[tab].next(response.tableRows);
          }

        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });
  }

  // preparedata for the multibar chart
  // called from DetectCpx, DetectSpl, DetectRule and analysis

  prepareMultiBayesData(multiBayesData, tab) {

    var datapoints = [];

    var dataintermediaire = [];
    var total = [];
    var nbcorrect = 0;
    var nbanomaly = 0;
    total["Combined"] = 0;
    total["Simple"] = 0;
    total["Rule"] = 0;

    for (var j in multiBayesData) {
      var argsname = "";
      var count = 0;
      var variable = "";

      var ichart = [];

      ichart["Combined"] = 0;
      ichart["Simple"] = 0;
      ichart["Rule"] = 0;
      for (var k in multiBayesData[j]) {
        if ((k === "Rule") || (k === "Combined") || (k === "Simple"))
          argsname = k;
        else {
          count = multiBayesData[j][k];
          variable = k;
          /// quand k="NoErr" alors le skipper 
        }
      }
      if (variable === "Correct") {
        nbcorrect = count;
      }
      else {
        if (variable === "Anomaly") {
          nbanomaly = count;
        }
        else {
          if (!(variable === "NoErr")) {
            ichart["x"] = variable;
            ichart[argsname] = count;
            total[argsname] = total[argsname] + count;
            dataintermediaire.push(ichart);
          }
        }
      }
    }
    // regrouper quand x identique
    for (var i = 0; i < dataintermediaire.length; i++) {
      // verifier que pas deja pris dans datapoint
      var exist = false
      for (var p in datapoints) {
        if (datapoints[p].x === dataintermediaire[i].x)
          exist = true;
      }

      if (exist === false) {
        var point;
        point = dataintermediaire[i];
        for (var l = i + 1; l < dataintermediaire.length; l++) {
          if (dataintermediaire[l].x === point.x) {
            point["Combined"] = point["Combined"] + dataintermediaire[l]["Combined"];
            point["Simple"] = point["Simple"] + dataintermediaire[l]["Simple"];
            point["Rule"] = point["Rule"] + dataintermediaire[l]["Rule"];
          }
        }
        datapoints.push(point);
        var varname = point.x;

      }
    }

    // color dans le html
    var totalerror;
    totalerror = [];

    totalerror["Combined"] = { name: 'Combined', count: total['Combined'] };
    totalerror["Simple"] = { name: 'Simple', count: total['Simple'] };
    totalerror["Rule"] = { name: 'Rule', count: total['Rule'] };

    var datacol = new Array(totalerror.length);
    for (i = 0; i < totalerror.length; i++) {
      datacol[i] = new Array(datapoints.length)

    }
    var dataCategorie = new Array(datapoints.length);

    for (i = 0; i < datapoints.length; i++) {

      dataCategorie[i] = datapoints[i].x;
      for (var q = 0; q < totalerror.length; q++) {
        datacol[q][i] = datapoints[i][totalerror[q].name];

      }
    }

    var data = [];

    var datacolfin = [];
    for (var o = 0; o < totalerror.length; o++) {
      datacolfin[o] = [];
    }

    for (i = 0; i < datapoints.length; i++) {
      var newdata;
      newdata = {};
      newdata.Categorie = datapoints[i].x;

      newdata["Combined"] = datapoints[i]["Combined"];
      newdata["Simple"] = datapoints[i]["Simple"];
      newdata["Rule"] = datapoints[i]["Rule"];
      data.push(newdata);
    }

    var allData = [];

    allData = [].concat(allData)

    if (tab === "") {
      this.preparedDataChange.next(data);
    }
    else {
      this.analysisPreparedDataChange[tab].next(data);
    }

  }

  //allows to display the multi bar chart data
  // called from DetectCpx, DetectSpl, DetectRule
  setChartMultiDetect(tagHtml, multiBarChartData, variable, height, width) {

    var yVariable = "col";
    var title = "";
    var colorMultiBarChart = [this.appService.anomaly_combined, this.appService.anomaly_simple, this.appService.anomaly_rule, 'grey', 'blue'];
    var lines: any[] = [];

    let result = new StackedBarChart(tagHtml, yVariable, title, height, width, variable, colorMultiBarChart, lines, multiBarChartData);
    return result;
  }
}