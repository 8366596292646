import { Injectable } from '@angular/core';
import { AppService } from '../app.service';
import { HttpParams, HttpClient } from '@angular/common/http';
import { CustomHttpParamEncoder } from '../shared/custom-http-param-encoder';
import { catchError } from 'rxjs/operators';

@Injectable()
export class UserSettingsService {
    constructor(private appService: AppService,
        private httpClient: HttpClient,
    ) { }

    webUrl = this.appService.getWebUrl();

    themes: any[] = []

    //update the user password data from admin role
    //called in change password component
    updateUserPassword(username, newpassword, oldpassword) {
        this.appService.startSpin();
   
        const url = this.webUrl;
        const webservice = "UserAccessUpdatePassword";
        const completeUrl = url + webservice;

        const headers = this.appService.getHeaders();
        
        //create new http params
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("userEmailorName", username)
            .set("newuserPassword", newpassword)
            .set("olduserPassword", oldpassword)

        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(
                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {

                response = JSON.parse(response);
                if (response.statusCode > -1) {
                    this.appService.showMessage('Success', response.statusText)
                }
                else {
                    this.appService.showMessage('Error', response.statusText)
                }
                this.appService.stopSpin();
            }, (error) => {
                this.appService.showMessage('Error', error.statusText);
                this.appService.stopSpin();
            });


    }

    update_themes(theme_name: string, theme: any) {
        let themes = this.format_themes(this.themes, theme_name, theme);

        this.save_themes(themes)

    }

    format_themes(themes: any[], theme_name: string, theme: any) {
        let index = this.themes.findIndex(item => item.name === theme_name);
        if (index > -1) {
            this.themes[index].theme = theme;
        }
        else {
            this.themes.push({name: theme_name, theme: theme})
        }
    }

    delete_theme(theme_name) {
        let index = this.themes.findIndex(item => item.name === theme_name);
        this.themes.splice(index, 1);
    }

    save_themes(themes) {
        
    }
}