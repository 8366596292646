import { Component, Input, NgModule, OnInit, Output, SimpleChanges, EventEmitter } from '@angular/core';
import { CardabelSelectionListItemComponent } from './cardabel-selection-list-item/cardabel-selection-list-item.component';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ProgressBarModule } from 'primeng/progressbar';
import { CardabelSearchBoxModule } from '../tools/cardabel-search-box/cardabel-search-box.component';
import { ViewEncapsulation } from '@angular/core';
import { CardabelTooltipModule } from '../cardabel-tooltip/cardabel-tooltip.directive';


@Component({
  selector: 'cardabel-selection-list',
  templateUrl: './cardabel-selection-list.component.html',
  styleUrls: ['./cardabel-selection-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CardabelSelectionListComponent implements OnInit {

  @Input() title: string = '';
  @Input() multipleSelection: boolean = false;
  @Input() options: any[] = [];
  @Input() optionLabel: string = '';
  @Input() height: number = 300;

  @Output() changeSelectedOptions = new EventEmitter<any>();
  @Input() inputSelectedOptions: any[] = [];

  filteredOptions: any[] = [];

  activeSelectedOptions: any = {};

  selectedOptions: string[] = [];
  allOptionsSelected: boolean = false;
  constructor() { }

  ngOnInit() {
    if (this.options) {
      this.filteredOptions = []
      this.filteredOptions = [...this.options];
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('options' in changes) {
      if (this.options) {
        this.options.sort((a, b) => a[this.optionLabel].localeCompare(b[this.optionLabel]))

        this.filteredOptions = []
        this.filteredOptions = [...this.options];
        if (this.inputSelectedOptions) { // agnes 27/03/2024
          if (this.inputSelectedOptions.length != 0) {
            this.changeInputSelectedOptions();
          }
        }
        this.checkAllOptionsSelected()
      }
    }

    if ('inputSelectedOptions' in changes) {
      // add agnes 22/03/2024
      if (this.inputSelectedOptions) {
        // end add
        if (this.inputSelectedOptions.length != 0) {
          this.changeInputSelectedOptions();

        }
      }
      this.checkAllOptionsSelected()
    }
  }

  //called when clicking on one of the options
  selectOption(option) {

    let optionName = this.optionLabel != '' ? option[this.optionLabel] : option;

    if (this.selectedOptions.indexOf(optionName) > -1) {
      this.selectedOptions.splice(this.selectedOptions.indexOf(optionName), 1);
    }
    else {
      if (!this.multipleSelection) {
        this.selectedOptions = [];
      }
      this.selectedOptions.push(optionName);
    }

    let formattedOptions = this.formatReturnType(this.selectedOptions)
    this.returnActiveSelectedOptions();

    this.checkAllOptionsSelected()
    this.changeSelectedOptions.emit({ value: formattedOptions })
  }

  //format return type (string array or array of objects)
  formatReturnType(options) {

    let formattedOptions = [];

    for (var i = 0; i < options.length; i++) {
      let option = this.options.find(item => this.optionLabel != '' ? item[this.optionLabel].toString().toLowerCase() === options[i].toString().toLowerCase() : item.toString().toLowerCase() === options[i].toString().toLowerCase())

      formattedOptions.push(option);
    }

    return formattedOptions;

  }

  //this allows to set all options to false (active selected option is used to get active class for button)
  initActiveSelectedOptions() {
    let activeSelectedOptions = {};
    for (var k in this.options) {
      if (this.optionLabel != "") {
        activeSelectedOptions[this.options[k][this.optionLabel]] = false;
      }
      else {
        activeSelectedOptions[this.options[k]] = false;
      }
    }
    return activeSelectedOptions;
  }

  //this allows to set options to true (active) when option is selected
  returnActiveSelectedOptions() {
    this.activeSelectedOptions = this.initActiveSelectedOptions();
    for (var i in this.selectedOptions) {
      this.activeSelectedOptions[this.selectedOptions[i]] = true;
    }
  }

  //set input selected options from parent component to this component if the arrays are different
  changeInputSelectedOptions() {
    let selectedOptions = this.formatInputSelectedOptions();

    if (!this.checkIfArrayIsEqual(selectedOptions, this.selectedOptions)) {
      this.selectedOptions = selectedOptions;
      this.activeSelectedOptions = this.initActiveSelectedOptions();
      this.returnActiveSelectedOptions();
      this.checkAllOptionsSelected()
    }
  }

  //this is to format the selected options that we get from parent component to fit to selected options in this component
  formatInputSelectedOptions() {
    let selectedOptions = [];

    if (this.optionLabel != "") {
      for (var k in this.inputSelectedOptions) {
        if (this.inputSelectedOptions[k][this.optionLabel]) {
          selectedOptions.push(this.inputSelectedOptions[k][this.optionLabel])
        }
      }
    }
    return selectedOptions;
  }

  //this allows to check whether two arrays are equal
  checkIfArrayIsEqual(inputSelectedOptions, selectedOptions) {
    let isEqual = false;

    if (inputSelectedOptions.length === selectedOptions.length) {
      inputSelectedOptions.sort();

      return inputSelectedOptions.every((val, idx) => val === selectedOptions[idx])
    }
    return isEqual;
  }

  //select or unselect options from all checkbox
  selectAllOptions(param) {
    this.allOptionsSelected = param;

    if (param) {
      this.inputSelectedOptions = [...this.filteredOptions]
      this.selectedOptions = this.formatInputSelectedOptions();
    }
    else {
      this.selectedOptions = []
    }
    let formattedOptions = this.formatReturnType(this.selectedOptions)
    this.returnActiveSelectedOptions();
    this.checkAllOptionsSelected()
    this.changeSelectedOptions.emit({ value: formattedOptions })

  }

  //check if all checkbox should be checked
  checkAllOptionsSelected() {
    if (this.selectedOptions.length === this.filteredOptions.length) {
      this.allOptionsSelected = true;
    }
    else {
      this.allOptionsSelected = false;
    }
  }

}

@NgModule({
  imports: [
    CommonModule, ReactiveFormsModule, FormsModule, ProgressBarModule, CardabelSearchBoxModule, CardabelTooltipModule
  ],
  exports: [CardabelSelectionListComponent, ReactiveFormsModule, FormsModule, CardabelSearchBoxModule],
  declarations: [CardabelSelectionListComponent, CardabelSelectionListItemComponent],
  providers: [

  ]
})
export class CardabelSelectionListModule {

}

