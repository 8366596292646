<div class="row">
  <div class="col-xs-12 col-md-12 col-sm-12">
    <i class="pi pi-question-circle piHelp" id="helpIcon" (click)="getHelpDocumentation()"></i>
  </div>
</div>

<div class="row">
  <div class="col-xs-12 col-md-12 col-sm-12 justify-content-center">
    <div class="col-xs-10 offset-xs-1 col-sm-6 offset-sm-3 col-md-4 offset-md-4">
      <h3 class="sectionTitle">Market Data Source Options</h3>
    </div>

  </div>
</div>

<div class="row">
  <div class="col-md-10 offset-md-1 col-xs-12 col-sm-12">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-xs-12 col-sm-12">
          <div class="btn-group applyButtonGroup">
            <button class=" btn btn-primary btn-sm" (click)="onSaveChanges()">Save changes</button>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 col-md-12 col-sm-12">

          <div class="row margin-top-20">
            <div class="col-md-4 offset-md-4 col-xs-4 col-sm-7">
              <p-selectButton [options]="optionsMarketDataSource" [(ngModel)]="selectedOptionSource.value">
                <ng-template let-item>
                  <div style="padding: .5em 1em">
                    <i class="pi pi-file" style="width:16px;vertical-align:middle" *ngIf="item.label === 'File'"></i>
                    <i class="pi pi-globe" style="width:16px;vertical-align:middle" *ngIf="item.label === 'Web'"></i>
                    <i class="pi pi-ban" style="width:16px;vertical-align:middle" *ngIf="item.label === 'None'"></i>

                    {{item.label}}
                  </div>
                </ng-template>
              </p-selectButton>

            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>