import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppService } from 'src/app/app.service';
import { Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CustomHttpParamEncoder } from 'src/app/shared/custom-http-param-encoder';

@Injectable()
export class EmailConfigurationService {

  constructor(private httpClient: HttpClient,
    private appService: AppService,
  ) { }

  webUrl = this.appService.getWebUrl();

  emailConfigurationData: Object;
  //this is for the email configuration component to automatically update the config data      
  emailConfigurationDataChange = new Subject<Object>();
  arrayObjects: { label: string, value: string }[];

  //Gets admin email configuration.
  //called in email configuration component
  getEmailConfigurationData() {

    const url = this.webUrl;

    const webservice = "AdminGetEmailConfiguration";
    const completeUrl = url + webservice;

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() });

    const headers = this.appService.getHeaders();

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {
          if (response.wfStructure) {

            this.emailConfigurationData = response.wfStructure[0];
            this.emailConfigurationDataChange.next(this.emailConfigurationData);
          }
        }
        else {
          this.appService.showMessage('Error', response.statusText)
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText)
        this.appService.stopSpin();
      });

  }

  //Sets admin email configuration.
  //called in email configuration component
  setEmailConfigurationData(emailConfigurationFormValue: any) {

    let recap = []
    for (var i in emailConfigurationFormValue.selectedFieldRC) {
      recap.push(emailConfigurationFormValue.selectedFieldRC[i].value)
    }

    const url = this.webUrl;
    const webservice = "AdminSetConfigurationEmail";
    const completeUrl = url + webservice;

    this.appService.startSpin();

    const headers = this.appService.getHeaders();
    //Create new HttpParams

    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })

      .set("emailSender", emailConfigurationFormValue.emailSender)
      .set("emailPassword", emailConfigurationFormValue.emailPassword)
      .set("emailTitle", emailConfigurationFormValue.emailTitle)
      .set("companyText", emailConfigurationFormValue.companyText)
      .set("signatureText", emailConfigurationFormValue.signatureText)
      .set("recapStatus", emailConfigurationFormValue.emailTrueStatus)
      .set("recapFields", JSON.stringify(recap))
      .set("nbDays", emailConfigurationFormValue.nbDays)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.appService.showMessage('Success', response.statusText)
        }
        else {
          this.appService.showMessage('Error', response.statusText)
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText)
        this.appService.stopSpin();
      });
  }

  //Sets admin email configuration.
  //called in email configuration component
  sendEmailToUsers() {

    const url = this.webUrl;
    const webservice = "AdminSendEmailToUsers";
    const completeUrl = url + webservice;

    this.appService.startSpin();

    const headers = this.appService.getHeaders();
    //Create new HttpParams

    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.appService.showMessage('Success', response.statusText)
        }
        else {
          this.appService.showMessage('Error', response.statusText)
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText)
        this.appService.stopSpin();
      });
  }

  //Formats data 
  //called in email configuration component
  convertToPrimeArray(stringArray: string[]) {
    this.arrayObjects = [];
    for (var i in stringArray) {
      if (stringArray[i] != "") {
        this.arrayObjects.push({ label: stringArray[i], value: stringArray[i] })
      }

    }
    return this.arrayObjects;
  }

}