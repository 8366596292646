<div class="cardabel-selection-list-container">
  <div class="row cardabel-selection-list-header">
    <div class="col-md-1">
      <button [disabled]="!multipleSelection" class="nopadding" *ngIf="allOptionsSelected"
        (click)="selectAllOptions(false)"><i class="fa fa-check" aria-hidden="true"></i>
      </button><button [disabled]="!multipleSelection" class="nopadding" *ngIf="!allOptionsSelected"
        (click)="selectAllOptions(true)"><i class="fa fa-square"></i></button>
    </div>
    <div class="col-md-9">
      <cardabel-search-box (filter)="filteredOptions = $event.value; checkAllOptionsSelected()" [title]="title"
        [options]="options" [optionLabel]="optionLabel"></cardabel-search-box>
    </div>
    <div class="col-md-1">

    </div>
  </div>


  <div class="cardabel-selection-list" [ngStyle]="{'height': 'calc(100vh - '+height+'px)'}">
    <cardabel-selection-list-item *ngFor="let option of filteredOptions" [option]="option" [optionLabel]="optionLabel"
      [selectedOption]="optionLabel != ''? activeSelectedOptions[option[optionLabel]]: activeSelectedOptions[option]"
      (emitClick)="selectOption(option)" ></cardabel-selection-list-item>
  </div>
</div>