import { Injectable } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { HttpClient, HttpParams} from '@angular/common/http';
import { WorkflowNameService } from './workflow-name.service';
import { catchError } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CustomHttpParamEncoder } from 'src/app/shared/custom-http-param-encoder';



@Injectable()
export class WorkflowTemporalStepsService {
  constructor(private appService: AppService,
    private httpClient: HttpClient,
    private workflowService: WorkflowNameService) { }

  webUrl = this.appService.getWebUrl();

  nbLeavesChange = new Subject<any[]>();
  allIntraModelsChange = new Subject<any[]>();
  allInterModelsChange = new Subject<any[]>();
  leafModelsChange = new Subject<any[]>();
  leafModelsResultChange = new Subject<any[]>();
  leafModelsResultOneLeafChange = new Subject<any[]>();

  //Sets the nodes  attributes in the workflow/table/subtable and returns back the workflow
  //called in workflow temporal steps
  setTemporalSubTableTreeStructAttributes(apply_and_chain, workflow, tableName, subtableName, nodeList) {

    const url = this.webUrl;
    const webservice = "WFTempSubTableSaveTreeStructure";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("apply_and_chain", apply_and_chain)
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subtableName)
      .set('nodeList', nodeList)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.appService.showMessage("Success", response.statusText);
          this.workflowService.updateWorkflowTableSubTable(tableName, response.wfStructure, "");
        }
        else {
          this.appService.showMessage("Error", response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage("Error", error.statusText);
        this.appService.stopSpin();
      });
  }

  //Sets the measure attributes in the workflow/table/subtable and returns back the workflow
  //called in workflow temporal steps
  setTemporalSubTableLeafMeasuresAttributes(apply_and_chain, workflow, tableName, subtableName, measuresList) {

    const url = this.webUrl;
    const webservice = "WFTempSubTableSaveMeasures";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("apply_and_chain", apply_and_chain)
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subtableName)
      .set('measuresList', measuresList)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.appService.showMessage("Success", response.statusText);
          this.workflowService.updateWorkflowTableSubTable(tableName, response.wfStructure, "");
        }
        else {
          this.appService.showMessage("Error", response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage("Error", error.statusText);
        this.appService.stopSpin();
      });
  }

  //Sets the sequence key in the workflow/table/subtable and returns back the workflow
  //called in workflow temporal steps
  setTemporalSubTableSequenceKey(apply_and_chain, workflow, tableName, subtableName, seqKeyList, seqKeyType, seqKeyFunction, seqKeyScript, seqKeyTime, seqKeyGroupBy, measureFields) {

    const url = this.webUrl;
    const webservice = "WFTempSubTableSaveSequenceKey";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("apply_and_chain", apply_and_chain)
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subtableName)
      .set('seqKeyList', seqKeyList)
      .set('seqKeyType', seqKeyType)
      .set('seqKeyTime', seqKeyTime)
      .set('seqKeyGroupBy', seqKeyGroupBy)
      .set('seqKeyMeasures', measureFields)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.appService.showMessage("Success", response.statusText);
          this.workflowService.updateWorkflowTableSubTable(tableName, response.wfStructure, "");
        }
        else {
          this.appService.showMessage("Error", response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage("Error", error.statusText);
        this.appService.stopSpin();
      });
  }

  getTemporalAllModels(modelType) {

    const url = this.webUrl;
    const webservice = "WFTempGetAllModelParams";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("modelType", modelType)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {

          if (modelType === 'intra') {
            this.allIntraModelsChange.next(response.tableRows);
          }
          else {
            this.allInterModelsChange.next(response.tableRows);
          }
        }
        else {
          this.appService.showMessage("Error", response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage("Error", error.statusText);
        this.appService.stopSpin();
      });
  }

  //generate the leafs with  all the models in the workflow/table/subtable and returns back the workflow
  //called in workflow temporal steps
  launchTemporalSubTableModel(workflow, tableName, subtableName, modelList) {

    const url = this.webUrl;
    const webservice = "WFTempSubTableApplyModels";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin2();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subtableName)
      .set('modelList', modelList)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);

        if (response.statusCode > -1) {
          this.appService.showMessage("Success", response.statusText);
          this.leafModelsChange.next(response.tableRows);
          this.leafModelsResultChange.next(response.tableRows2);
          this.workflowService.updateWorkflowTableSubTable(tableName, response.wfStructure, "");
        }
        else {
          this.appService.showMessage("Error", response.statusText);
        }
        this.appService.stopSpin2();
      }, (error) => {
        this.appService.showMessage("Error", error.statusText);
        this.appService.stopSpin2();
      });
  }

  getTemporalSubTableModelResults(workflow, tableName, subtableName) {

    const url = this.webUrl;
    const webservice = "WFTempSubTableGetModelResults";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subtableName)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.appService.showMessage("Success", response.statusText);
          this.leafModelsResultChange.next(response.tableRows);
        }
        else {
          this.appService.showMessage("Error", response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage("Error", error.statusText);
        this.appService.stopSpin();
      });
  }

}