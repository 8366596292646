import { HttpClient,  HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { catchError } from 'rxjs/operators';
import { CustomHttpParamEncoder } from 'src/app/shared/custom-http-param-encoder';
import { SharedInitializationService } from 'src/app/shared/shared-common-initialization.service';


@Injectable()
export class ScriptManagementService {
    constructor(
        private appService: AppService,
        private httpClient: HttpClient,
        private sharedService: SharedInitializationService,
    ){}

    downloadNameChange =new Subject<number>();
    
    webUrl = this.appService.getWebUrl();

    adminDownloadScriptName(subDir) {

      const url = this.webUrl;
      const webservice = "AdminDownloadScriptFileName";
      const completeUrl = url + webservice;
  
      const headers = this.appService.getHeaders();

      this.appService.startSpin();
      //Create new HttpParams */
      let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("subDir", subDir)

      return this.httpClient.post(completeUrl, params, {headers, responseType:'text', withCredentials:true})
        .pipe(
          catchError(this.appService.handleError)
        )
        .subscribe((response:any) => {
          response = JSON.parse(response);
          if (response.statusCode > -1 ) {
              var downloadName = response.tableRows[0].FileName;
              this.downloadNameChange.next(downloadName);
          }
          else {
            this.appService.showMessage('Error', response.statusText)          
          }
          this.appService.stopSpin();
        }, (error) => {
          this.appService.showMessage('Error', error.statusText)          
          this.appService.stopSpin();
        });
  }

    adminDownloadScript(subDir, fileName) {

        const url = this.webUrl;
        const webservice = "AdminDownloadScriptFile";
        const completeUrl = url + webservice;
    
        const headers = this.appService.getHeaders();
        
        this.appService.startSpin();

       //Create new HttpParams */
       let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
       .set("subDir", subDir)
 
       return this.httpClient.post(completeUrl, params, {headers, responseType:'blob', withCredentials:true})
         .pipe(
           catchError(this.appService.handleError)
         )
      .subscribe((response: any) => {

        if (response["type"] == 'application/json'){
          this.appService.showMessage('Error', "unable to download the script");
        }
        else {

          let name = fileName;
          this.sharedService.downloadAllFiles(response, name, "txt")
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });
    }

    adminUploadScript(files, subDir) {

      const url = this.webUrl;
      const webservice = "AdminUploadScriptFile";
      const completeUrl = url + webservice;
  
      const formData = new FormData();
    
      const headers = this.appService.getHeadersUpload();
      
      for (var i = 0; i < files.length; i++) {
        let fileToUpload = <File>files[i];
        formData.append('file', fileToUpload, fileToUpload.name);
      }
      formData.append('subDir', subDir)
      formData.append('enctype', 'multipart/form-data');
      this.appService.startSpin();
      //Create new HttpParams */
  

     return this.httpClient.post(completeUrl, formData, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {

            this.appService.showMessage('Success', response.statusText);
          }
          else {

          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();

      }, (error) => {
        this.appService.stopSpin();
        this.appService.showMessage('Error', error.statusText);

      });

  }

}