/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./topics-dashboard-topic-card-item.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./topics-dashboard-topic-card-item.component";
var styles_TopicsDashboardTopicCardItemComponent = [i0.styles];
var RenderType_TopicsDashboardTopicCardItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TopicsDashboardTopicCardItemComponent, data: {} });
export { RenderType_TopicsDashboardTopicCardItemComponent as RenderType_TopicsDashboardTopicCardItemComponent };
export function View_TopicsDashboardTopicCardItemComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DecimalPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "col-md-12 topics-dashboard-secondary-number"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵppd(4, 2), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "col-md-12 topics-dashboard-predictions-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v, 0), _co.itemNumber, "1.0-0")); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.itemTitle; _ck(_v, 7, 0, currVal_1); }); }
export function View_TopicsDashboardTopicCardItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "cardabel-topics-dashboard-topic-card-item", [], null, null, null, View_TopicsDashboardTopicCardItemComponent_0, RenderType_TopicsDashboardTopicCardItemComponent)), i1.ɵdid(1, 114688, null, 0, i3.TopicsDashboardTopicCardItemComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TopicsDashboardTopicCardItemComponentNgFactory = i1.ɵccf("cardabel-topics-dashboard-topic-card-item", i3.TopicsDashboardTopicCardItemComponent, View_TopicsDashboardTopicCardItemComponent_Host_0, { itemTitle: "itemTitle", itemNumber: "itemNumber" }, {}, []);
export { TopicsDashboardTopicCardItemComponentNgFactory as TopicsDashboardTopicCardItemComponentNgFactory };
