
export class AnalysisNavigationTab {
    public tab: string;
    public defaultWorkflow: string;
    public targetWorkflow:string;
    public defaultTable: string;
    public targetTable: string;
    public defaultSubTable: string;
    public targetSubTable: string;
    public defaultSegAttribute: string;
    public targetSegAttribute: string;
    public defaultType: string;
    public targetType: string;
    public defaultSource: string;
    public targetSource: string;
    public Filters: {defaultHeader: string, targetHeader:string, placeholder: string}[];
    public TreeLeafs: string;
    public Sorts: string;


    constructor(
        tab: string,
        defaultWorkflow: string,
        targetWorkflow: string,
        defaultTable: string,
        targetTable: string,
        defaultSubTable: string,
        targetSubTable: string,
        defaultSegAttribute: string,
        targetSegAttribute: string,
        defaultType: string,
        targetType: string,
        defaultSource: string,
        targetSource: string,
        Filters: {defaultHeader: string, targetHeader:string, placeholder: string}[],
        TreeLeafs: string,
        Sorts: string,
                ) {
        this.tab = tab;
        this.defaultWorkflow = defaultWorkflow;
        this.targetWorkflow = targetWorkflow;
        this.defaultTable = defaultTable;
        this.targetTable = targetTable;
        this.defaultSubTable = defaultSubTable;
        this.targetSubTable = targetSubTable;
        this.defaultSegAttribute = defaultSegAttribute;
        this.targetSegAttribute = targetSegAttribute;
        this.defaultType = defaultType;
        this.targetType = targetType;
        this.defaultSource = defaultSource;
        this.targetSource = targetSource;
        this.Filters = Filters;
        this.TreeLeafs = TreeLeafs;
        this.Sorts = Sorts;

    }
}