import { Injectable} from '@angular/core';
import { AppService } from 'src/app/app.service';
import { HttpClient, HttpParams} from '@angular/common/http';
import { Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CustomHttpParamEncoder } from '../../shared/custom-http-param-encoder';
import { GlobalFilter } from '../models/analysis-global-filters.model';
import { AnalysisNavigationTab } from '../models/analysis-navigation-tab.model';
import { AuthService } from 'src/app/auth/auth-service';

@Injectable()
export class AnalysisNavigationService {
  constructor (
     private httpClient: HttpClient,
     private appService: AppService, 
     private authService: AuthService){
   
  }

  webUrl = this.appService.getWebUrl();

  navigationDataFromFile: any[] = [];
  navigationData: any = {};
  //this is called in analysis grid component and automatically updates the navigation data
  navigationDataChange:{ [index: string]: Subject<any[]> } = {};
  //this is to see if window is navigation or not
  navigation: boolean = false;
  //this is called in analysis component to check whether window is navigation or nto
  navigationChange = new Subject<boolean>();

  sourceHeaders: any[] = [];
  targetHeaders: any[] = [];
  //this is called in analysis grid component for navigation
  sourceHeadersChange: { [index: string]: Subject<any[]> } = {};
  targetHeadersChange: { [index: string]: Subject<any[]> } = {};

  globalFilters: any = {};
  globalFiltersForGrid: any = {};
  globalFiltersDataChange: { [index: string]: Subject<GlobalFilter[]> } = {};

  username = this.authService.getUsername();
  //
  iniTabSubjects(tabs) {
    for (var i in tabs) {
      this.sourceHeaders[tabs[i].name] = [];
      this.targetHeaders[tabs[i].name] = [];
      this.sourceHeadersChange[tabs[i].name] = new Subject<any[]>();
      this.targetHeadersChange[tabs[i].name] = new Subject<any[]>();

      this.navigationData[tabs[i].name] = {};
      this.navigationDataChange[tabs[i].name] = new Subject<any[]>();

      this.globalFilters[tabs[i].name] = [];
      this.globalFiltersForGrid[tabs[i].name] = [];
      this.globalFiltersDataChange[tabs[i].name] = new Subject<GlobalFilter[]>();
  }
  this.getNavigationParameters(this.username);
  }
  
  //set navigation to load workflow details for nav or not
  setNavigation(param) {
    this.navigation = param;
    this.navigationChange.next(this.navigation)
  }

  //this allows to get the navigation parameters
  //called in this service and in analysis grid component
  getNavigationParameters(username) {
    //const url = this.url;
    const url = this.webUrl;
    const webservice = "AnalysisGetNavigationResults";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();
        //this.appService.startSpin();
    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("userName", username)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.navigationDataFromFile = [...response.tableRows];
  
            for (var i in response.tableRows) {
              this.navigationData[response.tableRows[i].tab] = response.tableRows[i];
              this.navigationData[response.tableRows[i].tab].Filters = JSON.parse(response.tableRows[i].Filters);
              this.navigationData[response.tableRows[i].tab].TreeLeafs = JSON.parse(response.tableRows[i].TreeLeafs);
              this.navigationData[response.tableRows[i].tab].Sorts = JSON.parse(response.tableRows[i].Sorts);
              if (this.navigationDataChange[response.tableRows[i].tab]) {
                this.navigationDataChange[response.tableRows[i].tab].next(this.navigationData[response.tableRows[i].tab]);
              }
            }
         
         
        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }

      }, (error) => {

        this.appService.showMessage('Error', error.statusText);

      });
  }
  //this allows to get the workflow attributes for the navigation (ie headers)
  //called in analysis grid component
  getNavigationWorkflowAttributes(workflow, table, subtable, navType, mode, tab) {

    const url = this.webUrl;
    const webservice = "AnalysisGetNavigationWorkflowAttributes";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();
    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      .set("segAttrValue", table)
      .set("subTableName", subtable)
      .set('mode', mode)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {
          var headers = navType + "Headers";
          var headersChange = navType + "HeadersChange";
          this[headers][tab] = [];
          for (var i in response.tableRows[0].headers) {
            this[headers][tab].push({ headerName: response.tableRows[0].headers[i] })
          }
          this[headersChange][tab].next(this[headers][tab]);

        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();

      }, (error) => {
        this.appService.stopSpin();
        this.appService.showMessage('Error', error.statusText);

      });
  }
  //this allows to set the navigation parameters (change nav)
  //this is called in the analysis grid component
  setNavigationParameters(tabs, username, tab) {

    const url = this.webUrl;
    const webservice = "AnalysisSetNavigationSettings";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    tabs = JSON.stringify(tabs);
    this.appService.startSpin();

    let params = new HttpParams({encoder: new CustomHttpParamEncoder})
      .set("tabs", tabs)
      .set("userName", username)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.appService.showMessage('Success', response.statusText);
          this.getNavigationParameters(username);
        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();

      }, (error) => {
        this.appService.stopSpin();
        this.appService.showMessage('Error', error.statusText);

      });
  }

  changeNavigationData(navigationData, tab) {
    this.navigationDataChange[tab].next(navigationData);
  }

  resetFilters(navigationType, savedNavigationType, navigationData, tab) {
      this[navigationType+"Headers"][tab] = [];
      this[navigationType+"HeadersChange"][tab].next(this[navigationType+"Headers"][tab]);

      for (var k in navigationData.Filters) {
        navigationData.Filters[k][savedNavigationType+"Header"] = "";
        navigationData.Filters[k].placeholder = "=";
      }

      this.changeNavigationData(navigationData, tab);
  }

  //prepare navigation parameters before setting them

  prepareNavigationParameters(tab, username, sourceWorkflow, targetWorkflow, sourceTable, targetTable, sourceSubtable, targetSubtable, sourceSegAttr, targetSegAttr, sourceType, targetType, sourceSource, targetSource, filterArray, treeLeafArray, sortArray) {

    let navigationTab = new AnalysisNavigationTab(tab, sourceWorkflow, targetWorkflow, sourceTable, targetTable, sourceSubtable, targetSubtable, sourceSegAttr, targetSegAttr, sourceType, targetType, sourceSource, targetSource, filterArray, treeLeafArray, sortArray);
 
    let navigationIndex = this.navigationDataFromFile.findIndex(item => item.tab === navigationTab.tab);

    if (navigationIndex > -1) {
      this.navigationDataFromFile[navigationIndex] = navigationTab;
    }
    else {
      this.navigationDataFromFile.push(navigationTab);
    }

    this.setNavigationParameters(this.navigationDataFromFile, username, tab);
  }
  //this is to return navigation data
  getNavigationData(tab) {
    return this.navigationData[tab];
  }

  setNavigationGlobalFilters(globalFiltersData, globalFilterDataForGrid, tab) {
    this.globalFilters[tab.name] = [...globalFiltersData];
    this.globalFiltersForGrid[tab.name] = [...globalFilterDataForGrid];
    this.globalFiltersDataChange[tab.name].next(this.globalFilters[tab.name]);
  }

  getNavigationGlobalFilters(tab) {
    return [...this.globalFilters[tab]];
  }

  getNavigationGlobalFiltersForGrid(tab) {
    return [...this.globalFiltersForGrid[tab]];
  }
 
  deleteTab(tabName, username) {
    let index = this.navigationDataFromFile.findIndex(item => item.tab === tabName);
    if (index > -1) {
      this.navigationDataFromFile.splice(index, 1);
      this.setNavigationParameters(this.navigationDataFromFile, username, "");
    }
    
  }
}