import { Injectable } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { WorkflowNameService } from './workflow-name.service';
import { CustomHttpParamEncoder } from 'src/app/shared/custom-http-param-encoder';


@Injectable()
export class WorkflowKernelStepsService {
  constructor(private appService: AppService,
    private httpClient: HttpClient,
    private workflowService: WorkflowNameService) { }

  webUrl = this.appService.getWebUrl();

  kernelChartStatusChange = new Subject<any[]>();
  kernelMissingValuesChange = new Subject<any[]>();
  kernelDuplicatesChange = new Subject<any[]>();
  kernelDuplicatesNumberChange = new Subject<any[]>();
  kernelResultChange = new Subject<any[]>();
  kernelDuplicatesRecordsChange = new Subject<any[]>();


  //Sets the attrlist with new attrtypes in the workflow/table/and returns back the workflow
  //called in workflow kernel steps
  setKernelTableAttrTypes(apply_and_chain, workflow, tableName, subtableName, attributesList) {

    const url = this.webUrl;
    const webservice = "WFKernelTableSetAttrTypes";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("apply_and_chain", apply_and_chain)
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subtableName)
      .set('attributesList', attributesList)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.appService.showMessage("Success", response.statusText);

          this.workflowService.updateWorkflowTable(tableName, response.wfStructure);
          this.workflowService.updateWorkflowTableSubTable(tableName, response.wfStructure, subtableName);
        }
        else {
          this.appService.showMessage("Error", response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage("Error", error.statusText);
        this.appService.stopSpin();
      });
  }

  //Sets the attrlist with new attrtypes in the workflow/table/and returns back the workflow
  //called in workflow kernel steps
  getKernelTableMissingValues(workflow, tableName, subtableName) {

    const url = this.webUrl;
    const webservice = "WFKernelTableGetMissingValues";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subtableName)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.kernelMissingValuesChange.next(response.tableRows);
        }
        else {
          if (response.statusCode == -2) {

            this.appService.showMessage("Warning", response.statusText);
            this.kernelMissingValuesChange.next(response.tableRows)
          }
          else {
            this.appService.showMessage("Error", response.statusText);
          }
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage("Error", error.statusText);
        this.appService.stopSpin();
      });
  }

  //Sets the attrlist with new attrtypes in the workflow/table/and returns back the workflow
  //called in workflow kernel steps
  setKernelTableMissingValues(apply_and_chain, workflow, tableName, subtableName, attributesList, missingDefault) {

    const url = this.webUrl;
    const webservice = "WFKernelTableSetMissingValues";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("apply_and_chain", apply_and_chain)
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subtableName)
      .set('attributesList', attributesList)
      .set('missingDefault', missingDefault)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.appService.showMessage("Success", response.statusText);

          this.workflowService.updateWorkflowTable(tableName, response.wfStructure);
          this.workflowService.updateWorkflowTableSubTable(tableName, response.wfStructure, subtableName);
        }
        else {
          this.appService.showMessage("Error", response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage("Error", error.statusText);
        this.appService.stopSpin();
      });
  }

  //Sets the attrlist with new attrtypes in the workflow/table/and returns back the workflow
  //called in workflow kernel steps
  getKernelTableDuplicates(workflow, tableName, subTableName, attributesList, method, threshold, diff_attributesList) {

    const url = this.webUrl;
    const webservice = "WFKernelTableGetDuplicatesValues";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();
    ''
    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subTableName)
      .set('attributesList', attributesList)
      .set('method', method)
      .set('threshold', threshold)
      .set('diff_attributesList', diff_attributesList)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {
          if (response.tableRows.length > 0) {
            this.kernelDuplicatesChange.next(response.tableRows);
            this.kernelDuplicatesNumberChange.next(response.tableRows2[0]);
          }
          else {
            this.appService.showMessage("Warning", response.statusText);
            if (response.tableRows) {
              this.kernelDuplicatesChange.next(response.tableRows);
              this.kernelDuplicatesNumberChange.next(response.tableRows2[0]);
            }
          }
        }
        else {
          this.appService.showMessage("Error", response.statusText);
          if (response.tableRows) {
            this.kernelDuplicatesChange.next(response.tableRows);
            this.kernelDuplicatesNumberChange.next(response.tableRows2[0]);
          }
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage("Error", error.statusText);
        this.appService.stopSpin();
      });
  }

  //Sets the attrlist with new attrtypes in the workflow/table/and returns back the workflow
  //called in workflow kernel steps
  getKernelTableDuplicatesRecords(workflow, tableName, subTableName, attributesList, duplicateSet,
    method, threshold, diff_attributesList) {

    const url = this.webUrl;
    const webservice = "WFKernelTableGetDuplicatesRecords";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subTableName)
      .set('attributesList', attributesList)
      .set('duplicateSet', duplicateSet)
      .set('method', method)
      .set('threshold', threshold)
      .set('diff_attributesList', diff_attributesList)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {
          if (response.tableRows.length > 0) {
            this.kernelDuplicatesRecordsChange.next(response.tableRows);
          }
        }
        else {
          this.appService.showMessage("Error", response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage("Error", error.statusText);
        this.appService.stopSpin();
      });
  }

  //Sets the attrlist with new attrtypes in the workflow/table/and returns back the workflow
  //called in workflow kernel steps
  setKernelTableDuplicatesValues(apply_and_chain, workflow, tableName, subtableName, duplicatesList) {

    const url = this.webUrl;
    const webservice = "WFKernelTableSetDuplicatesValues";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("apply_and_chain", apply_and_chain)
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subtableName)
      .set('attributesList', duplicatesList)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.appService.showMessage("Success", response.statusText);

          this.workflowService.updateWorkflowTable(tableName, response.wfStructure);
          this.workflowService.updateWorkflowTableSubTable(tableName, response.wfStructure, subtableName);
        }
        else {
          this.appService.showMessage("Error", response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage("Error", error.statusText);
        this.appService.stopSpin();
      });
  }

  //setKernelSubTableBenford(apply_and_chain, workflow, tableName, subTableName, slicingAttributesList, benfordAudit, benfordDefault){
  setKernelSubTableBenford(apply_and_chain, workflow, tableName, subTableName, slicingAttributesList, benfordDefault) {

    const url = this.webUrl;
    const webservice = "WFKernelTableSetBenfordValues";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })

      .set("apply_and_chain", apply_and_chain)
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subTableName)
      .set('attributesList', slicingAttributesList)
      .set('benfordDefault', benfordDefault)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.appService.showMessage("Success", response.statusText);

          this.workflowService.updateWorkflowTable(tableName, response.wfStructure);
          this.workflowService.updateWorkflowTableSubTable(tableName, response.wfStructure, subTableName);

        }
        else {
          this.appService.showMessage("Error", response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage("Error", error.statusText);
        this.appService.stopSpin();
      });
  }

  //Sets the attrlist with new attrtypes in the workflow/table/and returns back the workflow
  //called in workflow kernel steps
  setKernelTableRuleScripts(workflow, tableName, subTableName, ruleScriptsList) {

    const url = this.webUrl;
    const webservice = "WFKernelTableSetRuleScripts";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })

      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subTableName)
      .set('attributesList', ruleScriptsList)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.appService.showMessage("Success", response.statusText);

          this.workflowService.updateWorkflowTable(tableName, response.wfStructure);
          this.workflowService.updateWorkflowTableSubTable(tableName, response.wfStructure, subTableName);

        }
        else {
          this.appService.showMessage("Error", response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage("Error", error.statusText);
        this.appService.stopSpin();
      });
  }

}