import { HttpClient, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AdminMarketGetFactorSource } from './admin-market-get-factor-source.model';
import { AdminMarketSetFactorSource } from './admin-market-set-factor-source.model';
import { AppService } from 'src/app/app.service';
import { catchError } from 'rxjs/operators';
import { CustomHttpParamEncoder } from 'src/app/shared/custom-http-param-encoder';


@Injectable()
export class MarketDataSourceService {
    constructor(
        private appService: AppService,
        private httpClient: HttpClient,
    ){}

    marketDataSource:string;
    //this is for the market data source component, it automatically updates the market data source
    marketDataSourceChange =new Subject<string>();

    webUrl = this.appService.getWebUrl();
    //Gets all the sources for the existing factors.
    //called in market data source component
    getMarketDataSource() {

        const url =this.webUrl;
        const webservice = "AdminMarketGetFactorSource";
        const completeUrl = url+webservice;

        const headers = this.appService.getHeaders();
        this.appService.startSpin();

        //Create new HttpParams */
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() });
 
        return this.httpClient.post(completeUrl, params, {headers, responseType:'text', withCredentials:true})
          .pipe(
            catchError(this.appService.handleError)
          )
          .subscribe((response:any) => {

            response = JSON.parse(response);
            if (response.statusCode > -1 ) {
                this.marketDataSource = response.tableRows[0].Source;
                this.marketDataSourceChange.next(this.marketDataSource);
            }
            else {
              this.appService.showMessage('Error', response.statusText)          
            }
            this.appService.stopSpin();
          }, (error) => {
            this.appService.showMessage('Error', error.statusText)          
            this.appService.stopSpin();
          });

    }

    //Saves the source for the factor passed as an argument.
    //called in market data source component
    setMarketDataSource(source) {

        const url =this.webUrl;
        const webservice = "AdminMarketSetFactorSource";
        const completeUrl = url+webservice;
        
        const headers = this.appService.getHeaders();

        this.appService.startSpin();

       let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
       .set("factor","FX")
       .set("source", source); //Create new HttpParams
 
       return this.httpClient.post(completeUrl, params, {headers, responseType:'text', withCredentials:true})
         .pipe(
           catchError(this.appService.handleError)
         )
         .subscribe((response:any) => {

           response = JSON.parse(response);
           if (response.statusCode > -1 ) {
            this.appService.showMessage('Success', response.statusText)          
           }
           else {
             this.appService.showMessage('Error', response.statusText)          
           }
           this.appService.stopSpin();
         }, (error) => {
           this.appService.showMessage('Error', error.statusText)          
           this.appService.stopSpin();
         });
     
    }
    
}