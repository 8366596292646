import { Injectable } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { WorkflowNameService } from './workflow-name.service';
import { catchError } from 'rxjs/operators';
import { CustomHttpParamEncoder } from 'src/app/shared/custom-http-param-encoder';


@Injectable()
export class WorkflowAggregStepsService {
  constructor(private appService: AppService,
    private httpClient: HttpClient,
    private workflowService: WorkflowNameService) { }

  webUrl = this.appService.getWebUrl();

  //Sets the aggregation elements in the workflow/table/subtable and returns back the workflow
  //called in aggreg component
  setAggregSubTableAggregation(apply_and_chain, workflow, tableName, subtableName, aggregBy, aggregAttributes, tempKeyDate, tempKeyAsDateOnly, tempKeyTime) {

    const url = this.webUrl;
    const webservice = "WFAggregSubTableSetAggregation";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("apply_and_chain", apply_and_chain)
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subtableName)
      .set('aggregBy', aggregBy)
      .set('aggregAttributes', aggregAttributes)
      .set('tempKeyDate', tempKeyDate)
      .set('tempKeyAsDateOnly', tempKeyAsDateOnly)
      .set('tempKeyTime', tempKeyTime)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.appService.showMessage('Success', response.statusText);
          this.workflowService.updateWorkflowTableSubTable(tableName, response.wfStructure, subtableName);
        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });
  }
  
  //Sets the aggregation elements in the workflow/table/subtable and returns back the workflow
  //called in measures component
  setAggregSubTableMeasures(apply_and_chain, workflow, tableName, subtableName, measureFields) {

    const url = this.webUrl;
    const webservice = "WFAggregSubTableSetMeasures";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("apply_and_chain", apply_and_chain)
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subtableName)
      .set('measureFields', measureFields)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.appService.showMessage('Success', response.statusText)
          this.workflowService.updateWorkflowTableSubTable(tableName, response.wfStructure, subtableName);
        }
        else {
          this.appService.showMessage('Error', response.statusText)
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText)
        this.appService.stopSpin();
      });
  }

  //Sets the aggregation elements in the workflow/table/subtable and returns back the workflow
  //called in short long component
  setAggregSubTableShortLong(apply_and_chain, workflow, tableName, subtableName, shortLongNature,
    shortLongAttribute, shortLongMapsBuy, shortLongMapsSell) {

    const url = this.webUrl;
    const webservice = "WFAggregSubTableSetShortLong";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("apply_and_chain", apply_and_chain)
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subtableName)
      .set('shortLongNature', shortLongNature)
      .set('shortLongAttribute', shortLongAttribute)
      .set('shortLongMapsBuy', shortLongMapsBuy)
      .set('shortLongMapsSell', shortLongMapsSell)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.appService.showMessage('Success', response.statusText);
          this.workflowService.updateWorkflowTableSubTable(tableName, response.wfStructure, subtableName);
        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });
  }

  //Sets the aggregation elements in the workflow/table/subtable and returns back the workflow
  //called in post aggreg component
  setAggregSubTablePostAggreg(apply_and_chain, workflow, tableName, subtableName, postAggregMeasure, postAggregFunction, postAggregScript, postAggregYAttributes) {

    const url = this.webUrl;
    const webservice = "WFAggregSubTableSetPostAggreg";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("apply_and_chain", apply_and_chain)
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subtableName)
      .set('postAggregMeasure', postAggregMeasure)
      .set('postAggregFunction', postAggregFunction)
      .set('postAggregScript', postAggregScript)
      .set('postAggregYAttributes', postAggregYAttributes)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.appService.showMessage('Success', response.statusText);
          this.workflowService.updateWorkflowTableSubTable(tableName, response.wfStructure, subtableName);
        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
      });
  }

  //Sets the aggregation elements in the workflow/table/subtable and returns back the workflow
  //called in cycle component
  setAggregSubTablesCycle(apply_and_chain, workflow, tableName, subtableName, cycleType, cycleMethod, cycleAttributes) {

    const url = this.webUrl;
    const webservice = "WFAggregSubTableSetCycle";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("apply_and_chain", apply_and_chain)
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subtableName)
      .set('cycleType', cycleType)
      .set('cycleMethod', cycleMethod)
      .set('cycleAttributes', cycleAttributes)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {

          this.appService.showMessage('Success', response.statusText);
          this.workflowService.updateWorkflowTableSubTable(tableName, response.wfStructure, subtableName);
        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });
  }

  //Sets the aggregation elements in the workflow/table/subtable and returns back the workflow
  //called in super cycle component
  generateAggregSubTable(workflow, tableName, subtableName, isSource, superCycleAttributes) {

    const url = this.webUrl;
    const webservice = "WFAggregSubTableGenerate";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subtableName)
      .set('isSource', isSource)
      .set('superCycleAttributes', superCycleAttributes)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {

          let message = " ";
          for (var t = 0; t < response.tableRows.length; t++) {
            if (response.tableRows[t].StatusCode === -1) {
              message = message + "\n " + response.tableRows[t].StatusText + " for " + response.tableRows[t].Script + "\n";
            }
          }

          if (message.length > 1) {
            this.appService.showMessage('Warning', response.statusText + "\n " + message);
          }
          else {
            this.appService.showMessage('Success', response.statusText);
          }
          this.workflowService.updateWorkflowTableSubTable(tableName, response.wfStructure, subtableName);
        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });
  }
}