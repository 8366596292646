<div class="row">
  <div class="col-md-12">

    <as-split [useTransition]="true" direction="horizontal" id="topicsSplit">
      <as-split-area size="30">
        <div class="row">
          <div class="col-md-12 justify-content-center margin-top-20 offset-md-2">
            <p-selectButton [options]="viewNatures" [(ngModel)]="selectedViewNature" (onChange)="viewNatureChange()" >
            </p-selectButton>
          </div>
        </div>
        <div *ngIf="selectedViewNature=='Topics view'" class="row">
          <div class="col-md-12 margin-top-20">
            <div class="btn-group applyButtonGroup">
              <button routerLinkActive="active" class="btn btn-primary btn-sm" (click)="navigateNewTopic()">
                <span>
                  <i class="fa fa-user"></i> New
                </span>
              </button>
            </div>


          </div>
        </div>

        <div *ngIf="selectedViewNature=='Topics view'" class="row margin-top-10">
          <div class="col-md-1"></div>
          <div class="col-md-10">
            <cardabel-selection-list [attr.data-cy]="'admin-settings-topics-topic-selection-list'"  [inputSelectedOptions]="topic" [options]="filteredTopics" [height]="440" [title]="'topics'" [optionLabel]="'topicName'"
            [multipleSelection]="false" (changeSelectedOptions)="topic = $event.value; changeWorkflowTopic('topic', 'topicName')"></cardabel-selection-list>
          </div>
        </div>
        <div *ngIf="selectedViewNature=='Workflows view'" class="row margin-top-60">
          <div class="col-md-1"></div>
          <div class="col-md-10">
            <cardabel-selection-list [attr.data-cy]="'admin-settings-topics-workflow-selection-list'"  [inputSelectedOptions]="workflow" [options]="filteredWorkflows" [height]="440" [title]="'workflows'" [optionLabel]="'WFName'"
            [multipleSelection]="false" (changeSelectedOptions)="workflow = $event.value; changeWorkflowTopic('workflow', 'WFName')"></cardabel-selection-list>
          </div>
        </div>
      </as-split-area>
      <as-split-area size="70">
        <router-outlet></router-outlet>
      </as-split-area>
    </as-split>
  </div>
</div>