import { Injectable } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CustomHttpParamEncoder } from 'src/app/shared/custom-http-param-encoder';
import { SharedInitializationService } from 'src/app/shared/shared-common-initialization.service';

@Injectable()
export class LogsService {

  constructor(
    private appService: AppService,
    private httpClient: HttpClient,
    private sharedService: SharedInitializationService,
  ) { }

  webUrl = this.appService.getWebUrl();

  logsFileChange = new Subject<any>();

  adminExportLogs(logFile, nbLines) {

    const url = this.webUrl;
    const webservice = "AdminExportLogFile";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("logFile", logFile)
      .set("logNbLines", nbLines);

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.logsFileChange.next(response.tableRows);
        }
        else {
          this.appService.showMessage('Error', response.statusText)
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText)
        this.appService.stopSpin();
      });
  }

  adminDownloadLogs(logFile) {

    const url = this.webUrl;
    const webservice = "AdminDownloadLogFile";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();
    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("logFile", logFile)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'blob', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        if (response["type"] == 'application/json') {
          this.appService.showMessage('Error', "unable to download the file");
        }
        else {
          let date = new Date().toLocaleDateString()
          let name = logFile + "_" + date;
          this.sharedService.downloadCSVFile(response, name);

        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });
  }


}