
  <div class="row">
      <div class="col-xs-12 col-md-12 col-xs-12 col-sm-12 nopadding">
          <div class="btn-group col-md-12 col-xs-12 col-sm-12" role="group" aria-label="Basic example">
              <button type="button" class="btn btn-primary" routerLink="./marketdata" routerLinkActive="active">Market Data</button>
              <button type="button" class="btn btn-primary" routerLink="./settings" routerLinkActive="active" >Settings</button>
              <button type="button" class="btn btn-primary" routerLink="./sourcemanagement" routerLinkActive="active">Source Management</button>
              <button [attr.data-cy]="'admin-authorizations-link'" type="button" class="btn btn-primary" routerLink="./authorizations"routerLinkActive="active">Authorizations</button>
            </div>
      </div>
    </div>
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div>
