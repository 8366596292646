import { Injectable, ViewRef } from '@angular/core';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { AppService } from 'src/app/app.service';

@Injectable()
export class DashboardPDFService {

    constructor(private appService: AppService) {}
    displayPDF(vis) {
        vis.displayPDFClass = true;
        let PDF = new jsPDF('l', 'mm', 'a4');
        vis.windowWidth = PDF.internal.pageSize.width * 1 / 0.2645833333;
        vis.windowHeight = PDF.internal.pageSize.height * 1 / 0.2645833333;
        vis.sourceMaxDragPosition = JSON.parse(JSON.stringify(vis.maxDragPosition));
        vis.maxDragPosition = Math.max(vis.maxDragPosition, vis.maxDragPosition * 2.5 - PDF.internal.pageSize.height * 1 / 0.2645833333);
        vis.marginLeft = (window.innerWidth - vis.windowWidth) / 2;
        setTimeout(() => {
            vis.dashboardService.setChartChanges(vis.mainTab, vis.tabAlias, vis.columns)
        }, 100)

    }

    downloadPdf(vis) {
        vis.appService.startSpin2();
        if (vis.cd && !(vis.cd as ViewRef).destroyed) {
            vis.cd.detectChanges();
        }
        let PDF = new jsPDF('l', 'mm', 'a4');
        let theme = this.appService.theme; 
        let color = "#fff"
        if (theme === 'theme-dark') {
            //color = "#222"
        }
        PDF.setFillColor(color);
        PDF.rect(0, 0, 400, 400, "F");

        let performanceTime = performance.now();
        console.log(performanceTime)

        setTimeout(() => {
            var img = [];
            var requiredPages = vis.columns.length; // count of div
            let pages = 1;
            for (var i in vis.columns) {
                let id = vis.mainTab + vis.tabAlias + "column" + i;
                let htmlelement = document.getElementById(id);
                let position = 0;

                html2canvas(htmlelement).then(function (canvas) {
                    console.log(canvas);
                    console.log(htmlelement)
                    let number = +htmlelement.id.slice(-1);
                    let FILEURI = canvas.toDataURL('image/jpeg');
                    let alias = "alias" + number;
                    let pdfHeight = PDF.internal.pageSize.height;


                    var imgWidth = vis.columns[number].width * vis.windowWidth * 0.2645833333;
                    var imgHeight = vis.columns[number].height * vis.windowHeight * 0.2645833333;

                    var yPosition = vis.columns[number].topPosition * vis.windowHeight * 0.2645833333;
                    var xPosition = vis.columns[number].leftPosition * vis.windowWidth * 0.2645833333;

                    let pageIndex = Math.ceil((yPosition + imgHeight) / pdfHeight);

                    if (pageIndex > pages) {
                        PDF.addPage();
                        yPosition = yPosition + 30 - (pageIndex - 1) * pdfHeight;

                        pages++;
                        PDF.setPage(pages);

                    }
                    else {
                        if (pageIndex > 1) {
                            yPosition = yPosition + 30 - (pageIndex - 1) * pdfHeight;
                        }
                        PDF.setPage(pageIndex)
                    }

                    PDF.addImage(FILEURI, 'JPEG', xPosition, yPosition, imgWidth, imgHeight, alias);
                    img.push(FILEURI);

                    if (img.length === requiredPages) {
                        let sourceName = "";

                        for (var k in vis.tab.source) {
                            if (k.toString() === "0") {
                                sourceName = vis.tab.source[k].name
                            }
                            else {
                                sourceName = sourceName + "-" + vis.tab.source[k].name;
                            }

                        }
                        let filename = new Date().toLocaleDateString() + "-" + vis.tabAlias + "-" + sourceName + ".pdf";
                        PDF.save(filename);
                        vis.displayPDFClass = false;
                        vis.maxDragPosition = vis.sourceMaxDragPosition;
                        vis.windowHeight = window.innerHeight;
                        vis.windowWidth = window.innerWidth;
                        setTimeout(() => {
                            vis.dashboardService.setChartChanges(vis.mainTab, vis.tabAlias, vis.columns)
                        }, 100)

                        vis.appService.stopSpin2();
                    }
                    else {
                        // PDF.addPage();
                    }


                })
            }
        }, 4000)

        console.log(performance.now() - performanceTime)

    }
}