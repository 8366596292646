import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { SharedInitializationService } from 'src/app/shared/shared-common-initialization.service';

@Component({
  selector: 'app-settings-start',
  templateUrl: './settings-start.component.html',
  styleUrls: ['./settings-start.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingsStartComponent implements OnInit {

  constructor(private sharedService: SharedInitializationService) { }

  ngOnInit() {
  }

   //this allows to get the help documentation for settings
   getHelpDocumentation() {
    let parameters = ["admin", "settings"];
    this.sharedService.showHelpDocumentation("Admin Settings");

    this.sharedService.getHelpDocumentation(parameters)
  }
}
