<div class="topics-dashboard">

  <div class="btn-group applyButtonGroup refresh" >
   
    <button *ngIf="selectedTopic === ''" class=" btn btn-primary btn-sm" (click)="applyRefresh()">
    <span>
      <i class="fa fa-redo"></i>
    </span> Refresh</button>
    <div >
      <i class="pi pi-question-circle piHelp" id="helpIcon" (click)="getHelpDocumentation()"></i>
    </div>
  </div>

  <div class="container ">

    <div class="row" *ngIf="selectedTopic === ''">

      <div class="col-md-4" *ngFor="let topic of topics; let index = index">
        <cardabel-topics-dashboard-topic-card [topic]="topic" [topicNames]="topicNames" [index] = "index" (click)="filterCard(topicNames[index], topic)"></cardabel-topics-dashboard-topic-card>
       
      </div>

      <!-- <div class="col-md-4">
        <mat-card class="example-card topics-dashboard-card" (click)="filterCard(topicNames[index])">
          <mat-card-header>
            <mat-card-title class="topics-dashboard-title">Add topic <span class="topics-dashboard-title-content">    </span></mat-card-title>
          </mat-card-header>
          <mat-card-content class="topics-dashboard-add-content">
            <i class="fa fa-plus"></i>
            </mat-card-content>
            <div class="row">
              <div class="col-md-12 topics-dashboard-progress-bar">
                <div class="topics-dashboard-progress-bar-content" [style.minWidth]="0+'%'" [style.maxWidth]="100+'%'"></div>
              </div>
            </div>
            </mat-card>
      </div> -->
    </div>
   
  </div>
  <div class="row" *ngIf="selectedTopic != ''">
    <cardabel-topics-dashboard-item [selectedTopic]="selectedTopic" [selectedTopicData]="selectedTopicData"  [topicNames]="topicNames">
    </cardabel-topics-dashboard-item>
  </div>
</div>