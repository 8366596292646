import { Component, OnInit, NgModule, Input, ViewEncapsulation, ViewChild, ElementRef, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule, FormControl } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ChartDataViewModule } from '../chart-data-view/chart-data-view.component';
import { CardabelListBoxModule } from '../cardabel-listbox/cardabel-listbox.component';
import { CardabelSelectionListModule } from '../cardabel-selection-list/cardabel-selection-list.component';

@Component({
  selector: 'app-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ChartsComponent implements OnInit {

  @Input() attributes: any[] = [];
  @Input() attributesDataView: any[];
  @Input() attributesChartData: any[];
  @Input() detailData: any[];
  @Input() detailTableData: any[];
  @Input() visible: boolean;
  @Input() active: boolean;
  @Input() selectedTable: any; // json structure
  @Input() numberRecords: number;
  @Input() param: string;
  @ViewChild('dv') dv;

  AttrName: any[] = []

  sourceAttributes: any[];
  allShownButton: boolean = true;
//  tableNumberRecords:number=0;

  constructor() { }

  ngOnInit() {
    console.log(this.attributes)
    if (this.attributes) {
      let attributes = JSON.parse(JSON.stringify(this.attributes))
        for (var i  in attributes) {
          if (attributes[i].AttrName === "COMMON") {
            attributes.splice(+i,1);
            break;
          }
        }
      this.sourceAttributes = JSON.parse(JSON.stringify(this.attributes))
    }
  
    /*if (this.selectedTable != undefined) {
      this.tableNumberRecords=this.selectedTable.NbRecords;

    }
    console.log(this.tableNumberRecords);
    */
  }

  ngOnChanges(changes: SimpleChanges)  {
    if (changes.attributes) {
      if (changes.attributes.currentValue) {
        let attributes = JSON.parse(JSON.stringify(this.attributes))
        for (var i  in attributes) {
          if (attributes[i].AttrName === "COMMON") {
            attributes.splice(+i,1);
            break;
          }
        }
        this.sourceAttributes = JSON.parse(JSON.stringify(attributes));
        this.attributesDataView = [];
      }
    }
  }

  changeChartAttributes(event) {
 //   console.log(event);
    this.attributesDataView = event.value;
  }

  public returnAttributes() {
   return this.dv.returnAttributes();
  }

  filterActive(param) {
    this.allShownButton = !this.allShownButton;
    if (param === "all") {
      this.sourceAttributes = JSON.parse(JSON.stringify(this.attributes))
    }
    else {
      this.sourceAttributes = this.attributes.filter((item => {
        if (item.AttrStatus === "Active") {
          return item;
        }
      }))
    }
  }
 

}

@NgModule({
  imports:[
   CommonModule, ReactiveFormsModule,FormsModule, ChartDataViewModule, CardabelListBoxModule, CardabelSelectionListModule
  ],
  exports: [RouterModule, ChartsComponent, ReactiveFormsModule,FormsModule, ChartDataViewModule, CardabelListBoxModule], 
  declarations: [ChartsComponent],
  providers: [
      
  ]
})
export class ChartsModule {

}

