import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { catchError } from "rxjs/operators";
import { AppService } from "src/app/app.service";
import { CustomHttpParamEncoder } from "src/app/shared/custom-http-param-encoder";
import { SharedGeneralFunctionsService } from "src/app/shared/shared-general-functions.service";

@Injectable()
export class AnalysisGaussService {
    constructor(private appService: AppService,
        private httpClient: HttpClient,
        private sharedFunctionService: SharedGeneralFunctionsService) {
    }

    webUrl = this.appService.getWebUrl();

    //this is for the gaussian menu, it gives the total number of anomalies
    gaussAnomaliesChange: { [index: string]: Subject<any> } = {}
    //this is for the gaussian chart, it allows to automatically update the chart
    gaussChartDataChange: { [index: string]: Subject<any> } = {}
    //init variables for 3D chart
    WFdataplot1: any = {};
    WFdataplot2: any = {};
    WFerrorScatter: any = {};
    //this is called in analysis grid menu to update cells that are anomalous
    alphaChange: { [index: string]: Subject<number> } = {}
    //init variables for 3D chart
    WFdatax: any = {};
    WFdatay: any = {};
    WFdatacolor: any = {};

    colorArray = this.appService.gauss_scatter_plot_color_scheme;
    methodArray = this.appService.gauss_scatter_plot_method_scheme;

    gaussPredictClassesChange: { [index: string]: Subject<any> } = {};

    initTabChanges(tabs) {
        for (var i in tabs) {
            this.alphaChange[tabs[i].name] = new Subject<number>();
            this.gaussAnomaliesChange[tabs[i].name] = new Subject<any>();
            this.gaussChartDataChange[tabs[i].name] = new Subject<any>();
            this.WFdataplot1[tabs[i].name] = [];
            this.WFdataplot2[tabs[i].name] = [];
            this.gaussPredictClassesChange[tabs[i].name] = new Subject<any>();
        }
    }


    //computes the number of records and the number of anomalies (3sigma) in the gaussian table
    //called in analysis menu gauss
    //getGaussStatsCount(workflow, source, segAttribute, table, gaussName, gaussIsMultiUni) {
    getGaussStatsCount(workflow, table, gaussName, gaussIsMultiUni, predictionMode, tab) {

        const url = this.webUrl;
        const webservice = "StatGaussCountTables";
        const completeUrl = url + webservice;

        const headers = this.appService.getHeaders();

        //Create new HttpParams */
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("wfName", workflow)
            .set('segAttrValue', table)
            .set('subTableName', gaussName)
            .set('gaussIsMultiUni', gaussIsMultiUni)
            .set('predictionMode', predictionMode);

        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(
                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {

                response = JSON.parse(response);
                if (response.statusCode > -1) {
                    this.gaussAnomaliesChange[tab].next(response.tableRows);
                }
                else {
                    this.appService.showMessage('Error', response.statusText);
                }
                this.appService.stopSpin();
            }, (error) => {
                this.appService.showMessage('Error', error.statusText);
                this.appService.stopSpin();
            });
    }

    // get the prediction results
    getGaussStatsCountClasses(workflow, segAttributeValue, subtableName, dataSetName, tab) {

        const url = this.webUrl;
        const webservice = "StatGaussCountClasses";
        const completeUrl = url + webservice;

        const headers = this.appService.getHeaders();

        //Create new HttpParams
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("wfName", workflow)
            .set("segAttrValue", segAttributeValue)
            .set('subTableName', subtableName)
            .set("dataSet", dataSetName)

        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(
                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {

                response = JSON.parse(response);
                if (response.statusCode > -1) {

                    this.gaussPredictClassesChange[tab].next(response.tableRows);
                }
                else {
                    this.appService.showMessage('Error', response.statusText);
                }
            }, (error) => {
                this.appService.showMessage('Error', error.statusText);
            });
    }
    //sets the value of alpha
    // called in analysis menu gauss subtable selected and is automatically updated in grid
    setAlphaValue(alpha, tab) {
        this.alphaChange[tab].next(alpha);
    }


    //computes the histogram and the probability of measures according to a specific Gaussian fit
    //called in analysis grid component
    prepareGaussChartData(workflow, table, gaussName, gaussMeasure, gaussMeasures, nbbins, dynSegAttrValue, gaussIsMultiUni, tab) {

        if ((gaussMeasure != null) && (gaussMeasure.AttrName !== undefined)) {
            var preferred = 0;

            for (var i = 0; i < gaussMeasures.length; i++) {
                if (gaussMeasures[i].AttrName === gaussMeasure.AttrName) {
                    preferred = i;
                }
            }
            var gaussMultiAttrValue = "";
            if (gaussIsMultiUni) {
                if (gaussMeasure != null) {
                    gaussMultiAttrValue = gaussMeasure.AttrName;
                }
                else {
                    gaussMultiAttrValue = gaussMeasures[0].AttrName;
                }
            }
            this.getGaussChartData(workflow, table, 'Gauss', 'normal', gaussName, '{"AttrName":["' + gaussMeasure.AttrName + '"]}', nbbins, preferred, dynSegAttrValue, gaussIsMultiUni, gaussMultiAttrValue, tab)
        }
    }



    //this gets the data for the gaussian chart and the gaussian 3D plot
    //called in analysis service
    getGaussChartData(workflow, table, mode, proMode, gaussName, varList, nbbins, varpreferred, dynSegAttrValue, gaussIsMultiUni, gaussMultiAttrValue, tab) {

        const url = this.webUrl;
        const webservice = "StatGaussTables";
        const completeUrl = url + webservice;

        const headers = this.appService.getHeaders();

        this.appService.startSpin();
        //Create new HttpParams */
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("wfName", workflow)
            .set('segAttrValue', table)
            .set('proMode', proMode)
            .set('subTableName', gaussName)
            .set('varList', varList)
            .set('nbbins', nbbins)
            .set('preferred', varpreferred)
            .set('dynSegAttrValue', dynSegAttrValue)
            .set('gaussIsMultiUni', gaussIsMultiUni)
            .set('gaussMultiAttrValue', gaussMultiAttrValue);

        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(
                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {

                response = JSON.parse(response);
                if (response.statusCode > -1) {
                    this.setGaussDataplot1and2(response.tableRows2, nbbins, tab)
                    this.gaussChartDataChange[tab].next(response.tableRows2);
                }
                else {
                    this.appService.showMessage('Error', response.statusText);
                }
                this.appService.stopSpin();
            }, (error) => {
                this.appService.showMessage('Error', error.statusText);
                this.appService.stopSpin();

            });
    }

    //this sets and formats the data for gaussian line charts once the data has arrived
    //Called in analysis grid component and gauss param component in workflow
    setGaussChartData(data2) {

        let data = [];

        for (var j in data2) {
            var newdata2 = {
                'Categorie': '',
                'col': 0,
                'minBucket': 0,
                'maxBucket': 0,
                'Max': 0,
                'Min': 0,
                'BinCount': 0,
                'ProbaBelowAlpha': 0,
                'Prob': 0,
            };
            var number = +j;

            newdata2.Categorie = data2[j].binName;
            newdata2.col = data2[j].binCount;

            if ((number + 1) === data2.length) {
                newdata2.maxBucket = 0;
            }
            else {
                newdata2.maxBucket = data2[number].binName + (data2[number + 1].binName - data2[number].binName) / 2;
            }

            if ((number - 1) < 0) {
                newdata2.minBucket = 0;
            }
            else {
                newdata2.minBucket = data2[number].binName - (data2[number].binName - data2[number - 1].binName) / 2;
            }

            newdata2.BinCount = data2[j].binNormCount;
            if (data2[j].binAverageProba <= 0.0013) {

                newdata2.ProbaBelowAlpha = data2[j].binAverageProba;
            }
            else {
                newdata2.ProbaBelowAlpha = null;
            }

            newdata2.Prob = data2[j].binAverageProba;
            data.push(newdata2);
        }//end for data2

        return data;
    }

    //this formats the data for the 3D plot and then calls the corresponding data
    //called in analysis grid component
    prepareGauss3DData(workflow, table, gaussName, gaussMeasure, gaussMeasures, nbbins, dynSegAttrValue, gaussIsMultiUni, tab) {

        let attrname = [];
        for (var i in gaussMeasures) {
            attrname.push(gaussMeasures[i].AttrName)
        }
        var vartab = JSON.stringify(attrname)
        var varList = "{\"AttrName\":" + vartab + "}";
        // 3D preferred = 0;
        var preferred = 0;
        var gaussMultiAttrValue = "";
        if (gaussIsMultiUni) {
            if (gaussMeasure != null) {
                gaussMultiAttrValue = gaussMeasure;
            }
            else {
                gaussMultiAttrValue = gaussMeasures[0].AttrName;
            }
        }
        this.getGaussChartData(workflow, table, 'Gauss', 'normal', gaussName, varList, nbbins, preferred, dynSegAttrValue, gaussIsMultiUni, gaussMultiAttrValue, tab)

    }


    //this sets the first and second data plot for the 3D plot
    //called in analysis grid component
    setGaussDataplot1and2(gaussData, bin, tab) {
        let argsname = "";
        var data = [];
        var dataCategorie = [];
        var dataCategorieD2 = [];

        var datazNormCount = [];
        var datazProb = [];
        var datazCount = [];
        for (var j in gaussData) {
            var newdata = {
                Categorie: gaussData[j].binName,
                CategorieD2: gaussData[j].binNameD2,
                col: gaussData[j].binCount,
                Bar: gaussData[j].binNormCount,
                Prob: gaussData[j].binAverageProba
            };
            data.push(newdata)
        }

        data = data.sort(this.sharedFunctionService.compareCategorie);

        for (let d = 0; d < data.length; d++) {

            dataCategorie.push(data[d].Categorie);
            dataCategorieD2.push(data[d].CategorieD2);
            datazNormCount.push(data[d].Bar);
            datazProb.push(data[d].Prob);
            datazCount.push(data[d].col);
        }


        let dataz1 = [];
        let dataz2 = [];

        let k = 0;

        for (var i = 0; i < bin; i++) {
            let newz1 = [];
            let newz2 = [];

            for (let m = k; +m < bin + k; m++) {
                m = +m;
                newz1.push(datazProb[m]);
                newz2.push(datazNormCount[m]);
            }
            k = +k + bin;
            dataz1.push(newz1);
            dataz2.push(newz2);
        }

        let datax = [];

        let datay = Array.from(new Set(dataCategorie));
        datax = Array.from(new Set(dataCategorieD2)).sort(function (a, b) { return a - b; });

        this.WFdataplot1[tab] = [
            {
                z: dataz1,
                x: datax,
                y: datay,

                type: 'surface',
                showscale: false,
                colorscale: [
                    ['0.0', this.appService.gauss_3D_plot_color_scheme[0]],
                    ['1.0', this.appService.gauss_3D_plot_color_scheme[0]]
                ],
                visible: true
            }];
        this.WFdataplot2[tab] = [
            {
                z: dataz2,
                x: datax,
                y: datay,

                type: 'surface',
                showscale: false,
                colorscale: [
                    ['0.0', this.appService.gauss_3D_plot_color_scheme[1]],
                    ['0.07', this.appService.gauss_3D_plot_color_scheme[2]],
                    ['1.0', this.appService.gauss_3D_plot_color_scheme[2]]
                ],
                opacity: 0.9,
                visible: true
            }];

    }

    //this returns the first data plot for the 3D plot
    //called in analysis grid component
    getGaussDataplot1(tab) {
        return this.WFdataplot1[tab];
    }

    //this returns the second data plot for the 3D plot
    //called in analysis grid component
    getGaussDataplot2(tab) {
        return this.WFdataplot2[tab];
    }

    //this returns the layout for the 3D plot
    //called in analysis grid component
    getGaussLayout(title, gaussMeasures) {
        let yaxistitle = gaussMeasures[0].AttrName;
        let xaxistitle = gaussMeasures[1].AttrName;
        let WFlayout = {};

        WFlayout = {
            title: {
                text: title,
                font: {
                    family: 'sans-serif',
                    size: 14
                },
                xanchor: "left",
                x: 0.009,
                yanchor: "bottom",
                y: 0.97,
            },
            autosize: false,
            width: 700,// 700
            height: 600,//700
            paper_bgcolor: '#EAEAEA',
            font: {
                family: 'Courier New, monospace',
                size: 12,
                color: '#262626'
            },
            margin: {
                l: 0,
                r: 0,
                b: 0,
                t: 20
            },

            scene: {
                bgcolor: '#EAEAEA',
                xaxis: { title: xaxistitle },
                yaxis: { title: yaxistitle },
                zaxis: { title: 'proba' }
            }
        };
        return WFlayout;
    }
    //computes the histogram and the probability of measures according to a specific Gaussian fit
    //called in analysis grid component
    //getGaussChartData(workflow, source, segAttribute, table, mode, proMode, gaussName, varList, nbbins,varpreferred, dynSegAttrValue, gaussIsMultiUni,gaussMultiAttrValue) {
    prepareGaussScatterData(workflow, table, gaussName, gaussMeasure, gaussMeasures, dynSegAttrValue, gaussIsMultiUni, fieldID, tab) {

        gaussMeasure = gaussMeasures[0];
        if ((gaussMeasure != null) && (gaussMeasure.AttrName !== undefined)) {
            var preferred = 0;

            for (var i = 0; i < gaussMeasures.length; i++) {
                if (gaussMeasures[i].AttrName === gaussMeasure.AttrName) {
                    preferred = i;
                }
            }

            var gaussMultiAttrValue = "";
            if (gaussIsMultiUni) {
                if (gaussMeasure != null) {
                    gaussMultiAttrValue = gaussMeasure.AttrName;
                }
                else {
                    gaussMultiAttrValue = gaussMeasures[0].AttrName;
                }
            }

           this.getGaussScatterData(workflow, table, 'Gauss', 'normal', gaussName, gaussMeasures, preferred, dynSegAttrValue, gaussIsMultiUni, gaussMultiAttrValue, fieldID, tab)

        }


    }
    //this gets the data for the gaussian chart and the gaussian 3D plot
    //called in analysis service
    getGaussScatterData(workflow, table, mode, proMode, gaussName, gaussMeasures, varpreferred, dynSegAttrValue, gaussIsMultiUni, gaussMultiAttrValue, fieldID, tab) {

        const url = this.webUrl;
        const webservice = "StatGaussTablesScatter";
        const completeUrl = url + webservice;

        const headers = this.appService.getHeaders();

        let varList = JSON.stringify(gaussMeasures);
        this.appService.startSpin();
        //Create new HttpParams */
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("wfName", workflow)
            .set('segAttrValue', table)
            .set('subTableName', gaussName)
            .set('varList', varList)
            .set('preferred', varpreferred)
            .set('dynSegAttrValue', dynSegAttrValue)
            .set('gaussIsMultiUni', gaussIsMultiUni)
            .set('gaussMultiAttrValue', gaussMultiAttrValue);

        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(
                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {
                response = JSON.parse(response);
                if (response.statusCode > -1) {
                    this.setGaussDataplot1Scatter(response.tableRows, fieldID, gaussMeasures, tab);
                    this.setGaussDataplot2Scatter(response.tableRows2, tab);
                    this.setGaussErrorScatter(response.tableRows3, tab);

                    this.gaussChartDataChange[tab].next(response.tableRows);

                }
                else {
                    this.appService.showMessage('Error', response.statusText);
                }
                this.appService.stopSpin();
            }, (error) => {
                this.appService.showMessage('Error', error.statusText);
                this.appService.stopSpin();

            });
    }

    //this sets the scatter plot dots
    //called in analysis grid component and gauss-param
    setGaussDataplot1Scatter(gaussData, fieldID, gaussMeasures, tab) {
        let argsname = "";
        var data = [];

        var keys = Object.keys(gaussData[0]);
        var measure = [];

        if (gaussMeasures.length > 2) {
            for (var k in keys) {
                if ((keys[k] !== 'rank_anomaly') && (keys[k] !== fieldID)) {
                    let found = false;
                    for (var i = 0; i < gaussMeasures.length; i++) {
                        if (keys[k] == gaussMeasures[i].AttrName) {
                            found = true;
                            break;
                        }
                    }
                    if (!found) {
                        measure.push(keys[k]);
                    }
                }
            }
        }
        else {
            for (var k in keys) {
                if ((keys[k] !== 'rank_anomaly') && (keys[k] !== fieldID)) {
                    measure.push(keys[k]);
                }
            }
        }
        let nb_measures = measure.length;
        for (var j in gaussData) {
            gaussData[j]["color"] = this.getcolor(gaussData[j]['rank_anomaly']);
        }

        this.WFdataplot1[tab] = gaussData;
        this.WFdatax[tab] = measure[0];
        if (nb_measures == 2) {
            this.WFdatay[tab] = measure[1];
        }
        else {
            this.WFdatay[tab] = '';
        }

        this.WFdatacolor[tab] = 'color';
    }

    setGaussDataplot2Scatter(statgaussData, tab) {

        var keys = Object.keys(statgaussData[0]);
        let stats = {};
        var ano_keys = this.colorArray[0];
        //rank_anomaly and rank_number
        for (var a in ano_keys) {

            for (var i in statgaussData) {
                if (statgaussData[i]['rank_anomaly'] == a) {
                    stats[a] = statgaussData[i]['rank_number']
                }
            }
        }
        this.WFdataplot2[tab] = stats;

    }
    setGaussErrorScatter(error, tab) {
        let scatter_error = error[0]["scatter_error"];
        this.WFerrorScatter[tab] = scatter_error;
    }
    //this returns the first data plot for the scatter plot
    //called in analysis grid component and workflow gauss param 
    getGaussDatax(tab) {
        return this.WFdatax[tab];
    }
    //this returns the first data plot for the scatter plot
    //called in analysis grid component and workflow gauss param 
    getGaussDatay(tab) {
        return this.WFdatay[tab];
    }
    getGaussDatacolor(tab) {
        return this.WFdatacolor[tab];
    }
    getGaussErrorScatter(tab) {
        let error = this.WFerrorScatter[tab];
        error = (error * 100).toFixed(2);
        return error;
    }
    getcolor(anomaly) {
        //order = ['s', 'k', 'sk','g', 'gs', 'gk', 'gsk']
        if (anomaly == '') {
            anomaly = 'n';
        }
        return this.colorArray[0][anomaly];
    }
    getcolorArray() {
        return this.colorArray[0];
    }
    getmethodArray() {
        return this.methodArray[0];
    }
}