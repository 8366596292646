import { Injectable, ViewRef } from '@angular/core';
import * as d3 from 'd3'
import { min } from 'rxjs/operators';
import { SharedGeneralFunctionsService } from 'src/app/shared/shared-general-functions.service';
import { DashboardService } from '../../dashboard.service';

@Injectable()
export class DashboardInitTypesScalesService {

    constructor(private sharedGeneralService: SharedGeneralFunctionsService, private dashboardService: DashboardService) { }
    //this allows to init and reset data
    initData(vis) {
        vis.filteredData = vis.data;
        vis.headers = [];
        if (vis.data.length != 0) {
            let keys = Object.keys(vis.data[0]);
            for (var i in keys) {
                let newdata = { name: keys[i] };
                vis.headers.push(newdata)
            }
            vis.headers = vis.headers.sort((a, b) => a.name > b.name ? 1 : -1)
            vis.filteredHeaders = [...vis.headers];
        }

    }

    resetHeaders(vis) {

        if (vis.editColumn.type === "bar" || (vis.editColumn.type === "pie") || (vis.editColumn.type === "combo") || (vis.editColumn.type === 'bubble')) {
            vis.editColumn.xHeader = "";
            vis.editColumn.yHeader = "";
            if (vis.editColumn.dateBucketing) {
                if (vis.editColumn.dateBucketing.name) {
                    vis.editColumn.dateBucketing.name = "";
                }
            }

            if (vis.editColumn.datePeriod) {
                if (vis.editColumn.datePeriod.name) {
                    vis.editColumn.datePeriod.name = "";
                }
            }
        }
    }

    filterHeaders(filtervalue, param, vis) {

        let filteredHeaders = "filtered" + param;

        if (param === "Headers") {
            param = "headers";
        }

        if (filtervalue.target.value === null) {
            vis[filteredHeaders] = [...vis[param]]
        }
        else {
            vis[filteredHeaders] = vis[param].filter(item => item.name.toLowerCase().indexOf(filtervalue.target.value.toLowerCase()) > -1)
        }

    }

    getNumericHeaders(vis) {
        let numericHeaders = [];
        if (vis.data.length != 0) {
            let keys = Object.keys(vis.data[0]);
            for (var i in keys) {
                let value = vis.sharedGeneralService.getFirstValueNotNull(vis.data, keys[i]);
                // 23092022 if (!isNaN(value) && (value != null) && (value != "")) {
                if (!isNaN(value) && (value != null) && (value.toString() != "")) {
                    let newdata = { name: keys[i] };

                    numericHeaders.push(newdata)
                }

            }
        }
        numericHeaders = numericHeaders.sort((a, b) => a.name > b.name ? 1 : -1)
        vis.filterednumericHeaders = [...numericHeaders];

        return numericHeaders;
    }

    changeAggMethod(value, param, vis) {
        let aggMethod = 'aggMethod' + param;
        if ((value) && (value != '')) {
            vis.editColumn[aggMethod] = value
        }
        else {
            vis.editColumn[aggMethod] = "count";
            vis.editColumn.numericHeader = "";
        }
        if ((value != "count") && (value)) {
            vis.showNumericDimensions = true;
            vis.numericHeaders = this.getNumericHeaders(vis);

            if (vis.cd && !(vis.cd as ViewRef).destroyed) {
                vis.cd.detectChanges();
            }
            setTimeout(() => {
                this.checkAggMethod('aggMethod', vis);
            }, 10)

        }
        else {
            vis.showNumericDimensions = false;
            vis.editColumn.numericHeader = "";
            vis.numericHeader = [{ name: "" }];
            vis.changeColumn(100);
        }
    }

    checkAggMethod(param, vis) {
        let aggMethodChange = false;
        if (vis.editColumn.type === "line") {
            if (vis.editColumn.dimension != "") {
                if (vis.editColumn.dimension != "") {
                    if (!vis.dashboardInitTypesScalesService.isDimensionDate(vis.editColumn.dimension, vis.data, vis)) {
                        if (vis.editColumn.aggMethod != "count") {
                            aggMethodChange = true;
                        }

                    }
                }
                else {
                    aggMethodChange = true;
                }

            } else {
                aggMethodChange = true;
            }

        }

        if (aggMethodChange) {
            vis.editColumn.aggMethod = "count";

            if (param === "aggMethod") {
                vis.appService.showMessage("Warning", "Cannot select another aggregation method when the dimension is not a date for a line chart. Thank you.")
            }
        }

        vis.changeColumn(100);

    }

    getScaleValues(chartData, column, mainTab, dimension, aggMethod, numericHeader) {
        let [data, systems, removeDates] = this.dashboardService.formatDashboardData(chartData, column.dimension, column.allHeaders, column.xHeader, column.yHeader, aggMethod, dimension, numericHeader, column.dateBucketing.name, column.numericDecimal, column.datePeriod, column.datePeriodValue, column.datePeriodFixedDate, mainTab);
        let scaleValue = {minimum:0, maximum: 1};
        scaleValue.minimum = d3.min(data, function (d) { return d[dimension] })
        scaleValue.maximum = d3.max(data, function (d) { return d[dimension] })
     
        return scaleValue;
    }
    
    changeScale(axis, param, value, event, vis) {
        vis.columns[vis.index][axis].changed = value;
        if (value) {
            vis.columns[vis.index][axis][param] = event.target.valueAsNumber;
        }
        else {
            vis.columns[vis.index][axis]['minimum'] = undefined;
            vis.columns[vis.index][axis]["maximum"] = undefined;
        }
        vis.dashboardService.setChartChange(vis.mainTab, vis.tabAlias, vis.columns, vis.index, vis.index);
    }

    setScales(scales, vis, chartData) {
        for (var i in scales) {
            vis[scales[i].name] = scales[i].type
                .range([scales[i].range, 0])

            if (scales[i].scaleType === "band") {
                vis[scales[i].name]
                    .padding(0.5)

                vis[scales[i].name]
                    .domain(chartData.map(a => a[scales[i].variable]));
            }
            else if (scales[i].scaleType === "linear") {
                if (scales[i].name === "x") {
                    vis[scales[i].name] = scales[i].type
                        .range([0, scales[i].range])
                }
                this.getAxisValuesChanges(scales[i], vis, chartData)
            }
            else if (scales[i].scaleType === "time") {
                vis[scales[i].name] = scales[i].type
                .range([0, scales[i].range])

                vis[scales[i].name].domain([d3.timeDay.offset(d3.min(chartData, function (d) {
                    return d[scales[i].variable];
                }), -1), d3.timeDay.offset(d3.max(chartData, function (d) {
                    return d[scales[i].variable];
                }), +1)]);


            }

            this.getAxisCalls(scales[i], vis, chartData)
        }
    }

    getAxisValuesChanges(scale, vis, chartData) {
        let axisValues = scale.name + "AxisValues";
        if (vis[axisValues].changed) {
            let minimum, maximum;
            if (vis[axisValues].minimum) {
                minimum = vis[axisValues].minimum;
            }
            else {
                minimum =  d3.min(chartData, function (d) { return d[scale.variable] })
            }
            if (vis[axisValues].maximum) {
                maximum = vis[axisValues].maximum;
            }
            else {
                maximum =  d3.max(chartData, function (d) { return d[scale.variable] })
            }
            vis[scale.name].domain([minimum,maximum])
        }
        else {
            let margin = (d3.extent(chartData, function (d) { return d[scale.variable]; })[1] - d3.extent(chartData, function (d) { return d[scale.variable]; })[0]) / 5;
            if (scale.graphType === "bar" || vis.aggMethod === "count") {
                margin = 0;
            }
            let minX = Math.min(0, d3.min(chartData, function (d) { return d[scale.variable] }));
            if (minX === 0) {
                margin = 0;
            }

            vis[scale.name].domain([Math.min(0, d3.min(chartData, function (d) { return d[scale.variable] }) - margin),
            Math.max(0, d3.extent(chartData, function (d) { return d[scale.variable]; })[1] + margin)]);

        }
    }

    getAxisCalls(scale, vis, chartData) {
        let axisCall = scale.name + "AxisCall";
        let dividor = scale.dividor;



        if (scale.scaleType === "time") {
            var numberOfTicks = 5;
            if (chartData.length < 5 && chartData.length != 0) {
                numberOfTicks = chartData.length;
            }

            vis[axisCall] = scale.axisOrientation
                .tickFormat(vis.multiFormat)
                .ticks(numberOfTicks)
        }
        else {
            var numberOfTicks = 5;
            if (chartData.length < 5 && chartData.length != 0) {
                numberOfTicks = chartData.length;
            }

            vis[axisCall] = scale.axisOrientation
               .tickFormat(function (d, i) {
                    d = vis.dashboardAxisFormattingService.getTickFormat(d, vis)
                    if (scale.name != "x") {
                        d = vis.dashboardAxisFormattingService.getValueEllipsis(d, vis);
                    }

                    return i % dividor == 0 ? d : "";

                })
                .ticks(numberOfTicks)
        }

        vis[axisCall].scale(vis[scale.name]);
        if (vis[scale.axisName]) {
            vis[scale.axisName].transition(vis.t()).call(vis[axisCall]);
        }
        vis.dashboardAxisFormattingService.getAxisColors(scale, vis)
    }

    getVariableTypes(data, variable, vis) {
        let type = "notnumeric";

        if (data.length != 0) {
            let value = this.sharedGeneralService.getFirstValueNotNull(data, variable)

            if (this.isDimensionDate(variable, data, vis)) {
                type = "date";
            }
            else if ((typeof +value === "number") && (!isNaN(value))) {
                type = "numeric";
            }

        }

        return type;
    }

    getCoordinates(vis, d, param) {
       
        let value;
       
            value= vis[param](d.data[vis[param +"Variable"]]);

            if (vis[param+"Type"] === "notnumeric") {
                value = value +vis[param].bandwidth()/2;
            }
        

        return value
    }

    isDimensionDate(dimension, dateData, vis) {
        if (dateData) {
            if (dateData.length != 0) {
                let data = [...dateData.filter(item => item[dimension] != 'Null')]
                let i = 0;
                if(data.length ===0) {
                    return false;
                }
                if (data[0][dimension] === "Other") {
                    i = 1;
                }
                if (data[i][dimension] instanceof Date) {
                    if (new Date(data[i][dimension]).toString() != "Invalid Date") {
                        if (vis.editColumn) {
                            vis.editColumn.dateHeader = true;
                        }
                        return true;
                    }
                    else {
                        return false;
                    }
                }
                else if (typeof data[i][dimension] === "string") {
                    if (new Date(data[i][dimension]).toString() != "Invalid Date") {
                        if (vis.editColumn) {
                            vis.editColumn.dateHeader = true;
                        }
                        return true;
                    }
                    else {
                        return false;
                    }
                }
                else {
                    return false;
                }
    
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
       

    }
}