import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { catchError } from "rxjs/operators";
import { AppService } from "src/app/app.service";
import { CustomHttpParamEncoder } from "src/app/shared/custom-http-param-encoder";
import { WorkflowNameService } from "src/app/workflow/services/workflow-name.service";
import { AnalysisService } from "../analysis.service";

@Injectable()
export class AnalysisAggregateService {
  constructor(private appService: AppService,
    private httpClient: HttpClient,
    private workflowNameService: WorkflowNameService,
    private analysisService: AnalysisService
  ) {
  }

  webUrl = this.appService.getWebUrl();

  //init variables for aggregate details button and area view
  enableAggregateDetailsButtonChange: { [index: string]: Subject<boolean> } = {};

  viewAggregateDetailsChange: { [index: string]: Subject<boolean> } = {};
  viewAggregateDetails: any = {};
  viewAggregateDetailsDataChange: { [index: string]: Subject<any> } = {};
  aggregateAnomaliesChange: { [index: string]: Subject<any> } = {};

  newshowAggregateDetailsChange: { [index: string]: Subject<boolean> } = {};

  initTabChanges(tabs) {
    for (var i in tabs) {
      this.enableAggregateDetailsButtonChange[tabs[i].name] = new Subject<boolean>();
      this.viewAggregateDetailsChange[tabs[i].name] = new Subject<boolean>();
      this.viewAggregateDetails[tabs[i].name] = false;
      this.viewAggregateDetailsDataChange[tabs[i].name] = new Subject<any>();
      this.aggregateAnomaliesChange[tabs[i].name] = new Subject<any>();
      this.newshowAggregateDetailsChange[tabs[i].name] = new Subject<boolean>();
    }
  }

  //determines whether the view aggregate details button within analysis menu should be enable or not
  //called in analysis grid component
  enableViewAggregateDetailsButton(param, tab) {
    this.enableAggregateDetailsButtonChange[tab].next(param);
  }

/*
  //determines whether the view combinations within labelise selected area should be shown or not
  //called in analysis menu component and analysis labelise selected component
  showViewAggregateDetails(tab) {
    this.viewAggregateDetails[tab] = !this.viewAggregateDetails[tab];
    this.viewAggregateDetailsChange[tab].next(this.viewAggregateDetails[tab])
  }
*/
  //set the view aggregate details param to make sur the real value of view aggregate details is given
  //called in analysis grid component
  setViewAggregateDetails(param, tab) {
    this.viewAggregateDetails[tab] = param;
  };

  setnewshowViewAggregateDetails(param, tab) {

    this.newshowAggregateDetailsChange[tab].next(param);
    this.viewAggregateDetails[tab] = !this.viewAggregateDetails[tab];
    this.viewAggregateDetailsChange[tab].next(this.viewAggregateDetails[tab]);
  }

  setNewShowAggregateDetails(param, tab) {
    this.newshowAggregateDetailsChange[tab].next(param)
  }

  //analysis get aggregate drill 
  //called in analysis grid component when aggregation details is enabled
  getAnalysisAggregateDrill(workflow, table, subtable, startRec, endRec, selectedRow, tab) {

    const url = this.webUrl;
    const webservice = "AnalysisAggregateDrill";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();

    var aggregInUse = this.workflowNameService.getWorkflowSubTable(subtable, tab, "Aggregate")
    if (aggregInUse) {
      let varFilter = this.getAggregVarFilter(aggregInUse, selectedRow);
      let varFactor = this.getAggregVarFactor(aggregInUse, selectedRow);
      let varPeriod = this.getAggregVarPeriod(aggregInUse, selectedRow);
      let varCycleString = this.getAggregVarCycleString(aggregInUse, selectedRow);

      //Create new HttpParams */
      let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
        .set("wfName", workflow)
        .set("segAttrValue", table)
        .set("subTableName", subtable)
        .set("startRec", startRec)
        .set("endRec", endRec)
        .set("factorValue", varFactor)
        .set("listAggregAttr", varFilter)
        .set("listAggregTempKey", varPeriod)
        .set("paramCycle", varCycleString)

      return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
        .pipe(
          catchError(this.appService.handleError)
        )
        .subscribe((response: any) => {

          response = JSON.parse(response);
          if (response.statusCode > -1) {
            this.analysisService.gridDataDetails[tab] = [...response.tableRows];
            this.analysisService.gridDataDetailsChange[tab].next(response.tableRows);
            this.analysisService.gridTotalRecordsDetailsChange[tab].next(response.tableRows.length);
            if (response.tableRows.length == 0) {
              this.appService.showMessage('Success', response.statusText);
            }
          }
          else {
            this.appService.showMessage('Error', response.statusText);
          }
          this.appService.stopSpin();

        }, (error) => {
          this.appService.stopSpin();
          this.appService.showMessage('Error', error.statusText);

        });
    }
    else {
      this.appService.stopSpin();
      this.appService.showMessage('Error', 'There was an error in the application. Please reload');
    }
  }


  getAggregVarFilter(aggregInUse, selectedRow) {

    let varFilter;
    var varaggregValue = [];
    if (aggregInUse) {
      var varaggregAttr = aggregInUse.Steps.Aggreg.Attributes;

      for (var i in varaggregAttr) {
        let newdata = {
          field: varaggregAttr[i].AttrName,
          term: selectedRow[0][varaggregAttr[i].AttrName],
        };
        varaggregValue.push(newdata);
      }
    }


    if (varaggregValue.length > 0) {
      varFilter = JSON.stringify(varaggregValue);
    }
    else {
      varFilter = "[]";
    }

    varFilter = "{\"listOfFilters\":" + varFilter + "}";

    return varFilter;

  }

  getAggregVarFactor(aggregInUse, selectedRow) {
    var varfactor = "";
    if (aggregInUse) {
      if (selectedRow[0][aggregInUse.Steps.Aggreg.AggregBy])
        varfactor = selectedRow[0][aggregInUse.Steps.Aggreg.AggregBy];
    }

    return varfactor;
  }

  getAggregVarPeriod(aggregInUse, selectedRow) {

    var varTempKey = [];
    let newdataTK1 = {
      field: aggregInUse.Steps.Aggreg.TempKey.Date,
      value: ""
    };
    if (selectedRow[0][aggregInUse.Steps.Aggreg.TempKey.Date])
      newdataTK1.value = selectedRow[0][aggregInUse.Steps.Aggreg.TempKey.Date];
    else
      newdataTK1.value = "";
    varTempKey.push(newdataTK1);

    let newdataTK2 = {
      field: "",
      value: ""
    };
    if (aggregInUse.Steps.Aggreg.TempKey.Time)
      newdataTK2.field = aggregInUse.Steps.Aggreg.TempKey.Time;
    else
      newdataTK2.field = "";

    if (selectedRow[0][aggregInUse.Steps.Aggreg.TempKey.Time])
      newdataTK2.value = selectedRow[0][aggregInUse.Steps.Aggreg.TempKey.Time];
    else
      newdataTK2.value = "";
    varTempKey.push(newdataTK2);
    let varPeriod;
    if (varTempKey.length > 0)
      varPeriod = "{\"listOfTempKey\":" + JSON.stringify(varTempKey) + "}";
    else
      varPeriod = "{\"listOfTempKey\":" + varTempKey + "}";

    return varPeriod;

  }
  getAggregVarCycleString(aggregInUse, selectedRow) {
    var varCycle = [];

    let postfix = "";
    if (aggregInUse) {
      if (aggregInUse.Steps['Cycle'].Type === 'Netting') {
        postfix = '_NetSplit_';
      }
      if (aggregInUse.Steps['Cycle'].Type === 'Trading') {
        postfix = '_TradeSplit_';
      }
      if (postfix.length > 0) {
        let newdataC = {
          fileName: aggregInUse.AggregateName + postfix,
          nbCycle: selectedRow[0].nbCycle
        };

        varCycle.push(newdataC);
      }
    }
    let varCycleString;
    if (varCycle.length > 0)
      varCycleString = "{\"cycle\":" + JSON.stringify(varCycle) + "}";
    else
      varCycleString = "{\"cycle\":" + "[]" + "}";

    return varCycleString;
  }
}
