<div class="row">
  <div class="col-xs-12 col-md-12 col-sm-12">
    <i class="pi pi-question-circle piHelp" id="helpIcon" (click)="getHelpDocumentation()"></i>
  </div>
</div>
<div class="row">
  <div class="col-md-1"></div>
  <div class="col-md-10">
    <div class="btn-group cardabel-new-topic-btn-group">
      <button class="btn btn-primary btn-sm"
        [disabled]="!workflowsChanged||(topicName=== '')"
        (click)="applyCreateTopicData()">
        <span>
          <i class="fa fa-cog"></i>
        </span> Apply
      </button>
    </div>
  </div>
</div>

<div id="newTopicContainer">
  <div class="row margin-top-20">
    <div class="col-md-1"></div>
    <div class="col-md-5">
      <h5 class="topicTitle textAlignCenter">New Topic Name</h5>
    </div>
    <div class="col-md-5">
      <input autocomplete="off" type="text" [(ngModel)]="topicName" class="form-control">
    </div>
  </div>

  <div class="row margin-top-40">
    <div class="col-md-1"></div>
    <div class="col-md-5 textAlignCenter">
      <h5 class="topicTitle">Workflows</h5>
      <cardabel-selection-list id="workflowContainer"[attr.data-cy]="'admin-settings-newtopic-workflow-selection-list'"  [inputSelectedOptions]="selectedWorkflows" [options]="workflows" [height]="440" [title]="'workflows'" [optionLabel]="'WFName'"
      [multipleSelection]="true" (changeSelectedOptions)="selectedWorkflows = $event.value; changeWorkflow()"></cardabel-selection-list>
    </div>

    <div class="col-md-1"></div>
  </div>



</div>