<p-table id="settingsSharedDataTable" [responsive]="true" [value]="tableData" dataKey="{{groupField1}}"
[scrollable]="true" scrollHeight="480px" editMode="row">
<ng-template pTemplate="header">
  <tr>
    <th>{{title1}}</th>
    <th>{{title2}}</th>
  </tr>
</ng-template>
<ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-expanded="expanded" let-columns="columns"
  let-editing="editing">
  <tr [pEditableRow]="rowData" class="ui-widget-header"
    *ngIf="rowGroupMetadata[rowData[groupField1]].index === rowIndex">
    <td colspan="2">
      <a *ngIf="rowData[groupField1]!== ''">
        <div class="ui-g-1">
        <i id="arrow" *ngIf="!editing" [ngClass]="'fa fa-lg fa-fw fa-chevron-circle-right'" pInitEditableRow (click)="displaySecondRow[rowData[groupField1]] = !displaySecondRow[rowData[groupField1]];"></i>
        <i id="arrow" pSaveEditableRow *ngIf="editing" [ngClass]="'fa fa-lg fa-fw fa-chevron-circle-down'" (click)="displaySecondRow[rowData[groupField1]] = !displaySecondRow[rowData[groupField1]]" ></i>
      </div>
      <div class="ui-g-4">
        <span>{{rowData[groupField1]}}</span>
      </div>
      <div class="ui-g-1">
        <i pCancelEditableRow *ngIf="editing" [ngClass]="'fa fa-fw fa-trash trash'" (click)="onRowEditCancel(rowData,rowIndex)"></i>
      </div>
      </a>
      <a *ngIf="rowData[groupField1] === ''">       
          <div class="ui-g-1">
            <i id="plus" *ngIf="!editing" [ngClass]="'fa fa-lg fa-fw fa-plus-circle'" pInitEditableRow></i>
            <i id="plus" pSaveEditableRow *ngIf="editing" [ngClass]="'fa fa-lg fa-fw fa-check-circle'" (click)="onRowEditSave(rowData,rowIndex);"></i>
         </div>
          <div class="ui-g-4">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input autocomplete="off" class="form-control" pInputText type="text"
                  [(ngModel)]="rowData.myNewGroupField1" required>
              </ng-template>
              <ng-template pTemplate="output">
                {{rowData[groupField1]}}
              </ng-template>
            </p-cellEditor>
          </div>
          <div class="ui-g-1">
          <i pCancelEditableRow *ngIf="editing" [ngClass]="'fa fa-fw fa-trash trash'" (click)="onRowEditCancel(rowData,rowIndex)"></i>
        </div>
      </a>
    </td>
  </tr>

  <tr class="ui-widget-header" *ngIf="displaySecondRow[rowData[groupField1]]" >
    <td></td>
    <td colspan="1">
      <a>
        <div class="ui-g-1">
          <div *ngIf="rowData[groupField2]=== ''" >
            <i id="plus" *ngIf="!rowData['editing']" [ngClass]="'fa fa-lg fa-fw fa-plus-circle'" (click)="rowData['editing']=!rowData['editing']"></i>
            <i id="plus" *ngIf="rowData['editing']" [ngClass]="'fa fa-lg fa-fw fa-check-circle'" (click)="onRowEditSave2(rowData,rowIndex);rowData['editing']=!rowData['editing'];"></i>
        </div>
        </div>
      <div class="ui-g-10">

          <div *ngIf="(rowData[groupField2]=== '')&&rowData['editing']">
          <input autocomplete="off" class="form-control" pInputText type="text"
          [(ngModel)]="rowData.myNewGroupField2" required>
        </div>

        <span *ngIf="rowData[groupField2]!== ''">{{rowData[groupField2]}}</span>
      </div>

      <div class="ui-g-1" *ngIf="(rowData[groupField2]!== '')||rowData['editing']">
        <i [ngClass]="'fa fa-fw fa-trash trash'" (click)="onRowEditCancel2(rowData,rowIndex);rowData['editing']=!rowData['editing']"></i>
      </div>
    </a>
    </td>
  </tr>
</ng-template>

</p-table>