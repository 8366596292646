import { Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { ComponentRef, Directive, ElementRef, HostListener, Input, NgModule, Renderer2, TemplateRef } from '@angular/core';
import { take } from 'rxjs/operators';
import { CardabelTooltipComponent } from './cardabel-tooltip.component';

@Directive({
  selector: '[cardabelTooltip]'
})
export class CardabelTooltipDirective {


  @Input('cardabelTooltip') text = '';
  @Input('cardabelTooltipPosition') position = 'bottom';
  @Input('cardabelTooltipDisabled') disabled = false;
  @Input() contentTemplate: TemplateRef<any>;

  private _overlayRef: OverlayRef;
  private _tooltipInstance;
  private _mouseInTooltip: boolean = false;
  private _hasListeners: boolean = false;

  constructor(private _overlay: Overlay,
    private _overlayPositionBuilder: OverlayPositionBuilder,
    private _elementRef: ElementRef,
    private _r2: Renderer2) {
  }

  /*ngOnInit(): void {
    console.log(this.elementRef);
    console.log(this.position);
  

    if (this.position === 'after') {
      this.positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions([{
        originX: 'start',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'bottom',
        offsetX: this.elementRef.nativeElement.clientWidth,
        offsetY: 40,
      }]);
    }
    else if (this.position === 'before') {
       this.positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions([{
        originX: 'end',
        originY: 'top',
        overlayX: 'end',
        overlayY: 'bottom',
        offsetX: -this.elementRef.nativeElement.clientWidth,
        offsetY: 40,
      }]);
    }
    else if (this.position === 'bottom') {
       this.positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions([{
        originX: 'center',
        originY: 'bottom',
        overlayX: 'center',
        overlayY: 'top',
        offsetX: 0,
        offsetY: 0,
      }]);
    }
    

    console.log(this.positionStrategy)
    let positionStrategy = this.positionStrategy;
    this.overlayRef = this.overlay.create({ positionStrategy });
  } */

  /*@HostListener('mousemove')
  show() {
    this.overlayRef.detach();
    console.log(this.overlayRef)
    if (!this.disabled) {
      const tooltipRef: ComponentRef<CardabelTooltipComponent>
        = this.overlayRef.attach(new ComponentPortal(CardabelTooltipComponent));
      tooltipRef.instance.text = this.text;
      window.document.querySelector<any>('.cdk-overlay-container').style.zIndex = "30000"
    }
  }
  @HostListener('document:click', ['$event'])
  click() {
    this.overlayRef.detach();
  
    window.document.querySelector<any>('.cdk-overlay-container').style.zIndex = "1000"
  }

  @HostListener('mouseout')
  hide() {
    this.overlayRef.detach();
    window.document.querySelector<any>('.cdk-overlay-container').style.zIndex = "1000"

  } */

  ngOnInit() {
    if ((this.disabled)|| (this.text === "")) {
      return;
    } 
    this._overlayRef = this._overlay.create();
  }

  /**
   * This method will be called whenever mouse enters in the Host element
   * i.e. where this directive is applied
   * This method will show the tooltip by instantiating the McToolTipComponent and attaching to the overlay
   */
  @HostListener("mouseenter")
  show(e) {
    if (this._overlayRef && !this._overlayRef.hasAttached() && !this.disabled && (this.text != '')) {
      //set tooltip instance
      this._tooltipInstance = this._overlayRef.attach(
        new ComponentPortal(CardabelTooltipComponent)
      );
      this._overlayRef.updatePositionStrategy(this._overlayPositionBuilder
        .flexibleConnectedTo(this._elementRef)
        .withPositions([
          {
            originX: this.getOriginX(),
            originY: this.getOriginY(),
            overlayX: "start",
            overlayY: "top",
            offsetX: this.getOffsetX(),
            offsetY: this.getOffsetY()
          }
        ]));
      this._overlayRef.updatePosition();
      //set CustomToolTipComponenet content/inputs
      
        this._tooltipInstance.instance.text = this.text;
      
     
     // this._tooltipInstance.contentTemplate = this.contentTemplate;

      //render tooltip
   //   this._tooltipInstance!.view(0);

      //sub to detach after hide anitmation is complete
   /*   this._tooltipInstance
        .pipe(take(1))
        .subscribe(() => {
          this._overlayRef.detach();
        }); */
      if (!this._hasListeners) {
        this._hasListeners = true;
        //attach mouseleave listener to detach when mouseleave on tooltip
        this._r2.listen(this._overlayRef.overlayElement, "mouseleave", () => {
          //call hide function in this directive
          this._mouseInTooltip = false;
          this.hide();
        });

          //attach click listener to detach when click on tooltip
          this._r2.listen(this._overlayRef.overlayElement, "click", () => {
            //call hide function in this directive
            this._mouseInTooltip = false;
            this.hideClick();
          });
        this._r2.listen(this._overlayRef.overlayElement, "mouseenter", () => {
          //call hide function in this directive
          this._mouseInTooltip = true;
        });
      }
    }
  }
  /**
   * This method will be called when mouse goes out of the host element
   * i.e. where this directive is applied
   * This method will close the tooltip by detaching the overlay from the view
   */
  @HostListener("mouseleave")
  hide(buttonClicked = null) {
    if (buttonClicked)
      this._mouseInTooltip = false;
    setTimeout(() => {
      if (!this._mouseInTooltip) {
        if (this._tooltipInstance) {
          this._tooltipInstance.destroy;
        }
        if (this._overlayRef) {
          this._overlayRef.detach();
        }
      
      }

    }, 20);
  }

  @HostListener("click")
  hideClick(buttonClicked = null) {
    if (buttonClicked)
      this._mouseInTooltip = false;
    setTimeout(() => {
      if (!this._mouseInTooltip) {
        if (this._tooltipInstance) {
          this._tooltipInstance.destroy;
        }
        if (this._overlayRef) {
          this._overlayRef.detach();
        }
      
      }

    }, 20);
  }

  private getOriginX() {
    if (this.position === "bottom") {
      return 'center';
    }
    else if (this.position === "before") {
      return 'start';
    }
    else if (this.position === "after") {
       return 'end';
     
    }
    else {
      return 'center'
    }
  }
  private getOriginY() {
    if (this.position === "bottom") {
      return 'bottom';

    }
    else if (this.position === "before") {
      return 'top';
    }
    else if (this.position === "after") {
     return 'top';  
    }
    else {
      return 'top';
    }
  }
  private getOffsetX() {
    console.log(this._elementRef.nativeElement.clientWidth)
    if (this.position === "bottom") {
      return 0;
    }
    else if (this.position === "before") {
      return -this._elementRef.nativeElement.clientWidth- 200;
    }
    else if (this.position === "after") {
      return 5;
    }
  }
  private getOffsetY() {
   /* if (this._elementRef.nativeElement.getBoundingClientRect().bottom > 500)
      return -400;
    if (this._elementRef.nativeElement.getBoundingClientRect().bottom > 400)
      return -300;
    if (this._elementRef.nativeElement.getBoundingClientRect().bottom > 300)
      return -200;*/
    return 0;
  }
}

@NgModule({
  imports: [CommonModule],
  exports: [CardabelTooltipDirective],
  declarations: [CardabelTooltipDirective, CardabelTooltipComponent]
})
export class CardabelTooltipModule { }
